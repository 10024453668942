(function() {
    'use strict';

    angular.module('app.paperworkforms').controller('LetterformController',
        LetterformController);

    function LetterformController($scope, $state, $rootScope, $auth, helperService, paperworksService, $anchorScroll, $location, SweetAlert, $filter, $stateParams, toastr, userperiods, students, exportService, studentsService) {
        var vm = this;

        /* assign for property*/
        vm.showvalue = 'edit';
        vm.editsuccess = '';
        vm.viewsucces = '';
        vm.letter_type = '';
        vm.activeformname = '';
        vm.address = 'Address';
        vm.date = new Date();
        vm.student_name = 'Student Name';
        vm.sId = '';
        vm.teachername = $rootScope.user.first_name + ' ' + $rootScope.user.last_name;
        vm.grade = 'N/A';
        vm.gender = 'N/A';
        vm.periods = 'N/A';
        vm.showDetails = false;
        vm.student_id = '';
        vm.userActivity = [];
        vm.selectedStudents = '';

        vm.userPeriods = userperiods;
        vm.userSelectedPeriod = 0;
        vm.students = '';
        vm.searchstudent = '';
        vm.countChange = 0;
        vm.FirstName = '';
        vm.LastName = '';
        vm.preview = preview;
        vm.checkWindow = checkWindow;
        var timer = '';
        var childWindow;
        vm.previewicon = false;
        vm.paperworkfromClass = $stateParams.paperworkfromClass;
        
        // assign
        vm.periodname = '';
		vm.searchtext = '';
		vm.searchByClass = false;
		vm.isAllSelected = false;
		vm.selectassignStudent = '';
		vm.selectedPeriod_letter = '';
		vm.studentsId = [];
        vm.assignedTooltips = 'send not assign';
        
        //assign close
        
           

        vm.ismeridian = true;
        vm.hstep = 1;
        vm.mstep = 1;

        vm.addUser = addUser;


        function addUser(form) {
            form.$setPristine();
        }

        $rootScope.$on('assign_message', function(event, data) {

            //	toastr.success(data);
            vm.userActivity.push(data);
            vm.letterform.saved = true;
            //helperService.showAlert(data);
            if(vm.paperworkfromClass){
            	$state.go('classes.roaster');
            }else{
            $state.go('paperworks.createForm');
            }
        });


        $scope.$watch('LetterformCtrl.userSelectedPeriod', selectStudentByPeriod, false);



        vm.options = {
            height: 300,
            focus: true,

            toolbar: [
                // ['edit',['undo','redo']],
                ['headline', ['style']],
                ['style', ['bold', 'italic', 'underline']],
                ['fontface', ['fontname']],
                ['textsize', ['fontsize']],
                ['fontclr', ['color']],
                ['alignment', ['ul', 'ol', 'paragraph', 'lineheight']],
                ['height', ['height']]

            ],
        	styleWithSpan: false
        };


        vm.letterform = {
            formid: '',
            student_id: '',
            letter_type: '',
            date_of_incident: new Date(),
            location_of_incident: '',
            other_location: '',
            time_of_incident: new Date(),
            other_information: '',
            detention_assigned1: new Date(),
            detention_assigned2: new Date(),
            detention_assigned3: new Date(),
            detention_assigned4: new Date(),
            time_of_detention: new Date(),
            detention_room: '',
            description: '',
            category: '',
            type: '',
            saved: false

        };

        vm.reason_detention = {
            formid: '',
            student_id: '',
            letter_type: '',
            absense: '',
            excess_talking: '',
            failure_to_complete_classwork: '',
            lack_of_class_materials: '',
            mischieve: '',
            litering: '',
            disobedience: '',
            disrespect: '',
            tardiness: '',
            poor_attitude: '',
            disturbing_class: '',
            category: '',
            type: '',
            other_reason: '',
            additional_info: '',
            saved: false
        };

        /* assign for methods*/
        vm.activeIcon = activeIcon;
        vm.activeletter = activeletter;
        vm.assign_student = assign_student;
        vm.letterSubmit = letterSubmit;
        vm.getletterData = getletterData;
        vm.reset = reset;
        vm.initialize = initialize;
        vm.selectStudentByPeriod = selectStudentByPeriod;
        vm.afterselected = afterselected;
        vm.getStduentsWithuserClasses = getStduentsWithuserClasses;
        vm.toggleAllselected = toggleAllselected;
        vm.optionToggled = optionToggled;
        vm.assignSave = assignSave;

        /* document ready methods */
        activeIcon();
        activeletter();
        getletterData();
        getStduentsWithuserClasses();

        $location.hash('top'); 
        function getStduentsWithuserClasses(){
			 
			 studentsService.getallstudentbyUser('/api/students/studentsanduserclasses').then(function(data){
				 vm.students = data[0];
				 vm.userPeriods = data[1];
				 
				
			 });
		 }
        
        function toggleAllselected(){
			 angular.forEach(vm.students, function (student) {
          		 student.selected = vm.isAllSelected;
          	      });
			 vm.selectassignStudent =    $filter('filter')(vm.students, {selected: true});
		 }
		 
		 function optionToggled() {
	        		
		            vm.isAllSelected = vm.students.every(function (student) {
		              return student.selected;
		            });
	        	
		         vm.selectassignStudent =    $filter('filter')(vm.students, {selected: true});
	          }
        
        
        

        function preview() {
        	timer = setInterval(checkWindow, 500);
            var paperworkType = '';
            if ($state.params.formtype == 'DN' || $state.params.formtype == 'NT') {
                paperworkType = 'General';
            } else {
                paperworkType = 'Letters';
            }
            var winName = 'TeachersInTouch';
            var winURL = '/api/paperwork/preview';
            var windowoption = 'resizable=yes,height=600,width=800,location=0,menubar=0,scrollbars=1';
            var params = {
                'paperwork_type': paperworkType,
                'formName': vm.letterform.letter_type,
                'description': vm.letterform.description,
                'category': vm.letterform.category,
                'type': vm.letterform.type,
                'student_id':vm.student_id,
                'studentsId':JSON.stringify(vm.studentsId),
                'formid': vm.letterform.formid,
                'date_of_incident': vm.letterform.date_of_incident,
                'location_of_incident': vm.letterform.location_of_incident,
                'other_location': vm.letterform.other_location,
                'time_of_incident': vm.letterform.time_of_incident,
                'other_information': vm.letterform.other_information,
                'detention_assigned1': vm.letterform.detention_assigned1,
                'detention_assigned2': vm.letterform.detention_assigned2,
                'detention_assigned3': vm.letterform.detention_assigned3,
                'detention_assigned4': vm.letterform.detention_assigned4,
                'detention_room': vm.letterform.detention_room,
                'reason_detention': JSON.stringify(vm.reason_detention)
            };

            var form = document.createElement("form");
            form.setAttribute("method", "post");
            form.setAttribute("action", winURL);
            form.setAttribute("target", winName);
            for (var i in params) {
                if (params.hasOwnProperty(i)) {
                    var input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = i;
                    input.value = params[i];
                    form.appendChild(input);
                }
            }
            document.body.appendChild(form);
            childWindow =  window.open('', winName, windowoption);
            form.target = winName;
            form.submit();
            document.body.removeChild(form);
        }


        function checkWindow() {
    		if (childWindow && childWindow.closed) {
    	        window.clearInterval(timer);
    	       
    	        $scope.$apply(function(){    	        	
   	        	vm.previewicon = false;
   	    	     
   	        	});

    	    }
		}

        function activeIcon() {
            if ($state.params.type == 'edit') {
                vm.editsuccess = 'icon-success';
                vm.viewsucces = '';
                vm.showvalue = 'edit';
            }
            if ($state.params.type == 'view') {
                vm.viewsucces = 'icon-success';
                vm.editsuccess = '';
                vm.showvalue = 'preview';
            }

        }

        function afterselected(selected) {
            if (selected) {
                initialize();
                vm.selectedstudent = selected.originalObject;
                $rootScope.selectedStudentDet = selected.originalObject;
                vm.searchstudent = selected.originalObject.id;
                getletterData();
                selected.originalObject = null;
                vm.FirstName = vm.selectedstudent.first_name;
                vm.LastName = vm.selectedstudent.last_name;
            }

        }

        function activeletter() {
        	 vm.assignedTooltips = ($state.params.studentId ? 'Send' : 'send not assign');
            if ($state.current.name == 'paperworkforms.letterform') {
                //vm.activeform = $state.params.formtype;
                vm.letterform.letter_type = $state.params.formtype;
                vm.reason_detention.letter_type = $state.params.formtype;



                switch ($state.params.formtype) {
                    case 'WL':
                        vm.activeformname = 'Welcome Letter';
                        vm.letter_type = 'welcome';
                        vm.letterform.category = '';
                        vm.letterform.type = 1;
                        break;

                    case 'FL':
                        vm.activeformname = 'Failure Letter';
                        vm.letter_type = 'failure';
                        vm.letterform.category = '';
                        vm.letterform.type = 2;
                        break;

                    case 'DL':
                        vm.activeformname = 'Deficiency Letter';
                        vm.letter_type = 'deficiency';
                        vm.letterform.category = '';
                        vm.letterform.type = 2;
                        break;

                    case 'PL':
                        vm.activeformname = 'Positive Letter';
                        vm.letter_type = 'positive';
                        vm.letterform.category = '';
                        vm.letterform.type = 1;
                        break;

                    case 'CDL':
                        vm.activeformname = 'Caught Doing Good Letter';
                        vm.letter_type = 'caught-good';
                        vm.letterform.category = '';
                        vm.letterform.type = 1;
                        break;

                    case 'PNGL':
                        vm.activeformname = 'Promoted to Next Grade Letter';
                        vm.letter_type = 'promoted-next';
                        vm.letterform.category = '';
                        vm.letterform.type = 1;
                        break;

                    case 'SIL':
                        vm.activeformname = 'Substitute Instruction Letter';
                        vm.letter_type = 'substitute';
                        vm.letterform.category = '';
                        vm.letterform.type = 2;
                        break;

                    case 'FTPL':
                        vm.activeformname = 'Field Trip Permission Letter';
                        vm.letter_type = 'field-trip';
                        vm.letterform.category = '';
                        vm.letterform.type = 1;
                        break;

                    case 'DTL':
                        vm.activeformname = 'Detention Letter';
                        vm.letter_type = 'detention';
                        vm.letterform.category = '';
                        vm.letterform.type = 2;
                        break;

                    case 'CIL':
                        vm.activeformname = 'Conference Invitation Letter';
                        vm.letter_type = 'confernce';
                        vm.letterform.category = '';
                        vm.letterform.type = '';
                        break;

                    case 'DN':
                        vm.activeformname = 'Detention Notice';
                        vm.reason_detention.category = "";
                        vm.reason_detention.type = 2;
                        break;

                    case 'NT':
                        vm.activeformname = 'Note';
                        vm.letterform.category = '';
                        vm.letterform.type = '';
                        break;
                }
            }

        }

        function getletterData() {
        	 
        	if($stateParams.unsaveform){

        		 paperworksService.getipData('/api/paperwork/unsaveletter?letter_form_id=' + $stateParams.formId).then(function(response) {
        			 vm.letterform.formid = response.id; 
        			 vm.letterform.description = response.description;
        			 vm.letterform.category = (response.category)?response.category:'';
        			 vm.letterform.type = response.type ? response.type:'';
        			// alert($rootScope.previousState);
        			 vm.letterform.saved = true;
        			       
        		 });
        	}
        	else{
            vm.letterform.formid = $stateParams.formId ? $stateParams.formId : '';
            var url = '/api/paperwork/letter?letter_type=' + vm.letterform.letter_type;
            if ($state.params.studentId) {
                vm.student_id = $state.params.studentId;
                url = '/api/paperwork/letter?letter_type=' + vm.letterform.letter_type + '&student_id=' + vm.student_id + '&letter_form_id=' + $stateParams.formId;
            } else if (vm.searchstudent !== '') {
                vm.student_id = vm.searchstudent;
                vm.letterform.student_id = vm.searchstudent;
                vm.reason_detention.student_id = vm.searchstudent;
                url = '/api/paperwork/letter?letter_type=' + vm.letterform.letter_type + '&student_id=' + vm.student_id + '&letter_form_id=' + $stateParams.formId;
            }
            paperworksService.getipData(url).then(function(response) {

                vm.letterform.description = response.default_text;
                if(response.student_data !== null){
                	
                	vm.selectedStudents = [response.student_data];
                }
                if (response.student_name !== null) {
                    vm.student_name = response.student_name;
                    vm.sId = response.studentId;
                }
                if (response.address !== null) {
                    vm.address = response.address;
                }

                if (response.teacher !== null) {
                    vm.teachername = response.teacher;
                }
                if (response.grade !== null) {
                    vm.grade = response.grade;
                }
                if (response.gender !== null) {
                    vm.gender = response.gender;

                }
                if (response.period_detail !== null) {
                    vm.periods = response.period_detail;
                }

                if (response.form_data !== null && vm.student_id !== '') {

                    if (response.form_data.date_of_incident !== null || response.form_data.location_of_incident !== null || response.form_data.time_of_incident !== null ||
                        response.form_data.other_information !== null || response.form_data.detention_assigned1 !== null || response.form_data.detention_assigned2 !== null ||
                        response.form_data.detention_assigned3 !== null || response.form_data.detention_assigned4 !== null || response.form_data.time_of_detention !== null ||
                        response.form_data.detention_room !== null || response.form_data.description !== null) {

                        //vm.letterform.saved = true;

                        var d = new Date();
                        var time_of_detention = response.form_data.time_of_detention;
                        var time_of_detention_preview = "";

                        if (time_of_detention !== null) {
                            var array = time_of_detention.split(':');
                            d.setHours(array[0]);
                            d.setMinutes(array[1]);
                            time_of_detention = d;

                        } else {
                            d.setHours(0);
                            d.setMinutes(0);
                            time_of_detention = d;

                        }


                        var p = new Date();
                        var time_of_incident = response.form_data.time_of_incident;

                        if (time_of_incident !== null) {
                            var array2 = time_of_incident.split(':');
                            p.setHours(array2[0]);
                            p.setMinutes(array2[1]);
                            time_of_incident = d;
                        } else {
                            p.setHours(0);
                            p.setMinutes(0);
                            time_of_incident = p;
                        }


                        vm.letterform.date_of_incident = response.form_data.date_of_incident;
                        vm.letterform.location_of_incident = response.form_data.location_of_incident;
                        vm.letterform.other_location = response.form_data.other_location;
                        vm.letterform.time_of_incident = time_of_incident;
                        vm.letterform.other_information = response.form_data.other_information;
                        vm.letterform.detention_assigned1 = response.form_data.detention_assigned1 ? response.form_data.detention_assigned1 : new Date();
                        vm.letterform.detention_assigned2 = response.form_data.detention_assigned2 ? response.form_data.detention_assigned2 : new Date();
                        vm.letterform.detention_assigned3 = response.form_data.detention_assigned3 ? response.form_data.detention_assigned3 : new Date();
                        vm.letterform.detention_assigned4 = response.form_data.detention_assigned4 ? response.form_data.detention_assigned4 : new Date();
                        vm.letterform.time_of_detention = time_of_detention ? time_of_detention : new Date();
                        vm.letterform.detention_room = response.form_data.detention_room;
                        //if(vm.student_id !== ''){
                        vm.letterform.description = response.form_data.description;
                        vm.letterform.category = response.form_data.category;
                        vm.letterform.type = response.form_data.type;
                        vm.letterform.saved = true;
                        //}
                    }


                    if (response.form_data.absense === 1 || response.form_data.excess_talking === 1 || response.form_data.failure_to_complete_classwork === 1 ||
                        response.form_data.lack_of_class_materials === 1 || response.form_data.mischieve === 1 || response.form_data.litering === 1 || response.form_data.disobedience === 1 ||
                        response.form_data.disrespect === 1 || response.form_data.tardiness === 1 || response.form_data.poor_attitude === 1 || response.form_data.disturbing_class === 1 ||
                        response.form_data.other_reason !== null || response.form_data.additional_info !== null) {
                        vm.reason_detention.saved = true;

                        vm.reason_detention.absense = response.form_data.absense;
                        vm.reason_detention.excess_talking = response.form_data.excess_talking;
                        vm.reason_detention.failure_to_complete_classwork = response.form_data.failure_to_complete_classwork;
                        vm.reason_detention.lack_of_class_materials = response.form_data.lack_of_class_materials;
                        vm.reason_detention.mischieve = response.form_data.mischieve;
                        vm.reason_detention.litering = response.form_data.litering;
                        vm.reason_detention.disobedience = response.form_data.disobedience;
                        vm.reason_detention.disrespect = response.form_data.disrespect;
                        vm.reason_detention.tardiness = response.form_data.tardiness;
                        vm.reason_detention.poor_attitude = response.form_data.poor_attitude;
                        vm.reason_detention.disturbing_class = response.form_data.disturbing_class;
                        vm.reason_detention.category = response.form_data.category;
                        vm.reason_detention.type = response.form_data.type;
                        vm.reason_detention.other_reason = response.form_data.other_reason;
                        vm.reason_detention.additional_info = response.form_data.additional_info;

                    }
                }
            });
        	}
        }

        function letterSubmit(form, activity, formname) {
            var data = '';
            var flag = 1;
            var regEx = /^\d{4}-\d{2}-\d{2}$/;
            if (form == 'letterform') {
                data = vm.letterform;
            } else if (form == 'reason_detention') {
                data = vm.reason_detention;
            }



            if (form === 'reason_detention' && activity === 'Reason For Detention') {

                if (vm.reason_detention.other_reason === "" || vm.reason_detention.other_reason === undefined) {
                    flag = 0;
                    toastr.error('Other resson required');

                }
            }

            if (form === 'reason_detention' || (form === 'letterform' && activity === 'overview')) {
                if (vm.letterform.letter_type !== '') {

                    if (vm.letterform.date_of_incident === "" || vm.letterform.date_of_incident === undefined) {
                        flag = 0;
                        toastr.error('Date of incident required');

                    } else if (vm.letterform.location_of_incident === "" || vm.letterform.location_of_incident === undefined) {
                        flag = 0;
                        toastr.error('Location of incident required');

                    } else if (vm.letterform.detention_assigned1 === "" || vm.letterform.detention_assigned1 === undefined) {
                        flag = 0;
                        toastr.error('Date required');
                    } else if (vm.letterform.detention_assigned2 === "" || vm.letterform.detention_assigned2 === undefined) {
                        flag = 0;
                        toastr.error('Date required');
                    } else if (vm.letterform.detention_assigned3 === "" || vm.letterform.detention_assigned3 === undefined) {
                        flag = 0;
                        toastr.error('Date required');
                    } else if (vm.letterform.detention_assigned4 === "" || vm.letterform.detention_assigned4 === undefined) {
                        flag = 0;
                        toastr.error('Date required');
                    } else if (vm.letterform.detention_room === "" || vm.letterform.detention_room === undefined) {
                        flag = 0;
                        toastr.error('Detention room required');
                    }
                }
            }

            if (flag === 1) {
                paperworksService.saveActivity('/api/paperwork/letter/save', data).then(function(response) {
                    data.saved = true;
                    vm.letterform.formid = response;
                    vm.reason_detention.formid = response;
                    vm.userActivity.push(activity + ' form saved at ' + $filter('date')(new Date(), 'M/d/yy h:mm a'));
                    helperService.showAlert('successfuly submitted ' + activity + '.');
                });
            }

            addUser(formname);

        }

        function reset() {
            var data = {
                letter_type: vm.letterform.letter_type,
                formId: vm.letterform.formid
            };

            SweetAlert.confirm("Are you sure?", {
                    title: "Reset this Letter Form!",
                    confirmButtonText: "Yes, I am sure",
                })
                .then(function(isConfirm) {
                    if (isConfirm) {
                        paperworksService.saveActivity('/api/paperwork/letter/reset', data).then(function(response) {
                        	vm.selectedStudents = [];
                        	vm.studentsId = [];
                        	vm.searchtext = '';
                        	vm.searchstudent = '';
                        	vm.isAllSelected = false;
                        	 angular.forEach(vm.students, function (student) {
                          		 student.selected = false;
                          	      });
                            initialize();
                            paperworksService.getipData('/api/paperwork/letter?letter_type=' + vm.letterform.letter_type).then(function(response) {
                   			 vm.letterform.description = response.default_text;                  			 
                   			   
                   		 });
                            activeletter();
                            vm.assignedTooltips = 'send not assign';
                        });
                    }
                }, function(p) {

                });

        }

        function assign_student() {
            $scope.letterform.$setPristine();
           
            if(!vm.letterform.formid){
				 SweetAlert.success("", {
					 // imageUrl: "images/thumbs-up.jpg"
					  title: '',
					  text: "You cannot send this letter without first updating this section.  please click the update button below to update this section.",
					  type: "warning",
					  confirmButtonText: "Ok, Got it!"
                });
			 }else{
            
            helperService.letterassignModal('send-students-modal', 'md', vm.letterform.formid, vm.letterform.letter_type, vm.student_id, vm.letterform.description, vm.letterform.category, vm.letterform.type,  vm.activeformname,  vm.selectedStudents).then(function(response) {

                //toastr.success(data);
            });
			 }
            //vm.userActivity.push('Student assigned at ' +  $filter('date')(new Date(), 'M/d/yy h:mm a'));

        }
        
        function assignSave(){
        	vm.studentsId =[];
			/* if(!vm.letterform.formid){
				 SweetAlert.success("", {
					 // imageUrl: "images/thumbs-up.jpg"
					  title: '',
					  text: "You cannot send this letter without first updating this section. \n please click the update button below to update this section.",
					  type: "warning",
					  confirmButtonText: "Ok, Got it!"
                 });
			 }else{*/
			 
			 var selectedData = '';
			 var assign_message = '';
			 var selectStudents=[];
			 var letterform = {
	 					letter_form_id: vm.letterform.formid,
	 					letter_type : vm.letterform.letter_type,
						students : [],
						email_sent: '0',
					 	description : vm.letterform.description,
					 	categoty: vm.letterform.category,
					 	type: vm.letterform.type
					 	};

			 selectedData = $filter('filter')(vm.students, {selected: true});
			 

			 var i=0;
			 angular.forEach(selectedData, function(value) {
				 vm.studentsId.push(value.id);
				 letterform.students.push(value.id);
         		selectStudents[i]=value.fullname;
         		i++;
         		  
         	 });
			 
			 vm.selectedStudents = selectedData;
				 	
			 		paperworksService.saveActivity('/api/paperwork/letter/assign',letterform).then(function(response){	


						if(vm.isAllSelected){
							assign_message=  "All of period " + vm.selectedPeriod_letter + ' have been assign to ' +  vm.activeformname ;
							vm.userActivity.push(assign_message);
						}
						else{
							var haveOrhas = (vm.selectassignStudent.length > 1)?' have':' has';
							assign_message=  selectStudents.toString() + '' +  haveOrhas + ' been assign to ' +  vm.activeformname ;
							vm.userActivity.push(assign_message);
						}

					toastr.success(assign_message);
					 vm.assignedTooltips = 'Send';
					});

			// } 
        }

        vm.scrollTo = function(id) {
            $location.hash(id);
            $anchorScroll();
        };


        function selectStudentByPeriod() {

          /*  if (vm.userSelectedPeriod === '') {
                vm.students = students;
            } else {
                var data = {
                    period: vm.userSelectedPeriod
                };
                studentsService.getStudentsByuserPeriod('/api/students/getstudentByuserPeriod', data).then(function(response) {
                    vm.students = response;
                });
            }*/
        }

        function initialize() {
        	 vm.student_name = 'Student Name';
        	 vm.sId = '';
             vm.grade = 'N/A';
             vm.gender = 'N/A';
             vm.periods = 'N/A';
        	
            vm.letterform.date_of_incident = new Date();
            vm.letterform.location_of_incident = '';
            vm.letterform.other_location = '';
            vm.letterform.time_of_incident = new Date();
            vm.letterform.other_information = '';
            vm.letterform.detention_assigned1 = new Date();
            vm.letterform.detention_assigned2 = new Date();
            vm.letterform.detention_assigned3 = new Date();
            vm.letterform.detention_assigned4 = new Date();
            vm.letterform.time_of_detention = new Date();
            vm.letterform.detention_room = '';
            vm.letterform.description = '';
            vm.letterform.category = '';
            vm.letterform.type = '';
            vm.letterform.saved = false;


            vm.reason_detention.absense = '';
            vm.reason_detention.excess_talking = '';
            vm.reason_detention.failure_to_complete_classwork = '';
            vm.reason_detention.lack_of_class_materials = '';
            vm.reason_detention.mischieve = '';
            vm.reason_detention.litering = '';
            vm.reason_detention.disobedience = '';
            vm.reason_detention.disrespect = '';
            vm.reason_detention.tardiness = '';
            vm.reason_detention.poor_attitude = '';
            vm.reason_detention.disturbing_class = '';
            vm.reason_detention.category = '';
            vm.reason_detention.type = 2;
            vm.reason_detention.other_reason = '';
            vm.reason_detention.additional_info = '';
            vm.reason_detention.saved = false;
        }
        // $scope.$watch('LetterformCtrl.letterform.description', checkActivity, true);  
    }

})();