(function () {
  'use strict';

  angular
    .module('app.setup-account')
    .controller('StudentSignUpSuccessController', StudentSignUpSuccessController);

  /* @ngInject */
  function StudentSignUpSuccessController($timeout,$scope,toastr,
    ClassesService,$rootScope,$state) {

    /* jshint validthis: true */
    var vm = this;
    //vm.VerifyAccessCode=VerifyAccessCode;

    vm.form = {
        accessCode : '', 
    };

    $timeout( function(){
            //$state.go('signup');
    window.open("https://www.goteachersintouch.com/","_self");        
    }, 5000 );


    
    
    
  }
})();