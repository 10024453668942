(function () {
  'use strict';

  angular
    .module('app')
    .controller('PrivateMessageController', PrivateMessageController);

  /* @ngInject */
  function PrivateMessageController($filter, $uibModalInstance, privateMessagesService, user, typeUser, typeCommunication,$rootScope, $scope, $interval,cannedMessagesService, messagesService, toastr) {
    /* jshint validthis: true */
    var vm = this;

    vm.user = user;
    vm.dialedPhone = '';
    vm.endCallbtn = false;
    vm.redialbtn = true;
    vm.settimer = '';

    vm.phones = [
      {
        id: 1,
        phone_number: user.officenumber,
        type: 'Office'
      },
      {
        id: 2,
        phone_number: user.mobilenumber,
        type: 'Mobile'
      },
    ];
    if (typeUser === 'vestedMember') {
      vm.phones.push({
        id: 3,
        phone_number: user.homenumber,
        type: 'Home'
      });
    }

    vm.form = {
      mode: typeCommunication,

      admin_id: undefined,
      vested_member_id: undefined,

      phone_id: undefined,

      topic: undefined,
      message: undefined,
    };
 
    vm.userBalance = ''; 
    vm.openCallModal = false;
    
    vm.phoneCall = phoneCall;
    vm.disconnect = disconnect;
    vm.checkCallStatus = checkCallStatus;
    vm.startTime = startTime;
    vm.timer = timer;
    vm.getUserBalance = getUserBalance;
    

    if (typeUser === 'admin') {
      vm.form.admin_id = user.id;
    } else {
      vm.form.vested_member_id = user.id;
    }

    if (typeCommunication == 3) {
      vm.form.email = user.email;
    }

    vm.send = send;
    vm.cancel = cancel;

    vm.showPhone = showPhone;

    getUserBalance();
    $scope.$watch('PrivateMessageCtrl.form.phone_id',function(newval, oldval){
    	
    	if(newval){
    		var dialednumber = $filter('filter')(vm.phones, {id: newval});
    		
    		vm.dialedPhone = dialednumber[0].phone_number;
    	
    	}
    });
    
    
    
    ////////////////////
    
    
    var second = 0;


    $interval(function() {
		if(vm.callSid){    			
			checkCallStatus();    			
		}	
		}, 5000);


    function startTime(){
    	if(second === 59){
    		//checkCallStatus();
    		second = 0;
    	}
    	second++;
    	timer();
    }
	
function timer(){    	
	vm.settimer =setTimeout(startTime, 1000);   	
}	
    

    function showPhone(phone) {
      return phone.phone_number + ' (' + phone.type + ')';
    }

    function send() {
      if (typeCommunication != 3 && vm.form.phone_id) {
        var phone = $filter('filter')(vm.phones, {id: vm.form.phone_id});
        if (phone.length) {
          vm.form.phone_number = phone[0].phone_number;
        }
      }
      $rootScope.modalerror=true;
      privateMessagesService.send(vm.form).then(function (response) {
        $rootScope.modalerror=false;
        $uibModalInstance.close();

        return response;
      });
    }
   
   var promise;
   var twilio; 
   var twilio_sid;

    
    Twilio.Device.disconnect(function (conn) {
        vm.endCallbtn = false;
        vm.redialbtn = true;
        twilio = null;
        getUserBalance();

    });
    
    Twilio.Device.error(function (error) {
        vm.endCallbtn = false;
        vm.redialbtn = true;
    });
    
    Twilio.Device.connect(function (conn) {
    	toastr.success('Thank you.  We are now connected to your speaker and microphone.');
    	  vm.openCallModal = true;
          vm.endCallbtn = true;
          vm.redialbtn = false;
          twilio_sid = conn.parameters.CallSid;  
          var addcallTrack = {'user_id':$rootScope.user.id, 'call_sid':twilio_sid};
          cannedMessagesService.checkCallduration('/api/add-user-call-track', addcallTrack).then(function(response){
          });
          
    });
    
    
    
    function checkStatus(sid)
    {
        promise = $interval(function() {
    		 cannedMessagesService.callPhoneNo('/api/call-status',{'callSid':sid}).then(function(response){
           });
    	}, 1000);
        
    }
    
    function phoneCall(callvalue){
        if(vm.dialedPhone){
            messagesService.getTwilioToken().then(function(response){
                Twilio.Device.setup(response,{ debug: true });
                var remainingCall = parseInt(vm.userBalance.call_balance - vm.userBalance.sended_call) * 60;
                var params = {"tocall":vm.dialedPhone, "timeLimit": remainingCall}; //10 in seconds
                twilio = Twilio.Device.connect(params);
            });
        }   
    }
    
    
    function disconnect(){
        Twilio.Device.disconnectAll();        
    }
    
    vm.closeCall = function() {
    	vm.openCallModal = false;
        Twilio.Device.disconnectAll();
        
    };
    
     
    function  checkCallStatus(){
    	
    	
    	var phoneData = {sid:vm.callSid, duration_time:second};
    	
    	cannedMessagesService.checkCallduration('/api/check-call-duration',phoneData).then(function(response){

			if(response.status === 'completed'){				
				$('#callModal').modal('hide'); 
				 Object.assign(phoneData, {call_finish:'finish'});
				cannedMessagesService.checkCallduration('/api/check-call-duration',phoneData).then(function(response){});
				vm.endCallbtn = false;
				vm.redialbtn = false;
				vm.callSid = '';
				clearTimeout(vm.settimer);
			}
			if(response.status === 'busy'){
				vm.endCallbtn = false;
				vm.redialbtn = false;
				vm.callSid = '';
				clearTimeout(vm.settimer);
			}
			
		});
      
    }
    

    function cancel() {
      $uibModalInstance.dismiss();
    }
    
    function getUserBalance(){
    	messagesService.getUserBalance('/api/email-sms-call-balance').then(function(response){
    		vm.userBalance = response;
    		
    	});
    }
  }
})();