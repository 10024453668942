(function() {
    'use strict';

    angular
        .module('app.classes')
        .controller('ShareClassroomSignupLinkController', ShareClassroomSignupLinkController);

    /* @ngInject */
    function ShareClassroomSignupLinkController($state,SweetAlert,helperService,toastr,FormData,$filter,$rootScope,ClassesService,$uibModalInstance,$scope,$uibModal) {
        /* jshint validthis: true */
        var vm = this;

        vm.cancel=cancel;
        vm.QrGeneralCode='';
        vm.QrAccessCode='';
        vm.QrImage='';
        vm.base_url='';
        vm.ResponseData='';
        vm.FormData=FormData;
        vm.PrintPreview=PrintPreview;
        vm.fullclassname=vm.FormData.period+" "+vm.FormData.period_letter+","+" "+vm.FormData.name;
        vm.success=0;
        ClassesService.getBarCode('api/classes/accesscode').then(function(data) {
            vm.ResponseData=data;
            vm.QrGeneralCode=data.generalAccessCode;
            vm.QrAccessCode=data.QrAccessCode;
            vm.QrImage=data.qrImageName;
            vm.base_url=data.base_url;
            //vm.QrImage=vm.QrImage.substr(1);

            vm.FormData.start_time = $filter('date')(vm.FormData.start_time, 'HH:mm:s');
            vm.FormData.end_time = $filter('date')(vm.FormData.end_time, 'HH:mm:s');
            vm.FormData.qr_code=data.QrAccessCode;
            vm.FormData.class_access_code=data.generalAccessCode;
            vm.FormData.qr_image_name=data.qrImageName;

            //console.log(data);

            var urlparemeter = 'api/classes/addewnclass';
            ClassesService.create(urlparemeter, vm.FormData).then(function(response) {
                //toastr.success('Class successfully submitted');
                vm.success=1;
                
            });

        });

        function PrintPreview()
        {
            if(vm.success===1)
            {
                var childWindow="";
                var winName = 'MyWindow';
                var winURL = 'api/classes/preview';
                var windowoption = 'resizable=yes,height=600,width=800,location=0,menubar=0,scrollbars=1';
                var params = {
                    'class_access_code': vm.FormData.class_access_code,
                };

                var form = document.createElement("form");
                form.setAttribute("method", "post");
                form.setAttribute("action", winURL);
                form.setAttribute("target", winName);
                for (var i in params) {
                    if (params.hasOwnProperty(i)) {
                        var input = document.createElement('input');
                        input.type = 'hidden';
                        input.name = i;
                        input.value = params[i];
                        form.appendChild(input);
                    }
                }
                document.body.appendChild(form);
                childWindow = window.open('', winName, windowoption);
                form.target = winName;
                form.submit();
                document.body.removeChild(form);
            }
        }

       
        function cancel()
        {
            $uibModalInstance.close();
            if(vm.success===1)
            {
                var formtype = (vm.FormData.group_type == 1 ? 'class' : 'club');
                helperService.showAlert('You have Successfully creted ' + vm.FormData.name + " " + formtype);
                $rootScope.user.has_class = true;
                        
                SweetAlert.confirm("Would you like to add another " + formtype + " ?", {
                    title: "Confirm!",
                    cancelButtonText: "Yes, I want add another "+formtype,
                    confirmButtonText: "No,please cancel",    
                })
                .then(function(isConfirm) {
                    if (isConfirm) 
                    {
                        vm.FormData="";
                        //$state.reload();
                        $state.go('dashboard.main');
                    }
                    else 
                    {

                        vm.FormData="";
                        $state.reload();
                    }
                }, function(p) {


                });

            }
            
        }

        

    }
})();