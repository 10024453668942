(function () {
  'use strict';

  angular
    .module('app.communications-analytics')
    .config(function ($stateProvider) {
      let analyticsPeriods = [
        {
          title: 'This Week',
          slug: 'week',
          get start() {
            return moment().subtract(6, 'days');
          },
          get end() {
            return moment();
          },
        },
        {
          title: 'Last 2 Weeks',
          slug: 'last_2_week',
          get start() {
            return moment().subtract(13, 'days');
          },
          get end() {
            return moment();
          },
        },
        {
          title: 'This Month',
          slug: 'month',
          get start() {
            return moment().subtract(1, 'month').add(1, 'day');
          },
          get end() {
            return moment();
          },
        },
        {
          title: 'This School Year',
          slug: 'year',
          get start() {
            let now = this.end,
              augustCurrentYear = moment(now).month(7).date(1),
              august;

            if (now < augustCurrentYear) {
              august = moment(`${now.year() - 1}-08-01`);
            } else {
              august = augustCurrentYear;
            }

            return august;
          },
          get end() {
            return moment();
          },
        },
        {
          title: 'All Time',
          slug: 'all_time',
          get start() {
            return moment().subtract(1, 'years');
          },
          get end() {
            return moment();
          },
        },
        {
          title: 'Custom Range',
          slug: 'custom_range',
          range: {
            startDate: moment(),
            endDate: moment().add(1, 'day'),
          },
          get start() {
            return this.range.startDate;
          },
          get end() {
            return this.range.endDate;
          },
        }
      ];

      // Now set up the states
      $stateProvider
        .state('communications-analytics', {
          parent: 'base',
          abstract: true,
          url: '/communications-analytics',
          templateUrl: '/app/partials/communications-analytics/layout.html',
          data: {
            permissions: {
              //only: ['analytics'],
              redirectTo: 'addnewclass',
            },
          },
        })
        .state('communications-analytics.quickview', {
          url: '/quickview',
          templateUrl: '/app/partials/communications-analytics/quickview/quickview.html',
          controller: 'QuickviewController',
          controllerAs: 'QuickviewCtrl',
          resolve: {
            teacherTypes: function (teacherTypesService) {
              return teacherTypesService.all().then(function (types) {
                types.unshift({
                  title: 'All Teacher Subjects',
                  id: undefined,
                });

                return types;
              });
            },
            grades: function (gradesService) {
              return gradesService.all().then((grades) => {
                grades.unshift({
                  title: 'All Grades',
                  id: undefined,
                });

                return grades;
              });
            },
            periods: function () {
              return analyticsPeriods;
            },
          }
        })
        .state('communications-analytics.subject-grade-comparisons', {
          url: '/subject-grade-comparisons',
          templateUrl: '/app/partials/communications-analytics/subject-grade-comparisons/subject-grade-comparisons.html',
          controller: 'SubjectGradeComparisonsController',
          controllerAs: 'SubjectGradeComparisonsCtrl',
          resolve: {
            teacherTypes: function (teacherTypesService) {
              return teacherTypesService.all();
            },
            grades: function (gradesService) {
              return gradesService.all();
            },
            categories: function (cannedMessagesService) {
              return cannedMessagesService.categories().then(function (categories) {
                categories.unshift({
                  title: 'All Categories',
                  id: undefined,
                });

                return categories;
              });
            },
            types: function (cannedMessagesService) {
              return cannedMessagesService.types().then(function (types) {
                types.unshift({
                  title: 'All',
                  id: undefined,
                });

                return types;
              });
            },
            periods: function () {
              return analyticsPeriods;
            },
          }
        })
        .state('communications-analytics.teacher-comparisons', {
          url: '/teacher-comparisons',
          templateUrl: '/app/partials/communications-analytics/teacher-comparisons/teacher-comparisons.html',
          controller: 'TeacherComparisonsController',
          controllerAs: 'TeacherComparisonsCtrl',
          resolve: {
            teacherTypes: function (teacherTypesService) {
              return teacherTypesService.all().then(function (types) {
                types.unshift({
                  title: 'All Teacher Subjects',
                  id: undefined,
                });

                return types;
              });
            },
            grades: function (gradesService) {
              return gradesService.all().then((grades) => {
                grades.unshift({
                  title: 'All Grades',
                  id: undefined,
                });

                return grades;
              });
            },
            periods: function () {
              return analyticsPeriods;
            },
          }
        })
        .state('communications-analytics.teacher-details', {
          url: '/teacher-details',
          templateUrl: '/app/partials/communications-analytics/teacher-details/teacher-details.html',
          controller: 'TeacherDetailsController',
          controllerAs: 'TeacherDetailsCtrl',
          resolve: {
            teachers: function (teachersService) {
              return teachersService.all();
            },
            categories: function (cannedMessagesService) {
              return cannedMessagesService.categories().then(function (categories) {
                categories.unshift({
                  title: 'All',
                  id: undefined,
                });

                return categories;
              });
            },
            types: function (cannedMessagesService) {
              return cannedMessagesService.types().then(function (types) {
                types.unshift({
                  title: 'All',
                  id: undefined,
                });

                return types;
              });
            },
            periods: function () {
              return analyticsPeriods;
            },
          }
        });
    });

})();