(function () {
  'use strict';

  angular
    .module('app')
    .service('statesService', statesService);

  /* @ngInject */
  function statesService($resource) {
    /* jshint validthis: true */
    let vm = this;

    let State = $resource('/api/states');

    vm.all = all;

    /////////////

    function all() {
      return State.query().$promise;
    }
  }
})();
