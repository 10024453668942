(function (){
	'use strict';
	
	angular
		.module('app.directives')
		.directive('customSubmenuDirective',customSubmenuDirective);
	
	function customSubmenuDirective($timeout){
		
    return {
        restrict: 'EA',
        link: function ($scope, element, attrs) {

            var toggleButton = $(element).find('.dropdown-toggle');
            var submenu = $(element).find('.dropdown-menu');

            /* 
             * handle keydown on the submenu itself - if we arrow up from the first element or down from the last,
             * close submenu
             */
            submenu.keydown(function (event) {
                if (!(event.keyCode === 38 || event.keyCode === 40)) return;

                var links = $(element).find('li:not(.divider):not(.disabled) a');

                if (event.keyCode === 38 && event.target == links[0]) {
                    // first submenu item - up arrow - close submenu, focus toggle button, stop propagation
                    $(element).removeClass('open');
                    submenu.attr('aria-hidden', true);
                    toggleButton.focus();
                    event.stopPropagation();
                    event.preventDefault();
                } else if (event.keyCode === 40 && event.target == links[links.length - 1]) {
                    // last submenu item - down arrow - close submenu, focus toggle button, stop propagation
                    $(element).removeClass('open');
                    submenu.attr('aria-hidden', true);
                    toggleButton.focus();
                    event.stopPropagation();
                    event.preventDefault();
                }
            });

            /* 
             * handle keydown on toggle button - space toggles submenu visibility, arrows navigate outer menu
             */
            toggleButton.keydown(function (event) {
                if (event.keyCode === 32) { // space bar - open/close submenu
                    if ($(element).hasClass('open')) {
                        $(element).removeClass('open');
                        submenu.attr('aria-hidden', true);
                    } else {
                        $(element).addClass('open');
                        submenu.attr('aria-hidden', false);
                    }
                    event.stopPropagation();
                    event.preventDefault();
                } else if (event.keyCode === 40) { // down arrow
                    if (!$(element).hasClass('open')) {
                        // even though the submenu isn't open, the bootstrap dropdown directive will try to focus the 
                        // hidden submenu items, so intercept the keydown and focus the next outer menu item instead
                        var nextSibling = $(element).nextAll('li:not(.divider):not(.disabled):visible');
                        if (nextSibling && nextSibling[0]) {
                            var nextSiblingLink = $(nextSibling[0]).find('a');
                            if (nextSiblingLink && nextSiblingLink[0]) {
                                // focus next menu item
                                $(nextSiblingLink[0]).focus();
                                
                                // while we're at it, let's attach a handler to that next link, telling it to focus this
                                // one when the up arrow is pressed (instead of trying to go into the hidden submenu items)
                                // (TODO: is this going to chain a bunch of these handlers?)
                                $(nextSiblingLink[0]).keydown(function (e) {
                                    if (e.keyCode === 38) { // up
                                        toggleButton.focus();
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }
                                });
                            }
                        }
                        event.stopPropagation();
                        event.preventDefault();
                    }
                }
            });

            /* 
             * handle click on toggle button - open or close submenu 
             */
            toggleButton.click(function (event) {
                if ($(element).hasClass('open')) {
                    $(element).removeClass('open');
                    submenu.attr('aria-hidden', true);
                } else {
                    $(element).addClass('open');
                    submenu.attr('aria-hidden', false);
                }
                event.stopPropagation();
                event.preventDefault();
            });
        }
    };
    }
    
})();