(function () {
  'use strict';

  angular
    .module('app.dashboard')
    .service('UserSettingService', UserSettingService);

  /* @ngInject */
  function UserSettingService($resource, $q,$http) {
    /* jshint validthis: true */
    var vm = this;

    vm.ProfileData=ProfileData;
    vm.updateUserData = updateUserData;
    vm.sendRequestForForgotPassword=sendRequestForForgotPassword;
    vm.resetPassword=resetPassword;
    vm.updateSubscription=updateSubscription;
    vm.Subscription=Subscription;
   
    function ProfileData($url) {
      return $http.get($url).then(fetchData);
    }


    function updateUserData(urlparemeter, form){
      return $http.post(urlparemeter, form).then(fetchData);
    }

    function sendRequestForForgotPassword(urlparemeter, userData){
      return $http.post(urlparemeter, userData).then(fetchData);
    }

    function resetPassword(urlparemeter, userData){
      return $http.post(urlparemeter, userData).then(fetchData);
    }

    function updateSubscription(urlparemeter,userData){
      return $http.post(urlparemeter,userData).then(fetchData);
    }

    function Subscription(urlparemeter,userData){
      return $http.post(urlparemeter,userData).then(fetchData);
    }


    function fetchData(response)
    {
        return response.data;
    }


  }


})();
