(function () {
  'use strict';

  angular
    .module('app')
    .filter('roleName', roleName);

  /* @ngInject */
  function roleName($filter) {
    return function (slug) {
      var roleName = '';

      switch (slug) {
        case 'admin':
          roleName = 'Administrator';
          break;
        case 'teacher':
          roleName = 'Teacher';
          break;
        default:
          throw new Exception(`Not found the slug ${slug} of role.`);
      }

      return roleName;
    };
  }
})();