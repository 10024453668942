(function() {
    'use strict';
    angular
        .module('app.classes')
        .controller('RoasterController', RoasterController);

    function RoasterController($scope, $filter, priority, ClassesService, periods, exportService,
        helperService, $uibModal, $rootScope, $timeout, $state, $stateParams, studentsService, toastr, SweetAlert, cannedMessagesService, $location) {
        var vm = this;
        cannedMessagesService.getmeessageandData().then(function(data) {

            helperService.setCannedData(data[0], data[1], data[2]);
            setCannedData(data[0], data[1], data[2]);

        });

 
        helperService.getVesteddata($rootScope.vestedMembers);
        vm.highprioritystudents = [];
        vm.lowprioritystudents = [];
        vm.category = 'All';
        vm.class_period = 0;
        vm.selectedPeriod = periods[0].value;
        vm.priority = priority;
        vm.selectedPriority = priority[0].value;
        vm.selectedPrioritytitle = priority[0].title;
        vm.selectedPrioritytext = priority[0].text;

        vm.generalReferal = generalReferal;
        vm.writeNoteModal = writeNoteModal;
        vm.incidentModal = incidentModal;
        vm.makeCall = makeCall;
        vm.sendMessage = sendMessage;
        vm.sendEmail = sendEmail;
        vm.classesCreateStudent = classesCreateStudent;
        vm.bulkhorn = bulkhorn;

        vm.showhighpriority = false;
        vm.showgeneralpriority = false;
        vm.changeState = changeState;

        vm.highpriorityOrder = 'last_name';
        vm.generalpriorityOrder = 'last_name';
        
        vm.AipStart = AipStart;
        vm.BipStart = BipStart;
        vm.toggleAllselected = toggleAllselected;
        // vm.toggleAllselectedlow = toggleAllselectedlow;
        vm.isAllSelected = false;
        vm.optionToggled = optionToggled;
        vm.sendBlast = sendBlast;
        vm.exportPDF = exportPDF;
        vm.GenerateRandomNum = GenerateRandomNum;
        vm.paperworkModal = paperworkModal;

        vm.countStudent = 0;
        vm.inprogressMonitorPaperwork = inprogressMonitorPaperwork;
        vm.classId = 0;
        vm.viewformState = viewformState;
        $rootScope.additionalRti = false;

       // $location.hash('top'); 
       /* $rootScope.db_class_list = [];

        $rootScope.db_club_list = [];*/

        // for method

        vm.setpriority = setpriority;

        //$scope.$watch('RoasterCtrl.selectedPriority', setpriority, false);
        vm.backdrop = '';
        
        $rootScope.$on('setBackdrop', function (event, data) {
        	vm.backdrop = data;

         });
        $rootScope.$on('refreshPage', function (event, data) {
        	if(data.sucess){
        		$state.reload();       	
        	}

         });
      
        $rootScope.$on("callCreateStudentModal", function(evt, data) {
            classesCreateStudent();
        });
        if ($stateParams.id !== null) {
            vm.classId = $stateParams.id;
            vm.class_period = $stateParams.id;
            $rootScope.$broadcast('class_id', $stateParams.id);
        }

        getStudents();

        function getStudents() {
        	
            ClassesService.getclassesData('api/classes?class_id=' + vm.classId).then(function(data) {
                if (data.class_period !== null) {
                    angular.forEach(data.class_period, function(class_period) {
                        if (class_period.group_type === '1'){
                        	if($rootScope.db_class_list.length === ''){
                        		
                        	$rootScope.db_class_list.push({
                                id: class_period.id,
                                name: class_period.name,
                                period: class_period.period,
                                period_letter: class_period.period_letter
                            });
                        	}
                        }
                        else{
                        	if($rootScope.db_club_list.length === '' ){
                        		
                            $rootScope.db_club_list.push({
                                id: class_period.id,
                                name: class_period.name
                            });
                        	}
                        }
                    });
                }
                vm.highprioritystudents = data.highpririty;
                vm.lowprioritystudents = data.lowpriority;
                
              
               
                setpriority(0);

            });
            
           
        }        

        function changeState(id) {
            $timeout(function() {
                $state.go("student.summary", {
                    studentId: id
                });
            });

        }

        function viewformState(id){
        	$rootScope.additionalRti = true;
        	$state.go('paperworks.studentForm',{sid: id});
        	
        }
        

        function setpriority(value) {
            var setdata = value ? value : 0;

            vm.selectedPriority = priority[setdata].value;
            vm.selectedPrioritytitle = priority[setdata].title;
            vm.selectedPrioritytext = priority[setdata].text;

            if (setdata === 0) {
                vm.showhighpriority = true;
                vm.showgeneralpriority = true;
                vm.countStudent = vm.highprioritystudents.length + vm.lowprioritystudents.length;

            } else if (setdata === 1) {
                vm.showhighpriority = true;
                vm.showgeneralpriority = false;
                vm.countStudent = vm.highprioritystudents.length;

            } else {
                vm.showhighpriority = false;
                vm.showgeneralpriority = true;
                vm.countStudent = vm.lowprioritystudents.length;
            }
        }



        function generalReferal(student) {
            helperService.communicationModal(student, 5, 'general-referral', 5, vm.selectedPeriod,'').then(function(response) {
                helperService.showAlert('The general referral was sent.');

                student.total_gwReferral++;
                return response;
            });

        }

        function writeNoteModal(student) {
            helperService.classesModal(student, '', 'note', 'write-note-modal', 'md').then(function(response) {
                helperService.showAlert('The note modal was submitted.');
                return response;
            });

        }

        function incidentModal(student, type, modaltype) {
            helperService.classesModal(student, type, modaltype, 'incident-modal', 'lg').then(function(response) {
                helperService.showAlert('The ' + modaltype + ' modal was submitted.');
                return response;
            });
        }

        function sendMessage(student) {
            helperService.communicationModal(student, 1, 'message', '', vm.selectedPeriod, '').then(function(response) {
                helperService.showAlert('The message was sent.');

               // updatePercent(student, response.type_id);

                student.total_texts++;

                return response;
            });
        }

        function makeCall(student) {
            helperService.communicationModal(student, 2, 'call', '', vm.selectedPeriod, vm.backdrop).then(function(response) {
                helperService.showAlert('The call was made.');

               // updatePercent(student, response.type_id);

                student.total_calls++;

                return response;
            });
        }

        function sendEmail(student) {
            helperService.communicationModal(student, 3, 'email', '', vm.selectedPeriod, '').then(function(response) {
                helperService.showAlert('The email was sent.');

               // updatePercent(student, response.type_id);

                student.total_emails++;

                return response;
            });
        }

        function inprogressMonitorPaperwork(student, tier, status, type) {
            if(type === 'AIP' && student.aip_text === 'Inprogress')
                $state.go("academicIntervention", { studentId: student.id, paperworkfromClass:true});
            else if(type === 'AIP-SST' && student.aipsst_text === 'Inprogress')
                $state.go("academicIntervention-sst", { studentId: student.id, paperworkfromClass:true});
            else if(type === 'BIP' && student.bip_text === 'Inprogress')
                $state.go("behaviorIntervention", { studentId: student.id, paperworkfromClass:true});
            else if(type === 'BIP-SST' && student.bipsst_text === 'Inprogress')
                $state.go("behaviorIntervention-sst", { studentId: student.id, paperworkfromClass:true});
            else
                helperService.paperworkModal(student, tier, status, type, 'inmonitor-paperwork-modal', 'lg').then(function(response) {
                });

        }


        function AipStart(student) {
            SweetAlert.confirm("Are you sure?", {
                    title: "Submit this?"
                })
                .then(function(isConfirm) {
                    if (isConfirm) {
                        $state.go("academicIntervention", {
                            studentId: student.id,
                            paperworkfromClass:true
                        });
                    }
                });
        }

        function BipStart(student) {
            SweetAlert.confirm("Are you sure?", {
                    title: "Submit this?"
                })
                .then(function(isConfirm) {
                    if (isConfirm) {
                        $state.go("behaviorIntervention", {
                            studentId: student.id,
                            paperworkfromClass:true
                        });
                    }

                });
        }


        function bulkhorn() {

            helperService.classesModal('', 1, '', 'bulk-horn-modal', 'md').then(function(response) {
                helperService.showAlert('The positive academic modal was submitted.');
                return response;
            });

        }


        function toggleAllselected(priority) {
            if (priority == 'high') {

                angular.forEach(vm.highprioritystudents, function(student) {
                    student.selected = vm.isAllSelected;
                });
            }
            if (priority == 'low') {
                angular.forEach(vm.lowprioritystudents, function(student) {
                    student.selected = vm.isAllSelectedlow;
                });
            }
        }


        function optionToggled(priority) {
            if (priority == 'high') {
                vm.isAllSelected = vm.highprioritystudents.every(function(student) {
                    return student.selected;
                });
            }
            if (priority == 'low') {
                vm.isAllSelectedlow = vm.lowprioritystudents.every(function(student) {
                    return student.selected;
                });
            }

        }


        function sendBlast() {
            var studentsBlastSend = [];
            angular.forEach(vm.highprioritystudents, function(student) {                        
                if(student.selected)
                    studentsBlastSend.push(student);
            });
            angular.forEach(vm.lowprioritystudents, function(student) {                        
                if(student.selected)
                    studentsBlastSend.push(student);
            });



            if (studentsBlastSend.length === 0) {
                toastr.error('Select student to send blast message');
                return;
            }

            $uibModal.open({
                templateUrl: '/app/partials/address-book/communication/selection-type-blast.html',
                controller: 'SelectionTypeBlastController',
                controllerAs: 'SelectionTypeBlastCtrl'
            }).result.then(function(mode) {

                communicationMassEmailModal(studentsBlastSend, mode).then(function(data) {
                    var sendCount = (data.to === 3) ? 2 : 1;
                    vm.isAllSelected = false;
                    vm.isAllSelectedlow = false;

                    helperService.showAlert('Blast has been sent.');
                    
                    angular.forEach(studentsBlastSend, function(student) {
                        student.selected = false;
                    });

                });
            });
        }


        function setCannedData(cannedTypes, cannedCategories, callStatuses) {
            vm.cannedTypes = cannedTypes;
            vm.cannedCategories = cannedCategories;
            vm.callStatuses = callStatuses;
        }



        function communicationMassEmailModal(students, type) {
            var partial;

            switch (type) {
                case 1:
                    partial = 'message-blast';
                    break;
                case 3:
                    partial = 'email-blast';
                    break;
            }


            return $uibModal.open({
                templateUrl: '/app/partials/address-book/communication/' + partial + '.html',
                controller: 'MessageBlastController',
                controllerAs: 'MessageBlastCtrl',
                size: 'lg',
                resolve: {
                    type: function() {
                        return type;
                    },
                    students: function() {
                        return students;
                    },
                    cannedTypes: function() {
                        return vm.cannedTypes;
                    },
                    cannedCategories: function() {
                        return vm.cannedCategories;
                    }
                }
            }).result;
        }




        function classesCreateStudent() {
            GenerateRandomNum();
            $uibModal.open({
                    templateUrl: '/app/partials/address-book/create-student-modal.html',
                    controller: 'CreateStudentController',
                    controllerAs: 'CreateStudentCtrl',
                    size: 'lg',
                    resolve: {
                        createfrom: function() {
                            return '';
                        }
                    },
                    parent: angular.element(document.body),
                })
                .result.then(function(student) {
                    getStudents();
                    userCreatedToast(student.fullname, 'student');
                });
        }

        function userCreatedToast(name, role) {
            toastr.success('The ' + role + ' ' + name + ' has been created!');
        }

        function GenerateRandomNum() {
            studentsService.generateRandom().then(function(response) {
                $rootScope.$emit('getRandomNum', {
                    num: response
                });
            });

        }


        function exportPDF() {
            exportService.exportClasses({
            	class_id: vm.classId,
                selected: vm.selectedPriority,
                highpriority_order: vm.highpriorityOrder,
                lowpriority_order: vm.generalpriorityOrder

            });
        }
        
        function paperworkModal(student){
        	 helperService.classesModal(student, '', 'paperworks', 'paperwork-modal', 'md').then(function(response) {
                 helperService.showAlert('You have successfully Assign');
                 return response;
             });
        }

    }


})();