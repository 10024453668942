(function () {
  'use strict';

  angular
    .module('app.response-to-intervention-analytics.teacher-details')
    .controller('RtiTeacherDetailsController', RtiTeacherDetailsController);

  /* @ngInject */
  function RtiTeacherDetailsController($scope, periods,rtiPeriods,rtiTypes, teachers, students, modes, categories, rtiTiers, responseToInterventionAnalyticsService) {
    /* jshint validthis: true */
    var vm = this;

    vm.periods = periods;
    vm.rtiPeriods = rtiPeriods;
    vm.teachers = teachers;
    vm.students = students;
    vm.modes = modes;
    vm.categories = categories;
    vm.rtiTiers = rtiTiers;
    vm.searchByStudent = '';

      vm.options = {
      legend: {display: false},
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: false,
          autoSkip: true,
          autoSkipPadding: 100,
        }],
        yAxes: [{
          stacked: false,
          ticks: {
            beginAtZero:true,
            callback: (value) => {
              if (value % 1 === 0) {
                return value;
              }
            }
          }
        }]
      }
    };
    vm.types = rtiTypes;

    vm.filters = {};
    Object.defineProperties(vm.filters, {
        _type: {
            value: vm.types[0],
            writable: true,
            enumerable: false,
        },
        type: {
            enumerable: true,
            get: function () {
                return this._type.slug;
            }
        },
      _teacher: {
        enumerable: false,
        value: vm.teachers[0],
        writable: true,
      },
      teacher_id: {
        enumerable: true,
        get: function () {
          if (this._teacher) return this._teacher.id;
        },
      },
        _student: {
            enumerable: false,
            value: vm.students[0],
            writable: true,
        },
        student_id: {
            enumerable: true,
            get: function () {
                if (this._student) return this._student.id;
            },
        },
      _period: {
        value: vm.periods[0],
        writable: true,
        enumerable: false,
      },
      period_slug: {
        enumerable: true,
        get: function () {
          return this._period.slug;
        },
      },
      period_start: {
        enumerable: true,
        get: function () {
          return this._period.start.format('D-M-Y');
        },
      },
      period_end: {
        enumerable: true,
        get: function () {
          return this._period.end.format('D-M-Y');
        },
      },
      _period_table: {
        value: vm.periods[0],
        writable: true,
        enumerable: false,
      },
      period_table_start: {
        enumerable: true,
        get: function () {
          return this._period_table.start.format('D-M-Y');
        },
      },
      period_table_end: {
        enumerable: true,
        get: function () {
          return this._period_table.end.format('D-M-Y');
        },
      },
      _mode: {
        value: vm.modes[0],
        writable: true,
        enumerable: false,
      },
      mode: {
        enumerable: true,
        get: function () {
          return this._mode.value;
        }
      },
        _rtiPeriod: {
            value: vm.rtiPeriods[0],
            writable: true,
            enumerable: false,
        },
        rtiPeriod_id: {
            enumerable: true,
            get: function () {
                return this._rtiPeriod.id;
            },
        },
      _canned_type: {
        value: vm.rtiTiers[0],
        writable: true,
        enumerable: false,
      },
      canned_type_slug: {
        enumerable: true,
        get: function () {
          return this._canned_type.slug;
        }
      },
      _category: {
        value: vm.categories[0],
        writable: true,
        enumerable: false,
      },
      category_slug: {
        enumerable: true,
        get: function () {
          return this._category.slug;
        }
      },
      page: {
        value: 1,
        writable: true,
        enumerable: true,
      }
    });

    vm.charts = {
      byPeriod: {
        data: [],
        labels: [],
        series: ['A'],
      },
      byCategories: {
        data: [0, 0, 0, 0],
        labels: ['Academic', 'General', 'Attendance', 'Behavior']
      },
      byType: {
        data: [0,0,0,0],
        labels: ["Tier 1(None)", "Tier 2(AIP, BIP)","Tier 3(AIP-SST, BIP-SST)","Tier 4(IEP-TDM, 504-TDM)"],
      }
    };

    vm.items = {};
    vm.request = false;

    vm.getStatistics = getStatistics;
    vm.changePage = changePage;

    $scope.$watchCollection('RtiTeacherDetailsCtrl.filters', function () {
      getStatistics();
    });

    /////////////////

    function getStatistics() {
      vm.request = true;

      responseToInterventionAnalyticsService.teacherDetails(vm.filters).then(function (response) {
        vm.items = response.items;
        vm.byType = response.byType;
        vm.byCategory = response.byCategory;
        vm.period = response.byPeriod;
        vm.total = response.total;

        recalculateCharts();
      }).finally(function () {
        vm.request = false;
      });
    }

    function recalculateCharts() {
        vm.charts.byType.data = vm.byType.map((item) => {
            return item.count;
        });


      vm.charts.byCategories.data = [
        vm.byCategory.academic / vm.total,
        vm.byCategory.general / vm.total,
        vm.byCategory.attendance / vm.total,
        vm.byCategory.behavior / vm.total
      ];
console.log(vm.period);
      vm.charts.byPeriod = vm.period;
    }

    function changePage(number) {
      vm.filters.page = number;

      getStatistics();
    }
      // function prepareItems(items) {
      //     items.data.forEach((referral) => {
      //         referral.studentFullName = referral.student.fullname;
      //     });
      //
      //     return items;
      // }
  }
})();