(function () {
  'use strict';

  angular
    .module('app.address-book')
    .controller('ShowVestedMemberController', ShowVestedMemberController);

  function ShowVestedMemberController($uibModalInstance, vestedMember, vestedMemberTypes, vestedMembersService) {
    /* jshint validthis: true */
    var vm = this;

    vm.vestedMember = angular.copy(vestedMember);
    vm.vestedMemberTypes = vestedMemberTypes;

    vm.cancel = cancel;
    vm.update = update;

    ////////////////

    function cancel() {
      $uibModalInstance.dismiss();
    }

/*    function update() {
      vestedMembersService.update(vm.vestedMember).then(function (vestedMember) {
        $uibModalInstance.close(vestedMember);
      });
    }*/
    
    function update() {
        vestedMembersService.updatedVested(vm.vestedMember).then(function (vestedMember) {
          $uibModalInstance.close(vestedMember);
        });
      }
  }
})();