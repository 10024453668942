(function(){
	'use restrict';
	
		angular
			.module('app.paperworkforms')
			.controller('LetterassignModalController', LetterassignModalController);
	
	function LetterassignModalController($scope, $rootScope, $uibModalInstance, studentsService, $filter,studentId, formid, formtype, description,category, type, formName, students, paperworksService,
				toastr, messagesService, SweetAlert, exportService){
		
		var vm = this;
		/*Define property*/
			
		vm.students = '';
		vm.userPeriods = '';
		vm.userSelectedPeriod = 0;
		vm.periodname = '';
		vm.searchtext = '';
		vm.searchByClass = false;
		vm.isAllSelected = false;
		vm.selectassignStudent = '';
		vm.selectedPeriod_letter = '';
		vm.form = {
				letter_form_id: formid ? formid :'',
				letter_type : formtype ? formtype : '',
				students : [],
			 	email_sent: 1,
			 	studentid : studentId ? studentId : '',
			 	description : description ? description : '',
			 	categoty: category ? category:'',
			 	type: type ? type:''		
		};
		
		vm.userBalance = '';	
		vm.formName = formName;
		vm.assignedStudents = students;
		
		/*Define Methods*/
		vm.cancel = cancel;
		vm.getStduentsWithuserClasses = getStduentsWithuserClasses;
		//vm.selectStudentByPeriod = selectStudentByPeriod;
		vm.toggleAllselected = toggleAllselected;
		vm.optionToggled = optionToggled;
		vm.assignSave = assignSave;
		vm.getUserBalance = getUserBalance;
		vm.viewAssignedData = viewAssignedData;
		
		
		/* document ready */
		
		getStduentsWithuserClasses();
		getStudent();
		getUserBalance();
		
		//$scope.$watch('LetterassignModalCtrl.userSelectedPeriod', selectStudentByPeriod, false);
		
		 function cancel() {
		        $uibModalInstance.dismiss();
		      }
		 
		 function getStduentsWithuserClasses(){
			 
			 studentsService.getallstudentbyUser('/api/students/studentsanduserclasses').then(function(data){
				 vm.students = data[0];
				 vm.userPeriods = data[1];
				 
				
			 });
		 }
		
		 function getStudent(){
			 studentsService.getStudent('/api/students').then(function(response){
					
					vm.students = response;
					
				});
		 }
		 
		 
		/* function selectStudentByPeriod(){
			
			 		//alert(vm.searchByClass);
					var data = {period: vm.userSelectedPeriod, searchkey: vm.searchtext };
					studentsService.getStudentsByuserPeriod('/api/students/serachstudentByperiod',data).then(function(response){
						vm.students = response;
						
					});
				
		 }*/
		 
		 
		 function toggleAllselected(){
			
			 angular.forEach(vm.students, function (student) {
           		 student.selected = vm.isAllSelected;
           	      });
			 vm.selectassignStudent =    $filter('filter')(vm.students, {selected: true});
		 }
		 
		 function optionToggled() {
	        		
		            vm.isAllSelected = vm.students.every(function (student) {
		              return student.selected;
		            });
	        	
		         vm.selectassignStudent =    $filter('filter')(vm.students, {selected: true});
	          }
		 
		 
		 function assignSave(){
			
			 
			 $rootScope.modalerror=true;
			 var selectedData = '';
			 var assign_message = '';
			 var selectStudents=[];

			 selectedData = $filter('filter')(vm.students, {selected: true});
			 

			 var i=0;
			 angular.forEach(students, function(value) {
         		
         		vm.form.students.push(value.id);
         		selectStudents[i]=value.fullname;
         		i++;
         		  
         	 });

			 
			 if(studentId){
				
				 paperworksService.saveActivity('/api/paperwork/letter/assign',vm.form).then(function(response){			
					 	$rootScope.modalerror=false;
						$uibModalInstance.close();
						
						if(vm.form.email_sent == 1){
							
							assign_message= selectStudents.toString()+ " successfully Assign and sended email " +  $filter('date')(new Date(), 'M/d/yy h:mm:ss a');
						}
						else{
						assign_message= selectStudents.toString()+ " successfully Assign in " +  $filter('date')(new Date(), 'M/d/yy h:mm:ss a');
					
						}
						$rootScope.$emit('assign_message',assign_message);
						toastr.success(assign_message);
                      	
					
				}); 
			 }
			 else{
				 	
			 		paperworksService.saveActivity('/api/paperwork/letter/assign',vm.form).then(function(response){	
			 		
				 	$rootScope.modalerror=false;
				 	if(response.balance_reached === 'yes'){
				 		 toastr.error('You cannot send more than ' + response.remaining + ' email');
				 	}
				 	else{
				 	
					$uibModalInstance.close();
					
					//if(vm.form.email_sent == 1){
						
						assign_message= selectStudents.toString()+ " successfully Assign and sended email " +  $filter('date')(new Date(), 'M/d/yy h:mm:ss a');
					
					//}
					/*else{
						if(vm.isAllSelected){
							assign_message=  "All of period " + vm.selectedPeriod_letter + ' have been assign to ' +  formName ;
						}
						else{
							var haveOrhas = (vm.selectassignStudent.length > 1)?' have':' has';
							assign_message=  selectStudents.toString() + '' +  haveOrhas + ' been assign to ' +  formName ;
						}
					
			 		}*/
					$rootScope.$emit('assign_message',assign_message);
					toastr.success(assign_message);
					
				 	}
					});

			 } 
		 }
		 
		    function getUserBalance(){
		    	messagesService.getUserBalance('/api/email-sms-call-balance').then(function(response){
		    		vm.userBalance = response;
		    		
		    	});
		    }
		    
		    function viewAssignedData(stdId){
		    	exportService.exportRtiform({
                    formId: formid,
                    student_id: stdId,
                    form_type: formtype,
                    reason: '',
                    teacherId: $rootScope.user.id,
                    url: '/api/paperwork/letter/pdfdownload',
                    type: ''
                });
		    }
		
	}
	
	
	
	
})();