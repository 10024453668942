(function () {
  'use strict';

  angular
    .module('app')
    .service('exportService', exportService);

  /* @ngInject */
  function exportService($http,$rootScope) {
    /* jshint validthis: true */
    var vm = this;

    vm.exportCommunication = exportCommunication;
    vm.exportStudentMessages = exportStudentMessages;
    vm.exportStudents = exportStudents;
    vm.exportClasses = exportClasses;
    vm.exportRtiform = exportRtiform;

    ////////////////////

    function exportCommunication(data) {
      var test = 'ok';
      $('<form>', {
        method: 'POST',
        action: '/api/reports/export/communication'
      }).append('<input type="hidden" name="order" value="' + data.order + '">' +
        '<input type="hidden" name="start_date" value="' + moment(data.custom_ranges.startDate).format('YYYY-MM-DD') + '">' +
        '<input type="hidden" name="end_date" value="' + moment(data.custom_ranges.endDate).format('YYYY-MM-DD') + '">' +
        '<input type="hidden" name="class_id" value="' + data.class_periodId + '">' +
        '<input type="hidden" name="range" value="' + data.range + '">' +
        '<input type="hidden" name="user_id" value="' + $rootScope.user.id + '">' +
        '<input type="hidden" name="report_type" value="' + data.report_type + '">').appendTo('body').submit(); 
    }

    function exportStudentMessages(data) {
      $('<form>', {
        method: 'POST',
        action: '/api/reports/export/students/' + data.studentId + '/messages'
      }).append('<input type="hidden" name="order" value="' + data.order + '">' +
        '<input type="hidden" name="start_date" value="' + moment(data.custom_ranges.startDate).format('YYYY-MM-DD') + '">' +
        '<input type="hidden" name="end_date" value="' + moment(data.custom_ranges.endDate).format('YYYY-MM-DD') + '">' +
        '<input type="hidden" name="mode" value="' + data.mode + '">' +
        '<input type="hidden" name="type" value="' + data.type + '">' +
        '<input type="hidden" name="category" value="' + data.category + '">' +
        '<input type="hidden" name="range" value="' + data.range + '">' + 
        '<input type="hidden" name="report_type" value="' + data.reportType + '">').appendTo('body').submit();
    }
    


    function exportStudents(data) {
    	
     // var test = 'ok';
      $('<form>', {
        method: 'POST',
        action: '/api/reports/export/communication'
      }).append('<input type="hidden" name="order" value="' + data.order + '">' +
    		  '<input type="hidden" name="user_id" value="' + $rootScope.user.id + '">' +
              '<input type="hidden" name="class_id" value="' + data.class_periodId + '">' +
        '<input type="hidden" name="report_type" value="' + data.report_type + '">').appendTo('body').submit(); 
    }
    
    
    function exportClasses(data){
    	$('<form>',{
    		method:'POST',
    		action: '/api/reports/export/classes'
    	}).append('<input type="hidden" name="hyprio_order" value="'+ data.highpriority_order +'">' +
    			'<input type="hidden" name="lowprio_order" value="'+ data.lowpriority_order +'">' +
    			'<input type="hidden" name="user_id" value="' + $rootScope.user.id + '">'+
                '<input type="hidden" name="selected" value="'+ data.selected +'">'+
                '<input type="hidden" name="class_id" value="'+ data.class_id +'">'
    			).appendTo('body').submit();
    	
    }
    
    function exportRtiform(data){
    	$('<form>',{
    		method:'GET',
    		action: data.url,
    		target: '_blank'
    	}).append('<input type="hidden" name="type" value="'+ data.type +'">' +
    			'<input type="hidden" name="student_id" value="'+ data.student_id +'">' +
    			'<input type="hidden" name="formtype" value="'+ data.form_type +'">' +
    			'<input type="hidden" name="reason" value="'+ data.reason +'">'+ 
    			'<input type="hidden" name="teacher_id" value="'+ data.teacherId +'">'+
    			'<input type="hidden" name="form_id" value="'+ data.formId +'">'
    	
    	).appendTo('body').submit();
    	
    	
    }
  }
})();
