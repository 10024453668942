(function () {
  'use strict';

  angular
    .module('app.referral-analytics.teacher-details')
    .controller('ReferralTeacherDetailsController', ReferralTeacherDetailsController);

  /* @ngInject */
  function ReferralTeacherDetailsController($scope, periods, teachers, students, referralTypes, locations, referralAnalyticsService) {
    /* jshint validthis: true */
    let vm = this;

    vm.periods = periods;
    vm.teachers = teachers;
    vm.students = students;
    vm.categories = referralTypes;
      locations.unshift({
          title: 'All Location',
          id: undefined,
      });
    vm.locations = locations;
    vm.items = [];
    vm.total = 0;
    vm.request = false;
    vm.searchByStudent = '';
    vm.options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: true,
          autoSkip: true,
          autoSkipPadding: 100,
        }],
        yAxes: [{
          stacked: true,
          ticks: {
            beginAtZero:true,
            callback: (value) => {
              if (value % 1 === 0) {
                return value;
              }
            }
          }
        }]
      }
    };

    vm.filters = {};
    Object.defineProperties(vm.filters, {
      _teacher: {
        enumerable: false,
        value: vm.teachers[0],
        writable: true,
      },
      teacher_id: {
        enumerable: true,
        get: function () {
          if (this._teacher) return this._teacher.id;
        },
      },
        _student: {
            enumerable: false,
            value: vm.students[0],
            writable: true,
        },
        student_id: {
            enumerable: true,
            get: function () {
                if (this._student) return this._student.id;
            },
        },
      _period: {
        value: vm.periods[4],
        writable: true,
        enumerable: false,
      },
      period_slug: {
        enumerable: true,
        get: function () {
          return this._period.slug;
        },
      },
      period_start: {
        enumerable: true,
        get: function () {
          return this._period.start.format('D-M-Y');
        },
      },
      period_end: {
        enumerable: true,
        get: function () {
          return this._period.end.format('D-M-Y');
        },
      },
      _period_table: {
        value: vm.periods[0],
        writable: true,
        enumerable: false,
      },
      period_table_start: {
        enumerable: true,
        get: function () {
          return this._period_table.start.format('D-M-Y');
        },
      },
      period_table_end: {
        enumerable: true,
        get: function () {
          return this._period_table.end.format('D-M-Y');
        },
      },
      _category: {
        value: vm.categories[0],
        writable: true,
        enumerable: false,
      },
      category_value: {
        enumerable: true,
        get: function () {
          return this._category.value;
        }
      },
      _location: {
        value: vm.locations[0],
        writable: true,
        enumerable: false,
      },
      location_slug: {
        enumerable: true,
        get: function () {
          return this._location.slug;
        }
      },
      page: {
        value: 1,
        writable: true,
        enumerable: true,
      }
    });

    vm.charts = {
      byPeriod: {
        data: [],
        labels: [],
        series: [],
      },
      byCategories: {
        data: [],
        labels: []
      },
      byLocation: {
        data: [],
        labels: [],
      }
    };

    vm.getStatistics = getStatistics;
    vm.changePage = changePage;

    $scope.$watchCollection('ReferralTeacherDetailsCtrl.filters', function () {
      getStatistics();
    });

    /////////////////

    function getStatistics() {
      vm.request = true;

      referralAnalyticsService.teacherDetails(vm.filters).then(function (response) {
        vm.items = prepareItems(response.items);
        vm.total = response.total;

        vm.charts.byLocation.data = response.byLocation.map((item) => {
          return item.count;
        });

        vm.charts.byLocation.labels = response.byLocation.map((item) => {
          return item.name +' : '+ Math.round((item.count / response.total) * 100, 2) + '% ';
        });

        vm.charts.byCategories.data = response.byCategory.map((item) => {
          return item.count;
        });

        vm.charts.byCategories.labels = response.byCategory.map((item) => {
          return item.name;
        });

        vm.charts.byPeriod.labels = response.byPeriods.labels;
        vm.charts.byPeriod.series = response.byPeriods.series;
        vm.charts.byPeriod.data = response.byPeriods.data;

      }).finally(function () {
        vm.request = false;
      });
    }

    function changePage(number) {
      vm.filters.page = number;

      getStatistics();
    }

    function prepareItems(items) {
      items.data.forEach((referral) => {

        referral.studentFullName = referral.student.fullname;
      });

      return items;
    }
  }
})();