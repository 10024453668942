(function() {
    'use strict';

    angular
        .module('app.classes')
        .controller('NewclassPopUpController', NewclassPopUpController);

    function NewclassPopUpController($uibModalInstance, $scope, students, $filter, $rootScope, UploadService, prevSelected,  $timeout, $window, formData) {

        var vm = this;
        vm.cancel = cancel;
        vm.students = students;
        vm.orderBy = 'last_name';
        vm.isAllSelected = false;
        vm.selectedStudentId = [];
        //  vm.addstudentfromCSV = [];

        vm.myFile = '';
        vm.progresspercent = '0%';
        vm.showbar = false;
        vm.nodata = students.length <= 0 ? true : false;
        
        vm.studentdata = {
        		selectedStudentId :[],
        		selectedName:[],
        		
        };

        vm.new_class_text = 'Period ' + formData.period + '-' + formData.period_letter + ' | ' + formData.name;
        // methods

        vm.toggleAllSelected = toggleAllSelected;
        vm.optionToggled = optionToggled;
        vm.attachData = attachData;
        vm.uploadFile = uploadFile;
        vm.downloadTemp = downloadTemp;
        vm.previousSelected = previousSelected;
        
        previousSelected();



        function cancel() {
            $uibModalInstance.dismiss();
        }
        
        
        function previousSelected(){
        	angular.forEach(vm.students, function(student){
        		
        		angular.forEach(prevSelected, function(prev){
        			if(prev.id == student.id){
        				
        				student.selected = true;
        			}
        			
        		});
        		
        	});
        	
        	
        }

        function toggleAllSelected() {
            angular.forEach(vm.students, function(student) {
                student.selected = vm.isAllSelected;
            });
           
        }

        function optionToggled() {

            vm.isAllSelected = vm.students.every(function(student) {
                return student.selected;

            });
        }

        function attachData() {
            var attachedStudents = $filter('filter')(vm.students, {
                selected: true
            });
            if (attachedStudents.length === 0) {
                return;
            }
            angular.forEach(attachedStudents, function(student) {
                //vm.selectedStudentId.push(student.id);
                vm.studentdata.selectedStudentId.push(student.id);
                vm.studentdata.selectedName.push(student.fullname + '-' + student.g1fullname);
              
            });
            
            $rootScope.$emit('attachStudent_id', vm.studentdata);
            $uibModalInstance.close(vm.studentdata);
        }

        function uploadFile() {
            var file = vm.myFile;

            vm.showbar = true;
            var uploadUrl = 'api/classes/upload';
            UploadService.uploadFileToUrl(file, uploadUrl).then(function(response) {

                vm.progresspercent = '100%';
                $rootScope.$emit('attachStudentCSV', response.data);
                $timeout(function() {
                    $uibModalInstance.close();
                }, 2000);

            });

        }

        function downloadTemp() {

            UploadService.downloadteplate('api/classes/downloadtemp').then(function(response) {
                $window.open('api/classes/downloadtemp', '_blank');
            });
        }

    }


})();