(function () {
  'use strict';

  angular
    .module('app')
    .service('cannedMessagesService', CannedMessagesService);

  /* @ngInject */
  function CannedMessagesService($http) {
    /* jshint validthis: true */
    var vm = this;

    vm.types = types;
    vm.rtiTiers = rtiTiers;
    vm.rtiPeriods = rtiPeriods;
    vm.periodTeachers = periodTeachers;
    vm.topics = topics;
    vm.categories = categories;
    vm.behaviorCategories = behaviorCategories;
    vm.callStatuses = callStatuses;
    vm.messages = messages;
    vm.create = create;
    vm.createBlast = createBlast;
    vm.getmeessageandData = getmeessageandData;
    vm.callPhoneNo = callPhoneNo;
    vm.checkCallduration = checkCallduration;

    //////////////////////////////

    function types() {
      return $http.get('/api/types').then(fetchData);
    }

    function periodTeachers() {
      return $http.get('/api/attendance-period').then(fetchData);
    }
    function rtiTiers() {
          return $http.get('/api/rti-tiers').then(fetchData);
        }
        function rtiPeriods() {
          return $http.get('/api/rti-period').then(fetchData);
        }

    function topics(category_id, type_id) {
      return $http.get('/api/topics?category_id=' + category_id + '&type_id=' + type_id).then(fetchData);
    }
    
    function getmeessageandData(){
    	return $http.get('/api/canned-message/type_topic').then(fetchData);
    	
    }

    function categories() {
      return $http.get('/api/categories').then(fetchData);
    }
    function behaviorCategories() {
          return $http.get('/api/behavior-categories').then(fetchData);
        }

    function callStatuses() {
      return $http.get('/api/call-statuses').then(fetchData);
    }

    function messages(data) {
      return $http.get('/api/canned-messages', {params: data}).then(fetchData);
    }

    function create(message) {
      return $http.post('/api/messages', message).then(fetchData);
    }

    function createBlast(message) {
      return $http.post('/api/messages-blast', message).then(fetchData);
    }

    function fetchData(response) {
      return response.data;
    }
    
    function callPhoneNo(url, params){
    	return $http.post(url,params).then(fetchData);
    }
    
    function checkCallduration(url,params){
    	//return $http.post(url,params).then(fetchData);
        return $http.get(url, {params: params}).then(fetchData);
    }
  }
})();