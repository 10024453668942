(function () {
  'use strict';

  angular
    .module('app')
    .filter('incidentsByType', incidentsByType);

  /* @ngInject */
  function incidentsByType() {
    return function (incidents, type) {
      type = type === 'positive' ? 1 : 2;

      return incidents.filter((incident) => {
        return +incident.incident_status === type;
      });
    };
  }

})();