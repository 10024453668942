(function () {
  'use strict';

  angular
    .module('app.address-book')
    .controller('ShowAdminController', ShowAdminController);

  function ShowAdminController($uibModalInstance, admin, adminTypes, adminsService) {
    /* jshint validthis: true */
    var vm = this;

    vm.admin = angular.copy(admin);

    vm.adminTypes = adminTypes;

    vm.cancel = cancel;
    vm.update = update;

    ////////////////

    function cancel() {
      $uibModalInstance.dismiss();
    }

/*    function update() {
      adminsService.update(vm.admin).then(function (admin) {
        $uibModalInstance.close(admin);
      });
    }*/
    
    function update() {
        adminsService.update(vm.admin).then(function (admin) {
          $uibModalInstance.close(admin);
        });
      }
  }
})();