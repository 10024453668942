(function () {
  'use strict';

  angular
    .module('app.dashboard')
    .service('toDoService', toDoService);

  /* @ngInject */
  function toDoService($resource, $q,$http) {
    /* jshint validthis: true */
    var vm = this;

    var ToDoItem = $resource('/api/to-do-items/:toDoItemId', {toDoItemId: '@id'}, {
      update: {method: 'POST'},
      complete: {method: 'POST', url: '/api/to-do-items/:toDoItemId/complete'},
      cancelCompletion: {method: 'POST', url: '/api/to-do-items/:toDoItemId/cancel-completion'},
    });

    vm.all = all;
    vm.getAllData = getAllData;
    vm.create = create;
    vm.update = update;
    vm.remove = remove;
    vm.removeItem = removeItem;
    vm.complete = complete;
    vm.cancelCompletion = cancelCompletion;

    //////////////////

    function all(data) {
      return ToDoItem.query(data).$promise.then(function (items) {
        return items.map(handleItem);
      });
    }


    function getAllData($url) {
      return $http.get($url).then(fetchData);
    }

     function fetchData(response){
        return response.data;
      }


    function create(_toDoItem) {
      var toDoItem = prepareItemBeforeRequest(_toDoItem);

      return ToDoItem.save(toDoItem).$promise.then(handleItem);
    }

    function update(_toDoItem) {
      var toDoItem = prepareItemBeforeRequest(_toDoItem);

      return ToDoItem.update(toDoItem).$promise.then(handleItem);
    }

    function complete(toDoItem) {
      
      return ToDoItem.complete({toDoItemId: toDoItem.id}, {}).$promise
        .then(handleItem, handleRejectedChangingCompletionStatus.bind(toDoItem));
    }

    function cancelCompletion(toDoItem) {
      return ToDoItem.cancelCompletion({toDoItemId: toDoItem.id}, {}).$promise
        .then(handleItem, handleRejectedChangingCompletionStatus.bind(toDoItem));
    }

    /**
     * this must be toDoItem model
     *
     * @param data
     * @returns {*|Promise}
     */
    function handleRejectedChangingCompletionStatus(data) {
      this.is_completed = !this.is_completed;

      return $q.reject(data);
    }

    function remove(toDoItem) {
      return ToDoItem.delete({toDoItemId: toDoItem.id}, {}).$promise;
    }

    function removeItem(urlparemeter,toDoItem) {
      //console.log(toDoItem);

      return $http.post(urlparemeter, toDoItem.id).then(fetchData);
    }

    function handleItem(toDoItem) {
      toDoItem.due_date = moment(toDoItem.due_date);
      toDoItem.completed_at = toDoItem.completed_at ? moment(toDoItem.completed_at) : undefined;

      return toDoItem;
    }

    function prepareItemBeforeRequest(_toDoItem) {
      var toDoItem = angular.copy(_toDoItem);

      toDoItem.due_date = toDoItem.due_date.format('DD-MM-YYYY');

      return toDoItem;
    }

  }
})();
