(function () {
  'use strict';

  angular.module('app.communications-analytics', [
    'app.communications-analytics.quickview',
    'app.communications-analytics.subject-grade-comparisons',
    'app.communications-analytics.teacher-comparisons',
    'app.communications-analytics.teacher-details',
  ]);

})();