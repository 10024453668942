(function () {
  'use strict';

  angular
    .module('app.login')
    .controller('ActivationController', ActivationController);

  /* @ngInject */
  function ActivationController($auth, $rootScope, user, authService, adminsService, vestedMembersService, toastr, $state,$stateParams) {
    /* jshint validthis: true */
    let vm = this;

    vm.user = user;

    vm.login = login;
	
	
	if($stateParams.type==='1')
	{
		login();
		
	}

    ///////////////////////
    function login() {
		
      $auth.login(user, {url: `/login-user/${vm.user.id}`}).then(function (data) {
        authService.me().then(function (res) {
          vm.isLoggedIn = res.isLoggedIn;

          if(authService.completedRegistration()) {
            adminsService.all().then(function (response) {
              $rootScope.admins = response;
            });

            vestedMembersService.all().then(function (response) {
              $rootScope.vestedMembers = response;
            });
            
            
          }

          toastr.success('You are successfully logged in.', 'Login');

          if(authService.isTeacher())
          {
             $rootScope.is_admin=0;
             $state.go('dashboard.main');
          }
          else if(authService.isAdmin()) 
          {
             $rootScope.is_admin=1;
             $state.go('communications-analytics.quickview');
          }

          //console.log($rootScope.user);

          /*if(($rootScope.user.is_school_account) || (!$rootScope.user.is_school_account))
          {
            $state.go('dashboard.main');
          }*/
          

          return res;
        });

        return data;
      });
	  
    }


  }
})();