(function () {
  'use strict';

  angular
    .module('app.core')
    .service('behaviorService', behaviorService);

  function behaviorService( $http) {
    /* jshint validthis: true */
    var vm = this;

    vm.get = get;
    
    function get(studentId){
    	return $http.get('/api/students/'+ studentId +'/behavior').then(fetchData);
    	
    }
    
    function fetchData(response){
    	return response.data;
    	
    }

    
  }
})();