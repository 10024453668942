(function () {
  'use strict';

  angular
    .module('app.dashboard.main')
    .controller('ShareUsController', ShareUsController);

  /* @ngInject */
  function ShareUsController($scope,toastr,ShareUsService,$uibModal,$window) {

    /* jshint validthis: true */
    var vm = this;

    vm.ActiveTab=1;
    vm.ActiveTabSetting=ActiveTabSetting;
    vm.SendRecommendUs=SendRecommendUs;
    vm.NoThanks=NoThanks;
    vm.ShareUsButton=ShareUsButton;
    vm.checkWindow = checkWindow;
    var childWindow='';
    var timer='';
    vm.SocialMediaLink="";

    vm.shareusEmail = {
      Emailbox: undefined,
    };
    

    function checkWindow() {
        if (childWindow && childWindow.closed) {
              window.clearInterval(timer);             
              $scope.$apply(function(){               
              vm.preview = false;
               
              });

          }
    }



    function ActiveTabSetting(MainTabSetting)
    {
       vm.ActiveTab=MainTabSetting;
    }

    function ShareUsButton(type)
    {
        var height="";
        var width="";
        var left="";
        var top="";

        if(type===4)
        {

           height="800";
           width="1000";
           left="100";
           top="100";

        }
        else if(type===1)
        {
           height="400";
           width="100";
           left="500";
           top="150";

        }
        else
        {
           height="550";
           width="800";
           left="150";
           top="100";

        }

        ShareUsService.ShareSocialMedia('api/shareus/social?type='+type).then(function(data) {

            vm.SocialMediaLink=data;  
			
		
			var winName = 'Social media';
            var winURL = data;
            var windowoption = 'resizable=yes,height='+height+',width='+width+',left='+left+',top='+top+',menubar=0,scrollbars=1';
			
			window.open(winURL, "pop", windowoption);

            
   
        });

        

    }

    function SendRecommendUs()
    {

        var flag=1;
        if(vm.shareusEmail.Emailbox===undefined || vm.shareusEmail.Emailbox==="")
        {
          flag=0;
          toastr.error("Email id required");
        }

        if(flag===1)
        {
           //console.log(vm.shareusEmail); 
           ShareUsService.SendRecommendUsEmail('api/shareus/sendmail',vm.shareusEmail).then(function(data) {

               if(data.error===0)
                {
                    toastr.success('Email successfully send');
                    NoThanks();

                }
                else
                {
                    
                    toastr.error(data.errortext);
                }
   
           });

        }
        

    }


    function NoThanks()
    {
      vm.shareusEmail.Emailbox="";
      vm.shareusEmail.Emailbox=undefined;
    }

    
  }
})();