(function (){
	'use strict';
	
	angular
		.module('app.directives')
		.directive('uploadDirective',uploadDirective);
	
	function uploadDirective($parse,$rootScope){
		
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
               var model = $parse(attrs.uploadDirective);
               var modelSetter = model.assign;
               
               element.bind('change', function(){
            	   $rootScope.file_name = element[0].files[0].name;
            	  // alert(element[0].files[0].name);
                  scope.$apply(function(){
                     modelSetter(scope, element[0].files[0]);
                  });
               });
            }
         };
	}
	
})();