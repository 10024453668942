(function () {
  'use strict';

  angular
    .module('app.core')
    .service('adminsService', adminsService);

  function adminsService($resource, $http) {
    /* jshint validthis: true */
    var vm = this;

    var Admin = $resource('/api/admins/:adminId', {adminId: '@id'}, {
      update: {method: 'PUT'}
    });

    vm.all = all;
    vm.create = create;
    vm.update = update;
    vm.remove = remove;
    vm.adminUpdate = adminUpdate;
    vm.adminDelete =adminDelete;
    vm.getBellSchedule = getBellSchedule;

    /////////////////

    function all() {
      return Admin.query().$promise;
    }

    function create(data) {
      return Admin.save(data).$promise;
    }

    function update(admin) {
      return Admin.update(admin).$promise;
    }

    function remove(admin) {
      return Admin.delete({adminId: admin.id}).$promise;
    }
    
    function adminUpdate(admin){
    	 return $http.post('/api/admins/adminupdate', admin).then(fetchData);
    }
    
    function adminDelete(admin){
    	return $http.post('/api/admins/admindelete', admin).then(fetchData);
    }
    
    function getBellSchedule(){
    	return $http.get('/api/user/bell-schedule').then(fetchData);
    }
    
    function fetchData(response) {
        return response.data;
      }
  }
})();