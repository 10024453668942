(function () {
  'use strict';
  angular
    .module('app.core')
    .service('ClassesService', ClassesService);

  /* @ngInject */
  function ClassesService($http, $resource) {
    /* jshint validthis: true */
    var vm = this;

    let Class = $resource('api/student-classes/:classId', {classId: '@id'}, {
      update: {method: 'PUT'}
    });

    vm.createClass = createClass;
    vm.updateClass = updateClass;
    vm.getclassesData = getclassesData;
    vm.create = create;
    vm.update = update;
    vm.setIcon = setIcon;
    vm.getIcon = getIcon;
    vm.getBarCode = getBarCode;
    vm.getperiod = getperiod;
    vm.checkperiodInput = checkperiodInput;
    vm.getUserCreatedPeriod = getUserCreatedPeriod;
    vm.VerifyClass=VerifyClass;
    vm.getUserDetails=getUserDetails;
    vm.updateVerified=updateVerified;
    vm.removeClass=removeClass;
    /////////////////////

    function VerifyClass(urlparemeter, form) {
      //return $http.get($url).then(fetchData);
      return $http.post(urlparemeter, form).then(fetchData);
    }

    function removeClass(urlparemeter, form) {
      //return $http.get($url).then(fetchData);
      return $http.post(urlparemeter, form).then(fetchData);
    }

    function getUserDetails($url) {
      return $http.get($url).then(fetchData);
    }


    function createClass(classData) {
      return Class.save(classData).$promise;
    }

    function updateClass(urlparemeter, form) {
      return $http.post(urlparemeter, form).then(fetchData);
    }

    function getclassesData($url) {
      return $http.get($url).then(fetchData);
    }

    function getBarCode($url) {
      return $http.get($url).then(fetchData);
    }

    function updateVerified(urlparemeter, form) {
      return $http.post(urlparemeter, form).then(fetchData);
    }

    function create(urlparemeter, form) {
      return $http.post(urlparemeter, form).then(fetchData);
    }

    function update(urlparemeter, form){
    	return $http.post(urlparemeter, form).then(fetchData);
    }
    
    function setIcon(icon) {
      return $http.post('api/classes/iconchange', icon).then(fetchData);
    }


    function getIcon() {
      return $http.get('api/classes/geticon').then(fetchData);
    }

    function getperiod(semester, grade, classid) {

      return $http.get('api/classes/period?semester=' + semester + '&grade=' + grade + '&classId='+ classid).then(fetchData);
    }

    function fetchData(response) {
      return response.data;
    }

    function checkperiodInput(data) {
      return $http.post('api/classes/checkperiod', data).then(fetchData);
    }

    function getUserCreatedPeriod(url) {
      return $http.get(url).then(fetchData);
    }

  }


})();