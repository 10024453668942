(function() {
    'use strict';

    angular
        .module('app')
        .config(config);
    
    function config($httpProvider, $authProvider, ChartJsProvider, ngIntlTelInputProvider, usSpinnerServiceProvider) {
        $httpProvider.interceptors.push('httpInterceptor');

        var spinnerFunction = function spinnerFunction(data, headersGetter) {
           // usSpinnerServiceProvider.$get().spin('spinner-1');
            return data;
        };
        $httpProvider.defaults.transformRequest.push(spinnerFunction);
        $authProvider.loginUrl = '/login';

        // Configure all charts
        ChartJsProvider.setOptions({
            chartColors: ['#008A80', '#FF0000', '#092CEA', '#FFE200', '#9DABB4', '#C604D6', '#00F740', '#921700'],
            responsive: true,
            legend: {
                display: true,
                position: 'bottom'
            },
        });
        // Configure all line charts
        ChartJsProvider.setOptions('line', {
            showLines: false
        });
        // Configure all line pie
        ChartJsProvider.setOptions('pie', {
            animation: {
                duration: 500,
                easing: "easeOutQuart",
                onComplete: function() {
                    let ctx = this.chart.ctx;
                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';

                    this.data.datasets.forEach(function(dataset) {

                        for (let i = 0; i < dataset.data.length; i++) {
                            let model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                                total = dataset._meta[Object.keys(dataset._meta)[0]].total,
                                mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius) / 2,
                                start_angle = model.startAngle,
                                end_angle = model.endAngle,
                                mid_angle = start_angle + (end_angle - start_angle) / 2;

                            let x = mid_radius * Math.cos(mid_angle);
                            let y = mid_radius * Math.sin(mid_angle);

                            ctx.fillStyle = '#fff';
                            if (i == 3) {
                                ctx.fillStyle = '#444';
                            }

                            let val = dataset.data[i];
                            let percent = String(Math.round(val / total * 100)) + "%";

                            if (val !== 0) {
                                ctx.fillText(percent, model.x + x, model.y + y + 15);
                            }
                        }
                    });
                }
            }
        });

        ngIntlTelInputProvider.set({
            initialCountry: 'us'
        });
    }
})();