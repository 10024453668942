(function () {
  'use strict';

  angular
    .module('app.login')
    .controller('TermsConditionController', TermsConditionController);

  /* @ngInject */
  function TermsConditionController($uibModalInstance) {
    /* jshint validthis: true */
    var vm = this;
    vm.cancel=cancel;

    function cancel() {
      $uibModalInstance.dismiss();
    }

      
    
  }
})();