(function() {
    angular
        .module('app.paperworks')
        .controller('CreateformController', CreateformController);

    function CreateformController($scope, dateRanges, periods, $state, $rootScope, paperworksService) {
        var vm = this;
        /*  assign properties*/
        vm.dateRanges = dateRanges;
        vm.periods = periods;
        vm.showformType = '';
        vm.searchbox = '';
        vm.paperwork_forms = [];



        /* assign methods*/
        vm.changeState = changeState;
        vm.callPreview = callPreview;
        
        paperworksService.getOptions('api/paperwork/types').then(function(data) {
            vm.paperwork_forms = data;
        });
        
        function callPreview(paperwork){

                 var winName = 'MyWindow';
                 var winURL = '/api/paperwork/preview';
                 var windowoption = 'resizable=yes,height=600,width=800,location=0,menubar=0,scrollbars=1';
                 var params = {
                     'paperwork_type': paperwork.form_type,
                     'formName': paperwork.abbreviated_name
 
                 };

                 var form = document.createElement("form");
                 form.setAttribute("method", "post");
                 form.setAttribute("action", winURL);
                 form.setAttribute("target", winName);
                 for (var i in params) {
                     if (params.hasOwnProperty(i)) {
                         var input = document.createElement('input');
                         input.type = 'hidden';
                         input.name = i;
                         input.value = params[i];
                         form.appendChild(input);
                     }
                 }
                 document.body.appendChild(form);
                 window.open('', winName, windowoption);
                 form.target = winName;
                 form.submit();
                 document.body.removeChild(form);
             }
       
        
        
        

        function changeState(paperwork, typevalue) {
            if(paperwork.type == 2 )
            {
                $rootScope.referralurl = paperwork.long_name;
                $state.go('paperworkforms.referralform', {
                    formtype: paperwork.abbreviated_name,
                    type: typevalue
                });
            }
            else if(paperwork.type == 1 )
            {
            	 $rootScope.referralurl = paperwork.long_name;
                $state.go('paperworkforms.letterform', {
                    formtype: paperwork.abbreviated_name,
                    type: typevalue
                });
            }
            else if(paperwork.abbreviated_name =='DN' )
            {
            	 $rootScope.referralurl = paperwork.long_name;
                $state.go('paperworkforms.letterform', {
                    formtype: paperwork.abbreviated_name,
                    type: typevalue
                });
            }
            else if(paperwork.abbreviated_name =='NT' )
            {
            	 $rootScope.referralurl = paperwork.long_name;
                $state.go('paperworkforms.letterform', {
                    formtype: paperwork.abbreviated_name,
                    type: typevalue
                });
            }
            
            else if(paperwork.abbreviated_name == 'AIP')
            {
                $state.go('academicIntervention', {viewtype :typevalue});
            }
            else if(paperwork.abbreviated_name == 'BIP')
            {
                $state.go('behaviorIntervention', {viewtype :typevalue});
            }
            else if(paperwork.abbreviated_name == 'AIP-SST')
            {
            	
                $state.go('academicIntervention-sst', {viewtype :typevalue});
            }
            else if(paperwork.abbreviated_name == 'BIP-SST')
            {
                $state.go('behaviorIntervention-sst', {viewtype :typevalue});
            }
        }
    }

})();