(function () {
  'use strict';

  angular
    .module('app.attendance-analytics')
    .config(function ($stateProvider) {
      let analyticsPeriods = [
        {
          title: 'This Week',
          slug: 'week',
          get start() {
            return moment().subtract(6, 'days');
          },
          get end() {
            return moment();
          },
        },
        {
          title: 'Last 2 Weeks',
          slug: 'last_2_week',
          get start() {
            return moment().subtract(13, 'days');
          },
          get end() {
            return moment();
          },
        },
        {
          title: 'This Month',
          slug: 'month',
          get start() {
            return moment().subtract(1, 'month').add(1, 'day');
          },
          get end() {
            return moment();
          },
        },
        {
          title: 'This School Year',
          slug: 'year',
          get start() {
            let now = this.end,
              augustCurrentYear = moment(now).month(7).date(1),
              august;

            if (now < augustCurrentYear) {
              august = moment(`${now.year() - 1}-08-01`);
            } else {
              august = augustCurrentYear;
            }

            return august;
          },
          get end() {
            return moment();
          },
        },
        {
          title: 'All Time',
          slug: 'all_time',
          get start() {
            return moment().subtract(1, 'years');
          },
          get end() {
            return moment();
          },
        },
        {
          title: 'Custom Range',
          slug: 'custom_range',
          range: {
            startDate: moment(),
            endDate: moment().add(1, 'day'),
          },
          get start() {
            return this.range.startDate;
          },
          get end() {
            return this.range.endDate;
          },
        }
      ];
        let attendanceType=[
            {
                title: 'Attendance Take Rate',
                id: '1',
            },
            {
                title: 'absenteeism',
                id: '2',
            },
            {
                title: 'Tardiness',
                id: '3',
            },

            {
                title: 'Skips',
                id: '4',
            }
        ];
        let TakeRateType=[
            {
                title: 'Teacher Take Rate',
                id: undefined,
            },
            {
                title: 'Student Attendance Rate',
                id: '1',
            },
            {
                title: 'absenteeism',
                id: '2',
            },
            {
                title: 'Tardiness',
                id: '3',
            },

            {
                title: 'Skips',
                id: '4',
            }
        ];
        let Grades=[
            {
                title: 'All Grades',
                id: undefined,
            },
            {
                title: 'kth',
                id: 1,
            },
            {
                title: '1th',
                id: 2,
            },
            {
                title: '2th',
                id: 3,
            },
            {
                title: '3th',
                id: 4,
            },
            {
                title: '4th',
                id: 5,
            },
            {
                title: '5th',
                id: 6,
            },
            {
                title: '6th',
                id: 7,
            },
            {
                title: '7th',
                id: 8,
            },
            {
                title: '8th',
                id: 9,
            },
            {
                title: '9th',
                id: 10,
            },
            {
                title: '10th',
                id: 11,
            },
            {
                title: '11th',
                id: 12,
            },
            {
                title: '12th',
                id: 13,
            },

        ];
        let GradesSubject=[
            {
                title: 'kth',
                id: 1,
            },
            {
                title: '1th',
                id: 2,
            },
            {
                title: '2th',
                id: 3,
            },
            {
                title: '3th',
                id: 4,
            },
            {
                title: '4th',
                id: 5,
            },
            {
                title: '5th',
                id: 6,
            },
            {
                title: '6th',
                id: 7,
            },
            {
                title: '7th',
                id: 8,
            },
            {
                title: '8th',
                id: 9,
            },
            {
                title: '9th',
                id: 10,
            },
            {
                title: '10th',
                id: 11,
            },
            {
                title: '11th',
                id: 12,
            },
            {
                title: '12th',
                id: 13,
            },

        ];

      // Now set up the states
      $stateProvider
        .state('attendance-analytics', {
          parent: 'base',
          abstract: true,
          url: '/attendance-analytics',
          templateUrl: '/app/partials/attendance-analytics/layout.html',
          data: {
            permissions: {
              //only: ['analytics'],
              redirectTo: 'addnewclass',
            },
          },
        })
        .state('attendance-analytics.quickview', {
          url: '/quickview',
          templateUrl: '/app/partials/attendance-analytics/quickview/quickview.html',
          controller: 'AttendanceQuickviewController',
          controllerAs: 'AttendanceQuickviewCtrl',
          resolve: {
            teacherTypes: function (teacherTypesService) {
              return teacherTypesService.all().then(function (types) {
                types.unshift({
                  title: 'All Teacher Subjects',
                  id: undefined,
                });

                return types;
              });
            },
            grades: function () {
                return Grades;
            },
            periods: function () {
              return analyticsPeriods;
            },
              attendanceType: function () {
                  return attendanceType;
              },
          }
        })
        .state('attendance-analytics.subject-grade-comparisons', {
          url: '/subject-grade-comparisons',
          templateUrl: '/app/partials/attendance-analytics/subject-grade-comparisons/subject-grade-comparisons.html',
          controller: 'AttendanceSubjectGradeComparisonsController',
          controllerAs: 'AttendanceSubjectGradeComparisonsCtrl',
          resolve: {
            teacherTypes: function (teacherTypesService) {
              return teacherTypesService.all();
            },
            grades: function () {
              return GradesSubject;
            },
            categories: function () {
                return TakeRateType;
            },
            types: function (cannedMessagesService) {
              return cannedMessagesService.types().then(function (types) {
                types.unshift({
                  title: 'All',
                  id: undefined,
                });

                return types;
              });
            },
            periods: function () {
              return analyticsPeriods;
            },
          }
        })
        .state('attendance-analytics.teacher-comparisons', {
          url: '/teacher-comparisons',
          templateUrl: '/app/partials/attendance-analytics/teacher-comparisons/teacher-comparisons.html',
          controller: 'AttendanceTeacherComparisonsController',
          controllerAs: 'AttendanceTeacherComparisonsCtrl',
          resolve: {
            teacherTypes: function (teacherTypesService) {
              return teacherTypesService.all().then(function (types) {
                types.unshift({
                  title: 'All Teacher Subjects',
                  id: undefined,
                });

                return types;
              });
            },
            periods: function () {
              return analyticsPeriods;
            },
              attendanceType: function () {
                  return TakeRateType;
              },
          }
        })
        .state('attendance-analytics.teacher-details', {
          url: '/teacher-details',
          templateUrl: '/app/partials/attendance-analytics/teacher-details/teacher-details.html',
          controller: 'AttendanceTeacherDetailsController',
          controllerAs: 'AttendanceTeacherDetailsCtrl',
          resolve: {
            teachers: function (teachersService) {
              return teachersService.all().then(function (teachers) {
                      teachers.unshift({
                          first_name: 'All',
                          last_name: 'Teacher',
                          id: undefined,
                      });
                  return teachers;
              });
            },
              periodTeachers: function (cannedMessagesService) {
                  return cannedMessagesService.periodTeachers().then(function (periodTeachers) {
                      periodTeachers.unshift({
                          title: 'All Period',
                          id: undefined,
                      });

                      return periodTeachers;
                  });
              },
            categories: function (cannedMessagesService) {
              return cannedMessagesService.categories().then(function (categories) {
                categories.unshift({
                  title: 'All',
                  id: undefined,
                });

                return categories;
              });
            },
            types: function (cannedMessagesService) {
              return cannedMessagesService.types().then(function (types) {
                types.unshift({
                  title: 'All',
                  id: undefined,
                });

                return types;
              });
            },


            periods: function () {
              return analyticsPeriods;
            },
              attendanceType: function () {
              return attendanceType;
            },

          }
        });
    });

})();