(function () {
  'use strict';

  angular
    .module('app.communications-analytics.quickview')
    .service('communicationAnalyticsService', communicationAnalyticsService);

  /* @ngInject */
  function communicationAnalyticsService($http, helperService) {
    /* jshint validthis: true */
    var vm = this;

    vm.quickview = quickview;
    vm.subjectGradeComparisons = subjectGradeComparisons;
    vm.teacherComparisons = teacherComparisons;
    vm.teacherDetails = teacherDetails;

    ////////////

    function quickview(filters) {
      // prepare filters

      return $http.get('/api/analytics/communications/quickview', {params: filters})
        .then(helperService.handleResponse)
        .then(function (data) {
          // Prepare data before view

          return data;
        });
    }

    function subjectGradeComparisons(filters) {
      // prepare filters

      return $http.get('/api/analytics/communications/subject-grade-comparisons', {params: filters})
        .then(helperService.handleResponse)
        .then(function (data) {
          // Prepare data before view

          return data;
        });
    }

    function teacherComparisons(filters) {
      // prepare filters

      return $http.get('/api/analytics/communications/teacher-comparisons', {params: filters})
        .then(helperService.handleResponse)
        .then(function (data) {
          // Prepare data before view

          return data;
        });
    }

    function teacherDetails(filters) {
      // prepare filters

      return $http.get('/api/analytics/communications/teacher-details', {params: filters})
        .then(helperService.handleResponse)
        .then(function (data) {
          // Prepare data before view

          return data;
        });
    }
  }
})();