(function() {
    'use strict';

    angular
        .module('app.classes')
        .controller('ClassesPopUpController', ClassesPopUpController);

    /* @ngInject */
    function ClassesPopUpController($uibModalInstance, $scope, $rootScope, positivebehavIncident, negativebehavIncident, positiveacadIncident, negativeacadIncident,
        Interventions, location, type, modaltype, student, ClassesService, paperworksService, $state) {
        /* jshint validthis: true */
        var vm = this;

        vm.student = student;
        vm.type = type;
        vm.modalType = modaltype;

        vm.cancel = cancel;
        vm.send = send;
        vm.changeIcon = changeIcon;
        vm.changetopic = changetopic;


        vm.errors = [];

        //////////////////

        //declare variable for modal
        vm.incidents = '';
        vm.intervaentions = Interventions;
        vm.locations = location;
        vm.shownegative = (type == 2) ? true : false;
        vm.settypeIcon = (type == 1) ? 'fa fa-smile-o' : 'fa fa-frown-o';
        vm.heading = '';
        if(modaltype === 'paperworks'){
        	 paperworksService.getOptions('api/paperwork/types').then(function(data) {
        		
                 vm.paperwork_forms = data;
             });
        }
        vm.showformType = '';
        vm.selectedpaperwork = '';
        vm.paperworktype ='';
        vm.paperworkfullname = '';
        vm.paperworkformId = '';
       
        vm.incidentform = {
            student_id: student.id,
            class_id: 0,
            incident_type: modaltype,
            incident_status: type,
            incident_topic: '',
            incident_detail: '',
            intervention_des: Interventions[0].value,
            intervention_det: '',
            incident_location: location[0].value,
            incident_other_location: '',
            other_incident_topic: '',
            other_intervention_des: '',
            intervention_work: "1",
        };
        
        vm.paperworkAssign = paperworkAssign;
        
        changetopic();
        
       function changetopic(){
    	
    	   switch (true) {
           case (type == 1 && modaltype == 'behavior'):
               vm.incidents = positivebehavIncident;
               vm.incidentform.incident_topic = positivebehavIncident[0].value;
               vm.heading = 'Positive Behavior Incident';
               break;
           case (type == 2 && modaltype == 'behavior'):
               vm.incidents = negativebehavIncident;
               vm.incidentform.incident_topic = negativebehavIncident[0].value;
               vm.heading = 'Negative Behavior Incident';
               break;
           case (type == 1 && modaltype == 'academic'):
               vm.incidents = positiveacadIncident;
               vm.incidentform.incident_topic = positiveacadIncident[0].value;
               vm.heading = 'Positive Academic Incident';
               break;
           case (type == 2 && modaltype == 'academic'):
               vm.incidents = negativeacadIncident;
               vm.incidentform.incident_topic = negativeacadIncident[0].value;
               vm.heading = 'Negative Academic Incident';
               break;
       }
       }

       /* angular.element(document).ready(function() {
        	alert();
            switch (true) {
                case (type == 1 && modaltype == 'behavior'):
                    vm.incidents = positivebehavIncident;
                    vm.incidentform.incident_topic = positivebehavIncident[0].value;
                    vm.heading = 'Positive Behavior Incident';
                    break;
                case (type == 2 && modaltype == 'behavior'):
                    vm.incidents = negativebehavIncident;
                    vm.incidentform.incident_topic = negativebehavIncident[0].value;
                    vm.heading = 'Negative Behavior Incident';
                    break;
                case (type == 1 && modaltype == 'academic'):
                    vm.incidents = positiveacadIncident;
                    vm.incidentform.incident_topic = positiveacadIncident[0].value;
                    vm.heading = 'Positive Academic Incident';
                    break;
                case (type == 2 && modaltype == 'academic'):
                    vm.incidents = negativeacadIncident;
                    vm.incidentform.incident_topic = negativeacadIncident[0].value;
                    vm.heading = 'Negative Academic Incident';
                    break;
            }
        });*/


        vm.noteform = {
            student_id: student.id,
            note: ''

        };


        function cancel() {
            $uibModalInstance.dismiss();
        }

        function changeIcon(value) {
            $rootScope.$emit("setIcon", {
                name: value
            });
            $uibModalInstance.close();
        }



        function send(typetitle) {

            $rootScope.modalerror = true;
            var form = {};
            var urlparemeter = '';
            if (typetitle == 'incident') {
                form = vm.incidentform;
                urlparemeter = 'api/classes/incident';
            }
            else if (typetitle == 'note') {
                form = vm.noteform;
                urlparemeter = 'api/classes/addnote';
            }


            ClassesService.create(urlparemeter, form).then(function(response) {

                $rootScope.modalerror = false;
                $uibModalInstance.close();

            });

        }
        
        function paperworkAssign(){
        	
        	switch(parseInt(vm.paperworktype)){
	        	case 1:
	        	
	               var letterform = {
	                    letter_form_id: vm.paperworkformId,
	                    letter_type : vm.selectedpaperwork,
	                    studentid : vm.student.id     
	            	};

	             paperworksService.saveActivity('/api/paperwork/letter/assign-from-classes',letterform).then(function(response){ 
                    
	            	 $rootScope.referralurl = vm.paperworkfullname;
	                 $state.go('paperworkforms.letterform', {
	                     formtype: vm.selectedpaperwork,
	                     type: 'edit',
	                     studentId: vm.student.id,
	                     formId: response.id,
	                     paperworkfromClass: true
	                 });
	             }); 	        			        		
	        		 
	                $uibModalInstance.close();
	            break;
	        	case 2:
	        		 var referralform = {
	                    student_id: vm.student.id,
	                    referral_type: vm.selectedpaperwork 
	            	};
	        		 paperworksService.saveActivity('/api/referralform/saveactivity', referralform).then(function(response) {
	        			 $rootScope.referralurl = vm.paperworkfullname;
		                 $state.go('paperworkforms.referralform', {
		                     formtype: vm.selectedpaperwork,
		                     type: 'edit',
		                     studentId: vm.student.id,
		                     formId: response.id,
		                     paperworkfromClass: true
		                 });
	        		 });
	        		
	                 $uibModalInstance.close();
	            break;
	        	case 3:
	        		 var rtiform = {
	                    student_id: vm.student.id,
	                    rti_type: vm.selectedpaperwork,
	                    reason: (vm.selectedpaperwork ==='AIP' || vm.selectedpaperwork ==='AIP-SST')?'Reading':'Behavior'
	            	};
	        		 paperworksService.saveipForm('/api/paperwork/aip/form', rtiform).then(function(response) {
	        			 
	        			 if (vm.selectedpaperwork === 'AIP') {
		                     $state.go("academicIntervention", {
		                         studentId: vm.student.id,
		                         reason: 'Reading',
		                         paperworkfromClass: true
		                     });
		                 } else if (vm.selectedpaperwork === 'AIP-SST') {
		                     $state.go("academicIntervention-sst", {
		                         studentId: vm.student.id,
		                         reason: 'Reading',
		                         paperworkfromClass: true
		                     });
		                 } else if (vm.selectedpaperwork === 'BIP') {
		                     $state.go("behaviorIntervention", {
		                         studentId: vm.student.id,
		                         reason: 'Behavior',
		                         paperworkfromClass: true
		                     });
		                 } else if (vm.selectedpaperwork === 'BIP-SST') {
		                     $state.go("behaviorIntervention-sst", {
		                         studentId: vm.student.id,
		                         reason: 'Behavior',
		                         paperworkfromClass: true
		                     });
		                 }
	        		 });
	        		
	        		
	        		 
	        		 $uibModalInstance.close();
	            break;
	        	case 4:
		        	
		               var generalform = {
		                    letter_form_id: vm.paperworkformId,
		                    letter_type : vm.selectedpaperwork,
		                    studentid : vm.student.id ,
		                    paperworkfromClass: true
		            	};

		             paperworksService.saveActivity('/api/paperwork/letter/assign-from-classes',generalform).then(function(response){ 
		            	 $rootScope.referralurl = vm.paperworkfullname;
		                 $state.go('paperworkforms.letterform', {
		                     formtype: vm.selectedpaperwork,
		                     type: 'edit',
		                     studentId: vm.student.id,
		                     formId: response.id
		                 });
		             }); 
	        		
	        		
	                 $uibModalInstance.close();
	        	break;	
	        	//$uibModalInstance.close();
        	}
       	
        }


    }
})();