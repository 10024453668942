(function (){
	'use strict';
	
	angular
		.module('app.classes')
		.controller('PreviewPopUpController', PreviewPopUpController);
	
	function PreviewPopUpController($uibModalInstance, $scope, students){
		
		var vm = this;

		vm.students = students;
		vm.orderBy = 'fullname';
		vm.cancel = cancel;
		
		
		 function cancel() {
	            $uibModalInstance.dismiss();
	        }

		
	}
	
	
	
	
})();