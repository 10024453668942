(function () {
  'use strict';

  angular
    .module('app.setup-account')
    .config(function ($stateProvider) {

      // Now set up the states
      $stateProvider
        .state('setup-account', {
          url: '/setup-account',
          templateUrl: '/app/partials/setup-account/layout.html',
          data: {
          },
          controller: 'SetupAccountController',
          controllerAs: 'SetupAccountCtrl',
          resolve: {
            states: (statesService) => {
              return statesService.all();
            },
            staffTypes: (staffTypesService) => {
              return staffTypesService.all();
            },
          }
        })
        .state('signup', {
          url: '/signup',
          templateUrl: '/app/partials/setup-account/signup.html',
          controller: 'StudentSignUpController',
          controllerAs: 'StudentSignUpCtrl',
        })
        .state('signupform', {
          url: '/signupform/:accesscode?',
          templateUrl: '/app/partials/setup-account/signupform.html',
          controller: 'StudentSignUpFormController',
          controllerAs: 'StudentSignUpFormCtrl',
        })
        .state('signupcomplete', {
          url: '/signup/complete',
          params: {
            accesscode: null,
          },
          templateUrl: '/app/partials/setup-account/signupsuccess.html',
          controller: 'StudentSignUpSuccessController',
          controllerAs: 'StudentSignUpSuccessCtrl',
        });
    });

})();