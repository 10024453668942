(function () {
  'use strict';

  angular
    .module('app.dashboard.main')
    .controller('RemoveToDoItemController', RemoveToDoItemController);

  /* @ngInject */
  function RemoveToDoItemController(toDoItem,SelectedtoDoItem, toDoService,$uibModalInstance) {

    /* jshint validthis: true */
    var vm = this;

    vm.toDoItem = toDoItem; 

    vm.selectedTodoItem=SelectedtoDoItem;
    vm.submitItem = submitItem;
    vm.submit = submit;
    vm.update = update;
    vm.cancel = cancel;

    ///////////////////

    function submitItem()
    {
      //usSpinnerService.spin('spinner-1');
      console.log(SelectedtoDoItem);
      angular.forEach(SelectedtoDoItem, function(value, key){
      
       if (value.is_completed) 
       {
           toDoService.cancelCompletion(value);
       } 
       else 
       {
          toDoService.complete(value);
       }

      });

      //usSpinnerService.stop('spinner-1');

      $uibModalInstance.close();

    }

    function submit() {
      $uibModalInstance.close();
    }

    function update() {
      $uibModalInstance.close();
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();