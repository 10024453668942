(function () {
  'use strict';

  angular
    .module('app')
    .service('staffTypesService', staffTypesService);

  /* @ngInject */
  function staffTypesService($http, helperService) {
    /* jshint validthis: true */
    let vm = this;

    vm.all = all;
    vm.admin = admin;
    vm.vestedMember = vestedMember;

    //////////////////////

    function all() {
      return $http.get('/api/staff-types').then(helperService.handleResponse);
    }

    function admin() {
      return $http.get('/api/staff-types/admin').then(helperService.handleResponse);
    }

    function vestedMember() {
      return $http.get('/api/staff-types/vested-member').then(helperService.handleResponse);
    }
  }
})();