(function () {
  'use strict';

  angular
    .module('app')
    .filter('accommodation', accommodation);

  /* @ngInject */
  function accommodation() {
    return function (accommodationIntValue) {
      var accommodationStringValue = 'None';

      switch (parseInt(accommodationIntValue)) {
        case 1:
          accommodationStringValue = 'IEP';
          break;
        case 2:
          accommodationStringValue = 'SST';
          break;
        case 3:
          accommodationStringValue = '504';
          break;
      }

      return accommodationStringValue;
    };
  }

})();