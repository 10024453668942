(function () {

  'use strict';

  angular
    .module('app')
    .constant('uiView', '<div ui-view></div>')
    .constant('statesWithoutAuthenticated', ['login', 'registration', 'activation','adminactivation','resetpassword','signup','signupform','signupcomplete','forgotpassword'])
    .constant('personTitles', ['Mr.', 'Mrs.', 'Miss.', 'Ms.', 'Dr.'])
    .constant('staffRoles', ['principal', 'assistant-principal', 'counselor','registrar', 'social-worker', 'sst-coordinator'])
    .constant('defaultStatesByRoles', {
      admin: 'communications-analytics.quickview',
      teacher: 'dashboard.main',
    })
    .constant('dateRanges', [
      {
        title: 'Today',
        value: 'today',
        index: 0,
      },
      {
        title: 'This Week',
        value: 'week',
        index: 1,
      },
      {
        title: 'Last 2 Weeks',
        value: 'twoWeek',
        index: 2
      },
      {
        title: 'This Month',
        value: 'month',
        index: 3
      },
      {
        title: 'This School Year',
        value: 'year',
        index: 4
      },
      {
        title: 'Custom Range',
        value: 'custom',
        index: 5
      }
    ])
    .constant('modes', [
      {
        title: 'All Modes',
        value: ''
      },
      {
        title: 'Text',
        value: 1
      },
      {
        title: 'Call',
        value: 2
      },
      {
        title: 'Email',
        value: 3
      }
    ])
    .constant('periods', [
      {
        title: 'All',
        value: ''
      },
      {
        title: 'Period 1',
        value: 1
      },
      {
        title: 'Period 2',
        value: 2
      },
      {
        title: 'Period 3',
        value: 3
      },
      {
        title: 'Period 4',
        value: 4
      },
      {
        title: 'Period 5',
        value: 5
      },
      {
        title: 'Period 6',
        value: 6
      },
      {
        title: 'Rocket Club',
        value: 7
      },
      {
        title: 'Astronomy Club',
        value: 8
      },
      {
        title: 'Running Club',
        value: 9
      }
    ])
    .constant('reports', [

      {
        title: 'Communications',
        value: 1
      },
      {
        title: 'Attendance',
        value: 2
      },
      {
        title: 'Behavior',
        value: 3
      },
      {
        title: 'Paperwork',
        value: 4
      },
      {
        title: 'Academic',
        value: 5
      }
    ])
    .constant('attendType', [
      {
        title: 'All',
        value: ''
      },
      {
        title: 'Present',
        value: 1

      },

      {
        title: 'Absent',
        value: 2

      },

      {
        title: 'Tardy',
        value: 3

      },

      {
        title: 'Skips',
        value: 4

      },

      {
        title: 'Contacts made',
        value: 5

      },


    ])
    .constant('incidentTopic', [

      {
        title: 'All',
        value: ''
      },
      {
        title: 'disruption',
        value: 1
      },

      {
        title: 'disrespect',
        value: 2
      },
      {
        title: 'property misuses',
        value: 3
      },
      {
        title: 'dress code',
        value: 4
      },
      {
        title: 'electronic violation',
        value: 5
      },

      {
        title: 'inappropriate language',
        value: 6
      },
      {
        title: 'bullying/harassment',
        value: 7
      },
      {
        title: 'well behaved',
        value: 8
      },
      {
        title: 'classroom contribution',
        value: 9
      },
      {
        title: 'other',
        value: 10
      },

    ])
    .constant('behaviorincidentTopic', [
      {
        title: 'All',
        value: '',
        index: 0
      },
      {
        title: 'Well Behaved',
        value: 'pos-1',
        index: 1
      },
      {
        title: 'Positive Class Contribution',
        value: 'pos-2',
        index: 2
      },
      {
        title: 'Disruption',
        value: 'neg-1',
        index: 3
      },
      {
        title: 'Disrespect',
        value: 'neg-2',
        index: 4
      },
      {
        title: 'Property Misuse',
        value: 'neg-3',
        index: 5
      },
      {
        title: 'Dress Code',
        value: 'neg-4',
        index: 6
      },
      {
        title: 'Electronic Violation',
        value: 'neg-5',
        index: 7
      },
      {
        title: 'Innaprorpiate Lanuage',
        value: 'neg-6',
        index: 8
      },
      {
        title: 'Bullying/Harrasment',
        value: 'neg-7',
        index: 9
      },
      {
        title: 'Fighting',
        value: 'neg-8',
        index: 10
      },
      {
        title: 'Theft',
        value: 'neg-9',
        index: 11
      },
      {
        title: 'Other',
        value: 'ot',
        index: 12
      }

    ])
    .constant('academicincidentTopic', [
      {
        title: 'All',
        value: '',
        index: 0
      },
      {
        title: 'Most Improved',
        value: 'pos-1',
        index: 1
      },
      {
        title: 'Homework Completed',
        value: 'pos-2',
        index: 2
      },
      {
        title: 'Assisting Classmate',
        value: 'pos-3',
        index: 3
      },
      {
        title: 'Answering Question',
        value: 'pos-4',
        index: 4
      },
      {
        title: 'Class Participation',
        value: 'pos-5',
        index: 5
      },
      {
        title: 'Hardwork',
        value: 'pos-6',
        index: 6
      },
      {
        title: 'Achievement',
        value: 'pos-7',
        index: 7
      },
      {
        title: 'Cheating',
        value: 'neg-1',
        index: 8
      },
      {
        title: 'Incomplete HW',
        value: 'neg-2',
        index: 9
      },
      {
        title: 'Messy Work',
        value: 'neg-3',
        index: 10
      },
      {
        title: 'No Heading',
        value: 'neg-4',
        index: 11
      },
      {
        title: 'No Notes',
        value: 'neg-5',
        index: 12
      },
      {
        title: 'Unorganized Notebook',
        value: 'neg-6',
        index: 13
      },
      {
        title: 'Off Task',
        value: 'neg-8',
        index: 14
      },
      {
        title: 'Unprepared for class',
        value: 'neg-9',
        index: 15
      },
      {
        title: 'Other',
        value: 'neg-ot',
        index: 16
      }

    ])


     .constant('paperworkstatus', [
      {
        title: 'All',
        value: '',
        valtitle:''
      },
      {
        title: 'Completed',
        value: 1,
        valtitle:'Completed'
        
      },
      {
        title: 'In Monitoring',
        value: 2,
        valtitle:'In-Monitoring'
      },
      {
        title: 'In Progress',
        value: 3,
        valtitle:'In-Progress'
      },

    ])
    .constant('paperworktype', [

      {
        title: 'All',
        value: ''
      }, {
        title: 'Letters/Notes',
        value: 1
      }, {
        title: 'Referrals',
        value: 2
      }, {
        title: 'RTI',
        value: 3
      }, {
        title: 'General',
        value: 4
      }
    ])
    .constant('academicIncident', [
      {
        title: 'All',
        value: '',
        id: ''
      },
      {
        title: 'Cheating',
        value: 'ch',
        id: 1
      },
      {
        title: 'Incomplete homework',
        value: 'ih',
        id: 2
      },
      {
        title: 'Messy work',
        value: 'mw',
        id: 3
      },
      {
        title: 'No heading',
        value: 'nh',
        id: 4
      },
      {
        title: 'No notes',
        value: 'nn',
        id: 5
      },
      {
        title: 'Unorganized notebook',
        value: 'un',
        id: 6
      },
      {
        title: 'Off task',
        value: 'ot',
        id: 7
      },
      {
        title: 'Unprepared for class',
        value: 'ufc',
        id: 8
      },
      {
        title: 'Most improved',
        value: 'mi',
        id: 9
      },
      {
        title: 'Homework Completed',
        value: 'hc',
        id: 10
      },
      {
        title: 'Assigning Yourself',
        value: 'ay',
        id: 11
      },
      {
        title: 'Answering question',
        value: 'aq',
        id: 12
      },
      {
        title: 'Class participation',
        value: 'cp',
        id: 13
      },
      {
        title: 'Hard work',
        value: 'hw',
        id: 14
      },
      {
        title: 'Achievement',
        value: 'at',
        id: 15
      },
    ])
    .constant('priority', [
      {
        title: 'All Students',
        value: '',
        text: ''
      },
      {
        title: 'High Priority',
        value: 1,
        text: 'highpriority'
      },
      {
        title: 'General Priority',
        value: 2,
        text: 'lowpriority'
      }
    ])
    .constant('classtype', [
      {
        title: 'Language Arts',
        value: 1
      },
      {
        title: 'Mathematics',
        value: 2
      },
      {
        title: 'Science',
        value: 3
      },
      {
        title: 'History',
        value: 4
      },
      {
        title: 'Foreign Language',
        value: 5
      },
      {
        title: 'Health Education',
        value: 6
      },
      {
        title: 'CTAE',
        value: 7
      },
      {
        title: 'Others',
        value: 8
      }
    ])

    .constant('clubtype', [
      {
        title: 'Environmental Club',
        value: 1
      },
      {
        title: 'Robotics Club',
        value: 2
      },
      {
        title: 'Chess Club',
        value: 3
      },
      {
        title: 'Debate Club',
        value: 4
      },
      {
        title: 'Video GameClub',
        value: 5
      },
      {
        title: 'Science Club',
        value: 6
      },
      {
        title: 'Math Club',
        value: 7
      },
      {
        title: 'Other',
        value: 8
      },

    ])
    .constant('grade', ['k', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])
    .constant('periods_no', [1, 2, 3, 4, 5, 6, 7, 8, 9])
    .constant('periods_letter', ['None', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'])
    .constant('positivebehavIncident', [
      {
        title: 'Well Behaved',
        value: 'pos-1'
      },
      {
        title: 'Positive Class Contribution',
        value: 'pos-2'
      },
      {
        title: 'Other',
        value: 'ot'
      },
    ])
    .constant('negativebehavIncident', [
      {
        title: 'Disruption',
        value: 'neg-1'
      },
      {
        title: 'Disrespect',
        value: 'neg-2'
      },
      {
        title: 'Property Misuse',
        value: 'neg-3'
      },
      {
        title: 'Dress Code',
        value: 'neg-4'
      },
      {
        title: 'Electronic Violation',
        value: 'neg-5'
      },
      {
        title: 'Innaprorpiate Lanuage',
        value: 'neg-6'
      },
      {
        title: 'Bullying/Harrasment',
        value: 'neg-7'
      },
      {
        title: 'Fighting',
        value: 'neg-8'
      },
      {
        title: 'Theft',
        value: 'neg-9'
      },
      {
        title: 'Other',
        value: 'ot'
      },])
    .constant('positiveacadIncident', [
      {
        title: 'Most Improved',
        value: 'pos-1'
      },
      {
        title: 'Homework Completed',
        value: 'pos-2'
      },
      {
        title: 'Assisting Classmate',
        value: 'pos-3'
      },
      {
        title: 'Answering Question',
        value: 'pos-4'
      },
      {
        title: 'Class Participation',
        value: 'pos-5'
      },
      {
        title: 'Hardwork',
        value: 'pos-6'
      },
      {
        title: 'Achievement',
        value: 'pos-7'
      },
      {
        title: 'Other',
        value: 'ot'
      },
    ])
    .constant('negativeacadIncident', [
      {
        title: 'Cheating',
        value: 'neg-1'
      },
      {
        title: 'Incomplete HW',
        value: 'neg-2'
      },
      {
        title: 'Messy Work',
        value: 'neg-3'
      },
      {
        title: 'No Heading',
        value: 'neg-4'
      },
      {
        title: 'No Notes',
        value: 'neg-5'
      },
      {
        title: 'Unorganized Notebook',
        value: 'neg-6'
      },
      {
        title: 'Off Task',
        value: 'neg-8'
      },
      {
        title: 'Unprepared for class',
        value: 'neg-9'
      },
      {
        title: 'Other',
        value: 'ot'
      },])
    .constant('Interventions', [
      {
        title: 'Indiviual Conference w/Student',
        value: 1
      },
      {
        title: 'Requested Parent Conference',
        value: 2
      },
      {
        title: 'Conferred with Team',
        value: 3
      },
      {
        title: 'Differentiation',
        value: 4
      },
      {
        title: 'Mailed Note',
        value: 5
      },
      {
        title: 'Temporary placement',
        value: 6
      },
      {
        title: 'Teacher detention',
        value: 7
      },
      {
        title: 'Phone call home',
        value: 8
      },
      {
        title: 'Verbal warning',
        value: 9
      },
      {
        title: 'Written warning',
        value: 10
      },
      {
        title: 'Academic contract',
        value: 11
      },
      {
        title: 'Timeout',
        value: 12
      },
      {
        title: 'Behavior Contract',
        value: 13
      },
      {
        title: 'Counselor Referralt',
        value: 14
      },
      {
        title: 'Social worker Referral',
        value: 15
      },
      {
        title: 'Seat change',
        value: 16
      },
      {
        title: 'Silent lunch',
        value: 17
      },
      {
        title: 'Other',
        value: 18
      },])
    .constant('location', [
      {
        title: 'In room 1314(this classroom)',
        value: 'in-room',
        index: 0
      },
      {
        title: 'Outside the classroom door',
        value: 'outside-classroom',
        index: 1
      },
      {
        title: 'In the immediate hallway',
        value: 'in-immediate-hallway',
        index: 2
      },
      {
        title: 'Other',
        value: 'other',
        index: 3
      }

    ]).constant('club_frequency', [
    {
      title: 'Meet Everyday',
      value: 'meet-everyday',

    },
    {
      title: 'Meet Every other day',
      value: 'meet-every-other-day',

    },
    {
      title: 'Meet Twice a week',
      value: 'meet-twice-a-week',

    },
    {
      title: 'Meet Three Times a Week',
      value: 'meet-three-times-a-Week',

    }
  ])
    .constant('days', [
      {
        title: 'Monday',
        value: 'monday',

      },
      {
        title: 'Tuesday',
        value: 'tuesday',

      },
      {
        title: 'Wednesday',
        value: 'wedneday',

      },
      {
        title: 'Thursday',
        value: 'thursday',

      },
      {
        title: 'Friday',
        value: 'friday',

      }
    ])

    .constant('semester', [1, 2, 3, 4])
    .constant('classs_frequency', ['Meet Everyday', 'Meet Every other day'])
    .constant('behavior_reason', [
    	{
            title: 'Select',
            value: ''
          },
    	{
        title: 'Repeated Disruption',
        value: 'repeated-disruption'
      },
      {
        title: 'RepeatedDisrespect',
        value: 'repeated-disrespect'
      },
      {
        title: 'RepeatedProperty Misuse',
        value: 'repeated-property-misuse'
      },
      {
        title: 'RepeatedDress Code',
        value: 'repeated-dress-code'
      },
      {
        title: 'RepeatedElectronic Violation',
        value: 'repeated-electronic-violation'
      },
      {
        title: 'RepeatedInnaprorpiate Lanuage',
        value: 'repeated-innaprorpiate-lanuage'
      },
      {
        title: 'Bullying/Harrasment',
        value: 'bullying/harrasment'
      },
      {
        title: 'Fighting',
        value: 'fighting'
      },
      {
        title: 'Theft',
        value: 'theft'
      },
      {
        title: 'Other',
        value: 'other'
      }

    ])
    .constant('attendance_reason', [
    	{
            title: 'Select',
            value: ''
          },
      {
        title: 'Excessive unexcused absences',
        value: 'excessive-unexcused-absences'
      },
      {
        title: 'Excessive excused absences',
        value: 'excessive-excused-absences'
      },
      {
        title: 'Excessive tardies',
        value: 'excessive-tardies'
      },
      {
        title: 'Other',
        value: 'other'
      }

				])
	 .constant('academic_reason',[
		 {title:'Select',
				value: ''
			},
		 {title:'Cheating',
				value: 'cheating'
			},
			{
				title:'Incomplete Homeworks',
				value: 'incomplete-homeworks'
			},
			{
				title:'Messy Work',
				value: 'messy-work'
			},
			{
				title:'No Heading',
				value: 'no-heading'
			},
	 {
	 title: 'No Notes',
			 value:'no-notes'
		 },
			{
				title:'Off task',
				value: 'off-task'
			},
			{
				title:'Unprepared for class',
				value: 'unprepared-for-class'
			},
			{
				title:'Unorganized Notebook',
				value: 'unorganized-notebook'
			},
{
				title: 'Other',
			 value:'other'
		 }])
	  .constant('socialwork_reason',[
		  {
			    title:'Select',
				value: ''
			},
		  {
			    title:'Death/Divorce',
				value: 'death/divorce'
			},
			{
				title:'Depression',
				value: 'depression'
			},
			{
				title:'Sexual Inappropriatness',
				value: 'sexual-inappropriatness'
			},
			{
				title:'Lack of Respect/Bullies/Intimidates',
				value: 'lack-of-respect/Bullies/Intimidates'
		 },
		 {
			 title: 'Physical Agression',
			 value:'Physical-agression'
		 },
		 {
			 title: 'Self Harm/Suicidal Thoughts',
			 value:'self-harm/Suicidal-thoughts'
			},
			{
				title:'Unprepared for class',
				value: 'unprepared-for-class'
		 },
		 {
			 title: 'Hygiene',
			 value:'hygiene'
		 },
		 {
			 title: 'Anexity',
			 value:'anexity'
		 },
		 {
			 title: 'Excessive Verbal Inappropriatness',
			 value:'excessive-verbal-inappropriatness'
		 },
		 {
			 title: 'Verbal Agression',
			 value:'verbal-agression'
		 },
		 {
			 title: 'FamilyIssues',
			 value:'familyIssues'
		 },
		 {
			 title: 'Poor Peer Relations/Social Skills',
			 value:'poor-peer-relations/Social-Skills'
			},
			{
				title:'Other',
				value: 'other'
		 }

    ])
    .constant('action_taken', [
      {
        title: 'Indiviual Conference w/Student',
        value: 'ic'
      },
      {
        title: 'Requested Parent Conference',
        value: 'rp'
      },
      {
        title: 'Conferred with Team',
        value: 'ct'
      },
      {
        title: 'Differentiation',
        value: 'dif'
      },
      {
        title: 'Mailed Note',
        value: 'm-notes'
      },
      {
        title: 'Temporary placement',
        value: 'tp'
      },
      {
        title: 'Teacher detention',
        value: 'td'
      },
      {
        title: 'Phone call home',
        value: 'pch'
      },
      {
        title: 'Verbal warning',
        value: 'v-warn'
      },
      {
        title: 'Written warning',
        value: 'w-warn'
      },
      {
        title: 'Academic contract',
        value: 'a-contract'
      },
      {
        title: 'Timeout',
        value: 't-out'
      },
      {
        title: 'Behavior Contract',
        value: 'b-contract'
      },
      {
        title: 'Counselor Referral',
        value: 'c-ref'
      },
      {
        title: 'Social worker Referral',
        value: 'sw-ref'
      },
      {
        title: 'Seat change',
        value: 's-change'
      },
      {
        title: 'Silent lunch',
        value: 's-lunch'
      },
      {
        title: 'Other',
        value: 'ot'
      }

    ]).constant('paperwork_referrals', [
    {
      title: 'Attendance Referral',
      state: 'paperworkforms.referralform',
      id: 1,
			 type :'ATR'

		 },
		 {
			 title: 'Social Worker Referral',
			 state: 'paperworkforms.referralform',
			 id: 2,
		type : 'SWR' },
		 {
			 title: 'Behavioral Referral',
			 state: 'paperworkforms.referralform',
			 id: 3,
		type :'BR' },
		 {
			 title: 'Academic Referral',
			 state: 'paperworkforms.referralform',
			 id: 4,
		type :'ACR' },
	 	 ]).constant('paperwork_rtiforms',[
		 {
			 title: 'Academic Intervention Plan',
			 state: 'academicIntervention',
			 id: 1,
		type :'AIP' },
		 {
			 title:'Behavior Intervention Plan',
			 state: 'behaviorIntervention',
			 id: 2,
		type: 'BIP' },
		 {
			 title: 'Academic Intervention Plan-SST',
			 state: 'academicIntervention-sst',
			 id: 3,
		type :'AIPS' },
		 {
			 title: 'Behavior Intervention Plan-SST',
			 state: 'behaviorIntervention-sst',
			 id: 4,
		type :'BIPS' },
		 {
			 title: 'IEP-TDM',
			 state: 'behaviorIntervention-sst',
			 id: 5,
		type :'IEP' },
		 {
			 title: '504-TDM',
			 state: 'behaviorIntervention-sst',
			 id: 6,
		type :'504' },

	 ]).constant('paperwork_letterforms',[
		 {
			 title: 'Welcome Letter',
			 state: 'paperworkforms.letterform',
			 id: 1,
type: 'WL'
		 },
			{ title:'Failure Letter',
			 state: 'paperworkforms.letterform',
			 id: 2,
		type: 'FL' },
	 {
	title: 'Deficiency Letter',
			 state: 'paperworkforms.letterform',
			 id: 3,
		type: 'DL' },
		 {
			 title: 'Positive Letter',
			 state: 'paperworkforms.letterform',
			 id: 4,
		type: 'PL' },
		 {
			 title: 'Caught Doing Good Letter',
			 state: 'paperworkforms.letterform',
			 id: 5,
		type :'CDL' },
		 {
			 title: 'Promoted to Next Grade Letter',
			 state: 'paperworkforms.letterform',
			 id: 6,
		type: 'PNGL' },
		 {
			 title: 'Substitute Instruction Letter',
			 state: 'paperworkforms.letterform',
			 id: 7,
		type :'SIL' },
		 {
			 title: '*Field Trip Permission Letter',
			 state: 'paperworkforms.letterform',
			 id: 8,
		type :'FTPL' },
		 {
			 title: 'Detention Letter',
			 state: 'paperworkforms.letterform',
			 id: 9,
		type :'DTL' },
		 {
			 title: 'Conference Invitation Letter',
			 state: 'paperworkforms.letterform',
			 id: 10,
		type: 'CIL' },


  ])
    .constant('alphabet', [
      'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L',
      'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
    ])
    .constant('referralTypes', [
      {
        title: 'Attendance',
        value: 1
      },
      {
        title: 'Behavior',
        value: 2
      },
      {
        title: 'Academic',
        value: 3
      }
      ])
    .constant('locations', [
      {
        title: 'Hall',
        slug: 'hall',
      },
      {
        title: 'Cafeteria',
        slug: 'cafeteria',
      },
      {
        title: 'Class',
        slug: 'class',
      },
      {
        title: 'Office',
        slug: 'office',
      },
      {
        title: 'Other',
        slug: 'other',
      },
    ]);
})();
