(function () {
  'use strict';

  angular
    .module('app.dashboard.main')
    .controller('NoStudentSelectedController', NoStudentSelectedController);

  /* @ngInject */
  function NoStudentSelectedController(studentsService,$scope,toastr,$uibModalInstance,NotVerifiedStudent) {

    /* jshint validthis: true */
    var vm = this;
    vm.cancel = cancel;
    vm.success = 0;
    vm.DeleteStudent = DeleteStudent;
    vm.NotVerifiedStudent=NotVerifiedStudent;
    vm.NotVerifiedStudentName=vm.NotVerifiedStudent.fullname;

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function DeleteStudent()
    {

       studentsService.removestudent(vm.NotVerifiedStudent).then(function() 
        {
              vm.success = 1;  
              $uibModalInstance.close(vm.success);        
        });

    }
    


    
  }
})();