(function () {
  'use strict';

  angular
    .module('app.address-book')
    .controller('SelectionTypeBlastController', SelectionTypeBlastController);

  /* @ngInject */
  function SelectionTypeBlastController($uibModalInstance) {

    var vm = this;

    vm.send = send;
    vm.cancel = cancel;


    function send($mode) {
    	
    	
      $uibModalInstance.close($mode);
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

  }

})();