(function () {
  'use strict';

  angular
    .module('app.core')
    .service('sparkService',sparkService);

  function sparkService( $http) {
    /* jshint validthis: true */
    var vm = this;

    vm.getDetails = getDetails;
    vm.UpdateSubscription = UpdateSubscription;
    vm.CancelSubscription = CancelSubscription;
     
    function getDetails(url){
	 return	$http.get(url).then(fetchData);
	}
    
    function UpdateSubscription(url, data){
    	return $http.put(url, data).then(fetchData);
    }
    
    function CancelSubscription(url){
    	return $http.delete(url).then(fetchData);
    }
    
    function fetchData(response){
    	return response.data;
    	
    }

    
  }
})();
