(function () {
  'use strict';

  angular
    .module('app')
    .filter('period', period);

  period.$inject = [];

  /* @ngInject */
  function period() {
    /*return function (periodIntValue) {
      var periodStringValue = '';

      switch (parseInt(periodIntValue)) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
          periodStringValue = 'Period ' + periodIntValue;
          break;
        case 7:
          periodStringValue = 'Rocket Club';
          break;
        case 8:
          periodStringValue = 'Astronomy Club';
          break;
        case 9:
          periodStringValue = 'Running Club';
          break;
      }

      if (periodIntValue === '') {
        periodStringValue = 'All';
      }

      return periodStringValue;
    };*/
    return function(students, class_period) {
        if(students.constructor === Array) {
            return students.filter(function(student) {
                if(class_period === 0)
                    return true;
                else
                {
                    return student.student_class.indexOf(class_period) !== -1;
                }
            });
        }
        return false;
    };
  }

})();