(function () {
  'use strict';

  angular
    .module('app')
    .service('authService', authService);

  /* @ngInject */
  function authService($q, $http, $rootScope, $auth, $filter, $state, helperService, PermRoleStore, PermPermissionStore, defaultStatesByRoles) {
    /* jshint validthis: true */
    let vm = this;

    vm.signUp = signUp;
    vm.setupAccount = setupAccount;
    vm.setUser = user;
    vm.currentSessionId = getCurrentSession;

    vm.isActivated = isActivated;
    vm.isSingleSubscription = isSingleSubscription;
    vm.isSchoolSubscription = isSchoolSubscription;

    vm.me = me;
    vm.completedRegistration = completedRegistration;
    vm.isRequestingMe = isRequestingMe;
    vm.isAuthorized = isAuthorized;
    vm.logout = logout;
    vm.activate = activate;
    vm.createsubSid = createsubSid;


    vm.defaultRedirect = defaultRedirect;

    vm.isAdmin = isAdmin;
    vm.isTeacher = isTeacher;

    let authorized = false,
      requestingMe = false;

    ////////////////////////////

    function signUp(data) {
      return $http.post('/register', data);
    }
    
    function createsubSid(userId){
    	return $http.get('/api/create-sub-sid?userid='+ userId).then(function(response){
    		
    	});
    }

    function setupAccount(data) {
      return $http.post('/setup-account', data).then(helperService.handleResponse);
    }

    function user() {
      return $http.get('/api/user').then(fetchAndSetUser);
    }

    function fetchAndSetUser(response) {
      var user = response.data;
      $rootScope.user = user;

      return user;
    }

    function isSingleSubscription() {
      return !isSchoolSubscription();
    }

    function isSchoolSubscription() {
      return $rootScope.user.school.district.is_full;
    }

    function completedRegistration() {
      return $rootScope.user && $rootScope.user.school;
    }

    function getCurrentSession() {
      return $http.get('/api/sessionid').then(function (response) {
        var sessionId = response.data;
        $rootScope.sessionId = sessionId;
        return sessionId;

      });
    }

    function isActivated() {
      return $rootScope.user.is_activated;
    }

    function isAdmin() {
      return $rootScope.role && ($rootScope.role.slug === 'admin');
    }

    function isTeacher() {
      return $rootScope.role && ($rootScope.role.slug === 'teacher');
    }

    function isRequestingMe() {
      return requestingMe;
    }

    function isAuthorized() {
      return authorized;
    }

    function me() {
      requestingMe = true;

      return $http.get('/auth/me')
        .then(helperService.handleResponse)
        .then(function (user) {
          $rootScope.user = user;

          return setPermissions(user).then(function () {
            return user;
          });
        })
        .finally(function () {
          requestingMe = false;
        });
    }

    function setPermissions(user) {
      let roles = {};

      angular.forEach(user.roles, function (role) {
        PermRoleStore
          .defineRole(role.slug.toUpperCase(), Object.keys(role.permissions));

        PermPermissionStore.defineManyPermissions(Object.keys(role.permissions), function (permissionName) {
          return true;
        });
      });

      $rootScope.role = user.roles.length ? {
        slug: user.roles[0].slug,
        name: $filter('roleName')(user.roles[0].slug)
      } : undefined;

      authorized = true;

      // We need wait while translations will be loading from server
      return $q.when(authorized);
    }

    function logout(sendRequest) {

      sendRequest = typeof sendRequest !== undefined ? sendRequest : true;

      if (sendRequest) {
        return $http.post('/auth/logout').then(helperService.handleResponse).then(clearLoggedUser);
      } else {
        return $q.when(clearLoggedUser());
      }
    }

    function clearLoggedUser() {
      $rootScope.user = undefined;
      $rootScope.role = undefined;

      $auth.removeToken();

      // Clear permissions and roles
      PermRoleStore.clearStore();
      PermPermissionStore.clearStore();

      $state.go('login');
    }

    function defaultRedirect() {
      $state.go(defaultStatesByRoles[$rootScope.role.slug]);
    }

    function activate(code) {
      return $http.post('/activation', {code: code}).then(helperService.handleResponse);
    }
  }
})();