(function () {
  'use strict';

  angular
    .module('app')
    .filter('counterLeft', counterLeft);

  /* @ngInject */
  function counterLeft() {
    return function (string, limit) {
      limit = limit || 140;

      var totalLength = 0;

      if (string !== undefined) {
        totalLength = string.length + (string.match(/\n/g) || []).length;
      }

      return limit - totalLength;
    };
  }
})();