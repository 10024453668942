(function () {
  'use strict';

  angular
    .module('app.address-book')
    .filter('adminPosition', adminPosition);

  /* @ngInject */
  function adminPosition($filter) {
    return function (id, adminTypes) {
      let adminType = $filter('filter')(adminTypes, {id: id}, true);

      return adminType.length ? adminType[0].title : '';
    };
  }

})();