(function () {
  'use strict';

  angular.module('app.attendance-analytics', [
    'app.attendance-analytics.quickview',
    'app.attendance-analytics.subject-grade-comparisons',
    'app.attendance-analytics.teacher-comparisons',
    'app.attendance-analytics.teacher-details',
  ]);

})();