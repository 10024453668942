(function() {
    'use strict';

    angular
        .module('app.dashboard.main')
        .controller('DashboardController', DashboardController);

    /* @ngInject */
    function DashboardController($uibModal, toDoItems, toDoService,$rootScope,toastr,usSpinnerService,$timeout, SweetAlert, 
    $state,ClassesService,communicationService, adminsService) {
        /* jshint validthis: true */
        var vm = this;

        vm.today = moment();

        initToDoItem();

        vm.HighPriorityCount = 0;
        vm.TodoHighPriorityItems = [];
        vm.TodoCurrentItems = [];
        vm.toDoItems = toDoItems;
        vm.Userhasclass = Userhasclass;
        vm.runService = runService;
        vm.HighPriorityTabSetting = HighPriorityTabSetting;
        vm.TodayTabSetting = TodayTabSetting;
        vm.toDoUpdateItem = [];
        vm.saveToDoItem = saveToDoItem;
        vm.RefreshList = RefreshList;
        vm.setToDoItemForUpdate = setToDoItemForUpdate;
        vm.removeToDoItem = removeToDoItem;
        vm.updateCompleion = updateCompleion;
        vm.clearCompletedToDoItems = clearCompletedToDoItems;
        vm.description = "";
        vm.TodoHighPriorityTab = 1;
        vm.searchTask = '';
        vm.TodoCurrentPriorityTab = 0;
        vm.deleteClass=deleteClass;
        vm.db_class_list=[];
        vm.db_club_list=[];

    ///////////////////
    vm.toDoItem.is_high_priority=false;
    vm.toDoItem.description="";
    vm.toDoItem.id="";
    vm.EmailCount=0;
    vm.TextCount=0;
    vm.PhoneCallCount=0;
    vm.SendCommunicationToGurdian = SendCommunicationToGurdian;
    vm.CommunicationCount=CommunicationCount;

    vm.LastWeekTotalCommunication='';
    vm.students=[];
    vm.TotalCommunication='';
    vm.getBellSchedulded = getBellSchedulded;

    vm.backdrop = '';
    
    $rootScope.$on('setBackdrops', function (event, data) {
    	vm.backdrop = data;

     });

    
    getBellSchedulded();

    communicationService.GetLastWeekData('api/classes/lastweekdata').then(function(data) {

         vm.LastWeekTotalCommunication=data.total_communication;               
    });


    function deleteClass(classesId,groupType)
    {

       return $uibModal.open({
          templateUrl: '/app/partials/classes/confirmation-remove-class.html',
          controller: 'RemoveClassController',
          controllerAs: 'RemoveClassCtrl',
              resolve: {
              classesId: function () {
                return classesId;
              },
              groupType: function () {
                return groupType;
              }  
            }
          }).result.then(function () {


          });     
            

    }


    

   function CommunicationCount()
   {
	   $rootScope.db_class_list = [];
       $rootScope.db_club_list = [];
        vm.db_class_list=[];
        vm.db_club_list=[];
        vm.students=[];
        
        ClassesService.getclassesData('api/classes').then(function(data) {
        if (data.class_period !== null) {
            angular.forEach(data.class_period, function(class_period) {
             
                if (class_period.group_type === '1')
                {
                  vm.db_class_list.push({
                        id: class_period.id,
                        name: class_period.name,
                        period: class_period.period,
                        class_icon: class_period.active_icon,
                        period_student_count: class_period.student_class_count,
                        period_letter: class_period.period_letter
                    });

                  $rootScope.db_class_list.push({
                        id: class_period.id,
                        name: class_period.name,
                        period: class_period.period,
                        period_letter: class_period.period_letter
                    });
                }
                else
                {
                      vm.db_club_list.push({
                        id: class_period.id,
                        name: class_period.name,
                        period: class_period.period,
                        class_icon: class_period.active_icon,
                        period_student_count: class_period.student_class_count,
                        period_letter: class_period.period_letter
                      });

                      $rootScope.db_club_list.push({
                        id: class_period.id,
                        name: class_period.name
                      });
                }
            });

           
            

           angular.forEach(data.highpririty, function(item) {


                  vm.EmailCount=vm.EmailCount+item.total_emails;
                  vm.TextCount=vm.TextCount+item.total_texts;
                  vm.PhoneCallCount=vm.PhoneCallCount+item.total_calls;

                  vm.students.push(item);
             
                
            });

           angular.forEach(data.lowpriority, function(item) {

                  vm.EmailCount=vm.EmailCount+item.total_emails;
                  vm.TextCount=vm.TextCount+item.total_texts;
                  vm.PhoneCallCount=vm.PhoneCallCount+item.total_calls;

                  //vm.students.push(item);
                 
            });

           //console.log(vm.students);

           $rootScope.students=vm.students;
           //console.log(vm.EmailCount);

           vm.TotalCommunication=vm.EmailCount+vm.TextCount+vm.PhoneCallCount;

            //vm.highprioritystudents = data.highpririty;
            //vm.lowprioritystudents = data.lowpriority;

             
        }
        

    });
   }


    CommunicationCount();

    function SendCommunicationToGurdian(CommunicationMode)
    {

      //var item=vm.highprioritystudents;

      return $uibModal.open({
          templateUrl: '/app/partials/dashboard/communication.html',
          controller: 'CommunicationController',
          controllerAs: 'CommunicationCtrl',
          size: 'lg',
          backdrop: vm.backdrop ? false: true,
          resolve: {
          CommunicationMode: function () {
            return CommunicationMode;
          } 
        }
      }).result.then(function () {

        vm.EmailCount=vm.EmailCount+1;
        vm.TextCount=vm.TextCount+1;
        vm.PhoneCallCount=vm.TextCount+1;
        vm.TotalCommunication=vm.TotalCommunication+1;

        //CommunicationCount();

      });



    }

  

        function Userhasclass() {

            if (!$rootScope.user.has_class && $rootScope.currentState.name !=='dashboard.main') {
                $timeout(function() {
                    SweetAlert.confirm("Please set up new class ", {
                            title: "Confirm!",
                            confirmButtonColor: "#4CAF50",
                            confirmButtonText: "Go to add a class"
                        })
                        .then(function(isConfirm) {
                            if (isConfirm) {
                                $state.go('addnewclass');

                            }
                        });
                }, 2000);
            }
        }

        Userhasclass();

        function runService() {
            toDoService.getAllData('api/to-do-items/show').then(function(data) {
                $rootScope.DashboardCount = data.high_priority_items_count;
                vm.TodoHighPriorityItems = data.high_priority_items;
                vm.TodoCurrentItems = data.current_items;
            });

        }

        function RefreshList() {
            if (vm.toDoItem.description.length <= 0) {
                runService();
                vm.toDoItem.is_high_priority = false;
                vm.toDoItem.id = "";
            }
        }

        function TodayTabSetting() {
            vm.TodoHighPriorityTab = 0;
            vm.TodoCurrentPriorityTab = 1;
        }

        function HighPriorityTabSetting() {
            vm.TodoHighPriorityTab = 1;
            vm.TodoCurrentPriorityTab = 0;
        }

        function initToDoItem() {
            vm.toDoItem = {
                due_date: moment(),
            };
        }

        runService();



        function saveToDoItem() {
            var savingPromise;
            var flag = 1;

            if (vm.toDoItem.description === undefined || vm.toDoItem.description === "") {
                toastr.error('Description required.');
                flag = 0;
            }

            if (vm.toDoItem.due_date === undefined || vm.toDoItem.due_date === "") {
                toastr.error('Due date required.');
                flag = 0;
            }

            if (flag === 1) {
                if (vm.toDoItem.id) {

                    vm.toDoUpdateItem = {
                        id: vm.toDoItem.id,
                        user_id: vm.toDoItem.user_id,
                        due_date: moment(vm.toDoItem.due_date),
                        description: vm.toDoItem.description,
                        is_high_priority: vm.toDoItem.is_high_priority,
                    };

                    //console.log(vm.toDoUpdateItem);

                    savingPromise = toDoService.update(vm.toDoUpdateItem).then(function(toDoItem) {
                        vm.toDoItem.description = "";
                        vm.toDoUpdateItem = [];
                        runService();
                        toastr.success('Task successfully updated.');
                        if (!isToDoItemForDashboard(toDoItem)) {
                            vm.toDoItems.splice(vm.toDoItems.indexOf(vm.toDoItem), 1);
                        }

                        return toDoItem;
                    });
                } else {

                    savingPromise = toDoService.create(vm.toDoItem).then(function(toDoItem) {
                        vm.toDoItem.description = "";
                        toastr.success('Task successfully created.');
                        runService();
                        if (isToDoItemForDashboard(toDoItem)) {
                            vm.toDoItems.push(toDoItem);
                        }


                        return toDoItem;
                    });
                }
            }
            return savingPromise.finally(function() {
                initToDoItem();
            });
        }

        function isToDoItemForDashboard(toDoItem) {
            //return toDoItem.is_high_priority || toDoItem.due_date.format('M-D-Y') <= vm.today.format('M-D-Y');
        }

        function setToDoItemForUpdate(item) {

            vm.toDoItem = item;
            vm.toDoItem.due_date = new Date(item.due_date);

            if (item.is_high_priority === 1) {
                vm.toDoItem.is_high_priority = true;
            } else {
                vm.toDoItem.is_high_priority = false;
            }
        }

        function clearCompletedToDoItems() {
            
            if (vm.toDoUpdateItem.length > 0) {
                vm.toDoUpdateItem = [];
                runService();
                toastr.success('Task successfully cleared.');

            } else {
                toastr.error('No task is selected.');
            }
        }

        function removeToDoItem(item) {
            return $uibModal.open({
                templateUrl: '/app/partials/dashboard/confirmation-remove-a-to-do-item.html',
                controller: 'RemoveToDoItemController',
                controllerAs: 'RemoveToDoItemCtrl',
                // size: 'lg',
                resolve: {
                    toDoItem: function() {
                        return item;
                    },
                    SelectedtoDoItem: function() {
                        return item;
                    },
                }
            }).result.then(function() {
                return toDoService.removeItem('api/to-do-items/remove', item).then(function() {
                    runService();
                    toastr.success('Task successfully deleted.');
                    vm.toDoItems.splice(vm.toDoItems.indexOf(item), 1);
                });

            });
        }

        function updateCompleion(item) {

            if (item.is_completed) {
                vm.toDoUpdateItem.push(item);
                toDoService.complete(item);
                toastr.success('You marked the task as completed successfully.');
            } else {
                var index = vm.toDoUpdateItem.indexOf(item);
                vm.toDoUpdateItem.splice(index, 1);
                toDoService.cancelCompletion(item);
                toastr.success('You marked the task as incomplete successfully.');
            }
        }
        
        function getBellSchedulded(){
        	adminsService.getBellSchedule().then(function(response){
             	vm.bellSchdule = response;
             	
             });
        }
    }
})();