(function () {
  'use strict';

  angular
    .module('app.base')
    .controller('BaseController', BaseController);

  /* @ngInject */
  function BaseController(studentsService,$scope,$state,usSpinnerService,$timeout) {

       $scope.afterselected={};
       $scope.student_name="";

      /*studentsService.all().then(function(data) {
        $scope.AllSchoolStudents=data;   
            
      });

      $scope.redirectToProfile = function(){

         if($scope.afterselected.originalObject!==undefined)
         {
            if($scope.afterselected.originalObject.id!=="")
            {
                
                $timeout(function() {
                  usSpinnerService.spin('spinner-1');
                  $state.go("student.summary", {
                    studentId: $scope.afterselected.originalObject.id
                  });

                  $scope.afterselected.originalObject=undefined;
                  $scope.afterselected={};
              });

            }
         }
         
      };  */

      
      

    
  }
})();