(function () {
  'use strict';

  angular
    .module('app.dashboard.main')
    .controller('UserSettingController', UserSettingController);

  /* @ngInject */
  function UserSettingController($scope, UserSettingService,states,toastr,$rootScope,$stateParams, $timeout,
    $state,usSpinnerService,authService, sparkService) {

    /* jshint validthis: true */
    var vm = this;

    vm.states = states;
    vm.ActiveTabSetting=ActiveTabSetting;
    vm.ProfileTabSetting=ProfileTabSetting;
    vm.SchoolInfoTabSetting=SchoolInfoTabSetting;
    vm.PasswordSetting=PasswordSetting;
    vm.UserProfileService=UserProfileService;
    vm.UpdateUser=UpdateUser;
    vm.ResetPasswordRequest=ResetPasswordRequest;
    vm.SubmitSubscription=SubmitSubscription;
    vm.exceptionDateOnSetTime=exceptionDateOnSetTime;
    vm.removeExceptionDate=removeExceptionDate;
    //vm.ActiveTab=$stateParams.active_tab;
    //vm.ProfileTab=$stateParams.sub_tab;
    $rootScope.counters = ($rootScope.counters === 1)? 1:'';
    vm.ActiveTab=1;
    vm.ProfileTab= ($rootScope.counters === 1) ? 3 : 1;
    vm.SchoolInfoTab=1;
    vm.PasswordTypeTab=1;
    vm.UserProfile='';
    vm.updateForm='';
    vm.userEmail='';
    vm.exceptionDate='';
    vm.ActivationDate='';
    vm.ExpirationDate='';

    
    
    
    vm.CurrentDate=new Date();
    vm.SubscriptionStatus='';
	vm.changeMonth=changeMonth;
	
	vm.getAllPlan = getAllPlan;
	vm.showplanfeature = showplanfeature;
	vm.cancelModal = cancelModal;
	vm.subscriptionCancel = subscriptionCancel;
	vm.selectedInterval = 'monthly'; 
	vm.selectPlan = selectPlan; 
	vm.selectedPlan = '';
	vm.activePlan = activePlan;
	vm.confirmPlanUpdate = confirmPlanUpdate;
	vm.approvePlanUpdate = approvePlanUpdate;
	vm.cancelSubscriptionConfirm = cancelSubscriptionConfirm;
  vm.resumeSubscription = resumeSubscription; 
	 vm.selecteingPlan = '';
	 vm.showCardInput = false;
	 vm.showFreePlan = true;
   vm.updateCardInformation = updateCardInformation;
   vm.formBusy = false;
   vm.IsActivePlan = IsActivePlan;
   vm.activePlaninterval = activePlaninterval;
	
	vm.showButton = true;
	getAllPlan();
	
	if($rootScope.user.check_subscription === 0){
	
		vm.ProfileTab = 3;
	}

	$rootScope.$on('subscriptionError', function(event, arr){
		vm.formBusy = false;
	});
	
    vm.profileform = {
      form_type: 1,
      id: undefined,
      first_name: undefined,
      last_name: undefined,
      title: undefined,
      full_name: undefined,
      email: undefined,
      trial_ends_at: undefined,
      country_code: undefined,
      phone: undefined,
      
    };

    vm.passwordform={
      form_type: 4,
      CurrentPassword: undefined,
      NewPassword: undefined,
      ConfirmPassword: undefined,
    };


    vm.schoolform = {
      form_type: 2,
      id: undefined,
      type_id: undefined,
      title: undefined,
      state_id: undefined,
      dis_title: undefined,
      calendar_id: undefined,
      address: undefined,
      city: undefined,
      district_id: undefined,
      phone: undefined,
      zip_code: undefined,
    };

    vm.schoolcalendar = {
      form_type: 3,
      id: undefined,
      district_id: undefined,
      exception_dates:[],
      start: undefined,
      end: undefined,
    };

    vm.forgotpassword = {
      userEmail: undefined,
      userName: undefined,
    };

    vm.subscription = {
      cardName: undefined,
      cardNumber: undefined,
      expMonth: undefined,
      expYear: undefined,
      cvv: undefined,
      stripe_token: undefined,
      plan: 'yearly-pro',
      url: undefined,
    };

   function ResetPasswordRequest()
   {
      usSpinnerService.spin('spinner-1');
      $rootScope.modalerror=true;
      UserSettingService.sendRequestForForgotPassword('api/user/forgotpassword', vm.forgotpassword).then(function(data)
      {
             if(data.error===0)
              {
                   if(data.response===1)
                   {
                     usSpinnerService.stop('spinner-1');
                     toastr.success('Reset password link successfully send to your email id');
                     $state.go('resetpassword_instruction');
                     $timeout(function () {
                 		  authService.logout();
                 	    }, 10000);

                   }

              }
              else
              {
                  $rootScope.modalerror=false;
                  toastr.error(data.errortext);
              }

      });
      $rootScope.modalerror=false;

   }
   
   function getAllPlan(){
	   
	   sparkService.getDetails('/api/user/plans').then(function(response){
		  vm.subscriptionPlans = response;        	
			  activePlan();		  
      UserProfileService();
	   });
	   
   }
   
   function IsActivePlan(plan){
	 return  vm.activatePlan.id === plan.id;
   }
   
   function activePlan(){
	   vm.activatePlan = '';
	   if(vm.subscriptionPlans){
		   angular.forEach(vm.subscriptionPlans, function(plan){
			   if($rootScope.user.stripe_plan === plan.id){
				   vm.activatePlan = plan;
				   vm.selectedInterval = plan.interval;
				   activePlaninterval(plan.id);
				   vm.showFreePlan = false;
			   }
		   });
		   
		   return vm.activatePlan;
	   }else{
		   return vm.activatePlan;
	   }
   }
   
   
   function showplanfeature(plan){
	   vm.plan = plan;
	   $('#planModal').modal('show');
   }

   function cancelModal(modalid){
	   $('#' + modalid).modal('hide'); 
   }
   
   function subscriptionCancel(){
	   $('#modal-confirm-cancellation').modal('show');
   }
   
   function selectPlan(plan){
	   vm.selectedPlan = plan;
	   vm.subscription.plan = vm.selectedPlan.id;
   }

   function confirmPlanUpdate(plan){
	   vm.confirmingPlan = plan;
	   $('#modal-confirm-plan-update').modal('show');
   }
   
   function resumeSubscription(plan){
     vm.selecteingPlan = plan;
      sparkService.UpdateSubscription('api/settings/subscription',{"plan": vm.selecteingPlan.id}).then(function(subs_data){
         vm.selecteingPlan = '';
         authService.setUser().then(function(response){
               $rootScope.user = response;
             });
             getAllPlan();
            // UserProfileService();      
            });
   }

   function approvePlanUpdate(){
	   $('#modal-confirm-plan-update').modal('hide');
	   vm.selecteingPlan = vm.confirmingPlan;
	   
		   sparkService.UpdateSubscription('api/settings/subscription',{"plan": vm.selecteingPlan.id}).then(function(subs_data){
			   vm.selecteingPlan = '';
			   authService.setUser().then(function(response){
        		   $rootScope.user = response;
        	   });
        	   getAllPlan();   
        	   $rootScope.counters =1;
        	   $timeout(function () {
        		   $rootScope.counters = '';
          	    }, 5000);
        	   $state.reload();   
        	   
            // UserProfileService();   
	         });	   
   }
   
   function cancelSubscriptionConfirm(){
    $('#modal-confirm-cancellation').modal('hide');
	   sparkService.CancelSubscription('api/settings/subscription').then(function(res){
		   
		   authService.setUser().then(function(response){
    		   $rootScope.user = response;
    	   });

    	   getAllPlan();  
         //UserProfileService();    
	   });
   }
   
   function updateCardInformation(){

	      vm.flag=1;

	     if(vm.subscription.cardNumber==="" || vm.subscription.cardNumber===undefined)
	      {
	        vm.flag=0;
	        toastr.error('Card number required');
	      }

	      if(vm.subscription.expMonth==="" || vm.subscription.expMonth===undefined)
	      {
	        vm.flag=0;
	        toastr.error('Expiration month required');
	      }
	      if(vm.subscription.expYear==="" || vm.subscription.expYear===undefined)
	      {
	        vm.flag=0;
	        toastr.error('Expiration year required');
	      }
	      if(vm.subscription.cvv==="" || vm.subscription.cvv===undefined)
	      {
	        vm.flag=0;
	        toastr.error('CVV required');
	      }

	      if(vm.flag===1)
	      {	
	    	  vm.formBusy = true;	
	          UserSettingService.updateSubscription('api/user/updatesubscription',vm.subscription).then(function(data)
	          {
	                
	             vm.subscription.stripe_token=data.response.token; 

	             if(vm.subscription.stripe_token!=="")
	             {
	            	 sparkService.UpdateSubscription('api/settings/payment-method',vm.subscription).then(function(subs_data)
	                {
	            	   authService.setUser().then(function(response){
	            		   $rootScope.user = response;
	            		   getAllPlan();
	            	   });
	            	   
	                    //console.log(subs_data);  
	                    toastr.success('Successfully update card information');
	                      vm.subscription.cardName="";
	                      vm.subscription.cardNumber="";
	                      vm.subscription.expYear="";
	                      vm.subscription.expMonth="";
	                      vm.subscription.cvv="";
	                      vm.showCardInput = false;
	                      vm.showFreePlan = false;
	                      vm.formBusy = false;
	                });
	             }
	              
	             // UserProfileService();
	             
	          });

	      }	 
	   
	   
	   
   }

   vm.subscription = {
      cardName: undefined,
      cardNumber: undefined,
      expMonth: undefined,
      expYear: undefined,
      cvv: undefined,
      stripe_token: undefined,
      plan: '',
      url: undefined,
    };

   function SubmitSubscription()
   {

      vm.flag=1;

     if(vm.subscription.cardNumber==="" || vm.subscription.cardNumber===undefined)
      {
        vm.flag=0;
        toastr.error('Card number required');
      }

      if(vm.subscription.expMonth==="" || vm.subscription.expMonth===undefined)
      {
        vm.flag=0;
        toastr.error('Expiration month required');
      }
      if(vm.subscription.expYear==="" || vm.subscription.expYear===undefined)
      {
        vm.flag=0;
        toastr.error('Expiration year required');
      }
      if(vm.subscription.cvv==="" || vm.subscription.cvv===undefined)
      {
        vm.flag=0;
        toastr.error('CVV required');
      }
      if( vm.subscription.plan === "" || vm.subscription.plan===undefined){
    	  vm.flag=0;
          toastr.error('Please select plan');
      }

      if(vm.flag===1)
      {	
    	  vm.formBusy = true;	
          UserSettingService.updateSubscription('api/user/updatesubscription',vm.subscription).then(function(data)
          {
                
             vm.subscription.stripe_token=data.response.token; 

             if(vm.subscription.stripe_token!=="")
             {
               UserSettingService.Subscription('api/settings/subscription',vm.subscription).then(function(subs_data)
                {
            	   authService.setUser().then(function(response){
            		   $rootScope.user = response;
            		   getAllPlan();
            	   });
            	   
                    //console.log(subs_data);  
                    toastr.success('Subscription completed successfully');
                      vm.subscription.cardName= '';
                      vm.subscription.cardNumber= '';
                      vm.subscription.expYear="";
                      vm.subscription.expMonth="";
                      vm.subscription.cvv="";
                      vm.showCardInput = false;
                      vm.showFreePlan = false;
                      vm.formBusy = false;
                    
                });
             }
              
             // UserProfileService();
              
          });

      }
      
   }
   
   function changeMonth()
   {
	   console.log(vm.schoolcalendar.start);
   }

    function UserProfileService()
    {
        UserSettingService.ProfileData('api/user/userprofile').then(function(data) {
        	
        	
            //PROFILE DATA
            vm.schoolcalendar.exception_dates=[];

            vm.profileform.id=data.profile.id;
            vm.profileform.first_name=data.profile.first_name;
            vm.profileform.last_name=data.profile.last_name;
            vm.profileform.title=data.profile.title;
            vm.profileform.full_name=data.profile.full_name;
            vm.profileform.email=data.profile.email;
            vm.profileform.trial_ends_at=data.profile.trial_ends_at;
            vm.profileform.country_code=data.profile.country_code;
            vm.profileform.phone=data.profile.phone;

            vm.ActivationDate= data.profile.subscription_created ? new Date(data.profile.subscription_created) :new Date(data.profile.last_read_announcements_at);
            var setexpDate = new Date(data.profile.subscription_created);
            vm.SubscribtionexpireDate = (vm.activePlanDuration === 'monthly') ? setexpDate.setDate(setexpDate.getDate() + 30) : setexpDate.setMonth(setexpDate.getMonth() + 10);
            
            vm.ExpirationDate = data.profile.trial_ends_at? new Date(data.profile.trial_ends_at):'';

            if(data.profile.trial_ends_at){
            if( vm.ExpirationDate > vm.CurrentDate)
            {
               vm.SubscriptionStatus=1;
            }
            else
            {
               vm.SubscriptionStatus=0;
            }
            }else{
            	if(data.profile.subscription_end){
            		vm.SubscriptionStatus=0;	
            	}else{
            		vm.SubscriptionStatus=1;
            	}
            }

            vm.passwordform.password=data.profile.password;
            vm.forgotpassword.userName=data.profile.first_name+" "+data.profile.last_name;

            //SCHOOL DATA

            vm.schoolform.id=data.school.id;
            vm.schoolform.type_id=data.school.type_id;
            vm.schoolform.calendar_id=data.school.calendar_id;
            vm.schoolform.title=data.school.title;
            vm.schoolform.address=data.school.address;
            vm.schoolform.district_id=data.school.district_id;
            vm.schoolform.city=data.school.city;
            vm.schoolform.phone=data.school.phone;
            vm.schoolform.zip_code=data.school.zip_code;
            vm.schoolform.state_id=data.school_district.state_id;
            vm.schoolform.dis_title=data.school_district.title;

            //SCHOOL CALENDAR

            vm.schoolcalendar.id=data.school_calendar.id;
            vm.schoolcalendar.district_id=data.school_calendar.district_id;
            vm.schoolcalendar.start=new Date(data.school_calendar.start);
            vm.schoolcalendar.end=new Date(data.school_calendar.end);


            angular.forEach(data.school_calendar_exp_date, function(item) {

              vm.schoolcalendar.exception_dates.push(item);
                        
            });

            //console.log(vm.schoolcalendar);

            
               
        });
    }


   // UserProfileService();

    
    function exceptionDateOnSetTime() {
		

		
	  var temp_date=vm.exceptionDate.format('YYYY-MM-DD');
	  angular.forEach(vm.schoolcalendar.exception_dates, function(item) {

      if(item.date===temp_date)
			  {
				  //console.log("pechi");
				  var index = vm.schoolcalendar.exception_dates.indexOf(item.date);
				  vm.schoolcalendar.exception_dates.splice(index, 1);
			  }
       });
	   
	   
	   vm.schoolcalendar.exception_dates.push({
        date: vm.exceptionDate.format('YYYY-MM-DD')
      });
	  

      //console.log(vm.schoolcalendar.exception_dates);

    }

    function removeExceptionDate(date)
    {
      var index = vm.schoolcalendar.exception_dates.indexOf(date);
      vm.schoolcalendar.exception_dates.splice(index, 1);

      //console.log(vm.schoolcalendar.exception_dates);
    }

    function ActiveTabSetting(MainTabSetting)
    {
       vm.ActiveTab=MainTabSetting;
    }

    function ProfileTabSetting(ProfileSetting)
    {
       vm.ProfileTab=ProfileSetting;
    }

    function SchoolInfoTabSetting(SchoolInfoSetting)
    {
       vm.SchoolInfoTab=SchoolInfoSetting;
    }


    function PasswordSetting(PasswordSetting)
    {
       vm.PasswordTypeTab=PasswordSetting;
    }


    function UpdateUser(type)
    {
        //console.log(vm.profileform);

        var form_name="";
        vm.flag=1;

        if(type===1)
        {
              vm.updateForm=vm.profileform;
              form_name="Profile information";

              if(vm.profileform.first_name==="" || vm.profileform.first_name===undefined)
              {
                vm.flag=0;
                toastr.error('First name required');
              }
              else if(vm.profileform.last_name==="" || vm.profileform.last_name===undefined)
              {
                vm.flag=0;
                toastr.error('Last name required');
              }
              else if(vm.profileform.title==="" || vm.profileform.title===undefined)
              {
                vm.flag=0;
                toastr.error('Title required');
              }
              else if(vm.profileform.phone==="" || vm.profileform.phone===undefined)
              {
                vm.flag=0;
                toastr.error('Phone number required');
              }


        }
        else if(type===2)
        {
              vm.updateForm=vm.schoolform;
              form_name="School information";

              if(vm.schoolform.title==="" || vm.schoolform.title===undefined)
              {
                vm.flag=0;
                toastr.error('School name required');
              }
              else if(vm.schoolform.dis_title==="" || vm.schoolform.dis_title===undefined)
              {
                vm.flag=0;
                toastr.error('District name required');
              }
              else if(vm.schoolform.state_id==="" || vm.schoolform.state_id===undefined)
              {
                vm.flag=0;
                toastr.error('State name required');
              }

        }
        else if(type===3)
        {
              vm.updateForm=vm.schoolcalendar;
              form_name="School information";
              if(vm.schoolcalendar.start==="" || vm.schoolcalendar.start===undefined)
              {
                vm.flag=0;
                toastr.error('School start sate required');
              }
              else if(vm.schoolcalendar.end==="" || vm.schoolcalendar.end===undefined)
              {
                vm.flag=0;
                toastr.error('District end date required');
              }

        }
        
        else if(type===4)
        {
              vm.updateForm=vm.passwordform;
              form_name="Password";
              if(vm.passwordform.CurrentPassword==="" || vm.passwordform.CurrentPassword===undefined)
              {
                vm.flag=0;
                toastr.error('Current password required');
              }
              else if(vm.passwordform.NewPassword==="" || vm.passwordform.NewPassword===undefined)
              {
                vm.flag=0;
                toastr.error('New password required');
              }
              else if(vm.passwordform.ConfirmPassword==="" || vm.passwordform.ConfirmPassword===undefined)
              {
                vm.flag=0;
                toastr.error('Confirm password required');
              }
              else if(vm.passwordform.NewPassword!==vm.passwordform.ConfirmPassword)
              {
                vm.flag=0;
                toastr.error('New password and confirm password is not matching');
              }
        }

        if(vm.flag===1)
        {
              vm.flag=0;
              UserSettingService.updateUserData('api/user/update', vm.updateForm).then(function(data)
              {
                 
                    if(data.error===0)
                    {
                         if(data.response===1)
                         {
                           UserProfileService();
                           toastr.success(form_name+' successfully updated');

                    
                           authService.setUser().then(function(data2)
                           {
                             $rootscope.user=data2;
                                
                           });

                         }

                    }
                    else
                    {
                        toastr.error(data.errortext);
                    }
                 

              });
        }


    }

   function activePlaninterval(planId){
	   vm.activePlanDuration = '';
	   	switch(planId){
	   	case 'tintpro':
	   		vm.activePlanDuration = 'monthly';
	   		break;
	   	case'tintpro_ten':
	   		vm.activePlanDuration = 'tenMonth';
	   		break;
	   	case 'tintpre':
	   		vm.activePlanDuration = 'monthly';
	   		break;
	   	case 'tintpre_ten':
	   		vm.activePlanDuration = 'tenMonth';
	   		break;	   		
	   	}
	  	return vm.activePlanDuration;
	   
   } 


  }
})();