(function () {
  'use strict';

  angular
    .module('app')
    .filter('guardian', guardian);

  /* @ngInject */
  function guardian() {
    return function (guardianIntValue) {
      var guardianStringValue = '';

      switch (parseInt(guardianIntValue)) {
        case 1:
          guardianStringValue = 'Mother';
          break;
        case 2:
          guardianStringValue = 'Father';
          break;
        case 3:
          guardianStringValue = 'Uncle';
          break;
        case 4:
          guardianStringValue = 'Aunt';
          break;
        case 5:
          guardianStringValue = 'Sister';
          break;
        case 6:
          guardianStringValue = 'Brother';
          break;
        case 7:
          guardianStringValue = 'Grandmother';
          break;
        case 8:
          guardianStringValue = 'Grandfather';
          break;
        case 9:
          guardianStringValue = 'Other';
          break;
      }

      return guardianStringValue;
    };
  }

})();