(function () {
  'use strict';

  angular
    .module('app.core')
    .service('messagesService', messagesService);

  function messagesService($http) {
    /* jshint validthis: true */
    var vm = this;

    vm.get = get;
    vm.createsubSid = createsubSid;
    vm.getUserBalance = getUserBalance;
    vm.getTwilioToken = getTwilioToken;

    /////////////////

    function get(studentId) {
      return $http.get('/api/students/' + studentId + '/messages').then(fetchData);
    }

    function fetchData(response) {
      return response.data;
    }
    
    function createsubSid(userId){
    	return $http.get('/create-sub-sid?userid= '+ userId).then(fetchData);
    }
    
    function getUserBalance(url){
    	return $http.get(url).then(fetchData);
    }
    
    function getTwilioToken(){
    	return $http.get('/api/generate-Token/').then(fetchData);
    }
  }
})();