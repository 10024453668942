(function() {
    'use strict';

    angular
        .module('app.address-book')
        .controller('AddressBookController', AddressBookController);

    function AddressBookController($mdDialog, $filter, $uibModal, toastr, $rootScope,
        studentsService, adminsService, vestedMembersService, helperService, exportService, ClassesService, cannedMessagesService,
        adminTypes, vestedMemberTypes, $state, $timeout) {
        /* jshint validthis: true */
        var vm = this;

        cannedMessagesService.getmeessageandData().then(function(data) {

            helperService.setCannedData(data[0], data[1], data[2]);
             setCannedData(data[0], data[1], data[2]);

        });
        //  helperService.setCannedData($rootScope.cannedTypes, $rootScope.cannedCategories, $rootScope.callStatuses);

        $rootScope.$on('refreshPage', function (event, data) {
        	if(data.sucess){
                studentsService.all().then(function(data) {
                    vm.students = data;
                   
                });    	
        	}

         });
        
        
        vm.studentsView = {};
        vm.category = '';
        vm.sortBy = '';
        vm.sortType = '';
        vm.sortReverse = false;
        vm.search = '';

        vm.adminTypes = adminTypes;
        vm.vestedMemberTypes = vestedMemberTypes;

        vm.students = [];
        vm.admins = [];
        vm.vestedMembers = [];


        vm.percent = percent;
        vm.showAdmin = showAdmin;
        vm.showVestedMember = showVestedMember;

        vm.createStudentModal = createStudentModal;
        vm.createAdminModal = createAdminModal;
        vm.createVestedMemberModal = createVestedMemberModal;

        vm.deleteStudent = deleteStudent;
        vm.deleteAdmin = deleteAdmin;
        vm.deleteVestedMember = deleteVestedMember;

        vm.sendMessage = sendMessage;
        vm.makeCall = makeCall;
        vm.sendEmail = sendEmail;

        vm.sendMessageToSystemUser = sendMessageToSystemUser;
        vm.sendBlast = sendBlast;

        vm.toggleAllSelected = toggleAllSelected;
        vm.optionToggled = optionToggled;
        vm.isAllSelected = false;
       // vm.getclassDetails = getclassDetails;

        vm.showloader = showloader;


        vm.sortType = 'last_name';
        vm.class_period = 0;
        vm.category = 'All';
        vm.exportPDF = exportPDF;
        vm.GenerateRandomNum = GenerateRandomNum;

        vm.vestedorder = 'fullname';
        vm.adminorder = 'fullname';

        vm.db_class_list = [];

        vm.db_club_list = [];

        vm.backdrop = '';
        
        $rootScope.$on('setBackdrop', function (event, data) {
        	vm.backdrop = data;

         });
        

        function showloader() {
            //usSpinnerService.spin('spinner-1');
        }


        studentsService.all().then(function(data) {
            vm.students = data;
           
        });


        adminsService.all().then(function(data) {
            vm.admins = data;
        });


        vestedMembersService.all().then(function(data) {
            vm.vestedMembers = data;
        });


        ClassesService.getUserCreatedPeriod('/api/classes/classperiod').then(function(data) {
            if (data !== null) {
                angular.forEach(data, function(class_period) {
                    if (class_period.group_type === '1')
                        vm.db_class_list.push({
                            id: class_period.id,
                            period: class_period.period,
                            period_letter: class_period.period_letter
                        });
                    else
                        vm.db_club_list.push({
                            id: class_period.id,
                            name: class_period.name
                        });
                });
            }
        }, function() {

        });


        angular.forEach(vm.students, function(student) {

            var total = student.positive_count + student.negative_count;

            student.positive_percent = percent(student.positive_count, total);
            student.negative_percent = percent(student.negative_count, total);

        });


        function percent(current, total) {
            if (total > 0) {
                return Math.round((100 * current) / total);
            } else {
                return 0;
            }
        }

        function exportPDF() {

            exportService.exportStudents({
                order: vm.sortType,
                class_periodId: vm.class_period,
                report_type: 'students'
            });
        }


        function sendMessageToSystemUser(user, typeUser, typeCommunication) {
            var view = 'message';
            switch (typeCommunication) {
                case 1:
                    view = 'message';
                    break;
                case 2:
                    view = 'call';
                    break;
                case 3:
                    view = 'email';
                    break;
            }

            return $uibModal.open({
                templateUrl: '/app/partials/address-book/communication-to-system-user/' + view + '.html',
                controller: 'PrivateMessageController',
                controllerAs: 'PrivateMessageCtrl',
                size: 'lg',
                resolve: {
                    user: function() {
                        return user;
                    },
                    typeUser: function() {
                        return typeUser;
                    },
                    typeCommunication: function() {
                        return typeCommunication;
                    }
                }
            }).result.then(function(response) {
                switch (typeCommunication) {
                    case 1:
                        helperService.showAlert('The message was sent.');
                        break;
                    case 2:
                        helperService.showAlert('The call was made.');
                        break;
                    case 3:
                        helperService.showAlert('The email was sent.');
                        break;
                }

                return response;
            });
        }

        function sendMessage(student) {

            helperService.communicationModal(student, 1, 'message', '', vm.category,'').then(function(response) {
                helperService.showAlert('The message was sent.');

                updatePercent(student, response.type_id);

                student.total_texts++;

                return response;
            });
        }

        function makeCall(student) {
            helperService.communicationModal(student, 2, 'call', '', vm.category, vm.backdrop).then(function(response) {
                helperService.showAlert('The call was made.');

                updatePercent(student, response.type_id);

                student.total_calls++;

                return response;
            });
        }

        function sendEmail(student) {

            helperService.communicationModal(student, 3, 'email', '', vm.category,'').then(function(response) {
                helperService.showAlert('The email was sent.');

                updatePercent(student, response.type_id);

                student.total_emails++;

                return response;
            });
        }

        function showAdmin(admin, index) {
            $uibModal.open({
                    templateUrl: '/app/partials/address-book/admin-modal.html',
                    controller: 'ShowAdminController',
                    controllerAs: 'ShowAdminCtrl',
                    size: 'lg',
                    resolve: {
                        admin: function() {
                            return admin;
                        },
                        adminTypes: function() {
                            return vm.adminTypes;
                        },
                    }
                })
                .result.then(function(admin) {
                    userUpdatedToast(vm.admins[index].fullname, 'admin');

                    vm.admins[index] = admin;
                });
        }

        function showVestedMember(vestedMember, index) {
            $uibModal.open({
                    templateUrl: '/app/partials/address-book/vested-member-modal.html',
                    controller: 'ShowVestedMemberController',
                    controllerAs: 'ShowVestedMemberCtrl',
                    resolve: {
                        vestedMember: function() {
                            return vestedMember;
                        },
                        vestedMemberTypes: function() {
                            return vm.vestedMemberTypes;
                        }
                    }
                })
                .result.then(function(vestedMember) {
                    userUpdatedToast(vm.vestedMembers[index].fullname, 'vested member');

                    vm.vestedMembers[index] = vestedMember;
                });
        }

        function createStudentModal() {
            GenerateRandomNum();
            $uibModal.open({
                    templateUrl: '/app/partials/address-book/create-student-modal.html',
                    controller: 'CreateStudentController',
                    controllerAs: 'CreateStudentCtrl',
                    size: 'lg',
                    resolve: {
                        createfrom: function() {
                            return '';
                        }
                    },
                    parent: angular.element(document.body),
                })
                .result.then(function(student) {
                    vm.students.push(student);

                    userCreatedToast(student.fullname, 'student');
                });
        }

        function GenerateRandomNum() {
            studentsService.generateRandom().then(function(response) {
                $rootScope.$emit('getRandomNum', {
                    num: response
                });
            });

        }


        $rootScope.$on("callCreateStudentModal", function(evt, data) {
            createStudentModal();
        });

        function createAdminModal() {
            $uibModal.open({
                    templateUrl: '/app/partials/address-book/create-admin-modal.html',
                    controller: 'CreateAdminController',
                    controllerAs: 'CreateAdminCtrl',
                    resolve: {
                        adminTypes: function() {
                            return vm.adminTypes;
                        },
                    }
                })
                .result.then(function(admin) {
                    vm.admins.push(admin);

                    userCreatedToast(admin.fullname, 'admin');
                });
        }

        function createVestedMemberModal() {
            $uibModal.open({
                    templateUrl: '/app/partials/address-book/create-vested-member-modal.html',
                    controller: 'CreateVestedMemberController',
                    controllerAs: 'CreateVestedMemberCtrl',
                    resolve: {
                        vestedMemberTypes: function() {
                            return vm.vestedMemberTypes;
                        }
                    }
                })
                .result.then(function(vestedMember) {
                    vm.vestedMembers.push(vestedMember);

                    userCreatedToast(vestedMember.fullname, 'vested member');
                });
        }

        function deleteStudent(student, index) {
            var indexstudent = vm.students.indexOf(student);

            $mdDialog
                .show(setupDialogContent(student.fullname, 'student'))
                .then(function() {

                    studentsService.removestudent(student).then(function() {
                        vm.students.splice(indexstudent, 1);
                        usedDeletedToast(student.fullname, 'student');
                    });
                });
        }

        function deleteAdmin(admin, index) {
            var indexadmin = vm.admins.indexOf(admin);
            $mdDialog
                .show(setupDialogContent(admin.fullname, 'admin'))
                .then(function() {
                    adminsService.adminDelete(admin).then(function() {
                        // Remove from list
                        vm.admins.splice(indexadmin, 1);

                        // Show the toast
                        usedDeletedToast(admin.fullname, 'admin');
                    });
                });
        }

        function deleteVestedMember(vestedMember, index) {
             var indexvestedmember = vm.vestedMembers.indexOf(vestedMember);

            $mdDialog
                .show(setupDialogContent(vestedMember.fullname, 'vested member'))
                .then(function() {
                    vestedMembersService.removeVested(vestedMember).then(function() {
                        // Remove from list
                        vm.vestedMembers.splice(indexvestedmember, 1);

                        // Show the toast
                        usedDeletedToast(vestedMember.fullname, 'vested member');
                    });
                });
        }

        function setupDialogContent(name, role) {
            var title = 'Deleting ';
            switch (role) {
                case 'student':
                    title += 'a Student';
                    break;
                case 'admin':
                    title += 'an Admin';
                    break;
                case 'vested member':
                    title += 'a Vested Member';
                    break;
                default:
                    title += 'an User';
                    break;
            }

            return $mdDialog.confirm()
                .title(title)
                .textContent('Deleting ' + name +
                    ' will remove him completely from your TeachersInTouch! Are you sure you want to delete ' + name +
                    ' completely?')
                .ok('Delete')
                .cancel('Cancel');
        }

        function usedDeletedToast(name, role) {
            toastr.success('The ' + role + ' ' + name + ' has been deleted!');
        }

        function userCreatedToast(name, role) {
            toastr.success('The ' + role + ' ' + name + ' has been created!');
        }

        function userUpdatedToast(name, role) {
            toastr.success('The ' + role + ' ' + name + ' has been updated!');
        }


        function sendBlast() {

            var studentsBlastSend = $filter('filter')(vm.students, {
                selected: true
            });

            if (studentsBlastSend.length === 0) {
                return;
            }

            $uibModal.open({
                templateUrl: '/app/partials/address-book/communication/selection-type-blast.html',
                controller: 'SelectionTypeBlastController',
                controllerAs: 'SelectionTypeBlastCtrl'
            }).result.then(function(mode) {

                communicationMassEmailModal(studentsBlastSend, mode).then(function(data) {
                    var sendCount = (data.to === 3) ? 2 : 1;
                    vm.isAllSelected = false;

                    helperService.showAlert('Blast has been sent.');
                    angular.forEach(studentsBlastSend, function(student) {

                        updatePercent(student, data.type_id);

                        student.total_texts += sendCount;
                        student.selected = false;

                    });

                });
            });
        }


        function setCannedData(cannedTypes, cannedCategories, callStatuses) {
            vm.cannedTypes = cannedTypes;
            vm.cannedCategories = cannedCategories;
            vm.callStatuses = callStatuses;
        }



        function communicationMassEmailModal(students, type) {
            var partial;

            switch (type) {
                case 1:
                    partial = 'message-blast';
                    break;
                case 3:
                    partial = 'email-blast';
                    break;
            }


            return $uibModal.open({
                templateUrl: '/app/partials/address-book/communication/' + partial + '.html',
                controller: 'MessageBlastController',
                controllerAs: 'MessageBlastCtrl',
                size: 'lg',
                resolve: {
                    type: function() {
                        return type;
                    },
                    students: function() {
                        return students;
                    },
                    cannedTypes: function() {
                        return vm.cannedTypes;
                    },
                    cannedCategories: function() {
                        return vm.cannedCategories;
                    }
                }
            }).result;
        }

        function toggleAllSelected() {
            angular.forEach(vm.students, function(student) {
                student.selected = vm.isAllSelected;
            });
        }

        function optionToggled() {
            vm.isAllSelected = vm.students.every(function(student) {
                return student.selected;
            });
        }

        function updatePercent(student, type_id) {
            if (type_id === 1) {
                student.positive_count++;
            } else {
                student.negative_count++;
            }

            var total = student.positive_count + student.negative_count;

            student.positive_percent = percent(student.positive_count, total);
            student.negative_percent = percent(student.negative_count, total);
        }
        

       
    }
})
();