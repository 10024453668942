(function () {
  'use strict';

  angular
    .module('app')
    .service('gradesService', gradesService);

  /* @ngInject */
  function gradesService($resource) {
    /* jshint validthis: true */
    var vm = this;

    var Grade = $resource('/api/grades/:gradeId');

    vm.all = all;

    //////////////////

    function all() {
      return Grade.query().$promise;
    }
  }
})();