(function () {
  'use strict';

  angular
    .module('app.login')
    .controller('RegistrationController', RegistrationController);

  /* @ngInject */
  function RegistrationController($uibModal,$auth, $rootScope, authService, toastr, types, personTitles, adminsService, vestedMembersService, messagesService, $state, $timeout) {
    /* jshint validthis: true */
    let vm = this;

    vm.request = false;

    //alert('hello world');

    types.push({title: 'Other'});
    vm.types = types;
    vm.TermsAndCondition=TermsAndCondition;
    $rootScope.userRegisType = 1;
    vm.personTitles = personTitles;
    vm.form = {
      title: vm.personTitles[0],
      type_id: vm.types.length ? vm.types[0].id : undefined,
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      plan: '',
      password_confirmation: '',
      agree: false
    };
    vm.userRegistrationType = $state.params.utype;
   $rootScope.userRegisType = $state.params.utype ;

  
   vm.hideframe = false;
   if(!$rootScope.logo_show){
   $rootScope.logo_show = false;
   }
   
    vm.logoShow = logoShow;
    vm.signUp = signUp;
    vm.passwordConfirmed = passwordConfirmed;
    vm.changeRegisType = changeRegisType;
    logoShow();
    ////////////////

    function TermsAndCondition()
    {
        return $uibModal.open({
          templateUrl: '/app/partials/login/terms-and-condition.html',
          controller: 'TermsConditionController',
          controllerAs: 'TermsConditionCtrl',
          size: 'lg',   
          }).result.then(function () {


          });   

    }

    function signUp() {
      vm.request = true;

      authService.signUp(vm.form).then(function () {
        //Alert that you are registered.
        toastr.success('You was success sign up. Now you can login.');
       
        //Redirect to login state
        $auth.login(vm.form).then(function (data) {
          authService.me().then(function (res) {
            vm.isLoggedIn = res.isLoggedIn;
            authService.createsubSid(res.id);
            toastr.success('You are successfully logged in.', 'Login');
            vm.hideframe = true;
        	$rootScope.logo_show = false;	
            $state.go('setup-account');
            //authService.defaultRedirect();

            return res;
          });

          return data;
        });
      }).finally(function () {
        vm.request = false;
      });
    }

    function passwordConfirmed() {
      return vm.form.password_confirmation && vm.form.password_confirmation === vm.form.password;
    }

    function changeRegisType(value){
      $rootScope.userRegisType = value;
    }
    
    function logoShow(){
    	if( $rootScope.logo_show === false){
    		 $timeout(function () {
    			 $rootScope.logo_show = true;
    		    }, 500);
    		
    	}
    }
  }
})();