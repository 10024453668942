

  function loading() {
	  alert('');
      return {
          restrict: 'E',
          replace:true,
          template: '<div class="loading"><img src="..."/>LOADING...</div>',
          link: function (scope, element, attr) {
                scope.$watch('loading', function (val) {
                    if (val)
                        $(element).show();
                    else
                        $(element).hide();
                });
          }
        };
    
  }

  angular
  .module('app.core')
  .directive('loading', loading);