(function () {
  'use strict';

  angular
    .module('app.response-to-intervention-analytics')
    .service('responseToInterventionAnalyticsService', responseToInterventionAnalyticsService);

  /* @ngInject */
  function responseToInterventionAnalyticsService($http, helperService) {
    /* jshint validthis: true */
    var vm = this;

    vm.quickview = quickview;
    vm.subjectGradeComparisons = subjectGradeComparisons;
    vm.teacherComparisons = teacherComparisons;
    vm.teacherDetails = teacherDetails;

    ////////////

    function quickview(filters) {
      // prepare filters

      return $http.get('/api/analytics/rti/quickview', {params: filters})
        .then(helperService.handleResponse)
        .then(function (data) {
          // Prepare data before view

          return data;
        });
    }

    function subjectGradeComparisons(filters) {
      // prepare filters

      return $http.get('/api/analytics/rti/subject-grade-comparisons', {params: filters})
        .then(helperService.handleResponse)
        .then(function (data) {
          // Prepare data before view

          return data;
        });
    }

    function teacherComparisons(filters) {
      // prepare filters

      return $http.get('/api/analytics/rti/teacher-comparisons', {params: filters})
        .then(helperService.handleResponse)
        .then(function (data) {
          // Prepare data before view

          return data;
        });
    }

    function teacherDetails(filters) {
      // prepare filters

      return $http.get('/api/analytics/rti/teacher-details', {params: filters})
        .then(helperService.handleResponse)
        .then(function (data) {
          // Prepare data before view

          return data;
        });
    }
  }
})();