(function () {
  'use strict';

  angular
    .module('app.attendance-analytics.quickview')
    .controller('AttendanceQuickviewController', AttendanceQuickviewController);

  /* @ngInject */
  function AttendanceQuickviewController($scope, attendanceType, teacherTypes, grades, periods, attendanceAnalyticsService) {
    /* jshint validthis: true */
    let vm = this;

    vm.periods = periods;
    vm.attendanceTypes = attendanceType;
    vm.total = 0;
    vm.total_type = 0;
    vm.options = {
        responsive: true,
      maintainAspectRatio: false,
        tooltips: {
            mode: 'label',
            callbacks: {
                label: function(tooltipItem, data) {
                    return  data.datasets[0].label +' : '+ data.datasets[0].data[tooltipItem.index] + '%';
                }
            }
        },
      scales: {
        xAxes: [{
          stacked: true,
          autoSkip: true,
          autoSkipPadding: 100,
        }],
        yAxes: [{
            scaleLabel: {
                display: true,
                // labelString:  '% of students',
            },
          stacked: true,
          ticks: {
              min: 0, max: 100 ,
              beginAtZero:true,
            callback: (value) => {
              if (value % 1 === 0) {
                return value + ' %';
              }
            }
          }
        }]
      }
    };
    vm.optionLine = {
        responsive: true,
        showLines: true,
        elements: {
            line: {
                fill: false
            }
        },
      maintainAspectRatio: false,
        tooltips: {
            mode: 'label',
            callbacks: {
                label: function(tooltipItem, data) {
                    return  data.datasets[0].label +' : '+ data.datasets[0].data[tooltipItem.index] + '%';
                }
            }
        },
      scales: {
        xAxes: [{
          stacked: true,
          autoSkip: true,
          autoSkipPadding: 100,
        }],
        yAxes: [{
          stacked: true,
          ticks: {
              min: 0, max: 100 ,
              beginAtZero:true,
            callback: (value) => {
              if (value % 1 === 0) {
                return value +' %';
              }
            }
          }
        }]
      }
    };
    vm.types = [
      {
        text: 'Subject',
        values: teacherTypes,
        property: 'subject',
      },
      {
        text: 'Grade',
        values: grades,
        property: 'grade',
      }
    ];

    vm.filters = {};
    Object.defineProperties(vm.filters, {
      _type: {
        value: vm.types[0],
        writable: true,
        enumerable: false,
      },
      _item: {
        enumerable: false,
        get: function () {
          if (this.type === 'subject') return this._type.values.filter((item) => {
            return item.id === this._teacher_type_id;
          })[0];
          if (this.type === 'grade') return this._type.values.filter((item) => {
            return item.id === this._grade_id;
          })[0];
        }
      },
      type: {
        enumerable: true,
        get: function () {
          return this._type.property;
        }
      },
      _teacher_type_id: {
        enumerable: false,
        value: undefined,
        writable: true,
      },
      teacher_type_id: {
        enumerable: true,
        get: function () {
          if (this.type === 'subject') return this._teacher_type_id;
        },
      },
      _grade_id: {
        enumerable: false,
        value: undefined,
        writable: true,
      },
      grade_id: {
        enumerable: true,
        get: function () {
          if (this.type === 'grade') return this._grade_id;
        },
      },
        _attendanceType: {
            value: vm.attendanceTypes[1],
            writable: true,
            enumerable: false,
        },
        attendanceType: {
            enumerable: true,
            get: function () {
                return this._attendanceType.id;
            }
        },
      _period: {
        value: vm.periods[3],
        writable: true,
        enumerable: false,
      },
      period_start: {
        enumerable: true,
        get: function () {
          return this._period.start.format('D-M-Y');
        },
      },
      period_end: {
        enumerable: true,
        get: function () {
          return this._period.end.format('D-M-Y');
        },
      },
      period_slug: {
        enumerable: true,
        get: function () {
          return this._period.slug;
        },
      },
    });

    vm.charts = {
      byPeriod: {
        color:[],
        data: [],
        labels: [],
        series: [],
      },
        byPresent: {
        color:[],
        data: [],
        labels: [],
        series: [],
      },
      byCategories: {
        data: [],
        labels: []
      },
      byType: {
        data: [],
        labels: [],
      }
    };

    vm.getStatistics = getStatistics;

    $scope.$watchCollection('AttendanceQuickviewCtrl.filters', function () {
      getStatistics();
    });

    /////////////////

    function getStatistics() {
      attendanceAnalyticsService.quickview(vm.filters).then(function (response) {
        vm.charts.byCategories.data = response.byCategory.map((item) => {
          return item.count;
        });
        vm.charts.byCategories.labels = response.byCategory.map((item) => {
          return item.name;
        });

        vm.charts.byType.data = response.byType.map((item) => {
          return item.count;
        });
        vm.charts.byType.labels = response.byType.map((item) => {
          return item.name;
        });

        vm.charts.byPeriod.labels = response.byPeriods.labels;
        vm.charts.byPeriod.series = response.byPeriods.series;
        vm.charts.byPeriod.data = response.byPeriods.data;
        vm.charts.byPeriod.color = response.byPeriods.color;

        vm.charts.byPresent.labels = response.byPresents.labels;
        vm.charts.byPresent.series = response.byPresents.series;
        vm.charts.byPresent.data = response.byPresents.data;
        vm.charts.byPresent.color = response.byPresents.color;

        vm.total = response.total;
        vm.total_type = response.total_type;
      });
    }
  }
})();