(function() {

    'use strict';

    angular
        .module('app.classes')
        .controller('AddnewclassController', AddnewclassController);

    /* @ngInject */
    function AddnewclassController($scope, dateRanges, classtype, clubtype, grade, periods_no, periods_letter, helperService, activateIcon,
        $rootScope, semester, classs_frequency, $uibModal, ClassesService, students, club_frequency, days, studentsService,
        $stateParams, SweetAlert, $state, schoolPeriods, schoolStudents, alphabet, $filter,toastr) {
        /* jshint validthis: true */
        var vm = this;

        vm.schoolPeriods = schoolPeriods;
        vm.students = schoolStudents;
        vm.ReloadClassList=ReloadClassList;
        vm.deleteClass=deleteClass;
        $rootScope.qr_code="";
        $rootScope.class_access_code="";

        vm.SelectedByLetter=[];
        vm.resetStudent=resetStudent;

        vm.class_edit=0;

        var curr_year = new Date().getMonth() >= 6 ? new Date().getFullYear() : (new Date().getFullYear() - 1);
        
        var stTime = new Date(); // for now
        stTime.getHours(); // => 9
        stTime.getMinutes(); // =>  30
        stTime.getSeconds(); // => 51

        ReloadClassList();

        vm.FormData = {
            group_type: '1',
            name: '',
            type: classtype[0].value,
            other: '',
            grade: grade[0],
            school_year: curr_year + '-' + (curr_year + 1),
            semester: semester[0],
            period: '',
            period_letter: '',
            active_icon: 'fa fa-pencil-square-o',
            start_date: new Date(),
            start_time: new Date(),
            end_date: new Date(),
            day_label_1: '',
            day_label_2: '',
            day_label_3: '',
            class_access_code: '',
            qr_image_name:'',
            qr_code: '',
            school_period_id:'',
            end_time: new Date(),
            class_frequency: classs_frequency[0],
            students: [],
            students_id: [],
            roaster_data: []
        };

        

        vm.clubperiods = [10, 11, 12, 13, 14, 15];
        vm.selectedclubPeriod = 'Select';
        vm.setperiodType = 'class';
        vm.clubFrequencies = club_frequency;
        vm.selectedclubfrequencyVal = 'meet-everyday';
        vm.selectedclubfrequencyTitle = 'Meet Everyday';
        vm.period = [];
        vm.period_letter = [];
        vm.student_selected = false;
        vm.assigned_students = 0;
        vm.error_msg = '';
        vm.otherTypeselcted = false;
        vm.searchName = '';
        vm.searchLetter = '';
        vm.alphabet = alphabet;

        vm.activeTab = '1';
        vm.getnextTab = getnextTab;
        vm.resetForm = resetForm;
        vm.tabdet = 'tab1';
        vm.checkvalue = 'class';
        vm.changeType = changeType;
        vm.namevariable = 'Class';
        vm.createNew = createNew;
        vm.updateSchoolClass = updateSchoolClass;
        vm.previewstudents = '';

        vm.Types = classtype;
        // alert(classtype[0].value);
        vm.selectedtypeValue = classtype[0].value;
        vm.selectedtypeTitle = classtype[0].title;

        //vm.Types = '';
        //vm.clubType = clubtype;
        vm.grades = grade;
        vm.periodNo = 'Select';
        vm.periodLetter = 'Select';
        vm.semesters = semester;
        vm.frequencies = classs_frequency;
        vm.getclassesdata = getclassesdata;
        vm.classEdit = classEdit;
        vm.redirectFrom = $stateParams.redirect_type;
        vm.checkGradeValue = checkGradeValue;

        //  $scope.$watch('AddnewclassCtrl.FormData.start_date', getclassesdata, false);

        vm.classesId = ($stateParams.id !== null || $stateParams.id !== '' ? $stateParams.id : '');

        vm.buttontype = (vm.classesId !== null ? 'Edit and continue' : 'Save and continue');

        if (vm.classesId) 
        {
            getclassesdata();
        }

        if(vm.class_edit===1)
        {
            vm.buttontype="Edit and continue";
        }
        else
        {
            vm.buttontype="Save and continue";
        }

        function deleteClass(classesId,groupType)
        {

            if(vm.class_edit===1 && classesId!=="")
            {
              return $uibModal.open({
              templateUrl: '/app/partials/classes/confirmation-remove-class.html',
              controller: 'RemoveClassController',
              controllerAs: 'RemoveClassCtrl',
                  resolve: {
                  classesId: function () {
                    return classesId;
                  },
                  groupType: function () {
                    return groupType;
                  }  
                }
              }).result.then(function () {


              });

            }
            else
            {

            }
            

        }

        function getclassesdata() {

            vm.FormData.students=[];

            ClassesService.getclassesData('/api/classes/show?id=' + vm.classesId).then(function(data) {

                changeType(data[0].classes.group_type);
                vm.class_edit=1;
                if(vm.class_edit===1)
                {
                    vm.buttontype="Edit and continue";
                }
                else
                {
                    vm.buttontype="Save and continue";
                }
                vm.FormData.id = data[0].classes.id;
                vm.FormData.school_period_id = data[0].classes.school_period_id;
                //vm.FormData.students.push(data[0].students);
                vm.FormData.students = data[0].students;
                vm.previewstudents = data[0].students;
                vm.assigned_students = data[0].students.length;
                vm.student_selected = true;
                vm.FormData.group_type = data[0].classes.group_type;
                vm.FormData.name = data[0].classes.name;
                vm.FormData.type = classtype[data[0].classes.type - 1].value;
                vm.selectedtypeValue = classtype[data[0].classes.type - 1].value;
                vm.selectedtypeTitle = (data[0].classes.group_type == 1 ? classtype[data[0].classes.type - 1].title : clubtype[data[0].classes.type - 1].title);
                vm.FormData.grade = data[0].classes.grade;
                vm.FormData.school_year = data[0].classes.school_year;

                vm.FormData.semester = semester[data[0].classes.semester - 1];
                if (vm.setperiodType == 'class') {
                    vm.periodNo = data[0].classes.period;


                } else {
                    vm.selectedclubPeriod = data[0].classes.period;

                    if (data[0].classes.class_frequency) {
                        let indexs = club_frequency.findIndex((item) => item.value === data[0].classes.class_frequency);
                        vm.selectedclubfrequencyTitle = club_frequency[indexs].title;
                        vm.selectedclubfrequencyVal = club_frequency[indexs].value;
                    }
                }
                vm.FormData.period = data[0].classes.period;
                checkperiod(data[0].classes.period);

                vm.periodLetter = data[0].classes.period_letter;
                vm.FormData.period_letter = data[0].classes.period_letter;
                vm.FormData.active_icon = data[0].classes.active_icon;
                /* var sd = new Date();
                 var sdata = data[0].classes.start_date.split('-');
                 sd.setYear(sdata[0]); sd.setMonth(sdata[1]); sd.setDate(sdata[2]);*/

                vm.FormData.start_date = moment(data[0].classes.start_date).format('YYYY-MM-DD');

                var st = new Date();
                var startdate = data[0].classes.start_time.split(':');
                st.setHours(startdate[0]);
                st.setMinutes(startdate[1]);
                st.setSeconds(startdate[2]);
                vm.FormData.start_time = st;
                vm.FormData.end_date = new Date(data[0].classes.end_date);

                vm.selectday1 = (data[0].classes.day_label_1 ? true : false);
                vm.selectday2 = (data[0].classes.day_label_2 ? true : false);
                vm.selectday3 = (data[0].classes.day_label_3 ? true : false);
                vm.selctday1Title = data[0].classes.day_label_1 ? data[0].classes.day_label_1.charAt(0).toUpperCase() + data[0].classes.day_label_1.substring(1) : 'Monday';
                vm.selctday2Title = data[0].classes.day_label_2 ? data[0].classes.day_label_2.charAt(0).toUpperCase() + data[0].classes.day_label_2.substring(1) : 'Monday';
                vm.selctday3Title = data[0].classes.day_label_3 ? data[0].classes.day_label_3.charAt(0).toUpperCase() + data[0].classes.day_label_3.substring(1) : 'Monday';
                vm.selectday1value = data[0].classes.day_label_1 ? data[0].classes.day_label_1 : 'monday';
                vm.selectday2value = data[0].classes.day_label_2 ? data[0].classes.day_label_2 : 'monday';
                vm.selectday3value = data[0].classes.day_label_3 ? data[0].classes.day_label_3 : 'monday';
                vm.FormData.day_label_1 = data[0].classes.day_label_1;
                vm.FormData.day_label_2 = data[0].classes.day_label_2;
                vm.FormData.day_label_3 = data[0].classes.day_label_3;
                vm.FormData.end_time = new Date(data[0].classes.end_time);
                var ed = new Date();
                var enddate = data[0].classes.end_time.split(':');
                ed.setHours(enddate[0]);
                ed.setMinutes(enddate[1]);
                ed.setSeconds(enddate[2]);
                vm.FormData.end_time = ed;

                vm.FormData.class_frequency = data[0].classes.class_frequency;


            });


        }

        function ReloadClassList()
        {
            $rootScope.db_class_list = [];
            $rootScope.db_club_list = [];

            ClassesService.getclassesData('api/classes').then(function(data) {
                        if (data.class_period !== null) {
                            angular.forEach(data.class_period, function(class_period) {
                                if (class_period.group_type === '1')
                                    $rootScope.db_class_list.push({
                                        id: class_period.id,
                                        name: class_period.name,
                                        period: class_period.period,
                                        period_letter: class_period.period_letter
                                    });
                                else
                                    $rootScope.db_club_list.push({
                                        id: class_period.id,
                                        name: class_period.name
                                    });
                            });
                        }

                    });
        }





        function classEdit() {

            //vm.FormData.qr_code=$rootScope.qr_code;
            //vm.FormData.class_access_code=$rootScope.class_access_code;

            delete vm.FormData.qr_code;
            delete vm.FormData.class_access_code;

        	vm.FormData.start_time = $filter('date')(vm.FormData.start_time, 'HH:mm:s');
        	vm.FormData.end_time = $filter('date')(vm.FormData.end_time, 'HH:mm:s');
            var classId = {
                id: vm.classesId
            };
            Object.assign(vm.FormData, classId);
            ClassesService.update('/api/classes/update', vm.FormData).then(function(response) {
                if (response) {
                    helperService.showAlert('You have Successfully edited ' + vm.namevariable);
                    ReloadClassList();
                    
                }
            });

            $state.go('dashboard.main');
        }

        vm.startdate = '';
        vm.enddate = '';
        vm.starttime = '';
        vm.endtime = '';
        vm.days = days;
        vm.selectday1 = false;
        vm.selectday2 = false;
        vm.selectday3 = false;
        vm.selectday1value = 'monday';
        vm.selctday1Title = 'Monday';
        vm.selectday2value = 'monday';
        vm.selctday2Title = 'Monday';
        vm.selectday3value = 'monday';
        vm.selctday3Title = 'Monday';
        vm.typeforOther = typeforOther;

        vm.classesCreateStudent = classesCreateStudent;
        vm.selectdataModal = selectdataModal;
        vm.classroomSignupLink = classroomSignupLink;
        vm.uploadRoasterModal = uploadRoasterModal;
        vm.GenerateRandomNum = GenerateRandomNum;
        vm.setclubFrequency = setclubFrequency;
        vm.iconchangeModal = iconchangeModal;
        vm.checkperiod = checkperiod;
        vm.init = init;
        vm.previewModal = previewModal;
        vm.create = create;

        //vm.submit = submit;
        vm.getPeriod = getPeriod;
        vm.addToClass = addToClass;
        vm.removeFromClass = removeFromClass;
        vm.setSearchByLetter = setSearchByLetter;

        vm.setDate = setDate;

        vm.open2 = open2;
        vm.popup2opened = false;

        getPeriod();

        vm.mytime = new Date();
        vm.ismeridian = true;
        vm.changed = changed;
        vm.hstep = 1;
        vm.mstep = 15;

        vm.step1data = {
            name: '',

        };




        $rootScope.$on("setIcon", function(event, arr) {
            vm.FormData.active_icon = arr.name;
        });

        $rootScope.$on("setStudent_form", function(event, value) {
            var assign = [];
            vm.FormData.students.push(value);

            vm.student_selected = true;
            vm.error_msg = '';
            vm.assigned_students = vm.FormData.students.length + vm.FormData.students_id.length;
            assign.push(value);
            if (vm.previewstudents.length > 0) {

                vm.previewstudents = vm.previewstudents.concat(assign);
            } else {

                vm.previewstudents = vm.FormData.students;
            }

            assign.length = '';
        });


        $rootScope.$on('setStudents_form', function(event, value) {
            vm.FormData.students.push(value);

        });


        $rootScope.$on('attachStudent_id', function(event, value) {
            var studentData = [];
            vm.FormData.students_id = value.selectedStudentId;
            vm.student_selected = true;
            vm.error_msg = '';
            vm.assigned_students = vm.FormData.students.length + vm.FormData.students_id.length;
            angular.forEach(value.selectedName, function(name) {
                studentData.push({
                    fullname: name.split('-')[0],
                    g1fullname: name.split('-')[1]
                });
            });

            if (vm.previewstudents.length > 0) {

                vm.previewstudents = vm.previewstudents.concat(studentData);

            } else {
                vm.previewstudents = studentData;
            }

        });

        $rootScope.$on('attachStudentCSV', function(event, value) {
            vm.FormData.roaster_data = value;
            vm.student_selected = true;
            vm.error_msg = '';
            vm.assigned_students = vm.FormData.roaster_data.length + vm.FormData.students_id.length;
            if (vm.previewstudents.length > 0) {
                vm.previewstudents = vm.previewstudents.concat(value);

            } else {
                vm.previewstudents = value;
            }

        });

        $rootScope.$on("callCreateStudentModal", function(evt, data) {
            classesCreateStudent();
        });



        function setDate(year, month, day) {
            vm.dt = new Date(year, month, day);
        }


        function open2() {
            vm.popup2opened = true;
        }

        function changed() {
            $log.log('Time changed to: ' + $scope.FormData.start_time);
        }


        function typeforOther(value) {
            if (value == 8) {
                vm.otherTypeselcted = true;
            } else {

                vm.otherTypeselcted = false;
            }


        }

        function resetForm(tab) {
        
            if (tab == 1) {

                SweetAlert.confirm("Are you sure?", {
                        title: "Cancel!",
                      cancelButtonText: "No please don't cancel",
                      confirmButtonText: "Yes, I want to cancel"	
                    })
                    .then(function(isConfirm) {
                        if (isConfirm) {
                            /* vm.FormData.name = '';
        		            vm.periodNo = 'Select';
        		            vm.periodLetter = 'Select';
        		            vm.FormData.start_date = new Date();
        		            vm.FormData.start_time = new Date();
        		            vm.selectedtypeTitle = classtype[0].title;
        		            vm.FormData.grade = grade[0];
        		            vm.FormData.group_type = '1';
        		           */
                            $state.go('dashboard.main');
                        }
                    }, function(p) {

                    });
            } else {
                SweetAlert.confirm("Are you sure?", {
                        title: "Cancel!",
                        cancelButtonText: "No please don't cancel",
                        confirmButtonText: "Yes, I want to cancel"	
                    })
                    .then(function(isConfirm) {
                        if (isConfirm) {
                            vm.FormData.name = '';
                            vm.periodNo = 'Select';
                            vm.periodLetter = 'Select';
                            vm.FormData.start_date = new Date();
                            vm.FormData.start_time = new Date();
                            vm.selectedtypeTitle = classtype[0].title;
                            vm.FormData.grade = grade[0];
                            vm.FormData.group_type = '1';
                            vm.FormData.semester = semester[0];
                            vm.selectedclubfrequencyVal = 'meet-everyday';
                            vm.selectedclubfrequencyTitle = 'Meet Everyday';
                            vm.activeTab = 1;
                            vm.FormData.end_date = new Date();
                            vm.FormData.day1 = '';
                            vm.FormData.day2 = '';
                            vm.FormData.day3 = '';
                            vm.FormData.end_time = new Date();
                            vm.period = [];
                            vm.period_letter = [];
                            vm.student_selected = false;
                            vm.assigned_students = 0;
                            getPeriod();
                            $state.go('dashboard.main');

                        }
                    }, function(p) {

                    });

            }

        }

        

        function addToClass(student) {

            //console.log(vm.FormData.students);

            vm.flag=1;

            angular.forEach(vm.FormData.students, function(item) {

                //console.log(item.id+" === "+student.id);

               if(item.id===student.id) 
               {
                  vm.flag=0;
               }
               
                
            });


            if(vm.flag===1)
            {
                vm.students.splice(vm.students.indexOf(student), 1);
                vm.FormData.students.push(student);
            }
            else
            {
                 toastr.error('Student already selected');
            }
            
        }

        function removeFromClass(student) {
            vm.FormData.students.splice(vm.FormData.students.indexOf(student), 1);
            vm.students.push(student);
        }


        function resetStudent()
        {
            vm.students = schoolStudents;
        }

        function setSearchByLetter(letter) {
            //vm.searchLetter = vm.searchLetter === letter ? '' : letter.toLowerCase();
            vm.students = schoolStudents;
            vm.SelectedByLetter=[];
            angular.forEach(vm.students, function(item) {

                if(item.last_name[0]===letter)
                {    
                  vm.SelectedByLetter.push(item);
                }
             
                
            });

            //console.log(vm.SelectedByLetter);
            vm.students=vm.SelectedByLetter;
        }


        function getnextTab(step, form) {

        	//console.log(form);
            if (form.$valid) {
                vm.activeTab = parseInt(step);
                form.$setPristine();
            } else {
                var field = null,
                    firstError = null;
                for (field in form) {
                    if (field[0] !== '$') {
                        if (firstError === null && !form[field].$valid) {
                            firstError = form[field].$name;
                        }

                        if (form[field].$pristine) {
                            form[field].$dirty = true;
                        }
                    }
                }

                angular.element('.ng-invalid[name=' + firstError + ']').focus();


            }
        }

        function setclubFrequency(value, title) {
            vm.selectedclubfrequencyVal = value;
            vm.selectedclubfrequencyTitle = title;
            if (value == 'meet-everyday') {
                vm.selectday1 = false;
                vm.selectday2 = false;
                vm.selectday3 = false;
            }
            if (value == 'meet-every-other-day') {
                vm.selectday1 = true;
                vm.selectday2 = false;
                vm.selectday3 = false;
            }
            if (value == 'meet-twice-a-week') {
                vm.selectday1 = true;
                vm.selectday2 = true;
                vm.selectday3 = false;
            }
            if (value == 'meet-three-times-a-Week') {
                vm.selectday1 = true;
                vm.selectday2 = true;
                vm.selectday3 = true;
            }

        }

        function changeType(value) {
            if (value == 1) {
                vm.Types = classtype;
                vm.selectedtypeTitle = classtype[0].title;
                vm.setperiodType = 'class';
                vm.namevariable = 'Class';
            }
            if (value == 2) {
                vm.Types = clubtype;
                vm.selectedtypeTitle = clubtype[0].title;
                vm.setperiodType = 'club';
                vm.namevariable = 'Club';
            }
        }


        function iconchangeModal() {

            helperService.classesModal('', '', 'icon', 'icon-change-modal', 'lg').then(function(response) {
                return response;
            });

        }


        function uploadRoasterModal() {
            helperService.newclassModal('upload-roaster-modal', 'md', students, vm.FormData, '').then(function(response) {
                helperService.showAlert('You have Successfully! uploaded roaster.');
                return response;
            });

        }

      
        function classroomSignupLink()
        {

            return $uibModal.open({
              //modalTemplate: '<div class="custom_modal modal fade" id="myModal" role="dialog"></div>',  
              templateUrl: '/app/partials/classes/share_classroom_signup_link_confirmation.html',
              windowClass : "custom_modal",
              controller: 'ConfimationShareClassroomSignupLinkController',
              controllerAs: 'ConfimationShareClassroomSignupLinkCtrl',
              size: 'md',
              resolve: {
                  FormData: function () {
                    return vm.FormData;
                  } 
                }
              }).result.then(function () {


              });
            
        }

        function selectdataModal() {


            helperService.newclassModal('select-db-modal', 'lg', students, vm.FormData, vm.previewstudents).then(function(response) {

                helperService.showAlert('student data selected successully.');
                return response;
            });

        }

        function previewModal() {
            helperService.previewModal('preview-modal', 'md', vm.previewstudents).then(function(response) {

                helperService.showAlert('student data selected successully.');
                return response;
            });

        }


        function classesCreateStudent() {
            GenerateRandomNum();
            $uibModal.open({
                    templateUrl: '/app/partials/address-book/create-student-class-modal.html',
                    controller: 'CreateStudentController',
                    controllerAs: 'CreateStudentCtrl',
                    size: 'lg',
                    resolve: {
                        createfrom: function() {
                            return 'formClasses';
                        }
                    },
                    parent: angular.element(document.body),
                })
                .result.then(function(student) {
                    vm.student_selected = true;
                });
        }

        function GenerateRandomNum() {
            studentsService.generateRandom().then(function(response) {
                $rootScope.$emit('getRandomNum', {
                    num: response
                });
            });

        }

        function create() {
            if (vm.request) {
                return;
            }
            vm.request = true;

            ClassesService.createClass(vm.FormData).then(() => {
                ReloadClassList();
                helperService.showAlert('Class Was Created');

                vm.activeTab = 3;
            }).finally(() => {
                vm.request = false;
            });
        }


        function updateSchoolClass()
        {
            delete vm.FormData.students_id;
            delete vm.FormData.roaster_data;

            ClassesService.updateClass('school-students/updatestudent',vm.FormData).then(() => {
                ReloadClassList();
                helperService.showAlert('Class edited successully');
                $state.go('dashboard.main');
            });
            
        }


        function createNew() {
        	
            //vm.FormData.qr_code=$rootScope.qr_code;
            //vm.FormData.class_access_code=$rootScope.class_access_code;



        	vm.FormData.start_time = $filter('date')(vm.FormData.start_time, 'HH:mm:s');
        	vm.FormData.end_time = $filter('date')(vm.FormData.end_time, 'HH:mm:s');
        	
            /*if (vm.student_selected) {*/
                vm.error_msg = '';
                var urlparemeter = 'api/classes/addewnclass';
                ClassesService.create(urlparemeter, vm.FormData).then(function(response) {
                    vm.activeTab = 4;
                    $rootScope.qr_code="";
                    $rootScope.class_access_code="";

                    //console.log(response);

                    if (response)
                        $rootScope.db_class_list = [];
                    $rootScope.db_club_list = [];
                    ClassesService.getclassesData('api/classes').then(function(data) {
                        if (data.class_period !== null) {
                            angular.forEach(data.class_period, function(class_period) {
                                if (class_period.group_type === '1')
                                    $rootScope.db_class_list.push({
                                        id: class_period.id,
                                        name: class_period.name,
                                        period: class_period.period,
                                        period_letter: class_period.period_letter
                                    });
                                else
                                    $rootScope.db_club_list.push({
                                        id: class_period.id,
                                        name: class_period.name
                                    });
                            });
                        }

                    });

                    helperService.showAlert('You have Successfully creted ' + response.name + " " + vm.namevariable);
                    $rootScope.user.has_class = true;
                    var formtype = (vm.FormData.group_type == 1 ? 'class' : 'club');
                    SweetAlert.confirm("Would you like to add another " + formtype + " ?", {
                            title: "Confirm!",
                            cancelButtonText: "No,plx don't cancel",
                            confirmButtonText: " Yes, I want to cancel",	
                        })
                        .then(function(isConfirm) {
                            if (isConfirm) {
                                getPeriod();
                                vm.previewstudents.length = '';
                                vm.activeTab = 1;
                                vm.FormData.name = '';
                                vm.periodNo = 'Select';
                                vm.periodLetter = 'Select';
                                vm.FormData.start_date = new Date();
                                vm.FormData.start_time = new Date();
                                vm.selectedtypeTitle = classtype[0].title;
                                vm.FormData.grade = grade[0];
                                vm.FormData.group_type = (formtype == 'class' ? 1 : 2);
                                vm.FormData.semester = semester[0];
                                vm.selectedclubfrequencyVal = 'meet-everyday';
                                vm.selectedclubfrequencyTitle = 'Meet Everyday';

                                vm.activeTab = 1;
                                vm.FormData.end_date = new Date();
                                vm.FormData.day1 = '';
                                vm.FormData.day2 = '';
                                vm.FormData.day3 = '';
                                vm.FormData.end_time = new Date();
                                vm.period = [];
                                vm.period_letter = [];
                                vm.student_selected = false;
                                vm.assigned_students = 0;

                            } else {

                                $state.go('dashboard.main');
                            }
                        }, function(p) {


                        });



                });
            /*} else
                vm.error_msg = 'Please add student(s) in order to save the ' + vm.checkvalue;*/
        }

        function getPeriod() {
            if ($stateParams.club !== null && parseInt($stateParams.club) === 1) {
                vm.FormData.group_type = '2';
                vm.FormData.class_frequency = club_frequency[0].value;
                changeType('2');
            }
            vm.FormData.period = '';
            vm.FormData.period_letter = '';
            vm.periodNo = 'Select';
            vm.periodLetter = 'Select';
            vm.period_letter = [];
            ClassesService.getperiod(vm.FormData.semester, vm.FormData.grade, vm.classesId).then(function(response) {
                vm.period = response;
            });
        }


        function checkperiod(periodvalue) {
            var data = {
                grade: vm.FormData.grade,
                semester: vm.FormData.semester,
                period: periodvalue,
                classId: vm.classesId
            };
            ClassesService.checkperiodInput(data).then(function(response) {
                vm.period_letter = response;
            });
        }
    }


    function init() {
        vm.FormData.group_type = '1';
        vm.FormData.name = '';
        vm.FormData.type = classtype[0].value;
        vm.FormData.other = '';
        vm.FormData.grade = grade[0];
        vm.FormData.school_year = new Date().getFullYear();
        vm.FormData.semester = semester[0];
        vm.FormData.period = '';
        vm.FormData.period_letter = '';
        vm.FormData.active_icon = 'fa fa-pencil-square-o';
        vm.FormData.start_date = new Date();
        vm.FormData.start_time = '';
        vm.FormData.end_date = new Date();
        vm.FormData.day_label_1 = '';
        vm.FormData.day_label_2 = '';
        vm.FormData.day_label_3 = '';
        vm.FormData.end_time = new Date();
        vm.FormData.class_frequency = classs_frequency[0];
        vm.FormData.students = [];
        vm.FormData.students_id = [];
        vm.FormData.roaster_data = [];
    }
    
    function checkGradeValue(value){
    	var val = '';
    	if(value == 1){
    		val = 'st';
    	}else if(value == 2){
    		val = 'nd';
    	}else if(value == 3){
    		val = 'rd';    	
    	}else if(value == 'k'){
    		val = '';  
    	}else {
    		val = 'th';
    	}
    	
    	return val;
    }

})();