(function () {
  'use strict';

  angular
    .module('app.setup-account')
    .config(function ($stateProvider) {

      // Now set up the states
      $stateProvider
        .state('base', {
          abstract: true,
          url: '/base',
          templateUrl: '/app/partials/base/base.html',
        });
    });

})();