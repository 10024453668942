(function() {
    'use strict';

    angular
        .module('app.paperworks')
        .controller('BehaviorInterventionController', BehaviorInterventionController);

    function BehaviorInterventionController($scope, $rootScope, $state, toastr, $stateParams, studentsService, ClassesService, paperworksService, SweetAlert, $location, $anchorScroll, helperService, $filter, $timeout) {
        var vm = this;
        /* Assign Property */
        vm.rti_form = 'BIP';
        vm.test = false;
        vm.showSearchBox = true;
        vm.periods = [];
        vm.students = [];
        vm.studentname = "Student's Name";
        vm.Sid = '';
        vm.studentperiod = "Student's Period";
        vm.userActivity = [];
        vm.studentgrade = "Student's Grade";
        vm.studentgender = "Student's Gender";
        vm.preview = ($stateParams.viewtype == 'view') ? true : false;
        vm.interventions = [];
        vm.other_concern = [];
        vm.area_concern = [];
        vm.behaviour_intervention = [];
        vm.other_intervention = [];
        vm.userSelectedPeriod = '';
        vm.hideforstatus = '';
        vm.showDetails = false;
        paperworksService.getOptions('/api/paperwork/options?rti_type=BIP').then(function(data) {
            vm.interventions = data.interventions;
            vm.area_of_concern = data.concerns;
        });

        vm.paperworkfromClass = $stateParams.paperworkfromClass;
        vm.create_session_notification = 0;
        vm.create_session_notification_2 = 0;
        vm.FirstName = '';
        vm.LastName = '';

        vm.Bip_form = {
            id: 0,
            student_id: 0,
            rti_type: 'BIP',
            reason: 'Behavior',
            date_plan: new Date(),
            category: 2,
            type: 2,
            teacher_initiating: $rootScope.user.first_name + ' ' + $rootScope.user.last_name,
            teacher_collaborator_1: '',
            teacher_collaborator_2: '',
            teacher_collaborator_3: '',
            saved: false
        };

        vm.bip_screen = {
            student_id: 0,
            rti_type: 'BIP',
            reason: 'Behavior',
            screen_by: '',
            screen_date: new Date(),
            screen_measurement_tool: 'behavior-emotional-screening-system',
            screen_other_measurement: '',
            screen_fall_result: '',
            screen_midyr_result: '',
            screen_spring_result: '',
            saved: false
        };


        vm.bip_problem = {
            student_id: 0,
            rti_type: 'BIP',
            reason: 'Behavior',
            target_behavior_problem: '',
            why_problem: '',
            additional_comments: '',
            saved: false

        };

        vm.bip_concernform = {
            student_id: 0,
            rti_type: 'BIP',
            reason: 'Behavior',
            concerns: [],
            area: vm.area_concern,
            other: vm.other_concern,
            saved: false,

        };

        vm.bip_intervention = {
            student_id: 0,
            rti_type: 'BIP',
            reason: 'Behavior',
            type: 'Behavior',
            interventions: [],
            behaviour: vm.behaviour_intervention,
            other: vm.other_intervention,
            saved: false
        };

        vm.email_notification_message3 = '';
        $rootScope.$on('activity_message', function(event, data) {
            //helperService.showAlert(data);
            vm.userActivity.push(data);

        });


        vm.student_incident = [];
        vm.paperwork_log = [];
        vm.communication_log =[];
        vm.bip_monitoring = [];
        vm.bip_types = [];
        vm.bip_monitor_log = [];
        vm.bip_monitor_result = [];
        vm.checkWindow = checkWindow;
        var timer = '';
        var childWindow;



        /* Assign Methods */
        vm.userPeriods = userPeriods;
        vm.getstudents = getstudents;
        vm.afterselected = afterselected;
        vm.Bip_submit = Bip_submit;
        vm.save_intervention = save_intervention;
        vm.save_concern = save_concern;
        vm.toggleSelection = toggleSelection;
        vm.getStudentdata = getStudentdata;
        vm.create_session = create_session;
        vm.update_session = update_session;
        vm.delete_session = delete_session;
        vm.intervention_val = intervention_val;
        vm.saveResult = saveResult;
        vm.saveMonitorStatus = saveMonitorStatus;
        vm.Reset = Reset;
        vm.selectStudentByPeriod = selectStudentByPeriod;
        vm.initialize = initialize;
        vm.sendtoAdmin = sendtoAdmin;
        vm.addBip = addBip;
        vm.printpreview = printpreview;
        vm.getRelation = getRelation;


        function addBip(myForm) {
            myForm.$setPristine();
        }

        /* onload methods */


        defaultOptions();
        getstudents();
        userPeriods();
        $scope.$watch('BehaviorInterventionCtrl.userSelectedPeriod', selectStudentByPeriod, false);


        function defaultOptions() {
            if ($state.params.type == 'view') {
                vm.preview = true;
            }
            if ($stateParams.studentId !== null && $stateParams.studentId > 0) {
                vm.showSearchBox = false;
                searchstudent($stateParams.studentId);
            }
        }


        function getstudents() {
            studentsService
                .getallstudentbyUser('/api/students/getstudentbyuser').then(function(students) {
                    vm.students = students;
                });

        }

        function selectStudentByPeriod() {

            if (vm.userSelectedPeriod === '') {
                studentsService
                    .getallstudentbyUser('/api/students/getstudentbyuser').then(function(students) {
                        vm.students = students;
                    });
            } else {

                var data = {
                    period: vm.userSelectedPeriod
                };
                studentsService.getStudentsByuserPeriod('/api/students/getstudentByuserPeriod', data).then(function(response) {
                    vm.students = response;
                });
            }

        }

        function userPeriods() {

            ClassesService.getUserCreatedPeriod('/api/classes/usercreatedperiod').then(function(response) {
                vm.periods = response;
            });

        }

        function afterselected(selected) {
            if (selected) {
                initialize();
                vm.selectedstudent = selected.originalObject;
                searchstudent(selected.originalObject.id);
                selected.originalObject = null;
                vm.FirstName = vm.selectedstudent.first_name;
                vm.LastName = vm.selectedstudent.last_name;
            }
        }

        function searchstudent(studentid) {
            studentsService.getStudentwithClasses('api/student/alldetail?id=' + studentid).then(function(response) {
                if ($stateParams.studentId !== null && $stateParams.studentId > 0) {
                    vm.selectedstudent = response;
                }
                vm.studentname = response.fullname;
                vm.Sid = response.studentid;
                if (response.studentclass[0]) {
                    vm.studentperiod = response.studentclass[0].period + '' + response.studentclass[0].period_letter;
                    vm.studentgrade = response.studentclass[0].grade;
                } else {
                    vm.studentperiod = 'N/A';
                    vm.studentgrade = 'N/A';
                }
                vm.studentgender = response.gender_type;
                vm.bip_concernform.student_id = response.id;
                vm.Bip_form.student_id = response.id;
                vm.bip_screen.student_id = response.id;
                vm.bip_problem.student_id = response.id;
                vm.bip_intervention.student_id = response.id;
                paperworksService.getipData('/api/paperwork/aip?rti_type=BIP&type=Behavior&student_id=' + response.id).then(function(data) {
                    getStudentdata(data);
                });
            });
        }

        function getStudentdata(data) {
            if (data.ip) {

                vm.userActivity = [];

                vm.Bip_form.id = data.ip.id;
                vm.Bip_form.reason = data.ip.reason;
                vm.Bip_form.date_plan = data.ip.date_plan;
                vm.Bip_form.category = data.ip.category;
                vm.Bip_form.type = data.ip.type;
                vm.Bip_form.teacher_initiating = data.ip.teacher_initiating;
                vm.Bip_form.teacher_collaborator_1 = data.ip.teacher_collaborator_1;
                vm.Bip_form.teacher_collaborator_2 = data.ip.teacher_collaborator_2;
                vm.Bip_form.teacher_collaborator_3 = data.ip.teacher_collaborator_3;
                if (vm.Bip_form.reason !== null || vm.Bip_form.date_plan !== null || vm.Bip_form.teacher_initiating !== null)
                    vm.Bip_form.saved = true;

                vm.bip_screen.screen_by = data.ip.screen_by;
                vm.bip_screen.screen_date = data.ip.screen_date;
                if (data.ip.screen_measurement_tool !== null)
                    vm.bip_screen.screen_measurement_tool = data.ip.screen_measurement_tool;
                vm.bip_screen.screen_fall_result = data.ip.screen_fall_result;
                vm.bip_screen.screen_midyr_result = data.ip.screen_midyr_result;
                vm.bip_screen.screen_spring_result = data.ip.screen_spring_result;
                if (vm.bip_screen.screen_by !== null || vm.bip_screen.screen_date !== null || vm.bip_screen.screen_measurement_tool !== null || vm.bip_screen.screen_fall_result !== null || vm.bip_screen.screen_midyr_result !== null || vm.bip_screen.screen_spring_result !== null)
                    //vm.bip_screen.saved = true;

                    vm.bip_problem.target_behavior_problem = data.ip.target_behavior_problem;
                vm.bip_problem.why_problem = data.ip.why_problem;
                vm.bip_problem.additional_comments = data.ip.additional_comments;
                if (vm.bip_problem.target_behavior_problem !== null || vm.bip_problem.why_problem !== null || vm.bip_problem.additional_comments !== null){
                   vm.bip_problem.saved = true;
                   vm.create_session_notification = 1;
                }
                if (vm.Bip_form.saved === true && vm.bip_problem.saved === true) {
                    vm.create_session_notification += 1;
                }
            }

            if (data.ip_concern) {
                if (data.ip_concern.length)
                    vm.bip_concernform.saved = true;
                for (var i = 0; i < data.ip_concern.length; i++) {
                    vm.bip_concernform.concerns.push(data.ip_concern[i].subject + '~' + data.ip_concern[i].option);

                    if (data.ip_concern[i].option == 'Other')
                        vm.other_concern[data.ip_concern[i].subject] = data.ip_concern[i].other_option;
                    else if (data.ip_concern[i].option.indexOf('#') !== -1)
                        vm.area_concern[data.ip_concern[i].subject] = data.ip_concern[i].other_option;
                }
                $scope.getCheckedTrue = function(val) {

                    if (vm.bip_concernform.concerns.indexOf(val) !== -1) {
                        return true;
                    }
                };

                if (vm.bip_concernform.saved === true) {
                    vm.create_session_notification += 1;
                }
            }
            if (data.ip_intervention) {
                if (data.ip_intervention.length)
                    vm.bip_intervention.saved = true;
                for (var j = 0; j < data.ip_intervention.length; j++) {
                    vm.bip_intervention.interventions.push(data.ip_intervention[j].subject + '~' + data.ip_intervention[j].option);
                    if (data.ip_intervention[j].option == 'Other')
                        vm.other_intervention[data.ip_intervention[j].subject] = data.ip_intervention[j].other_option;
                    else if (data.ip_intervention[j].option.indexOf('#') !== -1)
                        vm.behaviour_intervention[data.ip_intervention[j].subject] = data.ip_intervention[j].other_option;
                }
                $scope.getCheckedIntervention = function(val) {

                    if (vm.bip_intervention.interventions.indexOf(val) !== -1) {
                        return true;
                    }
                };

                if (vm.bip_intervention.saved === true) {
                    vm.create_session_notification += 1;
                }
            }
            if (data.ip_monitoring) {
                vm.bip_monitoring = data.ip_monitoring;
            }
            if (data.ip_monitor_log) {
                vm.bip_monitor_log = data.ip_monitor_log;
            }
            if (data.ip_monitor_result) {
                vm.bip_monitor_result = data.ip_monitor_result;
            }

            vm.bip_types = data.ip_types;
            vm.student_incident = data.incident;
            vm.paperwork_log = data.paperwork_log;
            vm.communication_log = data.communication_log;

        }


        function Bip_submit(formname, form) {

            var bipform = '';
            var preview_form = '';

            switch (formname) {
                case 'bip_form':
                    bipform = vm.Bip_form;
                    preview_form = 'Overview';
                    break;
                case 'bip_screen':
                    bipform = vm.bip_screen;
                    preview_form = 'Screening --Behavior';
                    break;
                case 'behaveinformation':
                    bipform = vm.behaveinformation;
                    break;
                case 'attendanceinformation':
                    bipform = vm.attendanceinformation;
                    break;
                case 'strength':
                    bipform = vm.strength;
                    break;
                case 'bip_problem':
                    bipform = vm.bip_problem;
                    preview_form = 'Problem Analysis';
                    break;
            }

            if (bipform.student_id > 0) {

                var flag = 1;
                if (formname === 'bip_form') {
                    if (vm.Bip_form.date_plan === "") {
                        toastr.error('Date required.');
                        flag = 0;
                    }
                }

               

                if (formname === 'bip_problem') {
                    if (vm.bip_problem.what_problem === "") {
                        flag = 0;
                        toastr.error('Define the problem required');

                    } else if (vm.bip_problem.why_problem === "") {
                        flag = 0;
                        toastr.error('Problem Hypothesis required');

                    }

                }


                if (flag === 1) {
                    paperworksService.saveipForm('/api/paperwork/aip/form', bipform).then(function(response) {
                        if (response) {
                            vm.Bip_form.id = response;
                            bipform.saved = true;
                            toastr.success('Submitted successfuly.');
                            vm.userActivity.push(preview_form + ' saved at ' + $filter('date')(new Date(), 'M/d/yy h:mm:ss a'));

                            vm.create_session_notification = 1 + parseInt(vm.create_session_notification_2);
                            if (vm.create_session_notification === 4) {
                                toastr.error('Please create a session to start monitoring and complete the form.');
                            }

                        }
                    });
                }
            }
            addBip(form);

        }

        function save_intervention(formData, form) {
            if (formData.student_id > 0) {
                paperworksService.saveipIntervention('/api/paperwork/aip/intervention', formData).then(function(response) {
                    if (response) {
                        vm.Bip_form.id = response;
                        formData.saved = true;
                        toastr.success(' Behavior interventions saved successfuly.');
                        vm.userActivity.push('Behavior Interventions saved at ' + $filter('date')(new Date(), 'M/d/yy h:mm:ss a'));

                        vm.create_session_notification += 1;
                        vm.create_session_notification_2 = vm.create_session_notification;
                        if (vm.create_session_notification_2 === 4) {
                            toastr.error('Please create a session to start monitoring and complete the form.');
                        }

                    }
                });
            }

            addBip(form);
        }

        function printpreview() {
        	timer = setInterval(checkWindow, 500);
            var winName = 'MyWindow';
            var winURL = '/api/paperwork/preview';
            var windowoption = 'resizable=yes,height=600,width=800,location=0,menubar=0,scrollbars=1';
            var params = {
                'paperwork_type': 'RTI',
                'formName': 'BIP',
                'form_id': vm.Bip_form.id,
                'student_id': vm.Bip_form.student_id,
                'reason': vm.Bip_form.reason,
                'other_reason': vm.Bip_form.other_reason,
                'date_plan': vm.Bip_form.date_plan,
                'category': vm.Bip_form.category,
                'type': vm.Bip_form.type,
                'teacher_initiating': vm.Bip_form.teacher_initiating,
                'teacher_collaborator_1': vm.Bip_form.teacher_collaborator_1,
                'teacher_collaborator_2': vm.Bip_form.teacher_collaborator_2,
                'teacher_collaborator_3': vm.Bip_form.teacher_collaborator_3,
                'screen_by': vm.bip_screen.screen_by,
                'screen_date': vm.bip_screen.screen_date,
                'screen_measurement_tool': vm.bip_screen.screen_measurement_tool,
                'screen_other_measurement': vm.bip_screen.screen_other_measurement,
                'screen_fall_result': vm.bip_screen.screen_fall_result,
                'screen_midyr_result': vm.bip_screen.screen_midyr_result,
                'screen_spring_result': vm.bip_screen.screen_spring_result,
                'what_problem': vm.bip_problem.target_behavior_problem,
                'why_problem': vm.bip_problem.why_problem,
                'additional_comments': vm.bip_problem.additional_comments,
                'concerns': vm.bip_concernform.concerns,
                'area': JSON.stringify(vm.bip_concernform.area),
                'concern_other': JSON.stringify(vm.bip_concernform.other),
                'intervtype': vm.bip_intervention.type,
                'interventions': vm.bip_intervention.interventions,
                'intervention_behaviour':JSON.stringify(vm.bip_intervention.behaviour),
                'other_intervention': JSON.stringify(vm.bip_intervention.other),
                'monitorcreateSession': vm.create_session_notification,
                'monitoring': JSON.stringify(vm.bip_monitoring),
                'monitoring_log': JSON.stringify(vm.bip_monitor_log),
                'monitoring_result': JSON.stringify(vm.bip_monitor_result)


            };

            var form = document.createElement("form");
            form.setAttribute("method", "post");
            form.setAttribute("action", winURL);
            form.setAttribute("target", winName);
            for (var i in params) {
                if (params.hasOwnProperty(i)) {
                    var input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = i;
                    input.value = params[i];
                    form.appendChild(input);
                }
            }
            document.body.appendChild(form);
            childWindow =  window.open('', winName, windowoption);
            form.target = winName;
            form.submit();
            document.body.removeChild(form);
        }

        function checkWindow() {
    		if (childWindow && childWindow.closed) {
    	        window.clearInterval(timer);    	       
    	        $scope.$apply(function(){    	        	
   	        	vm.preview = false;
   	    	     
   	        	});

    	    }
		}


        function save_concern(formname, form) {
        	
            if (vm.bip_concernform.student_id > 0) {
                paperworksService.saveipConcern('/api/paperwork/aip/concern', vm.bip_concernform).then(function(response) {
                    if (response) {
                        vm.Bip_form.id = response;
                        vm.bip_concernform.saved = true;
                        toastr.success('Areas of concerns saved successfuly.');
                        vm.userActivity.push('Areas of Concerns saved at ' + $filter('date')(new Date(), 'M/d/yy h:mm:ss a'));

                        vm.create_session_notification += 1;
                        vm.create_session_notification_2 = vm.create_session_notification;
                        //console.log(vm.create_session_notification);
                        if (vm.create_session_notification_2 === 4) {
                            toastr.error('Please create a session to start monitoring and complete the form.');
                        }
                    }
                });
            }

            addBip(form);
        }

        vm.scrollTo = function(id) {
            $location.hash(id);
            $anchorScroll();
        };


        function intervention_val(id) {
            var intervention_type = '';
            angular.forEach(vm.interventions, function(intervention) {
                if (intervention.id === parseInt(id)) {
                    intervention_type = intervention.name;
                }
            });

            return intervention_type;
        }

        function toggleSelection(value, chkboxesArr) {

            var idx = chkboxesArr.indexOf(value);
            // is currently selected
            if (idx > -1) {
                chkboxesArr.splice(idx, 1);
            }
            // is newly selected
            else {
                chkboxesArr.push(value);
            }

        }


        function create_session() {
            if (vm.Bip_form.id > 0 && vm.create_session_notification >= 4) {

                var formData = {
                    student_id: vm.Bip_form.student_id,
                    rti_type: 'BIP',
                    type: 'Behavior'
                };
                paperworksService.saveipMonitoring('/api/paperwork/aip/monitoring', formData).then(function(response) {
                    if (response) {

                        vm.bip_monitoring.push(response);
                        toastr.success('Session created successfuly.');
                        vm.userActivity.push('Session created at ' + $filter('date')(new Date(), 'M/d/yy h:mm:ss a'));
                    }
                });
            }else {
            	if(!vm.bip_concernform.saved && vm.bip_intervention.saved){
            		toastr.error('Please fillup and saved areaconcern to create session.');
            	}else if(!vm.bip_intervention.saved && vm.bip_concernform.saved){
            		toastr.error('Please fillup and saved interventions to create session.');
            	}else{
            		toastr.error('Please fillup and saved areaconcern and interventions to create session.');
            	}
               // toastr.error('You are not able to create session.');
            }
        }

        function update_session(formData, form) {
            addBip(form);
            var flag = 1;
            if (formData.teacher_name === "") {
                toastr.error('Teacher name required.');
                flag = 0;
            } else if (formData.duration === "") {
                toastr.error('Duration required.');
                flag = 0;
            }

            if (flag === 1) {
                if (vm.Bip_form.id > 0 && parseInt(formData.duration) > 0 && formData.teacher_name !== '') {
                    paperworksService.updateipMonitoring('/api/paperwork/aip/monitoring/update', formData).then(function(response) {
                        if (response) {
                            toastr.success('Session updated successfuly.');
                            vm.userActivity.push('Session updated at ' + $filter('date')(new Date(), 'M/d/yy h:mm:ss a'));
                            formData.saved = true;
                            $state.go("classes.roaster");
                        }
                    });
                }
            }
        }

        function delete_session(formData) {
            SweetAlert.confirm("Are you sure?", {
                    title: "Delete!"
                })
                .then(function(isConfirm) {
                    if (isConfirm) {

                        paperworksService.deleteipMonitoring('/api/paperwork/aip/monitoring/delete', formData).then(function(response) {
                            if (response) {

                                var idx = vm.bip_monitoring.indexOf(formData);
                                vm.bip_monitoring.splice(idx, 1);
                                helperService.showAlert('Session deleted successfuly.');
                                vm.userActivity.push('Session deleted at ' + $filter('date')(new Date(), 'M/d/yy h:mm:ss a'));
                            }

                        });
                    }
                }, function(p) {

                });
        }


        function saveResult(formData, form) {
            addBip(form);

            paperworksService.saveipMonitoringResult('/api/paperwork/aip/monitoring-result', {
                monitor_result: formData
            }).then(function(response) {
                helperService.showAlert('Comment submitted successfuly.');
            });
        }

        function saveMonitorStatus(formData, indexs, form) {
            addBip(form);
            if (formData.status == 2) {
                SweetAlert.confirm("Are you sure?", {
                    title: "Cancel this session!"
                }).then(function(isConfirm) {
                    if (isConfirm) {
                        paperworksService.updateipMonitoring('/api/paperwork/aip/monitoring/update', formData).then(function(response) {
                            if (response) {
                                helperService.showAlert('Status changed successfuly.');
                                vm.bip_monitoring[indexs].status = formData.status;

                                vm.hideforstatus = formData.id;
                                create_session();
                            }
                        });
                    }
                }, function(p) {

                });
            } else {
                paperworksService.updateipMonitoring('/api/paperwork/aip/monitoring/update', formData).then(function(response) {
                    vm.bip_monitoring[indexs].status = formData.status;

                    vm.hideforstatus = formData.id;
                    helperService.showAlert('Status changed successfuly.');
                });

            }

        }


        function initialize() {
            vm.Bip_form.id = 0;
            vm.Bip_form.other_reason = '';
            vm.Bip_form.date_plan = new Date();
            vm.Bip_form.category = 2;
            vm.Bip_form.type = 2;
            vm.Bip_form.teacher_initiating = $rootScope.user.first_name + ' ' + $rootScope.user.last_name;
            vm.Bip_form.teacher_collaborator_1 = '';
            vm.Bip_form.teacher_collaborator_2 = '';
            vm.Bip_form.teacher_collaborator_3 = '';
            vm.Bip_form.saved = false;

            vm.bip_screen.saved = false;
            vm.bip_screen.screen_by = '';
            vm.bip_screen.screen_date = new Date();
            vm.bip_screen.screen_fall_result = '';
            vm.bip_screen.screen_measurement_tool = 'behavior-emotional-screening-system';
            vm.bip_screen.screen_midyr_result = '';
            vm.bip_screen.screen_spring_result = '';

            vm.bip_problem.additional_comments = '';
            vm.bip_problem.saved = false;
            vm.bip_problem.target_behavior_problem = '';
            vm.bip_problem.why_problem = '';

            vm.bip_concernform.area = vm.area_concern;
            vm.bip_concernform.concerns = [];
            vm.bip_concernform.other = vm.other_concern;
            vm.bip_concernform.saved = false;

            vm.bip_intervention.interventions = [];
            vm.bip_intervention.saved = false;
            vm.bip_intervention.type = 'Behavior';

            vm.bip_monitoring = [];
            vm.bip_types = [];
            vm.bip_monitor_log = [];
            vm.bip_monitor_result = [];
            /* vm.area_concern = [];
             vm.other_concern = [];*/
        }

        function Reset() {
            SweetAlert.confirm("Are you sure?", {
                    title: "Reset BIP Form!",
                    cancelButtonText: "cancel",
                    confirmButtonText: "Yes"	
                })
                .then(function(isConfirm) {
                    if (isConfirm) {
                        paperworksService.resetip('/api/paperwork/aip/reset', {
                            student_id: vm.Bip_form.student_id,
                            rti_type: 'BIP',
                            type: 'Behavior'
                        }).then(function(response) {
                            vm.initialize();
                            $timeout(function () {
                            	 helperService.showAlert('Your All data has been reset. Please re enter.');
                            }, 2000);
                           
                            $state.reload();
                            
                        });
                    }
                }, function(p) {

                });

        }

        function sendtoAdmin() {

            helperService.paperworkformModal(vm.selectedstudent, vm.Bip_form.id, 'send-admin-modal', 'md', 'intervention', 'BIP', 'Behavior').then(function() {

            });
        }
        
        function getRelation(value){
        	var relation = '';
        	
        	switch(value){
        	case 1:
        		relation = 'mother';
       			break;
       		case 2:
       			relation = 'father';
       			break;
       		case 3:
       			relation = 'uncle';
       			break;
       		case 4:
       			relation = 'aunt';
       			break;
       		case 5:
       			relation = 'sister';
       			break;
       		case 6:
       			relation = 'brother';
       			break;
       		case 7:
       			relation = 'grandmother';
       			break;
       		case 8:
       			relation = 'grandfather';
       			break;
       		case 9:
       			relation = 'other';
       			break;
        	}
        	return relation;
        }
    }
})();