(function () {
  'use strict';

  angular
    .module('app.behavior-analytics.subject-grade-comparisons')
    .controller('BehaviorSubjectGradeComparisonsController', BehaviorSubjectGradeComparisonsController);

  /* @ngInject */
  function BehaviorSubjectGradeComparisonsController($scope, teacherTypes, grades, periods, modes, behaviorCategories, types, behaviorAnalyticsService) {
    /* jshint validthis: true */
    var vm = this;

    vm.periods = periods;

      vm.modes = modes;
    vm.categories = behaviorCategories;
    vm.cannedTypes = types;
    vm.selectedAll = false;
    vm.options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          ticks: {
            beginAtZero:true,
            callback: (value) => {
              if (value % 1 === 0) {
                return value;
              }
            }
          }
        }],
      }
    };

    vm.types = [
      {
        text: 'Subject',
        values: teacherTypes,
        property: 'subject',
      },
      {
        text: 'Grade',
        values: grades,
        property: 'grade',
      }
    ];

    vm.filters = {};
    Object.defineProperties(vm.filters, {
      _type: {
        value: vm.types[0],
        writable: true,
        enumerable: false,
      },
      type: {
        enumerable: true,
        get: function () {
          return this._type.property;
        }
      },
      _teacher_type_ids: {
        enumerable: false,
        value: [],
        writable: true,
      },
      teacher_type_ids: {
        enumerable: true,
        get: function () {
          if (this.type === 'subject') return JSON.stringify(this._teacher_type_ids);
        },
      },
      _grade_ids: {
        enumerable: false,
        value: [],
        writable: true,
      },
      grade_ids: {
        enumerable: true,
        get: function () {
          if (this.type === 'grade') return JSON.stringify(this._grade_ids);
        },
      },
      _period: {
        value: vm.periods[3],
        writable: true,
        enumerable: false,
      },
      period_slug: {
        enumerable: true,
        get: function () {
          return this._period.slug;
        },
      },
      period_start: {
        enumerable: true,
        get: function () {
          return this._period.start.format('D-M-Y');
        },
      },
      period_end: {
        enumerable: true,
        get: function () {
          return this._period.end.format('D-M-Y');
        },
      },
      _canned_type: {
        value: vm.cannedTypes[0],
        writable: true,
        enumerable: false,
      },
      canned_type_id: {
        enumerable: true,
        get: function () {
          return this._canned_type.id;
        }
      },
      _category: {
        value: vm.categories[0],
        writable: true,
        enumerable: false,
      },
      category_slug: {
        enumerable: true,
        get: function () {
          return this._category.slug;
        }
      },
    });

    vm.charts = {
      comparison: {
        data: [],
        labels: [],
        series: [],
      }
    };

    vm.data = {};

    vm.getStatistics = getStatistics;
    vm.checkedItem = checkedItem;
    vm.checkedAll = checkedAll;

    $scope.$watchCollection('BehaviorSubjectGradeComparisonsCtrl.filters', function () {
      vm.selectedAll = vm.filters._grade_ids.length === vm.filters._type.values.length || vm.filters._teacher_type_ids.length === vm.filters._type.values.length;

      getStatistics();
    });

    /////////////////

    function checkedItem(item) {
      item.selected = !item.selected;

      if (item.selected) {
        if (vm.filters.type === 'subject') {
          vm.filters._teacher_type_ids.push(item.id);
        }

        if (vm.filters.type === 'grade') {
          vm.filters._grade_ids.push(item.id);
        }

        return true;
      }

      if (vm.filters.type === 'subject') {
        vm.filters._teacher_type_ids.splice(vm.filters._teacher_type_ids.indexOf(item.id), 1);
      }

      if (vm.filters.type === 'grade') {
        vm.filters._grade_ids.splice(vm.filters._grade_ids.indexOf(item.id), 1);
      }
    }

    function getStatistics() {
      behaviorAnalyticsService.subjectGradeComparisons(vm.filters).then(function (response) {
        vm.charts.comparison = response;

        document.getElementById("subject-chart").style.maxHeight = response.labels.length * 25 + 150 + 'px';
      });
    }

    function checkedAll() {
      vm.selectedAll = !vm.selectedAll;

      if (vm.selectedAll) {
        vm.filters[vm.filters.type === 'subject' ? '_teacher_type_ids' : '_grade_ids'] =
          vm.filters._type.values.map((item) => {
            return item.id;
          });

        vm.filters._type.values.forEach((item) => {
          item.selected = true;
        });

        return true;
      }

      vm.filters[vm.filters.type === 'subject' ? '_teacher_type_ids' : '_grade_ids'] = [];
      vm.filters._type.values.forEach((item) => {
        item.selected = false;
      });
    }
      checkedAll();
  }
})();