(function(){
	'use strict';
	
	angular
		.module('app.paperworkforms')
		.controller('PaperworkformsController',PaperworkformsController);
	
	function PaperworkformsController($scope, helperService){
		var vm = this;
		
		
		/*assign property*/
		
		
		
		
		/*assign method*/
		
		
		
		

		
		
		
		
	}
	
	
})();