(function () {
  'use strict';

  angular
    .module('app.referral-analytics.quickview')
    .controller('ReferralQuickviewController', ReferralQuickviewController);

  /* @ngInject */
  function ReferralQuickviewController($scope, $filter, teacherTypes, grades, periods, referralAnalyticsService, referralTypes, locations) {
    /* jshint validthis: true */
    let vm = this;

    vm.periods = periods;
    vm.referralTypes = referralTypes;
    vm.locations = locations;
    vm.total = 0;
    vm.options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: true,
          autoSkip: true,
          autoSkipPadding: 100,
        }],
        yAxes: [{
          stacked: true,
          ticks: {
            beginAtZero:true,
            callback: (value) => {
              if (value % 1 === 0) {
                return value;
              }
            }
          }
        }]
      }
    };
    vm.optionsWithoutLabel = {
      legend: {display: false},
      scales: {
        xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero:true,
            callback: (value) => {
              if (value % 1 === 0) {
                return value;
              }
            }
          }
        }],
      }
    };
    vm.optionsWithoutLabelSimple = {
      legend: {display: false},
    };

    vm.types = [
      {
        text: 'Subject',
        values: teacherTypes,
        property: 'subject',
      },
      {
        text: 'Grade',
        values: grades,
        property: 'grade',
      }
    ];

    vm.filters = {};
    Object.defineProperties(vm.filters, {
      _type: {
        value: vm.types[0],
        writable: true,
        enumerable: false,
      },
      type: {
        enumerable: true,
        get: function () {
          return this._type.property;
        }
      },
      _item: {
        enumerable: false,
        get: function () {
          if (this.type === 'subject') return this._type.values.filter((item) => {
            return item.id === this._teacher_type_id;
          })[0];
          if (this.type === 'grade') return this._type.values.filter((item) => {
            return item.id === this._grade_id;
          })[0];
        }
      },
      _teacher_type_id: {
        enumerable: false,
        value: undefined,
        writable: true,
      },
      teacher_type_id: {
        enumerable: true,
        get: function () {
          if (this.type === 'subject') return this._teacher_type_id;
        },
      },
      _grade_id: {
        enumerable: false,
        value: undefined,
        writable: true,
      },
      grade_id: {
        enumerable: true,
        get: function () {
          if (this.type === 'grade') return this._grade_id;
        },
      },
      _period: {
        value: vm.periods[3],
        writable: true,
        enumerable: false,
      },
      period_start: {
        enumerable: true,
        get: function () {
          return this._period.start.format('D-M-Y');
        },
      },
      period_end: {
        enumerable: true,
        get: function () {
          return this._period.end.format('D-M-Y');
        },
      },
      period_slug: {
        enumerable: true,
        get: function () {
          return this._period.slug;
        },
      },
      referral_type: {
        value: vm.referralTypes[0].value,
        writable: true,
        enumerable: true,
      },
      _reason: {
        enumerable: false,
        get: function () {
          return $filter('filter')(vm.referralTypes, {value: this.referral_type})[0];
        }
      },
      location: {
        value: vm.locations[0].slug,
        writable: true,
        enumerable: true,
      },
      filter_by: {
        value: 'submitted',
        writable: true,
        enumerable: true,
      }
    });

    vm.charts = {
      made: {
        data: [],
        labels: [],
        series: []
      },
      byReason: {
        data: [],
        labels: []
      },
      submittedTo: {
        data: [],
        labels: [],
      }
    };

    vm.getStatistics = getStatistics;

    $scope.$watchCollection('ReferralQuickviewCtrl.filters', function () {
      getStatistics();
    });

    /////////////////

    function getStatistics() {
      referralAnalyticsService.quickview(vm.filters).then(function (response) {
        vm.charts.byReason.data = response.byReasons.map((item) => {
          return item.count;
        });
        vm.charts.byReason.labels = response.byReasons.map((item) => {
          return item.name;
        });

        vm.charts.submittedTo.data = response.byFilter.map((item) => {
          return item.count;
        });

        vm.charts.submittedTo.labels = response.byFilter.map((item) => {
          return item.name;
        });

        vm.charts.made.data = response.byPeriods.data;
        vm.charts.made.labels = response.byPeriods.labels;
        vm.charts.made.series = response.byPeriods.series;

        vm.total = response.total;

        document.getElementById("submitted-to").style.maxHeight = vm.charts.submittedTo.labels * 25 + 150 + 'px';
      });
    }
  }
})();