(function () {
  'use strict';

  angular
    .module('app.referral-analytics')
    .config(function ($stateProvider) {
      let analyticsPeriods = [
        {
          title: 'This Week',
          slug: 'week',
          get start() {
            return moment().subtract(6, 'days');
          },
          get end() {
            return moment();
          },
        },
        {
          title: 'Last 2 Weeks',
          slug: 'last_2_week',
          get start() {
            return moment().subtract(13, 'days');
          },
          get end() {
            return moment();
          },
        },
        {
          title: 'This Month',
          slug: 'month',
          get start() {
            return moment().subtract(1, 'month').add(1, 'day');
          },
          get end() {
            return moment();
          },
        },
        {
          title: 'This School Year',
          slug: 'year',
          get start() {
            let now = this.end,
              augustCurrentYear = moment(now).month(7).date(1),
              august;

            if (now < augustCurrentYear) {
              august = moment(`${now.year() - 1}-08-01`);
            } else {
              august = augustCurrentYear;
            }

            return august;
          },
          get end() {
            return moment();
          },
        },
        {
          title: 'All Time',
          slug: 'all_time',
          get start() {
            return moment().subtract(1, 'years');
          },
          get end() {
            return moment();
          },
        },
        {
          title: 'Custom Range',
          slug: 'custom_range',
          range: {
            startDate: moment(),
            endDate: moment().add(1, 'day'),
          },
          get start() {
            return this.range.startDate;
          },
          get end() {
            return this.range.endDate;
          },
        }
      ];
      let referralTypes = [
          {
              title:'All Category',
              value:undefined
          },
          {
              title: 'Attendance',
              value: 1
          },
          {
              title: 'Behavior',
              value: 2
          },
          {
              title: 'Academic',
              value: 3
          }
      ];
      let referralType = [
          {
              title: 'Attendance',
              value: 1
          },
          {
              title: 'Behavior',
              value: 2
          },
          {
              title: 'Academic',
              value: 3
          }
      ];

      // Now set up the states
      $stateProvider
        .state('referral-analytics', {
          parent: 'base',
          abstract: true,
          url: '/referral-analytics',
          templateUrl: '/app/partials/referral-analytics/layout.html',
          data: {
            permissions: {
              //only: ['analytics'],
              redirectTo: 'addnewclass',
            },
          },
        })
        .state('referral-analytics.quickview', {
          url: '/quickview',
          templateUrl: '/app/partials/referral-analytics/quickview/quickview.html',
          controller: 'ReferralQuickviewController',
          controllerAs: 'ReferralQuickviewCtrl',
          resolve: {
            teacherTypes: function (teacherTypesService) {
              return teacherTypesService.all().then(function (types) {
                types.unshift({
                  title: 'All Teacher Subjects',
                  id: undefined,
                });

                return types;
              });
            },
            grades: function (gradesService) {
              return gradesService.all().then((grades) => {
                grades.unshift({
                  title: 'All Grades',
                  id: undefined,
                });

                return grades;
              });
            },
            periods: function () {
              return analyticsPeriods;
            },
              referralTypes: function () {
              return referralType;
            },
          }
        })
        .state('referral-analytics.subject-grade-comparisons', {
          url: '/subject-grade-comparisons',
          templateUrl: '/app/partials/referral-analytics/subject-grade-comparisons/subject-grade-comparisons.html',
          controller: 'ReferralSubjectGradeComparisonsController',
          controllerAs: 'ReferralSubjectGradeComparisonsCtrl',
          resolve: {
            teacherTypes: function (teacherTypesService) {
              return teacherTypesService.all();
            },
            grades: function (gradesService) {
              return gradesService.all();
            },
            types: function (cannedMessagesService) {
              return cannedMessagesService.types().then(function (types) {
                types.unshift({
                  title: 'All',
                  id: undefined,
                });

                return types;
              });
            },
            periods: function () {
              return analyticsPeriods;
            },
              referralTypes: function () {
                  return referralTypes;
              },
          }
        })
        .state('referral-analytics.teacher-comparisons', {
          url: '/teacher-comparisons',
          templateUrl: '/app/partials/referral-analytics/teacher-comparisons/teacher-comparisons.html',
          controller: 'ReferralTeacherComparisonsController',
          controllerAs: 'ReferralTeacherComparisonsCtrl',
          resolve: {
            teacherTypes: function (teacherTypesService) {
              return teacherTypesService.all().then(function (types) {
                types.unshift({
                  title: 'All Teacher Subjects',
                  id: undefined,
                });

                return types;
              });
            },
            grades: function (gradesService) {
              return gradesService.all().then((grades) => {
                grades.unshift({
                  title: 'All Grades',
                  id: undefined,
                });

                return grades;
              });
            },
            periods: function () {
              return analyticsPeriods;
            },
              referralTypes: function () {
                  return referralTypes;
              },

          }
        })
        .state('referral-analytics.teacher-details', {
          url: '/teacher-details',
          templateUrl: '/app/partials/referral-analytics/teacher-details/teacher-details.html',
          controller: 'ReferralTeacherDetailsController',
          controllerAs: 'ReferralTeacherDetailsCtrl',
          resolve: {
            teachers: function (teachersService) {
              return teachersService.all();
            },
            students: function (studentsService) {
              return studentsService.getByCurrentSchool().then((students) => {
                  students.unshift({
                      first_name: 'All',
                      last_name: 'Students',
                      id: undefined,
                  });

                  return students;
              });
            },
            periods: function () {
              return analyticsPeriods;
            },
              referralTypes: function () {
                  return referralTypes;
              },
          }
        });
    });

})();