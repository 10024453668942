(function () {
  'use strict';

  angular
    .module('app')
    .config(function ($stateProvider) {

      $stateProvider
        .state('dashboard', {
          parent: 'base',
          url: "/dashboard",
          templateUrl: '/app/partials/dashboard/layout.html',
        })
        .state('dashboard.main', {
          url: '/main',
		      title: 'Dashboard',
          templateUrl: "/app/partials/dashboard/main.html",
          controller: 'DashboardController',
          controllerAs: 'DashboardCtrl',
          resolve: {
            toDoItems: function (toDoService) {
              return toDoService.all({for_dashboard: true});
            },
            
          },
        })
        .state('dashboard.setting', {
          url: '/setting',
          params: {
            active_tab: null,
            sub_tab:null
          },
          templateUrl: "/app/partials/dashboard/setting.html",
          controller: 'UserSettingController',
          controllerAs: 'UserSettingCtrl',
          resolve: {
            states: (statesService) => {
              return statesService.all();
            },
            staffTypes: (staffTypesService) => {
              return staffTypesService.all();
            },
          }
        })
        .state('forgot-password', {
          url: '/forgot-password',
          templateUrl: "/app/partials/dashboard/forgotpassword.html",
          controller: 'UserSettingController',
          controllerAs: 'UserSettingCtrl',
          resolve: {
            states: (statesService) => {
              return statesService.all();
            },
            staffTypes: (staffTypesService) => {
              return staffTypesService.all();
            },
          }
        })
        .state('forgotpassword', {
          url: '/forgotpassword',
          templateUrl: "/app/partials/dashboard/login_forgotpassword.html",
          controller: 'ForgotPasswordController',
          controllerAs: 'ForgotPasswordCtrl',
        })
        .state('resetpassword_instruction', {
          url: '/forgot-password',
          params: {
            active_tab: null,
            sub_tab:null
          },
          templateUrl: "/app/partials/dashboard/resetpassword_instruction.html",
          controller: 'UserSettingController',
          controllerAs: 'UserSettingCtrl',
          resolve: {
            states: (statesService) => {
              return statesService.all();
            },
            staffTypes: (staffTypesService) => {
              return staffTypesService.all();
            },
          }
        })
        .state('dashboard.share-us', {
          url: '/share-us',
          templateUrl: "/app/partials/dashboard/share-us.html",
          controller: 'ShareUsController',
          controllerAs: 'ShareUsCtrl',
        })
        .state('dashboard.resources', {
          url: '/resources',
          templateUrl: "/app/partials/dashboard/resources.html",
          controller: 'ResourceController',
          controllerAs: 'ResourceCtrl',
        })
        .state('dashboard.to-do-list', {
          url: '/to-do',
          templateUrl: "/app/partials/dashboard/to-do-list.html",
          controller: 'ToDoItemsController',
          controllerAs: 'ToDoItemsCtrl',
          resolve: {
            toDoItems: function (toDoService) {
              return toDoService.all();
            },
          },
        });
    });

})();