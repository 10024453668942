(function () {
    'use strict';

    angular
        .module('app.response-to-intervention-analytics')
        .config(function ($stateProvider) {
            let analyticsPeriods = [
                {
                    title: 'This Week',
                    slug: 'week',
                    get start() {
                        return moment().subtract(6, 'days');
                    },
                    get end() {
                        return moment();
                    },
                },
                {
                    title: 'Last 2 Weeks',
                    slug: 'last_2_week',
                    get start() {
                        return moment().subtract(13, 'days');
                    },
                    get end() {
                        return moment();
                    },
                },
                {
                    title: 'This Month',
                    slug: 'month',
                    get start() {
                        return moment().subtract(1, 'month').add(1, 'day');
                    },
                    get end() {
                        return moment();
                    },
                },
                {
                    title: 'This School Year',
                    slug: 'year',
                    get start() {
                        let now = this.end,
                            augustCurrentYear = moment(now).month(7).date(1),
                            august;

                        if (now < augustCurrentYear) {
                            august = moment(`${now.year() - 1}-08-01`);
                        } else {
                            august = augustCurrentYear;
                        }

                        return august;
                    },
                    get end() {
                        return moment();
                    },
                },
                {
                    title: 'All Time',
                    slug: 'all_time',
                    get start() {
                        return moment().subtract(1, 'years');
                    },
                    get end() {
                        return moment();
                    },
                },
                {
                    title: 'Custom Range',
                    slug: 'custom_range',
                    range: {
                        startDate: moment(),
                        endDate: moment().add(1, 'day'),
                    },
                    get start() {
                        return this.range.startDate;
                    },
                    get end() {
                        return this.range.endDate;
                    },
                }
            ];
            let rtiTiers = [
                {
                    title: 'All Tiers',
                    slug: 'undefined',
                },
                {

                    title: 'Tier 1(None)',
                    slug: 'tier1',
                },
                {
                    title: 'Tier 2(AIP, BIP)',
                    slug: 'tier2',
                },
                {
                    title: 'Tier 3(AIP-SST, BIP-SST)',
                    slug: 'tier3',
                },
                {
                    title: 'Tier 4(IEP-TDM, 504-TDM)',
                    slug: 'tier4',
                }];
            let rtiTypes = [
                {
                    title: 'Time',
                    slug: 'time',
                },
                {

                    title: 'Period',
                    slug: 'period',
                },

            ];

            // Now set up the states
            $stateProvider
                .state('response-to-intervention-analytics', {
                    parent: 'base',
                    abstract: true,
                    url: '/response-to-intervention-analytics',
                    templateUrl: '/app/partials/response-to-intervention-analytics/layout.html',
                    data: {
                        permissions: {
                            //only: ['analytics'],
                            redirectTo: 'addnewclass',
                        },
                    },
                })
                .state('response-to-intervention-analytics.quickview', {
                    url: '/quickview',
                    templateUrl: '/app/partials/response-to-intervention-analytics/quickview/quickview.html',
                    controller: 'RtiQuickviewController',
                    controllerAs: 'RtiQuickviewCtrl',
                    resolve: {
                        teacherTypes: function (teacherTypesService) {
                            return teacherTypesService.all().then(function (types) {
                                types.unshift({
                                    title: 'All Teacher Subjects',
                                    id: undefined,
                                });

                                return types;
                            });
                        },
                        grades: function (gradesService) {
                            return gradesService.all().then((grades) => {
                                grades.unshift({
                                    title: 'All Grades',
                                    id: undefined,
                                });

                                return grades;
                            });
                        },
                        periods: function () {
                            return analyticsPeriods;
                        },
                    }
                })
                .state('response-to-intervention-analytics.subject-grade-comparisons', {
                    url: '/subject-grade-comparisons',
                    templateUrl: '/app/partials/response-to-intervention-analytics/subject-grade-comparisons/subject-grade-comparisons.html',
                    controller: 'RtiSubjectGradeComparisonsController',
                    controllerAs: 'RtiSubjectGradeComparisonsCtrl',
                    resolve: {
                        teacherTypes: function (teacherTypesService) {
                            return teacherTypesService.all();
                        },
                        grades: function (gradesService) {
                            return gradesService.all();
                        },
                        categories: function (cannedMessagesService) {
                            return cannedMessagesService.categories().then(function (categories) {
                                categories.unshift({
                                    title: 'All Categories',
                                    id: undefined,
                                });

                                return categories;
                            });
                        },
                        types: function (cannedMessagesService) {
                            return cannedMessagesService.types().then(function (types) {
                                types.unshift({
                                    title: 'All',
                                    id: undefined,
                                });

                                return types;
                            });
                        },
                        periods: function () {
                            return analyticsPeriods;
                        },
                    }
                })
                .state('response-to-intervention-analytics.teacher-comparisons', {
                    url: '/teacher-comparisons',
                    templateUrl: '/app/partials/response-to-intervention-analytics/teacher-comparisons/teacher-comparisons.html',
                    controller: 'RtiTeacherComparisonsController',
                    controllerAs: 'RtiTeacherComparisonsCtrl',
                    resolve: {
                        teacherTypes: function (teacherTypesService) {
                            return teacherTypesService.all().then(function (types) {
                                types.unshift({
                                    title: 'All Teacher Subjects',
                                    id: undefined,
                                });

                                return types;
                            });
                        },
                        grades: function (gradesService) {
                            return gradesService.all().then((grades) => {
                                grades.unshift({
                                    title: 'All Grades',
                                    id: undefined,
                                });

                                return grades;
                            });
                        },
                        periods: function () {
                            return analyticsPeriods;
                        },
                    }
                })
                .state('response-to-intervention-analytics.teacher-details', {
                    url: '/teacher-details',
                    templateUrl: '/app/partials/response-to-intervention-analytics/teacher-details/teacher-details.html',
                    controller: 'RtiTeacherDetailsController',
                    controllerAs: 'RtiTeacherDetailsCtrl',
                    resolve: {
                        teachers: function (teachersService) {
                            return teachersService.all();
                        },
                        students: function (studentsService) {
                            return studentsService.getByCurrentSchool().then(function (students) {
                                students.unshift({
                                    fullname: 'All Student',
                                    first_name: 'All',
                                    last_name: 'Student',
                                    id: undefined,
                                });
                                return students;
                            });
                        },

                        categories: function (cannedMessagesService) {
                            return cannedMessagesService.categories().then(function (categories) {
                                categories.unshift({
                                    title: 'All',
                                    id: undefined,
                                });

                                return categories;
                            });
                        },
                        rtiTiers: function () {
                            return rtiTiers;
                        },
                        rtiPeriods: function (cannedMessagesService) {
                            return cannedMessagesService.rtiPeriods().then(function (rtiPeriods) {
                                rtiPeriods.unshift({
                                    title: 'All Period',
                                    id: "All",
                                });

                                return rtiPeriods;
                            });
                        },
                        // rtiPkeriods: function () {
                        //     return rtiPeriods;
                        // },
                        periods: function () {
                            return analyticsPeriods;
                        },
                        rtiTypes: function () {
                            return rtiTypes;
                        },
                    }
                });
        });

})();