(function () {
  'use strict';

  angular
    .module('app')
    .filter('customdate', customdate);

  /* @ngInject */
  function customdate($filter) {
    return function (userdate) {
      return new Date($filter('date')(userdate,'yyyy-MM-ddTHH:mm:ss.sssZ'));
    };
  }
})();