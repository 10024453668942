(function () {
  'use strict';

  angular
    .module('app.setup-account')
    .controller('StudentSignUpController', StudentSignUpController);

  /* @ngInject */
  function StudentSignUpController($scope,toastr,ClassesService,$rootScope,$state) {

    /* jshint validthis: true */
    var vm = this;
    vm.VerifyAccessCode=VerifyAccessCode;

    vm.form = {
        accessCode : '', 
    };


    function VerifyAccessCode()
    {
    	ClassesService.VerifyClass('api/classes/verifyaccesscode',vm.form).then(function(data) {
          
          if(data.error===0) 
          {
              $rootScope.accessCode=vm.form.accessCode;
              $state.go('signupform',{ 'accesscode':vm.form.accessCode});
          }
          else
          {
              toastr.error('Invalid Access code');
          }    
          

        });
    }
    

    
    
  }
})();