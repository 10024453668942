(function () {
  'use strict';

  angular
    .module('app.dashboard.main')
    .controller('NavbarController', NavbarController);

  /* @ngInject */
  function NavbarController(ClassesService,studentsService,$scope,$state,usSpinnerService,$timeout,$uibModal) {

       $scope.afterselected={};
       $scope.student_name="";
       $scope.close_trigger="";
       /*$scope.class_text ="";
       $scope.class_access_code ="";
       $scope.class_qr_code ="";
       $scope.qrcode_imagepath ="";
       $scope.unverified_student_count = "";*/

       

        studentsService.all().then(function(data) {
            $scope.AllSchoolStudents=data;   
                
         });

     $scope.focusIn = function() {
          
          studentsService.all().then(function(data) {
            $scope.AllSchoolStudents=data;   
                
         });
     };   

      $scope.printPreview= function(class_access_code)
      {
            var childWindow;
            // Print preview
            var winName = 'MyWindow';
            var winURL = 'api/classes/preview';
            var windowoption = 'resizable=yes,height=600,width=800,location=0,menubar=0,scrollbars=1';
            var params = {
                'class_access_code': class_access_code,
            };

            var form = document.createElement("form");
            form.setAttribute("method", "post");
            form.setAttribute("action", winURL);
            form.setAttribute("target", winName);
            for (var i in params) {
                if (params.hasOwnProperty(i)) {
                    var input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = i;
                    input.value = params[i];
                    form.appendChild(input);
                }
            }
            document.body.appendChild(form);
            childWindow = window.open('', winName, windowoption);
            form.target = winName;
            form.submit();
            document.body.removeChild(form);


      };

      $scope.notVerifiedModal= function(classid)
      {

          return $uibModal.open({
            templateUrl: '/app/partials/dashboard/not-verified-student.html',
            windowClass : "custom_signup_modal",
            controller: 'NotVerifiedStudentController',
            controllerAs: 'NotVerifiedStudentCtrl',
            size: 'lg',
            resolve: {
              classid: function () {
                return classid;
              } 
            }
           
          }).result.then(function () {

           //$state.go('dashboard.main');

          });

      };

      $scope.redirectToProfile = function(){

         if($scope.afterselected.originalObject!==undefined)
         {
            if($scope.afterselected.originalObject.id!=="")
            {
                
                $timeout(function() {
                  usSpinnerService.spin('spinner-1');
                  $state.go("student.summary", {
                    studentId: $scope.afterselected.originalObject.id
                  });

                  $scope.afterselected.originalObject=undefined;
                  $scope.afterselected={};
              });

            }
         }
         
      };  

      
      

    
  }
})();