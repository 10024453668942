(function () {
  'use strict';

  angular
    .module('app.setup-account')
    .controller('StudentSignUpFormController', StudentSignUpFormController);

  /* @ngInject */
  function StudentSignUpFormController($sce,studentsService,$scope,toastr,ClassesService,$rootScope,$state,$stateParams) {

    /* jshint validthis: true */
    var vm = this;
    vm.SubmitData=SubmitData;
    vm.ResetForm=ResetForm;
    vm.accesscode = $stateParams.accesscode;
    vm.errors='';
    vm.flag=1;
    vm.additionalGuardian=0;
    vm.AddGuardian=AddGuardian;
    vm.HideGuardian=HideGuardian;
    vm.validClass=0;
    vm.fullclassname="";
    vm.userfullname="";

    vm.RequiredMob1="";
    vm.RequiredMob2="";
    vm.RequiredMob3="";

    vm.RequiredMobOption1="0";
    vm.RequiredMobOption2="0";
    vm.RequiredMobOption3="0";


    vm.SecondGrMob1="";
    vm.SecondGrMob2="";
    

    vm.SecondGrOption2="0";
    vm.SecondGrOption1="0";
    

    function AddGuardian()
    {
        vm.additionalGuardian=1;
    }

    function HideGuardian()
    {
        vm.additionalGuardian=0;
    }

    vm.student = {
            fullname: '',
            studentid: '',
            bdate: moment().toDate(),
            accomodations: [],
            gender: '',
            school: '',
            term: '',
            currentperiod: '',
            relationship_other_measurement: '',
            more_relationship_other_measurement: '',
            clubs: '',
            other_club: '',
            classes: '',
            notes: '',
            is_verified:0,
            g1fullname: '',
            g1email: '',
            g1homenumber: '',
            g1worknumber: '',
            g1mobilenumber: '',
            g1preferredcomm: 3,
            g1address: '',
            g1relationship: 2,
            g1other_relation:'',
            g2value: '',
            g2fullname: '',
            g2email: '',
            g2homenumber: '',
            g2worknumber: '',
            g2mobilenumber: '',
            g2preferredcomm: '',
            g2address: '', 
            g2relationship: '',
            g2other_relation: '',
            created_by: ''
        };


    function ResetForm()    
    {
        vm.student.studentid="";
        vm.student.fullname="";
        vm.student.bdate=moment().toDate();
        vm.student.accomodations=[];
        vm.student.gender="";
        vm.student.school="";
        vm.student.term="";
        vm.student.currentperiod="";
        vm.student.relationship_other_measurement="";
        vm.student.more_relationship_other_measurement="";
        vm.student.clubs="";
        vm.student.other_club="";
        vm.student.classes="";
        vm.student.notes="";
        vm.student.g1fullname="";
        vm.student.g1homenumber="";
        vm.student.g1email="";
        vm.student.g1fullname="";
        vm.student.g1worknumber="";
        vm.student.g1mobilenumber="";
        vm.student.g1preferredcomm="";
        vm.student.g1address="";
        vm.student.g1relationship="";
        vm.student.g1other_relation="";
        vm.student.g2homenumber="";
        vm.student.g2email="";
        vm.student.g2fullname="";
        vm.student.g2worknumber="";
        vm.student.g2mobilenumber="";
        vm.student.g2preferredcomm="";
        vm.student.g2address="";
        vm.student.g2relationship="";
        vm.student.g2other_relation="";

    }

    ClassesService.getclassesData('api/classes/classdetails?access_code='+vm.accesscode).then(function(data) {
          
          if(data.error===0) 
          {
              
              vm.student.classes=data.id;
              vm.student.created_by=data.created_by;
              vm.student.currentperiod=data.period+"-"+data.period_letter;
              vm.student.studentid=Math.floor(Math.random()*9000000) + 1000000;
              vm.validClass=1;

              //alert('hello');
              vm.fullclassname=data.period+" "+data.period_letter+","+" "+data.name;
              vm.userfullname=data.title+" "+data.first_name+" "+data.last_name;
              


          }
          else
          {
              toastr.error('Invalid Access code');
              $state.go('signup');
          }    
          

        });


    function SubmitData()
    {
        if(vm.validClass===1)
        {
            
            vm.flag=1;
            
            if(vm.RequiredMob1==='' || vm.RequiredMob1===null)
            {
                
                toastr.error('g1 number is required');
                vm.flag=0;
            }
            else
            {
                vm.student.g1homenumber=vm.RequiredMob1;
                vm.student.g1worknumber=vm.RequiredMob1;
                vm.student.g1mobilenumber=vm.RequiredMob1;
                
            }

        
            if(vm.RequiredMobOption2==='1')
            {
                vm.student.g1worknumber="";
                vm.student.g1homenumber=vm.RequiredMob2;
            }
            else if(vm.RequiredMobOption2==='2')
            {
                vm.student.g1homenumber="";
                vm.student.g1worknumber=vm.RequiredMob2;
            }
            else if(vm.RequiredMobOption2==='3')
            {
                vm.student.g1worknumber="";
                vm.student.g1homenumber=vm.RequiredMob2;
            }
            
            

            if(vm.SecondGrOption1==='1')
            {
                vm.student.g2worknumber="";
                vm.student.g2homenumber="";
                vm.student.g2mobilenumber=vm.SecondGrMob1;
            }
            else if(vm.SecondGrOption1==='2')
            {
                vm.student.g2worknumber="";
                vm.student.g2mobilenumber="";
                vm.student.g2homenumber=vm.SecondGrMob2;
            }
            else if(vm.SecondGrOption1==='3')
            {
                vm.student.g2homenumber="";
                vm.student.g2mobilenumber="";
                vm.student.g2worknumber=vm.RequiredMob1;
            }


            if(vm.SecondGrOption2==='1')
            {
                vm.student.g2homenumber="";
                vm.student.g2worknumber="";
                vm.student.g2mobilenumber=vm.SecondGrMob1;
            }
            if(vm.SecondGrOption2==='2')
            {
                vm.student.g2mobilenumber="";
                vm.student.g2worknumber="";
                vm.student.g2homenumber=vm.SecondGrMob2;
            }
            if(vm.SecondGrOption2==='3')
            {
                vm.student.g2homenumber="";
                vm.student.g2mobilenumber="";
                vm.student.g2worknumber=vm.SecondGrMob3;
            }

            if(vm.flag===1)
            {
                studentsService.createExStudent(vm.student).then(function(response) {
                
                    studentsService.saveStudent(vm.student).then(function(student) 
                    {
                        ResetForm();
                        toastr.success('Student successsfully submitted');
                        $state.go('signupcomplete');
                    }, 
                    function(errorCallback) 
                    {
                        //console.log(vm.errors);
                        vm.errors = errorCallback;
                        //toastr.error(vm.errors.g1mobilenumber);
                    });
                }, function(errorCallback) {

                    vm.errors = errorCallback;
                    toastr.error(vm.errors);
                    
                });

                

            }
            

        }
        else
        {
              toastr.error('You are not allowed for this operation');
              $state.go('signup');
        } 
        
         
    	 
    }
    

    
    
  }
})();