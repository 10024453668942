(function () {
  'use strict';

  angular
    .module('app.address-book')
    .controller('MessageBlastController', MessageBlastController);

  /* @ngInject */
  function MessageBlastController($scope, $filter, $uibModalInstance, type, students, cannedTypes, cannedCategories,
                                  cannedMessagesService, $rootScope, messagesService, toastr) {
    /* jshint validthis: true */
    var vm = this;

    vm.request = false;

    vm.types = cannedTypes;
    vm.topics = [];
    vm.categories = cannedCategories;
    vm.cannedMessages = [];

    vm.studentIds = [];

    angular.forEach(students, function (student) {
      vm.studentIds.push(student.id);
    });

    vm.form = {
      mode: type,

      student_ids: vm.studentIds,

      to: undefined,
      type_phone: undefined,

      topic_id: undefined,
      category_id: undefined,
      type_id: undefined,
      canned_message_id: undefined,

      message: '',

      phone_id: undefined,
      email_id: undefined,

      call_status_id: undefined,
      additional_call_notes: undefined,
    };

    vm.userBalance = '';
    vm.getUserBalance = getUserBalance;

    vm.send = send;
    vm.cancel = cancel;
    vm.fetchCannedMessages = fetchCannedMessages;
    
    getUserBalance();

    $scope.$watch('MessageBlastCtrl.form.canned_message_id', setTextArea);
    $scope.$watch('MessageBlastCtrl.form.category_id', fetchTopics);
    $scope.$watch('MessageBlastCtrl.form.type_id', fetchTopics);

    //////////

    function fetchTopics() {
      if (!(vm.form.category_id && vm.form.type_id)) {
        return;
      }

      vm.request = true;

      cannedMessagesService.topics(vm.form.category_id, vm.form.type_id).then(function (topics) {
        vm.topics = topics;
      }).finally(function () {
        vm.request = false;
      });
    }

    function setTextArea() {
      var cannedMessage = $filter('filter')(vm.cannedMessages, {id: vm.form.canned_message_id});

      if (cannedMessage.length) {
        vm.form.message = cannedMessage[0].message;
      }
    }


    function send() {
    	 $rootScope.modalerror=true;
      cannedMessagesService.createBlast(vm.form).then(function (response) {
    	  $rootScope.modalerror=false;
    	  if(response.balance_reached === 'yes'){
    	  toastr.error('You cannot send more than ' + response.remaining + ' ' + response.mode );
    	  }
    	  else{
        $uibModalInstance.close({to: vm.form.to, type_id: vm.form.type_id});
    	  }
      });
    }

    function fetchCannedMessages() {
      if (!(vm.form.topic_id && vm.form.category_id && vm.form.type_id)) {
        return;
      }

      vm.request = true;

      cannedMessagesService.messages({
        topic_id: vm.form.topic_id,
        category_id: vm.form.category_id,
        type_id: vm.form.type_id,
      })
        .then(function (cannedMessages) {
          vm.cannedMessages = cannedMessages;
        })
        .finally(function () {
          vm.request = false;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
    
    function getUserBalance(){
    	messagesService.getUserBalance('/api/email-sms-call-balance').then(function(response){
    		vm.userBalance = response;
    		
    	});
    }
  }
})();