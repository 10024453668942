(function(){
	'use strict';
	angular.module('app.directives').directive('confirmonExitDirective', confirmonExitDirective);
	function confirmonExitDirective($state, $rootScope){
		return {
			restrict: 'A',
            link: function($scope, elem, attrs) 
            {
                var myForm = $scope.$eval(attrs.confirmonExitFormname);
                $rootScope.$$listeners.$stateChangeStart = [];
                $scope.$on("$stateChangeStart",function (event, toState, toParams, fromState, fromParams, usSpinnerService) {
                    
                    if (myForm.$dirty) {
                        if(!confirm("You have unsaved changes - are you sure you want to leave the page?")) {   
                            usSpinnerService.stop('spinner-1');
                            event.preventDefault();
                         }
                    }
                });
			}
		};
	}
})();