(function(){
	'use strict';
	
	angular
		.module('app.paperworkforms')
		.controller('PaperworkformModalController', PaperworkformModalController);
	
	
	function PaperworkformModalController($scope, $rootScope, $uibModalInstance, student, formid, type, formtype, 
		paperworksService, reason,toastr,$filter,adminsService, vestedMembersService, messagesService){
		var vm = this;
		vm.admins = '';
		vm.vestedmembers = '';

		adminsService.all().then(function (response) {        
          vm.admins = response;
        });

        vestedMembersService.all().then(function (response) {
          vm.vestedmembers = response;
        });
		
		//vm.test = $rootScope.selectedStudentDet.id;
		vm.student = student;
		vm.type = type;
		vm.userActivity=[];
		vm.cancel =  cancel;
		vm.send = send;
		vm.cc_guardianemail = cc_guardianemail;
		vm.buttondisable = true;
	
		vm.data="";
		vm.userBalance = '';	
		vm.getUserBalance = getUserBalance;

		vm.data = {
				student_id: student.id,
				sendto: [],
				studentguardianEmail: [],
				type : type,
				formtype: formtype,
				form_id : formid,
				reason: reason
		};

		getUserBalance();
		
		 function cancel() {
		        $uibModalInstance.dismiss();
		      }
		 
		 function send(){
	           $rootScope.modalerror=true;
	           var activity_message="";
	           paperworksService.sendtoAdmin(vm.data).then(function(response)
	           {
	
	           		  var admin_list="";
			   		  activity_message="";

	        		  $rootScope.modalerror=false;
	        		  
	        		  if(response.balance_reached === 'yes'){
					 		 toastr.error('You cannot send more than ' + response.remaining + ' email');
					 	}
					 	else{
	        		  $uibModalInstance.close();
	        		  var send_name = response;
	        		  admin_list= send_name.toString() + " in";
	        		 
	        		  //activity_message = (vm.data.studentguardianEmail.length >0) ? 'Email send to '+ admin_list+ " with guardians " +  $filter('date')(new Date(), 'M/d/yy h:mm:ss a') : 'Email send to '+ admin_list+ " " +  $filter('date')(new Date(), 'M/d/yy h:mm:ss a'); 
	        		  activity_message = "I emailed " + student.fullname + " regarding "+vm.data.formtype+" on "+
	        		  $filter('date')(new Date(), 'M/d/yy') +" at "+$filter('date')(new Date(), 'h:mm:ss a');
	        		  toastr.success(activity_message);
	        		  $rootScope.$emit('activity_message',activity_message);
					 	}
	           });
	           
	          
	        	
	        }  
		 
		 
		 function cc_guardianemail(){
			 if(student.id != 'undefined'){
				 vm.data.studentguardianEmail.push(student.g1email, student.g2email);
				
			 }
		 }
		 
		    function getUserBalance(){
		    	messagesService.getUserBalance('/api/email-sms-call-balance').then(function(response){
		    		vm.userBalance = response;
		    		
		    	});
		    }	 
		
		
	}
	
	
})();