(function () {
  'use strict';

  angular
    .module('app.login')
    .controller('LoginController', LoginController);

  /* @ngInject */
  function LoginController($auth, $state, toastr, authService, $rootScope, adminsService, vestedMembersService, $timeout,
    ClassesService,SweetAlert) {
    /* jshint validthis: true */
    var vm = this;

    vm.form = {
      email: '',
      password: ''
    };

    vm.login = login;
    vm.logoShow = logoShow;
    //vm.formbusy = false;
    
    $rootScope.db_class_list = [];

    $rootScope.db_club_list = [];
    $rootScope.isClass = false;
    vm.hideframe = false;
    if(!$rootScope.logo_show){
    $rootScope.logo_show = false;
    }
    
    logoShow();
    
    
    ///////////////////////
    function login() {
    	//vm.formbusy =true;
      $auth.login(vm.form).then(function (data) {
        authService.me().then(function (res) {
          vm.isLoggedIn = res.isLoggedIn;

          if(authService.completedRegistration()) {
        	 
            adminsService.all().then(function (response) {
              $rootScope.admins = response;
            });

            vestedMembersService.all().then(function (response) {
              $rootScope.vestedMembers = response;
            });
            
           
        
            
            ClassesService.getclassesData('api/classes').then(function(data) {
                if (data.class_period !== null) {
                    angular.forEach(data.class_period, function(class_period) {
                    	$rootScope.isClass=true;  
                        if (class_period.group_type === '1')
                        	$rootScope.db_class_list.push({
                                id: class_period.id,
                                name: class_period.name,
                                period: class_period.period,
                                period_letter: class_period.period_letter
                            });
                        else
                            $rootScope.db_club_list.push({
                                id: class_period.id,
                                name: class_period.name
                            });
                    });
                }
                if(authService.isTeacher() && $rootScope.user.check_subscription === 1) {
                	
                	 
                	vm.hideframe = true;
                	$rootScope.logo_show = false;
                    $state.go('dashboard.main');
                } else if(authService.isAdmin()) {
                    $rootScope.is_admin=1;
                    $state.go('communications-analytics.quickview');
                } else {
                	//location.href='';
                  $rootScope.is_admin=0;
                	$state.go('dashboard.setting');
                }
                toastr.success('You are successfully logged in.', 'Login');
            });


          }
          else {
              $state.go('setup-account');
          }

          
          

          //authService.defaultRedirect();

          return res;
        });

        return data;
      });
    }
    
    function logoShow(){
    	
    	if( $rootScope.logo_show === false){
    		 $timeout(function () {
    			 $rootScope.logo_show = true;
    			 //vm.hideframe = false;
    		    }, 1000);
    }
    }
    
    
  }
})();