(function () {
  'use strict';

  angular
    .module('app.address-book')
    .controller('ReferralController', ReferralController);

  /* @ngInject */
  function ReferralController($scope, $filter, $uibModalInstance, type, student, cannedTypes, cannedCategories, behavior_reason, attendance_reason, academic_reason,messagesService, 
                             callStatuses, toastr, cannedMessagesService, active,referralService, selectedPeriod, admindata, httpInterceptor, $rootScope, vesteddata, ClassesService) {
    /* jshint validthis: true */
    var vm = this;
    
    vm.student = student;
    vm.types = cannedTypes;
    vm.categories = cannedCategories;
    vm.callStatuses = angular.copy(callStatuses);
    vm.cannedMessages = [];
    vm.isActiveByDefault = false;
    vm.getAdmin = getAdmin;
    vm.to = '';
    vm.send = send;
    vm.cancel = cancel;
    vm.referral_type = (active ? active :'false');
    vm.userBalance = '';
   
    vm.showprincipal=false;
    vm.admins = admindata;
    vm.getUserBalance = getUserBalance;
    
    vm.vestedmembers = vesteddata;
    vm.setReason = setReason;
   // vm.refferal_value = active;
    vm.reasonValue = behavior_reason;	
   // vm.changeReason = changeReason;
  // vm.selectedReason = 'Selected';
    vm.sendingloader = false;
    vm.student_classes =  '';
    //vm.studentclass_name = 'period'.
    ClassesService.getclassesData('/api/students/studentwithclasses?id=' +student.id ).then(function(response){
    	
    	  	var classStudent = '';
    	angular.forEach(response.student_class, function(value){
    		classStudent += value.name + '|' + 'period ' + value.period + '' + value.period_letter + ',' + '\n';
    	});
    	
    	vm.student_classes = classStudent.replace(/,\s*$/, "");
    	//vm.student_classes = 'period ' + response[1][0].period +'' + response[1][0].period_letter + ' | ' + response[1][0].name;
    });
    
    
    vm.form ={
    		student_id: student.id,
        	user_id:'',
            mode: type,
        	student_name : student.fullname,
        	student_gender: student.gender,
        	selectedPeriod:selectedPeriod,
        	reffered_by:'' ,
        	type:2,
        	location:'',
         	other_location:'',
        	incident_date_time: new Date(),
        	priority:'',
        	reason:'Selected',
        	other_reason:'',
        	other_action:'',
        	description:'',
        	action:[],
        	sendto:[],
        	attendance_record:'no',
        	behavior_record:'no',
        	refferal_value:active
        			
    		
    };
    
    getUserBalance();
    angular.element(document).ready(function () {    
    	
       vm.referral_type = active;
      
          });
    
    function getAdmin(role){
    	 var data = [];

    	for(var i=0; i<admindata.length; i++){
    		
    		if(admindata[i].role == role){
    			if(admindata[i].first_letter !== ''){
    				//vm.showprincipal=true; 
    			}
    			data.push(admindata[i].first_letter);
    			data.push(admindata[i].last_name);
    			return data;
    		}
    	}
    }
    
    function send(){
           $rootScope.modalerror=true;

        	referralService.create(vm.form).then(function(response){       		
        		  $rootScope.modalerror=false;
        		  if(response.balance_reached === 'yes'){
        			  toastr.error('You cannot send more than ' + response.remaining +' emails' );
        		  }else{
        		  $uibModalInstance.close();
        		  }
        	});
        	
        }  
    
    function cancel() {
        $uibModalInstance.dismiss();
      }
 
    function setReason(value){
    	
    	if(value == 1){
    		vm.reasonValue = academic_reason;
    	}
    	else if(value == 2){
    		vm.reasonValue = behavior_reason;	
    	}    	
    	if(value == 3){
    		vm.reasonValue = attendance_reason;	
    	}
    	
    }
    
    function getUserBalance(){
    	messagesService.getUserBalance('/api/email-sms-call-balance').then(function(response){
    		vm.userBalance = response;
    		
    	});
    }
    

  }
})();