(function () {
  'use strict';

  angular
    .module('app.core')
    .service('studentsService', studentsService);

  function studentsService($resource, $http) {
    /* jshint validthis: true */
    var vm = this;

    var Student = $resource('/api/students/:studentId', {studentId: '@id'}, {
      update: {method: 'PUT'}
    });
    
//    var Attendancedetails = $resource('/api/students/attendance',{studentId: '@id'},{
//       update: {method: 'PUT'}
//    });

    vm.all = all;
    vm.create = create;
    vm.createStudentfromClass = createStudentfromClass;
    vm.get = get;
    vm.update = update;
    vm.remove = remove;
    vm.updatestudent = updatestudent;
    vm.removestudent = removestudent;
    vm.getForRange = getForRange;
    vm.getMessagesByFilters = getMessagesByFilters;
    vm.attendanceDetail = attendanceDetail;
    vm.getRangeForAttendance = getRangeForAttendance;
    vm.generateRandom = generateRandom; 
    vm.getallstudentbyUser = getallstudentbyUser;
    vm.getStudentsByuserPeriod = getStudentsByuserPeriod;
    vm.getStudentwithClasses = getStudentwithClasses;
    vm.getintervention = getintervention;
    vm.getByCurrentSchool = getByCurrentSchool;
    vm.getStudent = getStudent;
	vm.createStudent=createStudent;
    vm.saveStudent=saveStudent;
    vm.createExStudent=createExStudent;

    /////////////////

    function all() {
      return Student.query().$promise;
    }

    function create(data) {
      return Student.save(data).$promise;
    }
    
    function createStudentfromClass(data){
      return $http.post('api/students/createfromclasses',data).then(fetchData);
    	
    }
	
	function createStudent(data){
      return $http.post('api/students/createstudent',data).then(fetchData);
      
    }

    function createExStudent(data){
      return $http.post('api/students/createstudent_external',data).then(fetchData);
      
    }
	
	function saveStudent(data){
      return $http.post('api/students/savestudent',data).then(fetchData);
      
    }

    function get(studentId) {
      return Student.get({studentId: studentId}).$promise.then(handleDoBOfStudent);
    }

    function update(student) {
      return Student.update(student).$promise.then(handleDoBOfStudent);
    }

    function remove(student) {
      return Student.delete({studentId: student.id}).$promise;
    }

    function handleDoBOfStudent(student) {
      student.bdate = moment(student.bdate).toDate();

      return student;
    }

//    function getForRangetest(range) {
//
//        return $http.get(range.url, {params: range}).then(fetchData);
//      }
   
    function getStudent(url){
    	return $http.get(url).then(fetchData);
    }
    
    function  updatestudent(data){
      
      return $http.post('/api/students/studentupdate', data).then(fetchData);
    }
    
    function removestudent(data){
      return $http.post('/api/students/studentdelete', data).then(fetchData);
    }
    function getintervention(url,data){
          return $http.post(url, data).then(fetchData);
    }
    
    function getForRange(range) {

      return $http.get(range.url, {params: range}).then(fetchData);
    }
   
    function getMessagesByFilters(filters) {
      return $http.get(filters.url, {params: filters}).then(fetchData);
    }

    function fetchData(response) {
      return response.data;
    }
    
    function attendanceDetail(){
      return $http.get('/api/student/singleattendance').then(function(response){
          return  response.data;
        
      });
    }
    
    function getRangeForAttendance(range){
      return $http.get('/api/students/attendance',{params: range}).then(function(response){
          return response.data;
        
      });
      
    }

    function generateRandom(){
      return $http.get('api/students/randomnum').then(fetchData);
    }
    
    function getallstudentbyUser(url){
    	return $http.get(url).then(fetchData);
    }
    
    function getStudentsByuserPeriod(url, value){
    	return $http.post(url, value).then(fetchData);
    }
    
    function getStudentwithClasses(url){
    	return $http.get(url).then(fetchData);
    	
    }

    function getByCurrentSchool() {
      return $http.get('api/students/by-current-school').then(fetchData);
    }
  }
})();