(function () {
  'use strict';

  angular
    .module('app.dashboard.to-do-list')
    .controller('ToDoItemsController', ToDoItemsController);

  /* @ngInject */

  function ToDoItemsController(toDoItems,toDoService,$uibModal,toastr,usSpinnerService,$rootScope,$filter) {
    /* jshint validthis: true */
    var vm = this;
    
    vm.isHighPrioritySelected = false;
    vm.isCurrentSelected = false;
    vm.TomorrowSelected = false;
    vm.BiyondTwoDaySelected= false;

     vm.toDoSaveItem="";
     vm.toDoUpdateItem="";

    vm.CompleteItemSearch="";

    vm.setToDoItemForUpdate = setToDoItemForUpdate;

    vm.HighPriorityDiv = true;
    vm.CurrentDiv = true;
    vm.TomorrowDiv = true;
    vm.BiyondTwoDayDiv= true;

    vm.PrintOption = PrintOption;
    vm.removeToDoItem=removeToDoItem;

    vm.CancelUpdate=CancelUpdate;
   
    vm.saveToDoItem = saveToDoItem;

    vm.toDoItems = toDoItems;
    vm.changedValue=changedValue;
    vm.SelectedFilter='0';
    vm.removeItem=removeItem;
    vm.toggleAllselected = toggleAllselected;
    vm.optionToggled = optionToggled;
    vm.runService = runService;
    vm.TodoCompleteItems='';
    vm.TodoHighPriorityItems='';
    vm.TodoCurrentItems='';
    vm.TodoTomorrowItems='';
    vm.TodoByondTwodaysItems='';
    vm.markCompleted=markCompleted;
    vm.selectedItem=[];
    vm.due_date=moment();
    vm.activeTab = 1;

    vm.activeTask = 0;
    vm.CompleteTask = 0;

    vm.ButtonName="Create";
    vm.TabToggle=TabToggle;
    vm.UpdateLog=0;


    vm.myVar = false;
    vm.ShowHide = ShowHide;

    function ShowHide() {
      console.log('a');
        //vm.myVar = !vm.myVar;
        vm.due_date={ 
          singleDatePicker: true,
          showDropdowns: true,
          opens:'left',
          applyClass:'.to-do',
          alwaysShowCalendars:true,
          defaultDate: vm.due_date,locale: {format: 'YYYY-MM-DD' }

        };
    }


    function TabToggle()
    {
      CancelUpdate();
    }

    
    function PrintOption($option) 
    {
          if($option==='1')
          {

            vm.activeTask = 1;
            vm.CompleteTask = 0;
          }
          else if($option==='2')
          {
            vm.activeTask = 0;
            vm.CompleteTask = 2;
          }
    }


    function setToDoItemForUpdate(item) {

      /*return $uibModal.open({
        templateUrl: '/app/partials/dashboard/confirmation-update-a-to-do-item.html',
        controller: 'RemoveToDoItemController',
        controllerAs: 'RemoveToDoItemCtrl',
        // size: 'lg',
        resolve: {
          toDoItem: function () {
            return item;
          },
          SelectedtoDoItem: function () {
            return item;
          },
        }
      }).result.then(function () {


            //console.log(vm.due_date);
      });*/

            vm.toDoSaveItem = item;
            vm.ButtonName="Update";
            vm.UpdateLog=1;
            
            vm.description=item.description;
            vm.due_date=new Date(item.datepicker_due_date);

      
    }


    function CancelUpdate() {
      vm.toDoSaveItem =[];
      vm.ButtonName="Create";
      vm.UpdateLog=0;
      vm.description="";
      vm.due_date=moment();
    }


    function removeToDoItem(item) {
      return $uibModal.open({
        templateUrl: '/app/partials/dashboard/confirmation-remove-a-to-do-item.html',
        controller: 'RemoveToDoItemController',
        controllerAs: 'RemoveToDoItemCtrl',
        // size: 'lg',
        resolve: {
          toDoItem: function () {
            return item;
          },
          SelectedtoDoItem: function () {
            return item;
          },
        }
      }).result.then(function () {
        return toDoService.removeItem('api/to-do-items/remove',item).then(function () {
          toastr.success('Task successfully deleted.'); 
          runService();
        });
      });
    }


    function saveToDoItem() 
    {
      var flag=1;
      if(vm.description===undefined)
      {
          toastr.error('Description required.'); 
          flag=0;
      }

      if(vm.due_date===undefined)
      {
          toastr.error('Due date required.'); 
          flag=0;
      }

      if(flag===1)
      {

          if (vm.UpdateLog===1)
          {

              vm.toDoUpdateItem = {
                  id:vm.toDoSaveItem.id,
                  user_id:vm.toDoSaveItem.user_id,
                  due_date:moment(vm.due_date),
                  description: vm.description,
                  is_high_priority: vm.toDoSaveItem.is_high_priority,
                };
              
              console.log(vm.toDoUpdateItem);

                toDoService.update(vm.toDoUpdateItem).then(function (toDoItem) {

                    runService();
                    vm.description="";
                    vm.due_date=moment();
                    CancelUpdate();
                    toastr.success('Task successfully Updated.');
                  
                });
            
          }
          else
          {

                vm.toDoSaveItem = {
                  description: vm.description,
                  due_date: vm.due_date,
                  is_high_priority: 0,
                };

                console.log(vm.toDoSaveItem);


                toDoService.create(vm.toDoSaveItem).then(function (toDoItem) 
                {
                    runService();
                    vm.description="";
                    vm.due_date=moment();
                    toastr.success('Task successfully created.'); 
                });

          }

            
       }


    }

    function runService()
    {
	    toDoService.getAllData('api/to-do-items/show').then(function(data) {

	          vm.TodoCompleteItems=data.complete_items;
	          vm.TodoHighPriorityItems=data.high_priority_tems;
	          vm.TodoCurrentItems=data.current_tems;
	          vm.TodoTomorrowItems=data.tomorrow_tems;
	          vm.TodoByondTwodaysItems=data.byond_twodays_tems;
	          vm.HighPriorityCount=data.high_priority_tems_count;
            $rootScope.DashboardCount=data.high_priority_tems_count; 

	         //console.log(vm.TodoCurrentItems);
	                
	    });
   }


   runService();


   function changedValue(selectedValue)
   {

   		if(selectedValue==="0")
   		{
   			vm.HighPriorityDiv = true;
		    vm.CurrentDiv = true;
		    vm.TomorrowDiv = true;
		    vm.BiyondTwoDayDiv= true;
   		}
   		else if(selectedValue==='1')
   		{
   			vm.HighPriorityDiv = true;
		    vm.CurrentDiv = false;
		    vm.TomorrowDiv = false;
		    vm.BiyondTwoDayDiv= false;
   		}
   		else if(selectedValue==="2")
   		{

   			vm.HighPriorityDiv = false;
		    vm.CurrentDiv = true;
		    vm.TomorrowDiv = false;
		    vm.BiyondTwoDayDiv= false;
   		}
   		else if(selectedValue==="3")
   		{
   			vm.HighPriorityDiv = false;
		    vm.CurrentDiv = false;
		    vm.TomorrowDiv = true;
		    vm.BiyondTwoDayDiv= false;
   		}
   		else if(selectedValue==="4")
   		{
   			vm.HighPriorityDiv = false;
		    vm.CurrentDiv = false;
		    vm.TomorrowDiv = false;
		    vm.BiyondTwoDayDiv= true;
		    
   		}
   }


    function toggleAllselected(priority) {

        if (priority == 'current') 
        {
            
            if(vm.isCurrentSelected)
            {
                angular.forEach(vm.TodoCurrentItems, function(selected_task) {
                    vm.selectedItem.push(selected_task);
                    selected_task.selected = vm.isCurrentSelected;

                });
            }
            else
            {

                angular.forEach(vm.TodoCurrentItems, function(selected_task) {
                    var index = vm.selectedItem.indexOf(selected_task);
                    vm.selectedItem.splice(index, 1);
                    selected_task.selected = vm.isCurrentSelected;

                });

            }

        }
        else if (priority == 'high') 
        {
            if(vm.isHighPrioritySelected)
            {
                angular.forEach(vm.TodoHighPriorityItems, function(selected_task) {
                    vm.selectedItem.push(selected_task);
                    selected_task.selected = vm.isHighPrioritySelected;
                });
            }
            else
            {
              angular.forEach(vm.TodoHighPriorityItems, function(selected_task) {
                    var index = vm.selectedItem.indexOf(selected_task);
                    vm.selectedItem.splice(index, 1);
                    selected_task.selected = vm.isHighPrioritySelected;
                });
            }
        }
        else if (priority == 'two_day') 
        {
            if(vm.BiyondTwoDaySelected)
            {
                angular.forEach(vm.TodoByondTwodaysItems, function(selected_task) {
                    vm.selectedItem.push(selected_task);
                    selected_task.selected = vm.BiyondTwoDaySelected;
                });
            }
            else
            {
              angular.forEach(vm.TodoByondTwodaysItems, function(selected_task) {
                    var index = vm.selectedItem.indexOf(selected_task);
                    vm.selectedItem.splice(index, 1);
                    selected_task.selected = vm.BiyondTwoDaySelected;
                });
            }
        }
        else if (priority == 'tomorrow') 
        {
            if(vm.TomorrowSelected)
            {
              angular.forEach(vm.TodoTomorrowItems, function(selected_task) {
                  vm.selectedItem.push(selected_task); 
                  selected_task.selected = vm.TomorrowSelected;
              });
            }
            else
            {
              angular.forEach(vm.TodoTomorrowItems, function(selected_task) {
                  var index = vm.selectedItem.indexOf(selected_task);
                  vm.selectedItem.splice(index, 1);
                  selected_task.selected = vm.TomorrowSelected;
              });
            }
        }

        console.log(vm.selectedItem);
            
    }

    function optionToggled(id,selected,item)
    {

        if(selected)
        {
            //console.log(item);
            if (vm.selectedItem.indexOf(item) == -1)
            {
                if(item !==undefined)
                {
                  //console.log(item);
                  vm.selectedItem.push(item);
                }
            }
        }
        else
        {
             var index = vm.selectedItem.indexOf(id);
             vm.selectedItem.splice(index, 1);
        }


    }

  
   console.log(vm.selectedItem);
    

    
    /*function optionToggled(item,id, check) {


       if (check) 
        {
      			 vm.selectedItem.push(item);
    		} 
    		else 
    		{
      			var index = vm.selectedItem.indexOf(id);
      			 vm.selectedItem.splice(index, 1);
    		}

    		//console.log(vm.selectedItem);
  
            

   }*/

  //console.log(vm.selectedItem);


  function removeItem(index){
       vm.selectedItem.splice(index, 1);
   }

  function markCompleted()
  {

    //console.log(vm.selectedItem);

  	if (vm.selectedItem.length >0)
  	{
       // usSpinnerService.spin('spinner-1');
	  	   return $uibModal.open({
	        templateUrl: '/app/partials/dashboard/confirmation-bulk-complete.html',
	        controller: 'RemoveToDoItemController',
	        controllerAs: 'RemoveToDoItemCtrl',
	        // size: 'lg',
	        resolve: {
	          toDoItem: function () {
	            return vm.selectedItem;
	          },
	          SelectedtoDoItem: function () {
	            return vm.selectedItem;
	          },
	        }
	      }).result.then(function () 
	        {
	           runService();	
             vm.isHighPrioritySelected = false;
              vm.isCurrentSelected = false;
              vm.TomorrowSelected = false;
              vm.BiyondTwoDaySelected= false;

             toastr.success('Task successfully submitted.'); 
             //usSpinnerService.stop('spinner-1');
	       	   //console.log("hello");
        
            });

  	 }
  	 else
  	 {
  	 	 toastr.error('Please select the item.'); 
  	 }

  	//console.log(vm.selectedItem);

  	


  }


  }
})();