(function() {
    'use strict';

    angular
        .module('app.paperworks')
        .controller('AcademicInterventionSstController', AcademicInterventionSstController);

    function AcademicInterventionSstController($scope, $rootScope, $location, $anchorScroll, ClassesService, studentsService, paperworksService, helperService, SweetAlert, $stateParams, $state, toastr, $filter, $timeout) {
        var vm = this;
        vm.rti_form = 'AIP-SST';
        /*  assign properties*/
        vm.minitorId = [];
        vm.showSearchBox = true;
        vm.showDetails = false;
        vm.userActivity = [];
        vm.periods = [];
        vm.students = [];
        vm.userSelectedPeriod = '';
        vm.selectedstudent = '';
        vm.studentname = "Student's Name";
        vm.Sid = '';
        vm.studentperiod = "Student's Period";
        vm.studentgrade = "Student's Grade";
        vm.studentgender = "Student's Gender";
        vm.student_incident = [];
        vm.other_concern = [];
        vm.area_concern = [];
        vm.preview = ($stateParams.viewtype == 'view') ? true : false;
        vm.interventions = [];
        vm.area_of_concern = [];
        vm.paperwork_log = [];
        vm.communication_log =[];
        vm.strength = [];
        vm.attendance_reports = {
            total_absent: 'n/a',
            total_skiped: 'n/a',
            total_tardy: 'n/a'
        };
        vm.paperworkfromClass = $stateParams.paperworkfromClass;
        vm.create_session_notification = 0;
        vm.create_session_notification_2 = 0;
        vm.is_intervention = 0;
        vm.FirstName = '';
        vm.LastName = '';

        vm.Aip_form = {
            id: 0,
            student_id: 0,
            rti_type: vm.rti_form,
            reason: '',
            other_reason: '',
            date_plan: new Date(),
            category: 1,
            type: 2,
            teacher_initiating: '',
            teacher_collaborator_1: '',
            teacher_collaborator_2: '',
            teacher_collaborator_3: '',
            saved: false
        };
        if ($stateParams.reason !== null && ($stateParams.reason === 'Reading' || $stateParams.reason === 'Writing' || $stateParams.reason === 'Math' || $stateParams.reason === 'Other')) {
            vm.Aip_form.reason = $stateParams.reason;
        }
        vm.instructional = {
            student_id: 0,
            rti_type: vm.rti_form,
            instructional_procedure: '',
            reason: vm.Aip_form.reason,
            saved: false
        };
        vm.goal = {
            student_id: 0,
            rti_type: vm.rti_form,
            goal_percentage: '70',
            goal_statement: '',
            reason: vm.Aip_form.reason,
            saved: false
        };
        vm.aip_strength = {
            student_id: 0,
            rti_type: vm.rti_form,
            reason: vm.Aip_form.reason,
            strengths: [],
            other: [],
            saved: false
        };
        vm.aip_screen = {
            student_id: 0,
            rti_type: vm.rti_form,
            reason: vm.Aip_form.reason,
            screen_by: '',
            screen_date: new Date(),
            screen_measurement_tool: 'dibels-6th',
            screen_other_measurement: '',
            screen_fall_result: '',
            screen_midyr_result: '',
            screen_spring_result: '',
            saved: false
        };
        vm.aip_problem = {
            student_id: 0,
            rti_type: vm.rti_form,
            reason: vm.Aip_form.reason,
            what_problem: '',
            why_problem: '',
            additional_comments: '',
            saved: false
        };
        vm.aip_concernform = {
            student_id: 0,
            rti_type: vm.rti_form,
            reason: vm.Aip_form.reason,
            concerns: [],
            area: vm.area_concern,
            other: vm.other_concern,
            saved: false
        };

        vm.aip_intervention = {
            student_id: 0,
            rti_type: vm.rti_form,
            reason: vm.Aip_form.reason,
            type: vm.Aip_form.reason,
            interventions: [],
            saved: false
        };

        vm.email_notification_message4 = '';
        $rootScope.$on('activity_message', function(event, data) {

            vm.userActivity.push(data);

        });

   

        vm.aip_monitoring = [];
        vm.aip_types = [];
        vm.aip_monitor_log = [];
        vm.aip_monitor_result = [];

        vm.showOverView = false;
        vm.showScreening = false;
        vm.showProblem = false;
        vm.showIntervention = false;
        vm.checkWindow = checkWindow;
        var timer = '';
        var childWindow;


        vm.labels = [];
        vm.series = ['Series A'];

        vm.graphdata = [];

        vm.datasetOverride = [{
                fill: true,
                backgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",

                ]
            },
            {
                fill: true,
                backgroundColor: [
                    "#006400",


                ]
            }
        ];

        vm.options = {
            fill: false,
            lineTension: 0,
            pointRadius: 0
        };

        /* assign methods */
        vm.userPeriods = userPeriods;
        vm.afterselected = afterselected;
        vm.getstudents = getstudents;
        vm.getStudentdata = getStudentdata;
        vm.Aip_submit = Aip_submit;
        vm.save_concern = save_concern;
        vm.save_strength = save_strength;
        vm.save_intervention = save_intervention;
        vm.create_session = create_session;
        vm.update_session = update_session;
        vm.intervention_val = intervention_val;
        vm.saveResult = saveResult;
        vm.saveMonitorStatus = saveMonitorStatus;
        vm.Reset = Reset;
        vm.initialize = initialize;
        vm.delete_session = delete_session;
        vm.sendtoAdmin = sendtoAdmin;
        vm.aip_data = aip_data;
        vm.addaip = addaip;
        vm.selectStudentByPeriod = selectStudentByPeriod;
        vm.printpreview = printpreview;
        vm.getRelation = getRelation;

        vm.interven = [];



        /* document ready */
        defaultOptions();
        $scope.$watch('AcademicInterventionSstCtrl.userSelectedPeriod', selectStudentByPeriod, false);
        
        function addaip(myForm, $setPristine) {
            myForm.$setPristine();
        }

        // toggle selection for a given fruit by name
        vm.toggleSelection = function toggleSelection(value, chkboxesArr) {
            // alert(value);
            var idx = chkboxesArr.indexOf(value);
            // is currently selected
            if (idx > -1) {
                chkboxesArr.splice(idx, 1);
            }
            // is newly selected
            else {
                chkboxesArr.push(value);
            }
        };

        function defaultOptions() {
            if ($state.params.type == 'view') {
                vm.preview = true;
            }
            paperworksService.getOptions('/api/paperwork/options?rti_type=' + vm.rti_form).then(function(data) {
                vm.interventions = data.interventions;
                vm.area_of_concern = data.concerns;
                vm.Aip_form.teacher_initiating = data.teacher;
                vm.periods = data.class_period;
                vm.students = data.students;
                vm.strength = data.strength;

                if ($stateParams.studentId !== null && $stateParams.studentId > 0) {
                    vm.showSearchBox = false;
                    searchstudent($stateParams.studentId);
                }
            });
        }

        function userPeriods() {

            ClassesService.getUserCreatedPeriod('/api/classes/usercreatedperiod').then(function(response) {
                vm.periods = response;
            });

        }
        
        function selectStudentByPeriod() {

            if (vm.userSelectedPeriod === '') {
                studentsService
                    .getallstudentbyUser('/api/students/getstudentbyuser').then(function(students) {
                        vm.students = students;
                    });
            } else {

                var data = {
                    period: vm.userSelectedPeriod
                };
                studentsService.getStudentsByuserPeriod('/api/students/getstudentByuserPeriod', data).then(function(response) {
                    vm.students = response;
                });
            }
        }

        function getstudents() {
            studentsService
                .getallstudentbyUser('/api/students/getstudentbyuser').then(function(students) {
                    vm.students = students;
                });
        }

        function afterselected(selected) {
            if (selected) {
                initialize();
                vm.selectedstudent = selected.originalObject;
                searchstudent(selected.originalObject.id);
                selected.originalObject = null;
                vm.FirstName = vm.selectedstudent.first_name;
                vm.LastName = vm.selectedstudent.last_name;
            }

        }

        function searchstudent(studentid) {
            studentsService.getStudentwithClasses('api/students/studentwithclasses?id=' + studentid).then(function(response) {
                if ($stateParams.studentId !== null && $stateParams.studentId > 0) {
                    vm.selectedstudent = response.student;
                }

                vm.studentname = response.student.fullname;
                vm.Sid = response.student.studentid;
                if (response.student_class) {
                    angular.forEach(response.student_class, function(data) {
                        vm.studentperiod = data.period + '' + data.period_letter + '\n';
                        vm.studentgrade = data.grade;
                    });
                } else {
                    vm.studentperiod = 'N/A';
                    vm.studentgrade = 'N/A';
                }
                vm.studentgender = response.student.gender_type;
                vm.aip_concernform.student_id = response.student.id;
                vm.Aip_form.student_id = response.student.id;
                vm.aip_screen.student_id = response.student.id;
                vm.aip_problem.student_id = response.student.id;
                vm.aip_intervention.student_id = response.student.id;
                vm.instructional.student_id = response.student.id;
                vm.goal.student_id = response.student.id;
                vm.aip_strength.student_id = response.student.id;

                vm.attendance_reports.total_absent = response.student.total_absents;
                vm.attendance_reports.total_skiped = response.student.total_skipes;
                vm.attendance_reports.total_tardy = response.student.total_tardies;

                paperworksService.getipData('/api/paperwork/aip?rti_type=' + vm.rti_form + '&type=' + vm.Aip_form.reason + '&student_id=' + response.student.id).then(function(data) {
                    getStudentdata(data);
                });
            });
        }

        function aip_data() {
            if (vm.Aip_form.student_id > 0) {
                vm.initialize();
                paperworksService.getipData('/api/paperwork/aip?rti_type=' + vm.rti_form + '&type=' + vm.Aip_form.reason + '&student_id=' + vm.Aip_form.student_id).then(function(data) {
                    getStudentdata(data);
                });
            }
        }

        function getStudentdata(data) {

            if (data.ip) {


                vm.Aip_form.id = data.ip.id;
                vm.Aip_form.reason = data.ip.reason;
                vm.Aip_form.other_reason = data.ip.other_reason;
                vm.Aip_form.date_plan = data.ip.date_plan;
                vm.Aip_form.category = data.ip.category;
                vm.Aip_form.type = data.ip.type;
                //vm.Aip_form.teacher_initiating = data.ip.teacher_initiating;
                vm.Aip_form.teacher_collaborator_1 = data.ip.teacher_collaborator_1;
                vm.Aip_form.teacher_collaborator_2 = data.ip.teacher_collaborator_2;
                vm.Aip_form.teacher_collaborator_3 = data.ip.teacher_collaborator_3;
                if (vm.Aip_form.reason !== null || vm.Aip_form.date_plan !== null || vm.Aip_form.teacher_initiating !== null)
                    vm.Aip_form.saved = true;

                vm.aip_screen.screen_by = data.ip.screen_by;
                vm.aip_screen.screen_date = data.ip.screen_date;
                if (data.ip.screen_measurement_tool !== null)
                    vm.aip_screen.screen_measurement_tool = data.ip.screen_measurement_tool;
                vm.aip_screen.screen_fall_result = data.ip.screen_fall_result;
                vm.aip_screen.screen_midyr_result = data.ip.screen_midyr_result;
                vm.aip_screen.screen_spring_result = data.ip.screen_spring_result;

                if (vm.aip_screen.screen_by !== null || vm.aip_screen.screen_date !== null || vm.aip_screen.screen_fall_result !== null || vm.aip_screen.screen_midyr_result !== null || vm.aip_screen.screen_spring_result !== null)
                    vm.aip_screen.saved = true;

                vm.aip_problem.what_problem = data.ip.what_problem;
                vm.aip_problem.why_problem = data.ip.why_problem;
                vm.aip_problem.additional_comments = data.ip.additional_comments;
                if (vm.aip_problem.what_problem !== null || vm.aip_problem.why_problem !== null || vm.aip_problem.additional_comments !== null)
                    vm.aip_problem.saved = true;
                vm.goal.goal_percentage = data.ip.goal_percentage;
                vm.goal.goal_statement = data.ip.goal_statement;
                if (vm.goal.goal_percentage !== null || vm.goal.goal_statement !== null)
                    vm.goal.saved = true;
                vm.instructional.instructional_procedure = data.ip.instructional_procedure;
                if (vm.instructional.instructional_procedure !== null)
                    vm.instructional.saved = true;

                if (vm.Aip_form.saved === true && vm.aip_problem.saved === true && vm.goal.saved === true && vm.instructional.saved === true) {
                    vm.create_session_notification = 4;
                }              
                
            }

            if (data.ip_concern) {
                if (data.ip_concern.length)
                    vm.aip_concernform.saved = true;
                for (var i = 0; i < data.ip_concern.length; i++) {
                    vm.aip_concernform.concerns.push(data.ip_concern[i].subject + '~' + data.ip_concern[i].option);

                    if (data.ip_concern[i].option == 'Other')
                        vm.other_concern[data.ip_concern[i].subject] = data.ip_concern[i].other_option;
                    else if (data.ip_concern[i].option.indexOf('#') !== -1)
                        vm.area_concern[data.ip_concern[i].subject] = data.ip_concern[i].other_option;
                }

                if (vm.aip_concernform.saved === true) {
                    vm.create_session_notification += 1;
                }
            }
            if (data.ip_strength) {
                if (data.ip_strength.length)
                    vm.aip_strength.saved = true;
                for (var k = 0; k < data.ip_strength.length; k++) {
                    vm.aip_strength.strengths.push(data.ip_strength[k].strength_val + '~' + data.ip_strength[k].strength_text);

                    if (data.ip_strength[k].strength_text.indexOf('#') !== -1)
                        vm.aip_strength.other[data.ip_strength[k].strength_val] = data.ip_strength[k].other_strength;
                }

                if (vm.aip_strength.saved === true) {
                    vm.create_session_notification += 1;
                }
            }
            if (data.ip_intervention) {
                if (data.ip_intervention.length)
                    vm.aip_intervention.saved = true;
                for (var j = 0; j < data.ip_intervention.length; j++) {
                    vm.aip_intervention.interventions.push(data.ip_intervention[j].subject + '~' + data.ip_intervention[j].option);
                }

                if (vm.aip_intervention.saved === true) {
                    vm.create_session_notification += 1;
                    vm.is_intervention = 1;
                }
            }

            if (data.ip_monitoring) {
                vm.aip_monitoring = data.ip_monitoring;
                angular.forEach(data.ip_monitoring, function(value) {
                    vm.minitorId.push(value.id);
                });
            }

            vm.aip_types = data.ip_types;
            vm.aip_monitor_log = data.ip_monitor_log;
            vm.aip_monitor_result = data.ip_monitor_result;
            vm.student_incident = data.incident;
            vm.paperwork_log = data.paperwork_log;
            vm.communication_log = data.communication_log;
            
            vm.userActivity = [];

            angular.forEach(vm.minitorId, function(Id) {

                vm.labels[Id] = [];
                vm.graphdata[Id] = [];
                angular.forEach(data.ip_monitor_result[Id], function(value) {

                    vm.labels[Id].push(value.intervention_name);
                    vm.graphdata[Id].push(value.rate);

                });

            });
        }


        function printpreview() {
        	timer = setInterval(checkWindow, 500);
            var winName = 'MyWindow';
            var winURL = '/api/paperwork/preview';
            var windowoption = 'resizable=yes,height=600,width=800,location=0,menubar=0,scrollbars=1';
            var params = {
                'paperwork_type': 'RTI',
                'formName': 'AIP-SST',
                'form_id': vm.Aip_form.id,
                'student_id': vm.Aip_form.student_id,
                'reason': vm.Aip_form.reason,
                'other_reason': vm.Aip_form.other_reason,
                'date_plan': vm.Aip_form.date_plan,
                'category': vm.Aip_form.category,
                'type': vm.Aip_form.type,
                'teacher_initiating': vm.Aip_form.teacher_initiating,
                'teacher_collaborator_1': vm.Aip_form.teacher_collaborator_1,
                'teacher_collaborator_2': vm.Aip_form.teacher_collaborator_2,
                'teacher_collaborator_3': vm.Aip_form.teacher_collaborator_3,
                'screen_by': vm.aip_screen.screen_by,
                'screen_date': vm.aip_screen.screen_date,
                'screen_measurement_tool': vm.aip_screen.screen_measurement_tool,
                'screen_other_measurement': vm.aip_screen.screen_other_measurement,
                'screen_fall_result': vm.aip_screen.screen_fall_result,
                'screen_midyr_result': vm.aip_screen.screen_midyr_result,
                'screen_spring_result': vm.aip_screen.screen_spring_result,
                'what_problem': vm.aip_problem.what_problem,
                'why_problem': vm.aip_problem.why_problem,
                'additional_comments': vm.aip_problem.additional_comments,
                'concerns': vm.aip_concernform.concerns,
                'area': JSON.stringify(vm.aip_concernform.area),
                'concern_other': JSON.stringify(vm.aip_concernform.other),
                'intervtype': vm.aip_intervention.type,
                'interventions': vm.aip_intervention.interventions,
                'instructional_proc': vm.instructional.instructional_procedure,
                'goal_percent': vm.goal.goal_percentage,
                'goal_statement': vm.goal.goal_statement,
                'strength': vm.aip_strength.strengths,
                'strength_other': JSON.stringify(vm.aip_strength.other),
                'monitorcreateSession': vm.create_session_notification,
                'monitoring': JSON.stringify(vm.aip_monitoring),
                'monitoring_log': JSON.stringify(vm.aip_monitor_log),
                'monitoring_result': JSON.stringify(vm.aip_monitor_result)


            };

            var form = document.createElement("form");
            form.setAttribute("method", "post");
            form.setAttribute("action", winURL);
            form.setAttribute("target", winName);
            for (var i in params) {
                if (params.hasOwnProperty(i)) {
                    var input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = i;
                    input.value = params[i];
                    form.appendChild(input);
                }
            }
            document.body.appendChild(form);
            childWindow = window.open('', winName, windowoption);
            form.target = winName;
            form.submit();
            document.body.removeChild(form);
        }

        function checkWindow() {
    		if (childWindow && childWindow.closed) {
    	        window.clearInterval(timer); 
    	     
    	        $scope.$apply(function(){    	        	
   	        	vm.preview = false;
   	    	     
   	        	});

    	    }
		}


        function Aip_submit(formname, form) {
            var aipform = '';
            var preview_form = '';
            switch (formname) {
                case 'aip_form':
                    aipform = vm.Aip_form;
                    preview_form = "Overview";
                    break;
                case 'screen':
                    vm.aip_screen.reason = vm.Aip_form.reason;
                    aipform = vm.aip_screen;
                    preview_form = "Screening --Reading";
                    break;
                case 'aip_problem':
                    vm.aip_problem.reason = vm.Aip_form.reason;
                    aipform = vm.aip_problem;
                    preview_form = "Problem Analysis";
                    break;
                case 'goal':
                    vm.goal.reason = vm.Aip_form.reason;
                    aipform = vm.goal;
                    preview_form = "Goal Statement";
                    break;
                case 'procedure':
                    vm.instructional.reason = vm.Aip_form.reason;
                    aipform = vm.instructional;
                    preview_form = "Instructional Procedures";
                    break;
            }

            if (aipform.student_id > 0) {

                var flag = 1;
                if (formname === 'aip_form') {
                    if (vm.Aip_form.date_plan === "") {
                        toastr.error('Date required.');
                        flag = 0;
                    }
                }


                if (formname === 'aip_problem') {
                    if (vm.aip_problem.what_problem === "") {
                        flag = 0;
                        toastr.error('Define the problem required');

                    } else if (vm.aip_problem.why_problem === "") {
                        flag = 0;
                        toastr.error('Problem Hypothesis required');

                    }

                }

                if (formname === 'goal') {
                    if (vm.aip_problem.goal_statement === "") {
                        flag = 0;
                        toastr.error('Goal statement required');

                    }

                }

                if (formname === 'procedure') {
                    if (vm.aip_problem.instructional_procedure === "") {
                        flag = 0;
                        toastr.error('Instructional procedure required');

                    }

                }

                if (flag === 1) {
                    paperworksService.saveipForm('/api/paperwork/aip/form', aipform).then(function(response) {
                        if (response) {
                            vm.Aip_form.id = response.id;
                            aipform.saved = true;
                            toastr.success('Submitted successfully.');
                            vm.userActivity.push(preview_form + ' saved at ' + $filter('date')(new Date(), 'M/d/yy h:mm:ss a'));
                            vm.create_session_notification = 1 + parseInt(vm.create_session_notification_2);
                            if (vm.create_session_notification >= 7 && vm.is_intervention === 1) {
                                toastr.error('Please create a session to start monitoring and complete the form.');
                            }
                        }
                    });
                }
            }

            addaip(form);
        }

        function save_intervention(formData, form) {
            if (formData.student_id > 0) {

                formData.reason = vm.Aip_form.reason;
                paperworksService.saveipIntervention('/api/paperwork/aip/intervention', formData).then(function(response) {
                    if (response) {
                        vm.Aip_form.id = response;
                        formData.saved = true;

                        toastr.success(vm.Aip_form.reason + 'interventions saved successfuly.');
                        vm.userActivity.push(vm.Aip_form.reason + ' Intervention saved at ' + $filter('date')(new Date(), 'M/d/yy h:mm:ss a'));

                        vm.create_session_notification += 1;
                        vm.is_intervention = 1;
                        vm.create_session_notification_2 = vm.create_session_notification;
                        if (vm.create_session_notification_2 >= 7 && vm.is_intervention === 1) {
                            toastr.error('Please create a session to start monitoring and complete the form.');
                        }

                    }
                });
            }

            addaip(form);
        }

        function save_concern(formname, form) {
            if (vm.aip_concernform.student_id > 0) {
                vm.aip_concernform.reason = vm.Aip_form.reason;
                paperworksService.saveipConcern('/api/paperwork/aip/concern', vm.aip_concernform).then(function(response) {
                    if (response) {
                        vm.Aip_form.id = response;
                        vm.aip_concernform.saved = true;
                        toastr.success('Target academic skill saved successfuly.');
                        vm.userActivity.push('Target academic skill(s) saved at ' + $filter('date')(new Date(), 'M/d/yy h:mm:ss a'));

                        vm.create_session_notification += 1;
                        vm.create_session_notification_2 = vm.create_session_notification;
                        if (vm.create_session_notification_2 >= 7 && vm.is_intervention === 1) {
                            toastr.error('Please create a session to start monitoring and complete the form.');
                        }

                    }
                });
            }

            addaip(form);
        }

        function save_strength(formname, form) {
            if (vm.aip_strength.student_id > 0) {
                vm.aip_strength.reason = vm.Aip_form.reason;
                paperworksService.saveipForm('/api/paperwork/aip/strength', vm.aip_strength).then(function(response) {
                    if (response) {
                        vm.Aip_form.id = response;
                        vm.aip_strength.saved = true;
                        toastr.success('Student strength submitted successfuly.');
                        vm.userActivity.push('Student strengths saved at ' + $filter('date')(new Date(), 'M/d/yy h:mm:ss a'));

                        vm.create_session_notification += 1;
                        vm.create_session_notification_2 = vm.create_session_notification;
                        //console.log(vm.create_session_notification);
                        if (vm.create_session_notification_2 >= 7 && vm.is_intervention === 1) {
                            toastr.error('Please create a session to start monitoring and complete the form.');
                        }

                    }
                });
            }

            addaip(form);
        }

        vm.scrollTo = function(id) {
            $location.hash(id);
            $anchorScroll();
        };


        function create_session(type) {

            if (vm.Aip_form.id > 0 && (vm.create_session_notification >= 7 && vm.is_intervention === 1)) {

                var formData = {
                    student_id: vm.Aip_form.student_id,
                    rti_type: vm.rti_form,
                    type: type
                };

                paperworksService.saveipMonitoring('/api/paperwork/aip/monitoring', formData).then(function(response) {
                    if (response) {
                        vm.aip_monitoring.push(response);
                        toastr.success('Session created successfuly.');
                        vm.userActivity.push('Session created at ' + $filter('date')(new Date(), 'M/d/yy h:mm:ss a'));
                    }
                });
            } else {
            	if(!vm.aip_concernform.saved && vm.aip_intervention.saved){
            		toastr.error('Please fillup and saved areaconcern to create session.');
            	}else if(!vm.aip_intervention.saved && vm.aip_concernform.saved){
            		toastr.error('Please fillup and saved interventions to create session.');
            	}else{
            		toastr.error('Please fillup and saved areaconcern and interventions to create session.');
            	}
                //toastr.error('You are not able to create session.');
            }
        }

        function update_session(formData, form) {
            addaip(form);
            var flag = 1;
            if (formData.teacher_name === "") {
                toastr.error('Teacher name required.');
                flag = 0;
            } else if (formData.duration === "") {
                toastr.error('Duration required.');
                flag = 0;
            }

            if (flag === 1) {
                if (vm.Aip_form.id > 0 && parseInt(formData.duration) > 0 && formData.teacher_name !== '') {
                    paperworksService.updateipMonitoring('/api/paperwork/aip/monitoring/update', formData).then(function(response) {
                        if (response) {
                            helperService.showAlert('Session updated successfuly.');
                            response = response;
                            formData.saved = true;

                            vm.userActivity.push('Session updated at ' + $filter('date')(new Date(), 'M/d/yy h:mm:ss a'));
                            $state.go("classes.roaster");
                        }
                    });
                }
            }


        }

        function delete_session(formData) {
            SweetAlert.confirm("Are you sure?", {
                    title: "Delete!"
                })
                .then(function(isConfirm) {
                    if (isConfirm) {
                        paperworksService.deleteipMonitoring('/api/paperwork/aip/monitoring/delete', formData).then(function(response) {
                            if (response) {
                                var idx = vm.aip_monitoring.indexOf(formData);
                                vm.aip_monitoring.splice(idx, 1);
                                helperService.showAlert('Session deleted successfuly.');
                                vm.userActivity.push('Session deleted at ' + $filter('date')(new Date(), 'M/d/yy h:mm:ss a'));
                            }
                        });
                    }
                }, function(p) {

                });
        }

        function intervention_val(id) {
            var intervention_type = '';
            angular.forEach(vm.interventions, function(intervention) {
                if (intervention.id === parseInt(id)) {
                    intervention_type = intervention.name;
                }
            });

            return intervention_type;
        }

        function saveResult(formData, form) {
            addaip(form);
            paperworksService.saveipMonitoringResult('/api/paperwork/aip/monitoring-result', {
                monitor_result: formData
            }).then(function(response) {
                helperService.showAlert('Comment submitted successfuly.');
            });


        }

        function saveMonitorStatus(formData, form) {
            addaip(form);
            paperworksService.updateipMonitoring('/api/paperwork/aip/monitoring/update', formData).then(function(response) {
                helperService.showAlert('Status changed successfuly.');
                formData.in_progress = false;
                if (formData.status == 2) {
                    vm.create_session(formData.type);
                }
            });

        }

        function sendtoAdmin() {
            var student = vm.selectedstudent;
            helperService.paperworkformModal(vm.selectedstudent, vm.Aip_form.id, 'send-admin-modal', 'md', 'intervention', vm.rti_form, vm.Aip_form.reason).then(function(response) {
                helperService.showAlert('successfuly email sended.');

            });

        }

        function initialize() {
            vm.Aip_form.id = 0;
            vm.Aip_form.other_reason = '';
            vm.Aip_form.date_plan = new Date();
            vm.Aip_form.category = 1;
            vm.Aip_form.type = 2;
            vm.Aip_form.teacher_collaborator_1 = '';
            vm.Aip_form.teacher_collaborator_2 = '';
            vm.Aip_form.teacher_collaborator_3 = '';
            vm.Aip_form.saved = false;

            vm.goal.goal_percentage = '70';
            vm.goal.goal_statement = '';
            vm.goal.saved = false;

            vm.instructional.instructional_procedure = '';
            vm.instructional.saved = false;

            vm.aip_screen.saved = false;
            vm.aip_screen.screen_by = '';
            vm.aip_screen.screen_date = new Date();
            vm.aip_screen.screen_fall_result = '';
            vm.aip_screen.screen_measurement_tool = 'dibels-6th';
            vm.aip_screen.screen_midyr_result = '';
            vm.aip_screen.screen_spring_result = '';

            vm.aip_problem.additional_comments = '';
            vm.aip_problem.saved = false;
            vm.aip_problem.what_problem = '';
            vm.aip_problem.why_problem = '';

            vm.aip_concernform.area = vm.area_concern;
            vm.aip_concernform.concerns = [];
            vm.aip_concernform.other = vm.other_concern;
            vm.aip_concernform.saved = false;

            vm.aip_strength.other = [];
            vm.aip_strength.saved = false;
            vm.aip_strength.strengths = [];

            vm.aip_intervention.interventions = [];
            vm.aip_intervention.saved = false;
            vm.aip_intervention.type = vm.Aip_form.reason;

            vm.aip_monitoring = [];
            vm.aip_types = [];
            vm.aip_monitor_log = [];
            vm.aip_monitor_result = [];
        }

        function Reset() {
            SweetAlert.confirm("Are you sure?", {
                    title: "Reset AIP-SST Form!",
                    cancelButtonText: "cancel",
                    confirmButtonText: "Yes"	
                })
                .then(function(isConfirm) {
                    if (isConfirm) {
                        paperworksService.resetip('/api/paperwork/aip/reset', {
                            student_id: vm.Aip_form.student_id,
                            rti_type: vm.rti_form,
                            type: vm.Aip_form.reason
                        }).then(function(response) {
                            vm.initialize();
                            $timeout(function () {
                              	 helperService.showAlert('Your All data has been reset. Please re enter.');
                              }, 2000);
                            $state.reload();
                        });
                    }
                }, function(p) {

                });
        }
        
        function getRelation(value){
        	var relation = '';
        	
        	switch(value){
        	case 1:
        		relation = 'mother';
       			break;
       		case 2:
       			relation = 'father';
       			break;
       		case 3:
       			relation = 'uncle';
       			break;
       		case 4:
       			relation = 'aunt';
       			break;
       		case 5:
       			relation = 'sister';
       			break;
       		case 6:
       			relation = 'brother';
       			break;
       		case 7:
       			relation = 'grandmother';
       			break;
       		case 8:
       			relation = 'grandfather';
       			break;
       		case 9:
       			relation = 'other';
       			break;
        	}
        	return relation;
        }
    }

})();