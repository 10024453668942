(function () {
  'use strict';

  angular
    .module('app.dashboard')
    .service('ShareUsService', ShareUsService);

  /* @ngInject */
  function ShareUsService($resource, $q,$http) {
    /* jshint validthis: true */
    var vm = this;

    vm.SendRecommendUsEmail=SendRecommendUsEmail;
    vm.ShareSocialMedia=ShareSocialMedia;


    function ShareSocialMedia($url) {
      return $http.get($url).then(fetchData);
    }
    

    function SendRecommendUsEmail(urlparemeter, userdata){
      
      return $http.post(urlparemeter, userdata).then(fetchData);
    }

    

    function fetchData(response)
    {
        return response.data;
    }


  }


})();
