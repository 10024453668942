(function() {
    'use strict';

    angular
        .module('app.student')
        .controller('StudentController', StudentController);

    function StudentController($scope, studentsService, toastr, student, helperService, exportService,
        dateRanges, modes, types, categories, ClassesService, $filter,
        $stateParams, reports, attendType, incidentTopic, $timeout, paperworksService,
        periods, paperworkstatus, paperworktype, academicIncident, SweetAlert, $state, positivebehavIncident,
        negativebehavIncident, positiveacadIncident, negativeacadIncident, behaviorincidentTopic, 
        academicincidentTopic, cannedMessagesService, $rootScope) {
        /* jshint validthis: true */
        var vm = this;

        cannedMessagesService.getmeessageandData().then(function(data) {
            helperService.setCannedData(data[0], data[1], data[2]);
        });

        categories.unshift({
            title: 'All',
            id: ''
        });
        types.unshift({
            title: 'All',
            id: ''
        });

        vm.accomodation_text = '';
        vm.student_club = '';
        vm.other_student_club = '';
        vm.class_period = "";
        vm.checkedNone=checkedNone;

        vm.is_school_account=0;
        if($rootScope.user.is_school_account)
        {
           vm.is_school_account=1; 
        }


        vm.student = student;
        //vm.student = angular.copy(student);
        vm.messages = [];
        vm.attendances = [];
        vm.behaviors = [];
        vm.paperworks = [];
        vm.paperworkstatus = paperworkstatus;
        vm.paperworktype = paperworktype;
        vm.academics = [];
        vm.academicincident = academicIncident;
        vm.behaviorTopicTitle = behaviorTopicTitle;
        vm.academicTopicTitle = academicTopicTitle;
        vm.studentClasses = '';
        vm.studentClubes = '';
        vm.studentClubId = '';
        vm.isInArray = isInArray;
        vm.classesData = classesData;
        vm.getIncident = getIncident;
        vm.paperworkNote = paperworkNote;
        vm.getNotes = getNotes;
        vm.note = [];
        vm.studentclassYear = '';
        vm.schoolName = '';
        vm.student.bdate = (!isNaN(vm.student.bdate.getTime())) ? $filter('date')(vm.student.bdate, "yyyy-MM-dd") : '';

        vm.setSST = setSST;

        vm.db_class_list = [];
        vm.db_club_list = [];


        
         $rootScope.$broadcast('angucomplete-alt:clearInput');
        //$scope.$broadcast('angucomplete-alt:clearSearch');


        classesData();
        paperworkNote();
        //getIncident();


        vm.accomodationList = vm.student.accomodations.split(',');

        vm.Valueiep = '';
        vm.Value504 = '';
        vm.Valuesst = 'AIP-SST';
        vm.SSTcheck = '';
        vm.NoneOfAbove = false;

        angular.forEach(vm.accomodationList, function(value) {

            //console.log(value);

            if (value == 'IEP-TDM') {
                vm.Valueiep = 'IEP';
            } else if (value == '504-TDM') {
                vm.Value504 = '504';
            } else if (value == 'AIP-SST' || value == 'BIP-SST') {
                vm.Valuesst = value;
                vm.SSTcheck = true;
            }
            else if (value === 0)
            {
                vm.NoneOfAbove = true;
            }

        });

        function checkedNone()
        {
            console.log('hello');
            vm.Valueiep = '';
            vm.Value504 = '';
            vm.Valuesst = 'AIP-SST';
            vm.SSTcheck = '';
            vm.NoneOfAbove = true;

        }


        var newStr = vm.accomodation_text.substring(0, vm.accomodation_text.length - 1);

        vm.accomodation_text = newStr;

        function classesData() {

            ClassesService.getclassesData('/api/students/studentwithclasses?id=' + $stateParams.studentId).then(function(response) {
                //vm.student = response.student;

                vm.schoolName = response.school.title;
                var classStudent = '';
                var clubStudent = '';

                var student_class_detail = "";
                var student_club = "";

                vm.classesId = [];
                vm.clubId = [];

                angular.forEach(response.student_class, function(value) {
                    vm.studentclassYear = value.school_year;
                    if (value.group_type == 1) {
                        classStudent += value.name + '|' + 'period ' + value.period + '' + value.period_letter + ',';
                        student_class_detail += value.name + ' period- ' + value.period + '' + value.period_letter + ',';
                        vm.classesId.push(value.id + '-' + value.name);
                    } else {
                        clubStudent += value.name + '|' + 'period ' + value.period + ',';
                        student_club = value.name;
                        vm.studentClubId = value.id;
                        vm.clubId.push(value.id + '-' + value.name);
                    }

                });


                newStr = student_class_detail.substring(0, student_class_detail.length - 1);
                vm.class_period = newStr;
                vm.student_club = student_club;



                angular.forEach(response.classes, function(class_period) {
                    if (class_period.group_type === '1')
                        vm.db_class_list.push({
                            id: class_period.id,
                            name: class_period.name,
                            period: class_period.period,
                            period_letter: class_period.period_letter
                        });
                    else
                        vm.db_club_list.push({
                            id: class_period.id,
                            name: class_period.name
                        });
                });

                vm.studentClasses = classStudent.replace(/,\s*$/, "");
                vm.studentClubes = clubStudent.replace(/,\s*$/, "");


            });



            


        }

        vm.selectedReport = parseInt($stateParams.type);
       
        var typedata = $stateParams.type ? parseInt($stateParams.type) - 1 : 1;

        vm.selectedReportTitle = reports[typedata].title;

        //Selected filters
        vm.selectedDateRange = dateRanges[4].value;
        vm.selectedDateTitle = dateRanges[4].title;
        vm.selectedMode = modes[0].value;
        vm.selectedModetitle = modes[0].title;
        vm.selectedType = types[0].id;
        vm.selectedTypetitle = types[0].title;
        vm.selectedCategory = categories[0].id;
        vm.selectedCategorytitle = categories[0].title;
        vm.selectedPaperworkstatus = '';
        vm.selectedPaperworkstatusTitle = 'All';
        vm.selectedPaperworktype = paperworktype[0].value;
        vm.selectedPaperworktypetitle = paperworktype[0].title;

        vm.selectedPaperworkcategory = '';
        vm.selectedPaperworkcatTitle = 'All';
        vm.selectedPaperworkstatustype = '';
        vm.selectedpaperworkstatustyTitle = 'All';
        vm.order = '-created_at';
        vm.customRanges = {
            startDate: null,
            endDate: null
        };
        vm.selectedattendType = attendType[0].value;
        vm.selectedattendTitle = attendType[0].title;
        vm.selectedincidentTopic = '';
        vm.selectedincidentTopicTitle = '';
        /*  vm.selectedacademicincident = academicIncident[0].value;
          vm.selectedacademicincidentTitle = academicIncident[0].title;*/

        //Data for filters 
        vm.dateRanges = dateRanges;
        vm.modes = modes;
        vm.reports = reports;
        vm.types = types;
        vm.categories = categories;
        vm.attendType = attendType;
        vm.incidentTopic = incidentTopic;

        vm.form = vm.student;
        //vm.form = angular.copy(vm.student);

        vm.update = update;
        vm.sendMessage = sendMessage;
        vm.makeCall = makeCall;
        vm.sendEmail = sendEmail;
        vm.swReferral = swReferral;
        vm.generalReferal = generalReferal;
        vm.selectFilters = selectFilters;
        vm.exportReportPDF = exportReportPDF;
        // vm.loader = false;
        vm.hideloader = hideloader;

        vm.changeReport = changeReport;
        vm.changeMode = changeMode;
        vm.changeCategory = changeCategory;
        vm.changeType = changeType;
        vm.changeAttendType = changeAttendType;
        vm.changeIncidentTopic = changeIncidentTopic;
        vm.changepaperworktype = changepaperworktype;
        vm.changepaperworkstatus = changepaperworkstatus;
        vm.changeAcademicIncident = changeAcademicIncident;

        //After set customRanges sent request to server for getting data about students.
        $scope.$watch('StudentCtrl.customRanges', selectFilters, false);
        $scope.$watch('StudentCtrl.selectedReport', selectFilters, false);


        vm.showloader = showloader;
        vm.change_state = change_state;

        //////////////////


        //
        

        vm.backdrop = '';
        
        $rootScope.$on('setBackdrop', function (event, data) {
        	vm.backdrop = data;

         });

        function getIncident(type) {
            switch (true) {
                case (type == 3):
                    vm.incidentTopic = behaviorincidentTopic;
                    vm.selectedincidentTopic = behaviorincidentTopic[0].value;
                    vm.selectedincidentTopicTitle = behaviorincidentTopic[0].title;
                    break;
                case (type == 5):
                    vm.incidentTopic = academicincidentTopic;
                    vm.selectedincidentTopic = academicincidentTopic[0].value;
                    vm.selectedincidentTopicTitle = academicincidentTopic[0].title;
                    break;

            }
        }



        function showloader() {}

        function change_state(stateParameter, Sid) {

            if ($state.current.name != stateParameter) {
                $timeout(function() {}, 2);

            }

            $state.go(stateParameter, {
                studentId: Sid
            });

            $timeout(function() {}, 1000);
        }


        function changeReport(data) {
            vm.selectedReport = data;
            vm.ChangeReportData = data;	
            vm.selectedReportTitle = reports[data - 1].title;
            vm.selectFilters();
            if(data === 3 || data === 5){          
            getIncident(data);
            }
        }


        function changeMode(data) {
            if (data === '') {
                vm.selectedMode = modes[0].value;
                vm.selectedModetitle = modes[0].title;
            } else {
                vm.selectedMode = modes[data].value;
                vm.selectedModetitle = modes[data].title;
            }
        }

        function changeCategory(data) {
           
            if (data === '') {
                vm.selectedCategory = categories[0].id;
                vm.selectedCategorytitle = categories[0].title;
            } else {
                vm.selectedCategory = categories[data].id;
                vm.selectedCategorytitle = categories[data].title;
            }
        }

        function changeType(data) {

            if (data === '') {
                vm.selectedType = types[0].id;
                vm.selectedTypetitle = types[0].title;
            } else {
                vm.selectedType = types[data].id;
                vm.selectedTypetitle = types[data].title;
            }
        }

        function changeAttendType(data) {
            if (data === '') {
                vm.selectedattendType = attendType[0].value;
                vm.selectedattendTitle = attendType[0].title;
            } else {
                vm.selectedattendType = attendType[data].value;
                vm.selectedattendTitle = attendType[data].title;
            }
        }

        function changeIncidentTopic(data, type) {
        	
            if (type == 'behavior') {
                if (data === '') {
                    vm.selectedincidentTopic = behaviorincidentTopic[0].value;
                    vm.selectedincidentTopicTitle = behaviorincidentTopic[0].title;
                }
                vm.selectedincidentTopic = behaviorincidentTopic[data].value;
                vm.selectedincidentTopicTitle = behaviorincidentTopic[data].title;
            } else if (type == 'academic') {
                if (data === '') {
                    vm.selectedincidentTopic = academicincidentTopic[0].value;
                    vm.selectedincidentTopicTitle = academicincidentTopic[0].title;
                }
                vm.selectedincidentTopic = academicincidentTopic[data].value;
                vm.selectedincidentTopicTitle = academicincidentTopic[data].title;

            }

        }

        function changepaperworktype(data) {
            if (data === '') {
                vm.selectedPaperworktype = paperworktype[0].value;
                vm.selectedPaperworktypetitle = paperworktype[0].title;
            } else {
                vm.selectedPaperworktype = paperworktype[data].value;
                vm.selectedPaperworktypetitle = paperworktype[data].title;
            }
        }

        function changepaperworkstatus(data) {
            if (data === '') {
                vm.selectedPaperworkstatus = paperworkstatus[0].value;
                vm.selectedPaperworkstatusTitle = paperworkstatus[0].title;
            } else {
                vm.selectedPaperworkstatus = paperworkstatus[data].value;
                vm.selectedPaperworkstatusTitle = paperworkstatus[data].title;
            }
        }

        function changeAcademicIncident(data) {
            if (data === '') {
                vm.selectedacademicincident = academicIncident[0].value;
                vm.selectedacademicincidentTitle = academicIncident[0].title;
            } else {
                vm.selectedacademicincident = academicIncident[data].value;
                vm.selectedacademicincidentTitle = academicIncident[data].title;
            }
        }

        function hideloader() {

            //vm.selectFilters();

        }



        function sendMessage(activ) {
            helperService.communicationModal(vm.student, 1, 'message','','','').then(function(response) {
                refreshStudent();

                helperService.showAlert('The message was sent.');

                return response;
            });
        }

        function makeCall(activ) {
            helperService.communicationModal(vm.student, 2, 'call','','',vm.backdrop).then(function(response) {
                refreshStudent();

                helperService.showAlert('The call was made.');

                return response;
            });
        }

        function sendEmail(activ) {

            helperService.communicationModal(vm.student, 3, 'email','','','').then(function(response) {

                refreshStudent();

                helperService.showAlert('The email was sent.');

                return response;
            });
        }

        function swReferral(activ, period) {
            helperService.communicationModal(vm.student, 4, 'sw-referral', activ, period,'').then(function(response) {
                refreshStudent();

                helperService.showAlert('The sw referral was sent.');

                return response;
            });
        }


        function generalReferal(activ, period) {

            helperService.communicationModal(vm.student, 5, 'general-referral', activ, period,'').then(function(response) {
                refreshStudent();

                helperService.showAlert('The general referral was sent.');

                return response;
            });
        }

        function refreshStudent() {

            studentsService.get(vm.student.id).then(function(student) {
                vm.student = student;
            });
        }

        function update() {

            vm.accomodationList = vm.accomodationList.filter(function(e) {
                return e === 0 ? '0' : e;
            });
            var clubdata = {
                clubid: vm.clubId,
                classid: vm.classesId,
                accomodationlist: vm.accomodationList.toString()
            };

            Object.assign(vm.form, clubdata);


            studentsService.updatestudent(vm.form).then(function(student) {
                vm.student = student;
                vm.form = angular.copy(vm.student);

                vm.db_class_list = [];
                vm.db_club_list = [];


                refreshStudent();
                classesData();
                toastr.success('The student ' + student.fullname + ' has been updated!');
                $state.go("student.summary", {
                    studentId: $stateParams.studentId
                }, {
                    reload: true
                });

            });
        }

        function selectFilters(selectday) {

        	var reportDataType = '';
        	if($rootScope.previousState === 'student.summary' || $rootScope.previousState === 'student.info'){
        		reportDataType = 1;
        	}else{
             reportDataType = (vm.selectedReport !== '' ? vm.selectedReport : $stateParams.type);
        	}
        	var reportedata = vm.ChangeReportData ? vm.ChangeReportData: reportDataType;
            getIncident(reportedata);
       
            if (selectday == 'today') {
                vm.selectedDateTitle = dateRanges[0].title;
                vm.selectedDateRange = dateRanges[0].value;
            }
            if (selectday == 'week') {
                vm.selectedDateTitle = dateRanges[1].title;
                vm.selectedDateRange = dateRanges[1].value;
            }
            if (selectday == 'twoWeek') {
                vm.selectedDateTitle = dateRanges[2].title;
                vm.selectedDateRange = dateRanges[2].value;
            }
            if (selectday == 'month') {
                vm.selectedDateTitle = dateRanges[3].title;
                vm.selectedDateRange = dateRanges[3].value;
            }
            if (selectday == 'year') {
                vm.selectedDateTitle = dateRanges[4].title;
                vm.selectedDateRange = dateRanges[4].value;
            }
            if (selectday == 'custom') {
                vm.selectedDateTitle = dateRanges[5].title;
                vm.selectedDateRange = dateRanges[5].value;
            }




            if (vm.selectedDateRange == 'custom' && vm.customRanges.startDate === null && vm.customRanges.endDate === null) {
                return;
            }

            //if ($stateParams.type == 1 || vm.selectedReport == 1) {
            if (reportedata == 1 ) {
            	
                studentsService.getMessagesByFilters({
                    url: '/api/students/' + student.id + '/messages/by-filters',
                    studentId: student.id,
                    range: vm.selectedDateRange,
                    custom_ranges: vm.customRanges
                }).then(function(messages) {
                    if (messages.length > 0) {
                        vm.messages = messages;
                    } else {
                    	if($rootScope.ToStateName =='student.reports'){
                        SweetAlert.success("", {
                            title: "There were no contact made during this date Range!",
                            type: 'warning'
                        });
                    	}
                    }

                });

            }

            //if ($stateParams.type == 2 || vm.selectedReport == 2) {
            if (reportedata == 2) {
                studentsService.getMessagesByFilters({
                    url: '/api/students/' + student.id + '/attendances/by-filter',
                    studentId: student.id,
                    range: vm.selectedDateRange,
                    custom_ranges: vm.customRanges
                }).then(function(attendances) {
                    if (attendances.length > 0) {
                        vm.attendances = attendances;
                    } else {
                        SweetAlert.success("", {
                            title: "No attendance recorded for attendance report!",
                            type: 'warning'
                        });
                    }

                });

            }

            //if ($stateParams.type == 3 || vm.selectedReport == 3) {
            if (reportedata == 3) {
                studentsService.getMessagesByFilters({
                    url: '/api/students/' + student.id + '/behavior/by-filter',
                    studentId: student.id,
                    range: vm.selectedDateRange,
                    custom_ranges: vm.customRanges
                }).then(function(behaviors) {
                    if (behaviors.length > 0) {
                        vm.behaviors = behaviors;
                    } else {
                        SweetAlert.success("", {
                            title: "No behavior information for behavior report!",
                            type: 'warning'
                        });
                    }
                });

            }

            //if ($stateParams.type == 4 || vm.selectedReport == 4) {
            if (reportedata == 4) {
                //paperworkNote();
                studentsService.getMessagesByFilters({
                    url: '/api/students/' + student.id + '/paperwork/by-filter',
                    studentId: student.id,
                    range: vm.selectedDateRange,
                    custom_ranges: vm.customRanges
                }).then(function(paperworks) {
                    if (paperworks.length > 0) {
                        vm.paperworks = paperworks;
                    } else {
                        SweetAlert.success("", {
                            title: "No paperwork for paperwork report",
                            type: 'warning'
                        });
                    }
                });

            }

            if (reportedata == 5) {
                studentsService.getMessagesByFilters({
                    url: '/api/students/' + student.id + '/academic/by-filter',
                    studentId: student.id,
                    range: vm.selectedDateRange,
                    custom_ranges: vm.customRanges
                }).then(function(academics) {
                    if (academics.length > 0) {
                        vm.academics = academics;
                    } else {
                        SweetAlert.success("", {
                            title: "No academic information for academic report!",
                            type: 'warning'
                        });
                    }
                });

            }

        }

        function exportReportPDF(report_type) {

            exportService.exportStudentMessages({
                studentId: student.id,
                range: vm.selectedDateRange,
                custom_ranges: vm.customRanges,
                mode: vm.selectedMode,
                type: vm.selectedType,
                category: vm.selectedCategory,
                order: vm.order,
                reportType: report_type,
            });
        }

        function behaviorTopicTitle(status, topic) {
            var topics = (status == 1) ? positivebehavIncident : negativebehavIncident;
            var data = '';
            topics.forEach(function(topicdata) {
                if (topicdata.value == topic) {
                    data = topicdata.title;
                }
            });
            return data;
        }

        function academicTopicTitle(status, topic) {
            var topics = (status == 1) ? positiveacadIncident : negativeacadIncident;
            var data = '';
            topics.forEach(function(topicdata) {
                if (topicdata.value == topic) {
                    data = topicdata.title;
                }
            });
            return data;
        }

        function setSST(value, radioid, oppsiteId) {

            if (document.getElementById(radioid).checked === false) {


                if (value == 'AIP-SST') {
                    var bip = vm.accomodationList.indexOf('BIP-SST');
                    if (bip > -1) {
                        vm.accomodationList.splice(bip, 1);
                        var aip = vm.accomodationList.indexOf('AIP-SST');
                        if (aip > -1) {
                            vm.accomodationList.splice(aip, 1);

                        } else {
                            vm.accomodationList.push('AIP-SST');
                        }
                    }
                }
                if (value == 'BIP-SST') {
                    var ai_p = vm.accomodationList.indexOf('AIP-SST');
                    if (ai_p > -1) {
                        vm.accomodationList.splice(ai_p, 1);
                        var bi_p = vm.accomodationList.indexOf('BIP-SST');
                        if (bi_p > -1) {
                            vm.accomodationList.splice(bi_p, 1);

                        } else {
                            vm.accomodationList.push('BIP-SST');
                        }
                    }
                }

                return false;
            } else {
                var box = confirm("Are sure that you wants to edit the accommodations?");
                if (box === true) { // yes sure
                    if (value == 'AIP-SST') {
                        var bips = vm.accomodationList.indexOf('BIP-SST', radioid);
                        if (bips > -1) {
                            vm.accomodationList.splice(bips, 1);
                            var aips = vm.accomodationList.indexOf('AIP-SST');
                            if (aips > -1) {
                                vm.accomodationList.splice(aips, 1);

                            } else {
                                vm.accomodationList.push('AIP-SST');
                            }
                        }
                    }
                    if (value == 'BIP-SST') {
                        var ai_ps = vm.accomodationList.indexOf('AIP-SST');
                        if (ai_ps > -1) {
                            vm.accomodationList.splice(ai_ps, 1);
                            var bi_ps = vm.accomodationList.indexOf('BIP-SST');
                            if (bi_ps > -1) {
                                vm.accomodationList.splice(bi_ps, 1);

                            } else {
                                vm.accomodationList.push('BIP-SST');
                            }
                        }
                    }

                    return true;
                } else { // cancel
                    document.getElementById(oppsiteId).checked = true;
                }
            }

        }

        function isInArray(value, accomodations, checkId) {

            var idx = accomodations.indexOf(value);

            if (document.getElementById(checkId).checked === true) {
                if (document.getElementById('check3').checked === true) {
                    vm.SSTcheck = true;
                }
                if (idx > -1) {
                    accomodations.splice(idx, 1);
                } else {
                    accomodations.push(value);
                }
                return false;

            } else {
                var box = confirm("Are sure that you wants to edit the accommodations?");
                if (box === true) { // yes sure

                    if (idx > -1) {
                        accomodations.splice(idx, 1);
                    } else {
                        accomodations.push(value);
                    }

                    if (document.getElementById('check3').checked === false) {
                        vm.SSTcheck = false;
                        var aipSST = accomodations.indexOf('AIP-SST');
                        var bipSST = accomodations.indexOf('BIP-SST');
                        if (aipSST > -1) {
                            accomodations.splice(aipSST, 1);
                        }

                        if (bipSST > -1) {
                            accomodations.splice(bipSST, 1);
                        }
                    }



                    return true;
                } else { // cancel
                    document.getElementById(checkId).checked = true;
                    if (value === 'AIP-SST' || value === 'BIP-SST') {
                        vm.SSTcheck = true;
                    }
                }
            }



        }

        function paperworkNote() {

            paperworksService.getNote('/api/paperwork/note?studentId=' + $stateParams.studentId).then(function(response) {
                vm.note.push(response);

            });

        }

        function getNotes(noteid) {
            var data = '';
            angular.forEach(vm.note, function(notes) {

                if (notes.id == noteid) {

                    data = notes.note;
                }

            });

            return data;
        }

    }
})();