(function () {
  'use strict';

  angular
    .module('app.attendance-analytics.teacher-details')
    .controller('AttendanceTeacherDetailsController', AttendanceTeacherDetailsController);

  /* @ngInject */
  function AttendanceTeacherDetailsController($scope,periodTeachers, periods,attendanceType, teachers, modes, categories, types, attendanceAnalyticsService) {
    /* jshint validthis: true */
    console.log(attendanceType);
    var vm = this;
    vm.periods = periods;
    vm.attendanceTypes = attendanceType;
    vm.periodTeachers = periodTeachers;
    vm.teachers = teachers;
    vm.modes = modes;
    vm.categories = categories;
    vm.cannedTypes = types;
    vm.options = {
        showLines: true,
        legend: {display: false},
      responsive: true,
      maintainAspectRatio: false,
        tooltips: {
            mode: 'label',
            callbacks: {
                label: function(tooltipItem, data) {
                    return  data.datasets[0].label +' : '+ data.datasets[0].data[tooltipItem.index] + '%';
                }
            }
        },
      scales: {
        xAxes: [{
          stacked: true,
          autoSkip: true,
          autoSkipPadding: 100,
        }],
        yAxes: [{
          stacked: true,
            scaleLabel: {
                display: true,
                // labelString: ' % of students',
            },
          ticks: {
              min: 0, max: 100 ,
              beginAtZero:true,
            callback: (value) => {
              if (value % 1 === 0) {
                return value+ ' %';
              }
            }
          }
        }]
      }
    };
      vm.optionLine = {
          responsive: true,
          showLines: true,
          elements: {
              line: {
                  fill: false
              }
          },
          maintainAspectRatio: false,
          tooltips: {
              mode: 'label',
              callbacks: {
                  label: function(tooltipItem, data) {
                      return  data.datasets[0].label +' : '+ data.datasets[0].data[tooltipItem.index] + '%';
                  }
              }
          },
          scales: {
              xAxes: [{
                  stacked: true,
                  autoSkip: true,
                  autoSkipPadding: 100,
              }],
              yAxes: [{
                  stacked: true,
                  ticks: {
                      min: 0, max: 100 ,
                      beginAtZero:true,
                      callback: (value) => {
                          if (value % 1 === 0) {
                              return value + ' %';
                          }
                      }
                  }
              }]
          }
      };

    vm.filters = {};
    Object.defineProperties(vm.filters, {
      _teacher: {
        enumerable: false,
        value: vm.teachers[0],
        writable: true,
      },
      teacher_id: {
        enumerable: true,
        get: function () {
          if (this._teacher) return this._teacher.id;
        },
      },
      _period: {
        value: vm.periods[3],
        writable: true,
        enumerable: false,
      },
      period_slug: {
        enumerable: true,
        get: function () {
          return this._period.slug;
        },
      },
      period_start: {
        enumerable: true,
        get: function () {
          return this._period.start.format('D-M-Y');
        },
      },
      period_end: {
        enumerable: true,
        get: function () {
          return this._period.end.format('D-M-Y');
        },
      },
      _period_table: {
        value: vm.periods[0],
        writable: true,
        enumerable: false,
      },
      period_table_start: {
        enumerable: true,
        get: function () {
          return this._period_table.start.format('D-M-Y');
        },
      },
      period_table_end: {
        enumerable: true,
        get: function () {
          return this._period_table.end.format('D-M-Y');
        },
      },
      _mode: {
        value: vm.modes[0],
        writable: true,
        enumerable: false,
      },
      mode: {
        enumerable: true,
        get: function () {
          return this._mode.value;
        }
      },
        _attendanceType: {
        value: vm.attendanceTypes[0],
        writable: true,
        enumerable: false,
      },
        attendanceType: {
        enumerable: true,
        get: function () {
          return this._attendanceType.id;
        }
      },
        _periodTeacher: {
        value: vm.periodTeachers[0],
        writable: true,
        enumerable: false,
      },
        periodTeacher: {
        enumerable: true,
        get: function () {
            return this._periodTeacher.id;
        }
      },
      _canned_type: {
        value: vm.cannedTypes[0],
        writable: true,
        enumerable: false,
      },
      canned_type_slug: {
        enumerable: true,
        get: function () {
          return this._canned_type.slug;
        }
      },
      _category: {
        value: vm.categories[0],
        writable: true,
        enumerable: false,
      },
      category_slug: {
        enumerable: true,
        get: function () {
          return this._category.slug;
        }
      },
      page: {
        value: 1,
        writable: true,
        enumerable: true,
      }
    });

    vm.charts = {
      byPeriod: {
        data: [],
        labels: [],
        series: ['A'],
          color:[],
      },
        byPresent: {
            data: [],
            labels: [],
            series: ['A'],
            color:[],
        },
      byCategories: {
        data: [0, 0, 0, 0],
        labels: ['Academic', 'General', 'Attendance', 'Behavior']
      },
      byType: {
        data: [0, 0],
        labels: ["Negative", "Positive",],
      }
    };

    vm.items = {};
    vm.request = false;

    vm.getStatistics = getStatistics;
    vm.changePage = changePage;

    $scope.$watchCollection('AttendanceTeacherDetailsCtrl.filters', function () {
        getStatistics();
    });

    /////////////////
     /* function getPeriodTeacher() {
          vm.request = true;

          attendanceAnalyticsService.periodTeacher(vm.filters).then(function (response) {
              vm.periodTeachers = response;
              alert('lol');
              console.log(vm.periodTeachers);
          }).finally(function () {
              vm.request = false;
          });
      }*/

    function getStatistics() {
      vm.request = true;

      attendanceAnalyticsService.teacherDetails(vm.filters).then(function (response) {
        vm.items = response.items;
        vm.byType = response.byType;
        vm.byCategory = response.byCategory;
        vm.period = response.byPeriod;
        vm.period.color = response.byPeriod.color;
        vm.byPresent = response.byPresent;
        vm.byPresent.color = response.byPresent.color;
        vm.total = response.total;
        vm.total_type = response.total_type;

        recalculateCharts();
      }).finally(function () {
        vm.request = false;
      });
    }

    function recalculateCharts() {
      vm.charts.byType.data = [
        vm.byType.positive / vm.total,
        vm.byType.negative / vm.total
      ];

      vm.charts.byCategories.data = [
        vm.byCategory.academic / vm.total,
        vm.byCategory.general / vm.total,
        vm.byCategory.attendance / vm.total,
        vm.byCategory.behavior / vm.total
      ];

      vm.charts.byPeriod = vm.period;
      vm.charts.byPresent = vm.byPresent;
    }

    function changePage(number) {
      vm.filters.page = number;

      getStatistics();



    }
  }
})();