(function () {
  'use strict';

  angular
    .module('app')
    .service('schoolStudentsService', schoolStudentsService);

  /* @ngInject */
  function schoolStudentsService($resource) {
    /* jshint validthis: true */
    var vm = this;

    var Student = $resource('/api/school-students');

    vm.all = all;

    //////////////////

    function all() {
      return Student.query().$promise;
    }
  }
})();