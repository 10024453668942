(function () {
    'use strict';

    angular
        .module('app.response-to-intervention-analytics.quickview')
        .controller('RtiQuickviewController', RtiQuickviewController);

  /* @ngInject */
    function RtiQuickviewController($scope, teacherTypes, grades, periods, responseToInterventionAnalyticsService) {
      /* jshint validthis: true */
        let vm = this;

        vm.periods = periods;
        vm.total = 0;
        vm.options = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    stacked: false,
                    autoSkip: true,
                    autoSkipPadding: 100,
                }],
                yAxes: [{
                    stacked: false,
                    ticks: {
                        beginAtZero:true,
                        callback: (value) => {
                            if (value % 1 === 0) {
                                return value;
                            }
                        }
                    }
                }]
            }
        };

        vm.types = [
            {
                text: 'Subject',
                values: teacherTypes,
                property: 'subject',
            },
            {
                text: 'Grade',
                values: grades,
                property: 'grade',
            }
        ];

        vm.filters = {};
        Object.defineProperties(vm.filters, {
            _type: {
                value: vm.types[0],
                writable: true,
                enumerable: false,
            },
            _item: {
                enumerable: false,
                get: function () {
                    if (this.type === 'subject') return this._type.values.filter((item) => {
                        return item.id === this._teacher_type_id;
                    })[0];
                    if (this.type === 'grade') return this._type.values.filter((item) => {
                        return item.id === this._grade_id;
                    })[0];
                }
            },
            type: {
                enumerable: true,
                get: function () {
                    return this._type.property;
                }
            },
            _teacher_type_id: {
                enumerable: false,
                value: undefined,
                writable: true,
            },
            teacher_type_id: {
                enumerable: true,
                get: function () {
                    if (this.type === 'subject') return this._teacher_type_id;
                },
            },
            _grade_id: {
                enumerable: false,
                value: undefined,
                writable: true,
            },
            grade_id: {
                enumerable: true,
                get: function () {
                    if (this.type === 'grade') return this._grade_id;
                },
            },
            _period: {
                value: vm.periods[0],
                writable: true,
                enumerable: false,
            },
            period_start: {
                enumerable: true,
                get: function () {
                    return this._period.start.format('D-M-Y');
                },
            },
            period_end: {
                enumerable: true,
                get: function () {
                    return this._period.end.format('D-M-Y');
                },
            },
            period_slug: {
                enumerable: true,
                get: function () {
                    return this._period.slug;
                },
            },
        });

        vm.charts = {
            byPeriod: {
                data: [],
                labels: [],
                series: [],
            },
            byCategories: {
                data: [],
                labels: []
            },
            byType: {
                data: [],
                labels: [],
            }
        };

        vm.getStatistics = getStatistics;

        $scope.$watchCollection('RtiQuickviewCtrl.filters', function () {
            getStatistics();
        });

        /////////////////

        function getStatistics() {
            responseToInterventionAnalyticsService.quickview(vm.filters).then(function (response) {
                vm.charts.byCategories.data = response.byCategory.map((item) => {
                    return item.count;
                });
                vm.charts.byCategories.labels = response.byCategory.map((item) => {
                    return item.name;
                });

                vm.charts.byType.data = response.byType.map((item) => {
                    return item.count;
                });
                vm.charts.byType.labels = response.byType.map((item) => {
                    return item.name;
                });

                vm.charts.byPeriod.labels = response.byPeriods.labels;
                vm.charts.byPeriod.series = response.byPeriods.series;
                vm.charts.byPeriod.data = response.byPeriods.data;

                vm.total = response.total;
            });
        }
    }
})();