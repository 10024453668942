(function () {
  'use strict';

  angular
    .module('app.login')
    .controller('ResetPasswordController', ResetPasswordController);

  /* @ngInject */
  function ResetPasswordController(UserSettingService,toastr,$state,$stateParams) {
    /* jshint validthis: true */
    var vm = this;

    vm.ResetPassword=ResetPassword;
   
    vm.passwordform={
      UserId:undefined,
      NewPassword: undefined,
      ConfirmPassword: undefined,
    };

    function ResetPassword()
    {
          vm.flag=1;

          vm.passwordform.UserId=$stateParams.uid;
          
          if(vm.passwordform.UserId!==undefined || vm.passwordform.UserId!=="")
          {

                if(vm.passwordform.NewPassword==="" || vm.passwordform.NewPassword===undefined)
                {
                  vm.flag=0;
                  toastr.error('New password required');
                }
                else if(vm.passwordform.ConfirmPassword==="" || vm.passwordform.ConfirmPassword===undefined)
                {
                  vm.flag=0;
                  toastr.error('Confirm password required');
                }
                else if(vm.passwordform.NewPassword!==vm.passwordform.ConfirmPassword)
                {
                  vm.flag=0;
                  toastr.error('New password and confirm password is not matching');
                }

                if(vm.flag===1) 
                {
                    UserSettingService.resetPassword('api/user/resetpassword', vm.passwordform).then(function(data)
                    {
                        if(data.error===0)
                        {
                             if(data.response===1)
                             {
                               toastr.success('Password successfully updated');
                               $state.go('login');
                             }

                        }
                        else
                        {
                            toastr.error(data.errortext);
                        }

                    });

                } 

          }
          else
          {
            vm.flag=0;
            toastr.error('Invalid operation');
          }

          

        
    }

      
    
  }
})();