(function () {
  'use strict';

  angular
    .module('app.core', [
      'ngResource',
      'ngAnimate',
      'ngMaterial',
      'ngMessages',
      'ui.router',
      'ui.bootstrap',
      'vAccordion',
      'md.data.table',
      'satellizer',
      'ui.select',
      'toastr',
      'angularUtils.directives.dirPagination',
      'daterangepicker',
      'AngularPrint',
      'ngTablescroll',
      'ui.bootstrap.datetimepicker',
      'summernote',
      'angucomplete-alt',
      'ng-sweet-alert',
      'chart.js',
      'permission',
      'permission.ui',
      'ngIntlTelInput'
    ]);
})();