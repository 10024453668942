(function () {
  'use strict';

  angular
    .module('app.core')
    .service('paperworksService', paperworksService);

  function paperworksService( $http) {
    /* jshint validthis: true */
    var vm = this;

    vm.get = get;
    vm.getstudentAttendanceCount = getstudentAttendanceCount;
    vm.getreferralstudentYear = getreferralstudentYear;
    vm.getstudentgrade = getstudentgrade;
    vm.saveActivity = saveActivity;
    vm.getActivity = getActivity;
    vm.getOptions = getOptions;
    vm.getipData = getipData;
    vm.saveipForm = saveipForm;
    vm.saveipConcern = saveipConcern;
    vm.saveipIntervention = saveipIntervention;
    vm.saveipMonitoring = saveipMonitoring;
    vm.saveipMonitoringResult = saveipMonitoringResult;
    vm.updateipMonitoring = updateipMonitoring;
    vm.deleteipMonitoring = deleteipMonitoring;
    vm.resetip = resetip;
    vm.getpaperworkAipIntervention = getpaperworkAipIntervention;
    vm.sendtoAdmin = sendtoAdmin;
    vm.getReferralformActivity = getReferralformActivity;
    vm.deleteReferralform = deleteReferralform;
    vm.updateReferralform = updateReferralform;
    vm.getpaperwork = getpaperwork;
    vm.getNote = getNote;
    
    function get(studentId){
    	return $http.get('/api/students/'+ studentId +'/paperwork').then(fetchData);
    	
    }
    
    function fetchData(response){
    	return response.data;
    	
    }
    
    function getstudentAttendanceCount(url ,studentid){
    	
    	return $http.get(url +'?studentid='+ studentid).then(fetchData);
    }

    function getreferralstudentYear(url ,studentid){
    	return $http.get(url +'?studentid='+ studentid).then(fetchData);
    }	
    
    function getReferralformActivity(url){
    	return $http.get(url).then(fetchData);
    	
    }
    
    function deleteReferralform(url, form){
    	return $http.post(url,form).then(fetchData);
    }
    
    function updateReferralform(url, form){
    	return $http.post(url, form).then(fetchData);
    }
    
    
    function getstudentgrade(url ,studentid){
    	return $http.get(url +'?studentid='+ studentid).then(fetchData);
    }
    
    function saveActivity(url, data){
    	return $http.post(url, data).then(fetchData);
    }
    
    function getActivity(url){
    	return $http.get(url).then(fetchData);
    }
    
    function getOptions(url){    	
    	return $http.get(url).then(fetchData);
    }
    
    function getipData(url){    	
    	return $http.get(url).then(fetchData);
    }
    
    function saveipForm(url ,data){
    	return $http.post(url, data).then(fetchData);
    }
    function saveipConcern(url ,data){
    	return $http.post(url, data).then(fetchData);
    }
    function saveipIntervention(url ,data){
    	return $http.post(url, data).then(fetchData);
    }
    function saveipMonitoring(url, data){
    	return $http.post(url, data).then(fetchData);
    }
    function saveipMonitoringResult(url ,data){
    	return $http.post(url, data).then(fetchData);
    }
    function updateipMonitoring(url ,data)
    {
        return $http.post(url, data).then(fetchData);
    }
    
    function deleteipMonitoring(url ,data)
    {
        return $http.post(url ,data).then(fetchData);
    }
    
    function resetip(url ,data)
    {
        return $http.post(url, data).then(fetchData);
    }

    function getpaperworkAipIntervention(url){
        return $http.get(url).then(fetchData);
    }
    
    function sendtoAdmin(data){
    	return $http.post('/api/referrals/sendreport', data).then(fetchData);
    }
    
    function getpaperwork(url){
        return $http.get(url).then(fetchData);
       // return $http.get(range.url, {params: range}).then(fetchData);
       
    }  
    
    function getNote(url){
    	return $http.get(url).then(fetchData);
    }
  }
})();