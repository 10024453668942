(function () {
  'use strict';

  angular
    .module('app.response-to-intervention-analytics.teacher-comparisons')
    .controller('RtiTeacherComparisonsController', RtiTeacherComparisonsController);

  /* @ngInject */
  function RtiTeacherComparisonsController($scope, $filter, responseToInterventionAnalyticsService, periods, teacherTypes, grades) {
    /* jshint validthis: true */
    var vm = this;

    vm.periods = periods;
    vm.teacherTypes = teacherTypes;
    vm.currentPage = 1;
    vm.options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero:true,
            callback: (value) => {
              if (value % 1 === 0) {
                return value;
              }
            }
          }
        }],
        yAxes: [{
          stacked: true
        }]
      }
    };
    vm.optionsWithoutStacked = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          ticks: {
            beginAtZero:true,
            callback: (value) => {
              if (value % 1 === 0) {
                return value;
              }
            }
          }
        }]
      }
    };

    vm.types = [
      {
        text: 'Subject',
        values: teacherTypes,
        property: 'subject',
      },
      // {
      //   text: 'Grade',
      //   values: grades,
      //   property: 'grade',
      // }
    ];

    vm.filters = {};
    Object.defineProperties(vm.filters, {
      _type: {
        value: vm.types[0],
        writable: true,
        enumerable: false,
      },
      type: {
        enumerable: true,
        get: function () {
          return this._type.property;
        }
      },
      _teacher_type_id: {
        enumerable: false,
        value: undefined,
        writable: true,
      },
      _teacher_type: {
        enumerable: false,
        get: function () {
          if (this.type === 'subject') return $filter('filter')(this._type.values, {id: this._teacher_type_id})[0];
        },
      },
      teacher_type_id: {
        enumerable: true,
        get: function () {
          if (this.type === 'subject') return this._teacher_type_id;
        },
      },
      _grade_id: {
        enumerable: false,
        value: undefined,
        writable: true,
      },
      _grade: {
        enumerable: false,
        get: function () {
          if (this.type === 'grade') return $filter('filter')(this._type.values, {id: this._grade_id})[0];
        },
      },
      grade_id: {
        enumerable: true,
        get: function () {
          if (this.type === 'grade') return this._grade_id;
        },
      },
      _period: {
        value: vm.periods[3],
        writable: true,
        enumerable: false,
      },
      period_start: {
        enumerable: true,
        get: function () {
          return this._period.start.format('D-M-Y');
        },
      },
      period_end: {
        enumerable: true,
        get: function () {
          return this._period.end.format('D-M-Y');
        },
      },
    });

    vm.charts = {
      totalCommunication: {
        data: [],
        labels: [],
        series: [],
      },
      totalPosNeg: {
        data: [],
        labels: [],
        series: [],
      },
    };

    vm.getStatistics = getStatistics;
    vm.percentMessages = percentMessages;

    $scope.$watchCollection('RtiTeacherComparisonsCtrl.filters', function () {
      getStatistics();
    });

    /////////////////

    function getStatistics() {

      responseToInterventionAnalyticsService.teacherComparisons(vm.filters).then(function (response) {
        vm.charts.totalPosNeg.data = response.byReason.data;
        vm.charts.totalPosNeg.labels = response.byReason.labels;
        vm.charts.totalPosNeg.series = response.byReason.series;

        vm.charts.totalCommunication.labels = response.byCategory.labels;
        vm.charts.totalCommunication.series = response.byCategory.series;
        vm.charts.totalCommunication.data = response.byCategory.data;

        vm.teachers = response.teachers;

        document.getElementById("communication").style.maxHeight = response.byReason.labels.length * 25 + 150 + 'px';
        document.getElementById("positive-negative").style.maxHeight = response.byCategory.labels.length * 25 + 150 + 'px';
      });
    }

    function percentMessages(messagesByType, total) {
      if(!(messagesByType && total)) {
        return 0;
      }

      return messagesByType / total * 100;
    }
  }
})();