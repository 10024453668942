(function() {
    'use strict';
    angular
        .module('app.classes')
        .controller('TakeattendanceController', TakeattendanceController);

    function TakeattendanceController($scope, $stateParams, $rootScope, helperService, $uibModal, attendancesService, 
        $filter, $timeout, $state, toastr, cannedMessagesService, ClassesService) {
        var vm = this;

        cannedMessagesService.getmeessageandData().then(function(data) {

            setCannedData(data[0], data[1], data[2]);

        });

        vm.students = [];
        vm.attendance = [];
        vm.showAlert = false;
        vm.period = '';
        vm.period_text = '';
        vm.orderBy = 'last_name';
        vm.present = 0;
        vm.absent = 0;
        vm.tardy = 0;
        vm.skipped = 0;
        vm.isAllSelected = false;
        vm.db_class_list = [];
        vm.db_club_list = [];

        vm.takeAttendance = takeAttendance;
        vm.sendBlast = sendBlast;
        vm.toggle_AllSelected = toggle_AllSelected;
        vm.optionToggled = optionToggled;
        vm.changeState = changeState;
        vm.classId = 0;

        $rootScope.Isfromattendance = $stateParams.IsfromAtendance;
        vm.students = [];

        if ($stateParams.id !== null && $stateParams.id > 0) {
            vm.classId = $stateParams.id;
            $rootScope.$broadcast('class_id', $stateParams.id);
        }

        loadData();


        function loadData() {
            ClassesService.getclassesData('api/classes?class_id=' + vm.classId).then(function(data) {
                if (data.class_period !== null) {
                    angular.forEach(data.class_period, function(class_period) {
                        if (class_period.group_type === '1') {
                            vm.db_class_list.push({
                                id: class_period.id,
                                period: class_period.period,
                                period_letter: class_period.period_letter
                            });
                            if (class_period.id == vm.classId) {
                                vm.period = (String)(class_period.id);
                                vm.period_text = class_period.period + '-' + class_period.period_letter;
                            }
                        }else{
                        	vm.db_club_list.push({
                                id: class_period.id,
                                name: class_period.name
                            });
                        	 if (class_period.id == vm.classId) {
                                 vm.period = (String)(class_period.id);
                                 vm.period_text = class_period.name;
                             }
                        }

                    });
                    attendancesService.getCurrent(vm.classId).then(function(response) {
                        vm.attendance = response.attendance;
                        vm.showAlert = response.taken;
                        vm.attendanceUpdated = response.taken_on;
                        angular.forEach(response.students, function(student) {
                            var comment = response.attendance[student.id] !== undefined ? vm.attendance[student.id].note : '';
                            var selected = response.attendance[student.id] !== undefined ? (String)(vm.attendance[student.id].type) : '1';
                            vm.students.push({
                                id: student.id,
                                fullname: student.fullname,
                                Sid:student.studentid,
                                first_name: student.first_name,
                                last_name: student.last_name,
                                comment: comment,
                                selected: selected
                            });
                        });

                        angular.forEach(vm.attendance, function(value, key) {
                            if (value.type == 1)
                                vm.present++;
                            else if (value.type == 2)
                                vm.absent++;
                            else if (value.type == 3)
                                vm.tardy++;
                            else if (value.type == 4)
                                vm.skipped++;
                        });
                    });
                }
            });
        }

        function takeAttendance() {
            var checkedStudents = [];
            angular.forEach(vm.students, function(value, key) {
                if (value.selected)
                    checkedStudents.push(value);
            });
            if (checkedStudents.length) {
                vm.present = 0;
                vm.absent = 0;
                vm.tardy = 0;
                vm.skipped = 0;
                var attendanceData = {
                    'students': checkedStudents,
                    'period': vm.period
                };
                attendancesService.submitAttendance(attendanceData).then(function(response) {
                    vm.showAlert = response.taken;
                    vm.attendanceUpdated = response.taken_on;
                    vm.attendance = response.attendance;
                    angular.forEach(vm.attendance, function(value, key) {
                        if (value.type == 1)
                            vm.present++;
                        else if (value.type == 2)
                            vm.absent++;
                        else if (value.type == 3)
                            vm.tardy++;
                        else if (value.type == 4)
                            vm.skipped++;
                    });
                    helperService.showAlert('Congratulations, you have successfully updated student attendance.');
                });
            } else {
                toastr.error('Select student to submit attendance');
            }
        }

        vm.changePeriod = function() {
        	var checkIsclub = $("#attendance_period option:selected").text();
        	var clubString = 'Club';
        	
        	$rootScope.isClubAttendance = false;
        	if(checkIsclub.indexOf(clubString) !== -1){
        		
        		$rootScope.isClubAttendance = true;
        	}
        	
            $state.go('classes.attendance', {id: vm.period});
        };




        function sendBlast() {
            var studentsBlastSend = $filter('filter')(vm.students, {
                selected1: true
            });
            if (studentsBlastSend.length === 0) {
                return;
            }

            $uibModal.open({
                templateUrl: '/app/partials/address-book/communication/selection-type-blast.html',
                controller: 'SelectionTypeBlastController',
                controllerAs: 'SelectionTypeBlastCtrl'
            }).result.then(function(mode) {

                communicationMassEmailModal(studentsBlastSend, mode).then(function(data) {
                    var sendCount = (data.to === 3) ? 2 : 1;
                    vm.isAllSelected = false;

                    helperService.showAlert('Blast has been sent.');
                    angular.forEach(studentsBlastSend, function(student) {
                        student.selected1 = '';

                    });

                });
            });
        }

        function setCannedData(cannedTypes, cannedCategories, callStatuses) {
            vm.cannedTypes = cannedTypes;
            vm.cannedCategories = cannedCategories;
            vm.callStatuses = callStatuses;
        }


        function communicationMassEmailModal(students, type) {
            var partial;

            switch (type) {
                case 1:
                    partial = 'message-blast';
                    break;
                case 3:
                    partial = 'email-blast';
                    break;
            }


            return $uibModal.open({
                templateUrl: '/app/partials/address-book/communication/' + partial + '.html',
                controller: 'MessageBlastController',
                controllerAs: 'MessageBlastCtrl',
                size: 'lg',
                resolve: {
                    type: function() {
                        return type;
                    },
                    students: function() {
                        return students;
                    },
                    cannedTypes: function() {
                        return vm.cannedTypes;
                    },
                    cannedCategories: function() {
                        return vm.cannedCategories;
                    }
                }
            }).result;
        }

        function toggle_AllSelected() {
            angular.forEach(vm.students, function(student) {
                student.selected1 = vm.isAllSelected;
            });
        }

        function optionToggled() {
            vm.isAllSelected = vm.students.every(function(student) {
                return student.selected1;
            });
        }

        function changeState(id) {
            $timeout(function() {
                $state.go("student.summary", {
                    studentId: id
                });
            });

        }

        vm.check_attendance = function(type) {
            angular.forEach(vm.students, function(student) {
                student.selected = (String)(type);
            });
        };

    }


})();