(function() {
    'use strict';

    angular
        .module('app.paperworks')
        .controller('StudentformController', StudentformController);

    function StudentformController($scope, dateRanges, periods, paperworksService, $state, $location, $window, $rootScope, exportService, SweetAlert) {
        var vm = this;
        /*  assign properties*/
        vm.dateRanges = dateRanges;

        vm.selecteddateRange = 'year';
        vm.selecteddateRangeTitle = 'This School Year';
        vm.periods = periods;
        vm.selectedperiod = '';
        vm.selectedPeriodTitle = 'All';
        vm.customRanges = {
            startDate: null,
            endDate: null
        };
        vm.form_submitted = [];

        vm.template = ($state.params.sid) ? 3 : 0;



        vm.student_id = ($state.params.sid) ? $state.params.sid : 0;
        vm.template_name = 'All Templates';
        vm.order = '-form_data.updated_at';
        vm.order_last_date = 'updated_at';
        vm.deleteInprogressPapaerwork = deleteInprogressPapaerwork;


        //Methods
        vm.viewforms = viewforms;
        vm.setTempalte = setTempalte;
        vm.afterselected = afterselected;
        //  vm.selectedRange = selectedRange;
        vm.getData = getData;


        $scope.$watch('StudentformCtrl.customRanges', getData, false);

        function afterselected(selected) {
            if (selected) {
                vm.student_id = selected.originalObject.id;
                getData();
            }
        }

        function viewforms(row) {
            if (row.form_status === 'Completed') {

                if (row.form_type === 'RTI') {
                    exportService.exportRtiform({
                        student_id: row.student_id,
                        form_type: row.form_data.rti_type,
                        reason: row.form_data.reason,
                        teacherId: row.form_data.added_by,
                        url: '/api/referrals/getrtidownload',
                        type: 'intervention'
                    });
                } else if (row.form_type === 'Referral') {

                    exportService.exportRtiform({
                        formId: row.form_data.id,
                        student_id: row.student_id,
                        form_type: row.form_data.referral_type,
                        reason: row.form_name,
                        teacherId: row.form_data.teacher_id,
                        url: '/api/referrals/getrtidownload',
                        type: 'referral'
                    });
                } else if (row.form_type === 'Letter') {

                    exportService.exportRtiform({
                        formId: row.form_data.id,
                        student_id: row.student_id,
                        form_type: row.form_data.letter_type,
                        reason: '',
                        teacherId: row.form_data.added_by,
                        url: '/api/paperwork/letter/pdfdownload',
                        type: ''
                    });
                } else if (row.form_type === 'general') {

                    exportService.exportRtiform({
                        formId: row.form_data.id,
                        student_id: row.student_id,
                        form_type: row.form_data.letter_type,
                        reason: '',
                        teacherId: row.form_data.added_by,
                        url: '/api/paperwork/letter/pdfdownload',
                        type: ''
                    });

                }

            } else {
                if (row.form_type === 'RTI') {
                    if (row.form_data.rti_type === 'AIP') {
                        $state.go("academicIntervention", {
                            studentId: row.student_id,
                            reason: row.form_data.reason
                        });
                    } else if (row.form_data.rti_type === 'AIP-SST') {
                        $state.go("academicIntervention-sst", {
                            studentId: row.student_id,
                            reason: row.form_data.reason
                        });
                    } else if (row.form_data.rti_type === 'BIP') {
                        $state.go("behaviorIntervention", {
                            studentId: row.student_id,
                            reason: row.form_data.reason
                        });
                    } else if (row.form_data.rti_type === 'BIP-SST') {
                        $state.go("behaviorIntervention-sst", {
                            studentId: row.student_id,
                            reason: row.form_data.reason
                        });
                    }
                } else if (row.form_type === 'Referral') {
                	
                    $rootScope.referralurl = row.form_name;
                    $state.go('paperworkforms.referralform', {
                        formtype: row.form_data.referral_type,
                        type: 'edit',
                        studentId: row.student_id,
                        formId: row.form_data.id
                    });
                } else if (row.form_type === 'Letter') {
                    $rootScope.referralurl = row.form_name;
                    $state.go('paperworkforms.letterform', {
                        formtype: row.form_data.letter_type,
                        type: 'edit',
                        studentId: row.student_id,
                        formId: row.form_data.id
                    });


                } else if (row.form_type === 'general') {
                    $rootScope.referralurl = row.form_name;
                    $state.go('paperworkforms.letterform', {
                        formtype: row.form_data.letter_type,
                        type: 'edit',
                        studentId: row.student_id,
                        formId: row.form_data.id
                    });
                }
            }
        }

        function setTempalte(template, template_name) {
            vm.template = template;
            vm.template_name = template_name;
            getData();
        }

        function getData() {


            if (vm.selecteddateRange == 'custom' && vm.customRanges.startDate === null && vm.customRanges.endDate === null) {

                return;
            }

            paperworksService.getpaperwork('/api/paperwork?template=' + vm.template + '&student_id=' + vm.student_id + '&range=' + vm.selecteddateRange + '&customRanges=' + vm.customRanges.startDate).then(function(data) {
                if (data)

                    vm.form_submitted = data;

            });
        }


        function deleteInprogressPapaerwork(data){
        	var deletedData = vm.form_submitted.indexOf(data);
        	var formVariable = {};
        	 SweetAlert.confirm("Are you sure?", {
                 title: "Delete this Form!",
                 confirmButtonText: "Yes, I am sure",
             }).then(function(isConfirm){
            	 if(isConfirm){
            		 
                 	if(data.form_type === 'Letter' || data.form_type === 'general'){
              		  formVariable = {
              	                letter_type: data.form_data.letter_type,
              	                formId: data.form_data.id,
              	                studentId:data.student_id
              	            };
              		 paperworksService.saveActivity('/api/paperwork/letter/reset', formVariable).then(function(response) {
              			vm.form_submitted.splice(deletedData, 1);
              		 });
              	}else if(data.form_type === 'Referral'){
              		formVariable = {
          	                form_id: data.form_data.id,
          	            };
              		 paperworksService.deleteReferralform('/api/referralform/deleted', formVariable).then(function(response) {                         
              			vm.form_submitted.splice(deletedData, 1);
                     });
              		}
            		 
            	 }
             });
        	
        }


    }

})();