(function() {
    'use strict';

    angular
        .module('app.reports')
        .controller('Communi_cationController', Communi_cationController);
    /* @ngInject */
    function Communi_cationController($scope, dateRanges, students, helperService, ClassesService, cannedMessagesService, $rootScope, exportService, studentsService, periods, $location, $anchorScroll, $state, communication, $timeout) {

        var vm = this;
        cannedMessagesService.getmeessageandData().then(function(data) {

            helperService.setCannedData(data[0], data[1], data[2]);

        });

        
        helperService.getAdmindata($rootScope.admins);
        helperService.getVesteddata($rootScope.vestedMembers);
        vm.students = [];
        vm.periods = periods;
        vm.selectedDateRange = dateRanges[4].value;
        vm.selectedDateTitle = dateRanges[4].title;
        vm.selectedPeriod = periods[0].value;
        vm.dateRanges = dateRanges;
        vm.searchtext = '';
        vm.customRanges = {
            startDate: null,
            endDate: null
        };

        vm.swReferral = swReferral;
        vm.generalReferal = generalReferal;
        vm.sstReferal = sstReferal;
        vm.sendMessage = sendMessage;
        vm.makeCall = makeCall;
        vm.sendEmail = sendEmail;
        vm.exportPDF = exportPDF;
        vm.selectRange = selectRange;
        vm.changeState = changeState;
        vm.nodata = false;
        vm.class_period = 0;
        vm.db_class_list = [];

        vm.db_club_list = [];
        vm.backdrop = '';
        
        $rootScope.$on('setBackdrop', function (event, data) {
        	vm.backdrop = data;

         });

        //After set customRanges sent request to server for getting data about students


        $scope.$watch('CommunicationCtrl.customRanges', selectRange, false);

        vm.order = 'last_name';


        ClassesService.getUserCreatedPeriod('/api/classes/classperiod').then(function(data) {

            if (data !== null) {

                angular.forEach(data, function(class_period) {
                    if (class_period.group_type === '1')
                        vm.db_class_list.push({
                            id: class_period.id,
                            period: class_period.period,
                            period_letter: class_period.period_letter
                        });
                    else
                        vm.db_club_list.push({
                            id: class_period.id,
                            name: class_period.name
                        });
                });
            }
        }, function() {});


        function swReferral(student) {

            helperService.communicationModal(student, 4, 'sw-referral', 5, vm.selectedPeriod,'').then(function(response) {
                helperService.showAlert('The sw referral was sent.');

                student.total_swReferral++;

                return response;
            });

        }

        function generalReferal(student) {

            helperService.communicationModal(student, 5, 'general-referral', 5, vm.selectedPeriod,'').then(function(response) {
                helperService.showAlert('The general referral was sent.');

                student.total_gwReferral++;

                return response;
            });

        }

        function sstReferal(student) {
            helperService.communicationModal(student, 6, 'sst-referral', 5, vm.selectedPeriod,'').then(function(response) {
                helperService.showAlert('The sst referral was sent.');

                student.total_sstReferral++;

                return response;
            });

        }

        function sendMessage(student) {
            helperService.communicationModal(student, 1, 'message', '', vm.selectedPeriod,'').then(function(response) {
                helperService.showAlert('The message was sent.');

                student.total_texts++;
                student.total_communication++;
                return response;
            });
        }


        function makeCall(student) {

            helperService.communicationModal(student, 2, 'call', '', vm.selectedPeriod,vm.backdrop).then(function(response) {
                helperService.showAlert('The call was made.');

                student.total_calls++;
                student.total_communication++;
                return response;
            });
        }

        function sendEmail(student) {
            helperService.communicationModal(student, 3, 'email', '', vm.selectedPeriod,'').then(function(response) {
                helperService.showAlert('The email was sent.');

                student.total_emails++;
                student.total_communication++;
                return response;
            });
        }

        function changeState(id) {
            $timeout(function() {
                $state.go("student.reports", {
                    studentId: id,
                    type: 1
                });
            });
        }

        function initStudents(students) {
            angular.forEach(students, function(student) {
                if (student.range_total_texts !== undefined && student.range_total_calls !== undefined && student.range_total_emails !== undefined) {
                    student.total_texts = student.range_total_texts;
                    student.total_calls = student.range_total_calls;
                    student.total_emails = student.range_total_emails;
                }

                student.total_communication = student.total_texts + student.total_emails + student.total_calls;
            });
            if (students.length <= 0) {
                vm.nodata = true;
            } else {
                vm.nodata = false;
            }
            return students;
        }

        function exportPDF() {
            exportService.exportCommunication({
                order: vm.order,
                class_periodId: vm.class_period,
                range: vm.selectedDateRange,
                custom_ranges: vm.customRanges,
                report_type: 'communication'
            });
        }

        function selectRange(selectday) {

            if (selectday == 'today') {
                vm.selectedDateTitle = dateRanges[0].title;
                vm.selectedDateRange = dateRanges[0].value;
            }
            if (selectday == 'week') {
                vm.selectedDateTitle = dateRanges[1].title;
                vm.selectedDateRange = dateRanges[1].value;
            }
            if (selectday == 'twoWeek') {
                vm.selectedDateTitle = dateRanges[2].title;
                vm.selectedDateRange = dateRanges[2].value;
            }
            if (selectday == 'month') {
                vm.selectedDateTitle = dateRanges[3].title;
                vm.selectedDateRange = dateRanges[3].value;
            }
            if (vm.selectedDateRange == 'year' || selectday == 'year') {
                vm.selectedDateTitle = dateRanges[4].title;
                vm.selectedDateRange = dateRanges[4].value;
                vm.students = initStudents(communication);
            }

            if (selectday == 'custom') {
                vm.selectedDateTitle = dateRanges[5].title;
                vm.selectedDateRange = dateRanges[5].value;
            }


            //TODO fix: start date falls short on day
            if (vm.selectedDateRange == 'custom' && vm.customRanges.startDate === null && vm.customRanges.endDate === null) {
                return;
            }

            if (vm.selectedDateRange != 'year' || selectday != 'year') {
                studentsService.getForRange({
                    url: '/api/students/for-period',
                    range: vm.selectedDateRange,
                    customRanges: vm.customRanges
                }).then(function(students) {
                    if (students.length > 0) {
                        vm.students = initStudents(students);

                    } else {

                        vm.students = initStudents(students);
                    }

                });

            }
        }
    }
})();