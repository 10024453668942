(function () {
  'use strict';

  angular
    .module('app.address-book')
    .controller('CreateAdminController', CreateAdminController);

  /* @ngInject */
  function CreateAdminController($uibModalInstance, adminsService, adminTypes) {
    /* jshint validthis: true */
    let vm = this;

    vm.admin = {
      fullname: '',
      email: '',
      role: '',
      officenumber: '',
      mobilenumber: ''
    };

    vm.adminTypes = adminTypes;

    vm.create = create;
    vm.cancel = cancel;

    //////////////////

    function create() {
      adminsService.create(vm.admin).then(function (admin) {
        $uibModalInstance.close(admin);
      });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

  }
})();