(function () {
  'use strict';

  angular
    .module('app.dashboard.main')
    .controller('RemoveClassController', RemoveClassController);

  /* @ngInject */

  function RemoveClassController($rootScope,$state,classesId,ClassesService,$uibModalInstance,toastr,groupType) {
    /* jshint validthis: true */
    var vm = this;
    vm.cancel=cancel;
    vm.submit=submit;
    vm.groupType=groupType;
    vm.ReloadClassList=ReloadClassList;
    
    vm.FormData = {
      group_type: groupType,
      classId: classesId,
    };


    function ReloadClassList()
    {
      $rootScope.db_class_list = [];
      $rootScope.db_club_list = [];

      ClassesService.getclassesData('api/classes').then(function(data) {
                  if (data.class_period !== null) {
                      angular.forEach(data.class_period, function(class_period) {
                          if (class_period.group_type === '1')
                              $rootScope.db_class_list.push({
                                  id: class_period.id,
                                  name: class_period.name,
                                  period: class_period.period,
                                  period_letter: class_period.period_letter
                              });
                          else
                              $rootScope.db_club_list.push({
                                  id: class_period.id,
                                  name: class_period.name
                              });
                      });
                  }

      });
  
    }


    function submit() 
    {
       ClassesService.removeClass('/api/classes/removeclass',vm.FormData).then(function(data) 
       {

            if(data.error===0)
            {
              ReloadClassList();
              toastr.success('Class successfully deleted');
              $uibModalInstance.dismiss();
              $state.go('classes.roaster');

            }
            else
            {
              toastr.error(data.errortext);
            }

       }); 

    }
   
     


    function cancel() {
      $uibModalInstance.dismiss();
    }


  }
})();