(function () {
  'use strict';

  angular
    .module('app.dashboard.main')
    .controller('ForgotPasswordController', ForgotPasswordController);

  /* @ngInject */
  function ForgotPasswordController($timeout,$state,UserSettingService,$scope,toastr,ShareUsService,$uibModal,$window) {

    /* jshint validthis: true */
    var vm = this;
    vm.ResetPasswordRequest=ResetPasswordRequest;

    vm.forgotpassword = {
      userEmail: undefined,
      userName: undefined,
    };


  function ResetPasswordRequest()
   {
      console.log('hello');
      UserSettingService.sendRequestForForgotPassword('api/user/forgotpassword', vm.forgotpassword).then(function(data)
      {
             console.log(data);
             if(data.error===0)
              {
                   if(data.response===1)
                   {
                     toastr.success('Reset password link successfully send to your email id');
                     //$state.go('resetpassword_instruction');
                     $timeout(function () {

                      $state.go('login');
                      }, 10000);

                   }

              }
              else
              {
                  toastr.error(data.errortext);
              }

      });
      

   }

    
  }
})();