(function() {
    'use strict';

    angular
        .module('app.paperworks')
        .controller('TeacherformController', TeacherformController);

    function TeacherformController($scope, $timeout, paperworksService, $rootScope, $state) {
        var vm = this;
        /*  assign properties*/

        vm.order = '-updated_at';
        vm.getTeachersform = getTeachersform;
        vm.setTempalte = setTempalte;
        vm.template = 0;
        vm.template_name = '';
        vm.viewforms = viewforms;

        getTeachersform();

        /* assign methods*/

        function setTempalte(template, template_name) {
            vm.template = template;
            vm.template_name = template_name;
            getTeachersform();
        }



        function getTeachersform() {
            paperworksService.getpaperwork('/api/teacherform?template=' + vm.template).then(function(response) {

                vm.formData = response;
            });

        }


        function viewforms(row) {
            $rootScope.referralurl = row.form_name;
            $state.go('paperworkforms.letterform', {
                formtype: row.form_type,
                type: 'edit',
                //studentId: row.student_id,
                formId: row.form_id,
                unsaveform :true
                
            });

        }

    }

})();