(function () {
  'use strict';

  angular
    .module('app.dashboard.main')
    .controller('ResourceController', ResourceController);

  /* @ngInject */
  function ResourceController() {

    /* jshint validthis: true */
    var vm = this;

    vm.ActiveTabSetting=ActiveTabSetting;
    vm.ActiveTab=1;
    

    function ActiveTabSetting(MainTabSetting)
    {
       vm.ActiveTab=MainTabSetting;
    }

    
  }
})();