(function() {
    'use strict';


    angular
        .module('app.paperworkforms')
        .controller('ReferralformController', ReferralformController);

    function ReferralformController($scope,$element, $rootScope, helperService, $state, $stateParams, paperworksService, students, behavior_reason, attendance_reason, academic_reason, action_taken, socialwork_reason, referralService, $location, userperiods, studentsService, $filter,toastr) {
        var vm = this;
        /* assign for property*/
        vm.name = "Student's Name";
        vm.Sid = '';
        vm.period = "Student's Period";
        vm.gender = "Student's Gender";
        vm.grade = "Student's Grade";
        vm.url = '';
        vm.admin = $rootScope.admins;
        vm.showDetails = false;
        vm.showvalue = 'edit';
        vm.editsuccess = '';
        vm.viewsucces = '';
        vm.currenturl = $location.absUrl();
        vm.classes =[];
        vm.clubes = [];
       
        vm.FirstName='';
        vm.LastName='';

        angular.forEach(userperiods, function(usperiod){
        	if(usperiod.group_type === '1'){
        		 vm.classes.push({id:usperiod.id, period:usperiod.period, period_letter:usperiod.period_letter});
        	}else{
        		vm.clubes.push({id:usperiod.id, name:usperiod.name });
        	}
        });
        
       // vm.userPeriods = userperiods;
        vm.userSelectedPeriod = '';
        vm.referral_type = '';

        vm.academic_reports = [];
        vm.behavior_reports = [];
        vm.paperwork_log = [];
        vm.communication_log =[];
        vm.attendance_reports = {};
        vm.referraldata = '';
        vm.reasonValue = '';
		vm.userActivity=[];
		vm.Issaved = 1;
		vm.paperworkfromClass = $stateParams.paperworkfromClass;
        
        vm.priorityTitle = priorityTitle;

        vm.actionTaken = action_taken;
        
        vm.student_id = 0;
        vm.getactiontaken = getactiontaken;
        
        vm.addUser = addUser;
        	
        	
        function addUser(myForm) {
            myForm.$setPristine();
        }


        vm.students = '';
        vm.selectedstudent = '';
        vm.form = {
            form_id: '',
            student_id: 0,
            referral_type: '',
            other_information: '',
            selectedPeriod: "Student's Period",
            referral_date: new Date(),
            des_problem:'',
            priority: '',
            category:'',
            type:'',
            reason: '',
            detail_des: '',
            other_reason: '',
            other_action_taken: '',
            action_taken: [],
            activity: 0,
            approved_by:'',
            swr_title:'',
            swr_report:'',
            swr_names:'',
            case_status_continue_yes:'',
            case_status_continue_no:'',
            case_status_close_yes:'',
            case_status_close_no:'',
            case_code:'',
            swr_date:'',
            sendto: [],
            guardianEmail: [],
            saved: false

        };
       
        $rootScope.$on('activity_message', function (event, data) {
           
            vm.userActivity.push(data); 
            if(vm.paperworkfromClass){
            	$state.go('classes.roaster');
            }else{
            $state.go('paperworks.createForm');
            }
         });


        /* assign for methods*/
        vm.sendtoAdmin = sendtoAdmin;
        vm.activereferralform = activereferralform;
        vm.searchstudent = searchstudent;
        vm.afterselected = afterselected;
        vm.clearData = clearData;
        vm.selectStudentByPeriod = selectStudentByPeriod;
        vm.saveActivity = saveActivity;
        vm.activeIcon = activeIcon;
        vm.init = init;
        vm.checkAcitivity = checkAcitivity;
        vm.checksave = 0;
        vm.preview = preview;
        vm.previewicon = false;
        vm.checkWindow = checkWindow;
        var timer = '';
        var childWindow;
        vm.getRelation = getRelation;
        /* document ready*/

        activereferralform();
        activeIcon();
        
        if($stateParams.studentId !== null && $stateParams.studentId > 0)
        {
            vm.student_id = $stateParams.studentId;
            searchstudent($stateParams.studentId);
        }

        $scope.$watch('ReferralformCtrl.userSelectedPeriod', selectStudentByPeriod, false);
        
        $scope.$watch('ReferralformCtrl.form.priority', checkAcitivity, false);
        $scope.$watch('ReferralformCtrl.form.reason', checkAcitivity, false);
        $scope.$watch('ReferralformCtrl.form.other_reason', checkAcitivity, false);
        $scope.$watch('ReferralformCtrl.form.detail_des', checkAcitivity, false);
        $scope.$watch('ReferralformCtrl.form.action_taken', checkAcitivity, false);
 

       /* $rootScope.$on("sendReferral", function(evt, data) {
            //alert(data);
            if (data !== '') {
                var form = {
                    form_id: data
                };
                paperworksService.updateReferralform('/api/referralform/updatestauts', form).then(function(response) {
                    if (response) {
                        helperService.showAlert('successfuly mail sended.');
                    }

                });
            }
        });*/

      /*  $scope.$on("$stateChangeStart",function (event, toState, toParams, fromState, fromParams) {
        	alert(vm.checksave);
    			});
        */
        
        function checkAcitivity(newVal, oldVal){

        	if(oldVal !== newVal){
        		vm.checksave = 0;
        	}
        	
        	
        }

        
    	function preview(){
    		timer = setInterval(checkWindow, 500);
			 var winName='MyWindow';
			  var winURL='/api/paperwork/preview';
			  var windowoption='resizable=yes,height=600,width=800,location=0,menubar=0,scrollbars=1';
			  var params = {'paperwork_type':'Referrals', 'formName' : vm.activeform,'form_id' : vm.form.form_id ,'student_id':vm.form.student_id,'des_problem':vm.form.des_problem,
					  'other_information':vm.form.other_information, 'priority':vm.form.priority,'category':vm.form.category,'type':vm.form.type,
					  'reason':vm.form.reason,'detail_des':vm.form.detail_des,'other_reason':vm.form.other_reason,'other_action_taken':vm.form.other_action_taken,
					  'action_taken':vm.form.action_taken.toString(), 'approved_by': vm.form.approved_by, 'swr_title':vm.form.swr_title, 
					  'swr_report':vm.form.swr_report, 'swr_names':vm.form.swr_names, 'case_status_continue_yes':vm.form.case_status_continue_yes,
					  'case_status_continue_no':vm.form.case_status_continue_no, 'case_status_close_yes':vm.form.case_status_close_yes,
					  'case_status_close_no':vm.form.case_status_close_no, 'case_code':vm.form.case_code, 'swr_date':vm.form.swr_date};    

			  
			  var form = document.createElement("form");
			  form.setAttribute("method", "post");
			  form.setAttribute("action", winURL);
			  form.setAttribute("target",winName);  
			  for (var i in params) {
			    if (params.hasOwnProperty(i)) {
			      var input = document.createElement('input');
			      input.type = 'hidden';
			      input.name = i;
			      input.value = params[i];
			      form.appendChild(input);
			    }
			  }              
			  document.body.appendChild(form);                       
			  childWindow  =  window.open('', winName,windowoption);
			  form.target = winName;
			  form.submit();                 
			  document.body.removeChild(form);    
			  
		}
		
    	function checkWindow() {
    		if (childWindow && childWindow.closed) {
    	        window.clearInterval(timer);
    	       
    	        $scope.$apply(function(){
   	        	 vm.previewicon = false;
   	    	     
   	        	});
    	       
    	        
    	    }
		}

        function afterselected(selected) {
            if (selected) {
            	init();
                vm.selectedstudent = selected.originalObject;
                $rootScope.selectedStudentDet = selected.originalObject;
                searchstudent(selected.originalObject.id);
                selected.originalObject = null;
                vm.FirstName=vm.selectedstudent.first_name;
                vm.LastName=vm.selectedstudent.last_name;
            }

        }


        function sendtoAdmin() {

            helperService.paperworkformModal(vm.selectedstudent, vm.form.form_id, 'send-admin-modal', 'md', 'referral', vm.form.referral_type, '').then(function(response) {

            	//helperService.showAlert('I emailed the '+ vm.name +' for '+ vm.form.referral_type +' at '+ $filter('date')(new Date(), 'M/d/yy h:mm:ss a'));
            	
            });

        }

        function activeIcon() {
            if ($state.params.type == 'edit') {
               // vm.editsuccess = 'icon-success';
            	 vm.previewicon = false;
                vm.viewsucces = '';
                vm.showvalue = 'edit';
            }
            if ($state.params.type == 'view') {
               // vm.viewsucces = 'icon-success';
            	 vm.previewicon = true;
                vm.editsuccess = '';
                vm.showvalue = 'preview';
            }

        }

        function activereferralform() {
            if ($state.current.name == 'paperworkforms.referralform') {
                vm.activeform = $state.params.formtype;
                vm.form.referral_type = $state.params.formtype;
                vm.referral_type = $state.params.formtype;
                switch ($state.params.formtype) {
                    case 'ATR':
                        vm.activeformname = 'Attendance Referral';
                        vm.form.reason = attendance_reason[0].value;
                        vm.reasonValue = attendance_reason;
                        vm.form.attendance_record = 'yes';
                        vm.form.category = 3;
                        vm.form.type = 2;
                        break;
                    case 'SWR':
                        vm.activeformname = 'Social worker Referral';
                        vm.url = '/api/students/studentbehavioryear';
                        vm.form.reason = socialwork_reason[0].value;
                        vm.reasonValue = socialwork_reason;
                        vm.form.behavior_record = 'yes';
                        vm.form.attendance_record = 'yes';
                        vm.form.category = '';
                        vm.form.type = 2;
                        break;
                    case 'BR':
                        vm.activeformname = 'Behavioral Referral';
                        vm.url = '/api/students/studentbehavioryear';
                        vm.form.reason = behavior_reason[0].value;
                        vm.reasonValue = behavior_reason;
                        vm.form.behavior_record = 'yes';
                        vm.form.mode = 2;
                        vm.form.category = 2;
                        vm.form.type = 2;
                        break;
                    case 'ACR':
                        vm.activeformname = 'Academic Referral';
                        vm.url = 'api/students/studentacademicyear';
                        vm.form.reason = academic_reason[0].value;
                        vm.reasonValue = academic_reason;
                        vm.form.mode = 3;
                        vm.form.category = 1;
                        vm.form.type = 2;
                        break;
                }
            }

        }



        function searchstudent(studentid) {
        	
        	var url = '';
        	if($stateParams.formId){
        		url = '/api/referralform/activity?student_id=' + studentid + '&referral_type=' + vm.referral_type + '&form_id=' + $stateParams.formId; 
        	}
        	else{
        		url = '/api/referralform/activity?student_id=' + studentid + '&referral_type=' + vm.referral_type ;
        	}
            paperworksService.getReferralformActivity(url)
                .then(function(activitydetail) {
                	vm.selectedstudent = {fullname : activitydetail.fullname , id: activitydetail.id , g1email:activitydetail.g1email , g2email:activitydetail.g2email };
                    vm.form.student_id = activitydetail.id;
                    vm.form.referral_type = vm.referral_type;
                    vm.attendance_reports = {
                        unexcuseabsence: activitydetail.student.unexcuse_absence,
                        excuse_absence: activitydetail.student.excuse_absence,
                        total_absents: activitydetail.student.total_absents,
                        total_skiped: activitydetail.student.total_skipes,
                        total_tardy: activitydetail.student.total_tardies
                    };
                    vm.name = activitydetail.fullname;
                    vm.Sid = activitydetail.student.studentid;
                    vm.gender = activitydetail.gender_type;
                    if (activitydetail.studentclass !== null) {
                        vm.form.selectedPeriod = activitydetail.studentclass[0];
                        vm.grade = activitydetail.studentclass[1];
                    } else {
                        vm.form.selectedPeriod = 'N/A';
                        vm.grade = 'N/A';
                    }
                    if (activitydetail.behavior)
                        vm.behavior_reports = activitydetail.behavior;
                    if (activitydetail.academic)
                        vm.academic_reports = activitydetail.academic;
                    if(activitydetail.paperwork_log){
                    	vm.paperwork_log = activitydetail.paperwork_log;
                    }
                    if(activitydetail.communication_log){
                    	vm.communication_log = activitydetail.communication_log;
                    }
                    	
                   // alert($stateParams.studentId);
                    if ($stateParams.studentId) {
                        vm.form.form_id = activitydetail.referralActivity.id;
                        vm.form.other_information = activitydetail.referralActivity.other_information;
                        vm.form.referral_date = activitydetail.referralActivity.referral_date;
                        vm.form.des_problem = activitydetail.referralActivity.des_problem;
                        vm.form.priority = activitydetail.referralActivity.priority;
                        vm.form.reason = activitydetail.referralActivity.reason;
                        vm.form.category = activitydetail.referralActivity.category;
                        vm.form.type = activitydetail.referralActivity.type;
                        vm.form.detail_des = activitydetail.referralActivity.detail_des;
                        vm.form.other_reason = activitydetail.referralActivity.other_reason;
                        vm.form.other_action_taken = activitydetail.referralActivity.other_action_taken;
                        vm.form.approved_by = activitydetail.referralActivity.approved_by;
                        vm.form.swr_title = activitydetail.referralActivity.swr_title;
                        vm.form.swr_report = activitydetail.referralActivity.swr_report;
                        vm.form.swr_names = activitydetail.referralActivity.swr_names;
                        vm.form.case_status_continue_yes = activitydetail.referralActivity.case_status_continue_yes;
                        vm.form.case_status_continue_no = activitydetail.referralActivity.case_status_continue_no;
                        vm.form.case_status_close_yes = activitydetail.referralActivity.case_status_close_yes;
                        vm.form.case_status_close_no = activitydetail.referralActivity.case_status_close_no;
                        vm.form.case_code = activitydetail.referralActivity.case_status_close_no;
                        vm.form.swr_date = activitydetail.referralActivity.swr_date;

                        var action = activitydetail.referralActivity.action_taken;
                        action = action.split(',');

                        angular.forEach(action, function(val) {
                            vm.form.action_taken.push(val.toString());
                        });
                        vm.form.activity = parseInt(activitydetail.referralActivity.activity);
                       // alert($rootScope.previousState);
                        vm.form.saved = true;
                    }

                });


        }

        function clearData() {

            var form = {
                form_id: vm.form.form_id
            };
            paperworksService.deleteReferralform('/api/referralform/deleted', form).then(function(response) {
                helperService.showAlert('Your data has been reseted.');
                init();


            });
        }

        
        
        function init(){
        	
        	vm.name = "Student's Name";
        	vm.Sid = ''; 
            vm.period = "Student's Period";
            vm.gender = "Student's Gender";
            vm.grade = "Student's Grade";
            vm.academic_reports = [];
            vm.behavior_reports = [];
            vm.attendance_reports = {};
            vm.form = {
                form_id: '',
                student_id: '',
                referral_type: '',
                other_information: '',
                selectedPeriod: "Student's Period",
                referral_date: new Date(),
                des_problem :'',
                priority: '',
                category:'',
                type:'',
                reason: '',
                detail_des: '',
                other_reason: '',
                action_taken: [],
                other_action_taken: '',
                activity: 0,
                approved_by:'',
                swr_title:'',
                swr_names:'',
                swr_report:'',
                case_status_continue_yes:'',
                case_status_continue_no:'',
                case_status_close_yes:'',
                case_status_close_no:'',
                case_code:'',
                swr_date:'',
                sendto: [],
                saved: false
            };
        	
            activereferralform();
        }
        
        function selectStudentByPeriod() {

            if (vm.userSelectedPeriod === '') {
                vm.students = students;
            } else {
                var data = {
                    period: vm.userSelectedPeriod
                };
                studentsService.getStudentsByuserPeriod('/api/students/getstudentByuserPeriod', data).then(function(response) {
                    vm.students = response;
                });
            }
        }

        function saveActivity(form) {
        	
        	
        	// $scope.formname.$setPristine();
        	vm.checksave = 1;
            if(vm.form.student_id>0)
            {
                var flag=1;
                if(vm.form.priority==="")
                {
                    flag=0;
                    toastr.error('Priority selection required'); 

                }
                

                if(flag===1)
                {
                    paperworksService.saveActivity('/api/referralform/saveactivity', vm.form).then(function(response) {
                    helperService.showAlert('successfuly saved data.');
                    vm.form.form_id = response.id;
                    vm.form.saved = true;
    				vm.userActivity.push('Referral saved for '+ vm.name + ' at ' +  $filter('date')(new Date(), 'M/d/yy h:mm:ss a'));
                    });
               }
            }else{
                toastr.error('Please select a student');
            }
            
            addUser(form);
        }
        
        function priorityTitle(value){
        	var returnvalue = '';
        	if(value == 'l'){
        		returnvalue = 'Low';
        	}
        	else if(value == 'm'){
        		returnvalue = 'Medium';
        	}
        	else if(value == 'h'){
        		returnvalue = 'High';
        	}
        	
        	return returnvalue;
        }
        
        
        function getactiontaken(arrayValue){

        	var actionTitle = '';
        	 angular.forEach(action_taken, function(val) {
        		 angular.forEach(arrayValue, function(arrval) {
        			 
        			  if(val.value == arrval){
          			 
            		 actionTitle +=  val.title + ' ,'; 
            		
        		 }
        		
        			 
        		 });        		        		 
        		
             });
        	        	 		    
        	return actionTitle.replace(/,\s*$/, "");
        }
        
        function getRelation(value){
        	var relation = '';
        	
        	switch(value){
        	case 1:
        		relation = 'mother';
       			break;
       		case 2:
       			relation = 'father';
       			break;
       		case 3:
       			relation = 'uncle';
       			break;
       		case 4:
       			relation = 'aunt';
       			break;
       		case 5:
       			relation = 'sister';
       			break;
       		case 6:
       			relation = 'brother';
       			break;
       		case 7:
       			relation = 'grandmother';
       			break;
       		case 8:
       			relation = 'grandfather';
       			break;
       		case 9:
       			relation = 'other';
       			break;
        	}
        	return relation;
        }
        
    }


})();