(function () {
  'use strict';

  angular
    .module('app.dashboard')
    .service('communicationService', communicationService);

  /* @ngInject */
  function communicationService($resource, $q,$http) {
    /* jshint validthis: true */
    var vm = this;

    vm.GetLastWeekData=GetLastWeekData;

   
    function GetLastWeekData($url) {
      return $http.get($url).then(fetchData);
    }

    function fetchData(response)
    {
        return response.data;
    }


  }


})();
