(function(){
	'use strict';
	
	angular
		.module('app')
		.filter('titlecase',titlecase);
	
	function titlecase(){
		return function(input){
			var first = input.split("-")[0];
			var second = input.split("-")[1];
			if(second){
				return first + ' ' + second;
			}else{
				return first;
			}
			
		};
	}
	
	
	
	
})();