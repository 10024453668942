(function () {
  'use strict';

  angular.module('app', [
    'app.core',
    'app.base',
    'app.login',
    'app.address-book',
    'app.student',
    'app.reports',
    'app.classes',
    'app.paperworks',
    'app.paperworkforms',
    'app.dashboard',
    'app.communications-analytics',
    'app.behavior-analytics',
    'app.referral-analytics',
    'app.response-to-intervention-analytics',
    'app.attendance-analytics',
    'angularSpinner',
    'app.directives',
    'app.setup-account',
  ]);

})();