(function () {
  'use strict';

  angular
    .module('app')
    .service('teacherTypesService', teacherTypesService);

  /* @ngInject */
  function teacherTypesService($resource) {
    /* jshint validthis: true */
    let vm = this;

    let TeacherType = $resource('/api/teacher-types');

    vm.all = all;

    /////////////

    function all() {
      return TeacherType.query().$promise;
    }

  }
})();