(function(){
	'use strict';
	
	angular
		.module('app')
		.filter('Calculatedate',Calculatedate);
	
	function Calculatedate(){
		return function(dateValue){		
			//alert(dateValue);
	        //alert(serverDate);
			/*var NowDateTime = new Date();
			 var ServerDateTime  = new Date(serverDate);
			 
			var diff = Math.abs(NowDateTime - ServerDateTime); */
			var DateValue = new Date(dateValue);
			return new Date(DateValue.getTime() + calculateDiffDateTime);
		};
				
	}
	
	
	
})();