(function(){
	'use strict';
	
	angular
	  .module('app.core')
	  .service('studentAttendanceService', studentAttendanceService);
	  function studentAttendanceService($resource, $http) {
		    /* jshint validthis: true */
		    var vm = this;

		    vm.all = all;
		    vm.create = create;
		    vm.get = get;
//		    vm.update = update;
//		    vm.remove = remove;
//		    vm.getForRange = getForRange;
//		    vm.getMessagesByFilters = getMessagesByFilters;
//		    vm.attendanceDetail = attendanceDetail;

		    /////////////////

		    function all() {
		    	return $http.get('/api/students/attendance').then(function(response){
	    			return 	response.data;
		    	});
		    }



		    function getForRange(range) {
		      return $http.get('/api/students/for-period', {params: range}).then(fetchData);
		    }


		    function fetchData(response) {
		      return response.data;
		    }
		    
		    function attendanceDetail(){
		    	//return Attendancedetails.query().$promise;
		    	return $http.get('/api/students/attendance').then(function(response){
		    			return 	response.data;
		    		
		    	});
		    }
		  }
	
	
})();