(function () {
  'use strict';

  angular
    .module('app.dashboard.main')
    .controller('CommunicationController', CommunicationController);

  /* @ngInject */
  function CommunicationController($scope ,$uibModalInstance,cannedMessagesService,studentsService,helperService,$interval,
    toastr,usSpinnerService,CommunicationMode,$filter, $rootScope, messagesService) {

    /* jshint validthis: true */
    var vm = this;

    cannedMessagesService.getmeessageandData().then(function(data) {

      helperService.setCannedData(data[0], data[1], data[2]);
      setCannedData(data[0], data[1], data[2]);

    });

    //alert("hello");

    vm.studentFound=0;
    vm.fetchCannedMessages = fetchCannedMessages;
    vm.request = true;
    vm.ToDropDown = true;
    vm.searchtext = '';
    vm.selectStudentByPeriod = selectStudentByPeriod;
    vm.categories = '';
    vm.setCannedData=setCannedData;
    vm.searchTask='';
    vm.SendBulkMessage = SendBulkMessage;
    vm.optionToggled=optionToggled;
    vm.toggleAllselected=toggleAllselected;
    vm.topics='';
    vm.cannedTypes='';
    vm.FetchTopic=FetchTopic;
    vm.db_class_list=[];
    vm.students='';
    vm.userSelectedPeriod = 0;
    vm.cancel = cancel;
    vm.student_name='';
    vm.selectedItem='';
    vm.buttonName='';
    vm.callStatuses='';
    vm.CommunicationMode=CommunicationMode;
    vm.HeaderIcon='';
    vm.HeaderText='';
    vm.periodname = '';
    vm.searchByClass = false;
    vm.userPeriods = '';
    vm.periodname = '';
    vm.userBalance = '';
    vm.getStduentsWithuserClasses=getStduentsWithuserClasses;
    vm.studentFoundbyPeriod=studentFoundbyPeriod;

    vm.studentFoundbySinglePeriod=studentFoundbySinglePeriod;
    vm.emails=[];
    vm.phones=[];
    vm.afterselected = afterselected;
    vm.SearchboxClose=SearchboxClose;
    vm.getEmail=getEmail;
    vm.getphonenumber=getphonenumber;
    vm.searchByName=searchByName;
    vm.ShowMessages=ShowMessages;
    vm.studentname='N/A';
    vm.studentgrade='N/A';
    vm.studentperiod='N/A';
    vm.studentgender='N/A';
    vm.Relationship=Relationship;
    vm.searchstudent=searchstudent;
    vm.getUserBalance = getUserBalance;
    vm.openCallModal = false;
    vm.studentData='';
    
    getUserBalance();

    function SearchboxClose()
    {
      vm.studentFound=0;
    }

    function searchByName()
    {
        vm.studentFound=1;

    }

    function ShowMessages()
     {
        //vm.eform.message=vm.eform.canned_message_id.selected;

        var cannedMessage = $filter('filter')(vm.cannedMessages, {id: vm.eform.canned_message_id});
        if (cannedMessage.length) {
          vm.eform.message = cannedMessage[0].message;
        }

       
     }

 function getEmail(students)
    {
         if(students.g1email){
            vm.emails.push({id: 1,
                  guardian_name: students.g1fullname,
                  guardian_relationship: Relationship(students.g1relationship),
                  guardian_email: students.g1email});
          }
          
          if(students.g2email){
            vm.emails.push({
              id: 2,
                  guardian_name: students.g2fullname,
                  guardian_relationship: Relationship(students.g2relationship),
                  guardian_email: students.g2email
            });
          }

        
         

    }

  function Relationship(status_id)  
  {
      var relatioship='';
      switch(status_id) 
      {
        case '1':
            relatioship='mother';
            break;
        case '2':
            relatioship='father';
            break;
        case '3':
            relatioship='uncle';
            break;
        case '4':
            relatioship='aunt'; 
            break; 
        case '5':
            relatioship='sister';           
            break;
         case '6':
            relatioship='brother';           
            break;  
         case '7':
            relatioship='grandmother';           
            break;  
         case '8':
            relatioship='grandfather';           
            break;   
         case '9':
            relatioship='other';           
            break;               
       
      } 

      return relatioship;
  }

  function getphonenumber(students){
      if(students.g1homenumber ){
        vm.phones.push({id: 1,
                guardian_name: students.g1fullname,
                guardian_relationship: Relationship(students.g1relationship),
                guardian_number: students.g1homenumber,
                guardian_number_type: 'Home Number'});
      }
      if(students.g1worknumber ){
        vm.phones.push({id: 2,
                guardian_name: students.g1fullname,
                guardian_relationship: Relationship(students.g1relationship),
                guardian_number: students.g1worknumber,
                guardian_number_type: 'Work Number'});
      }
      
      if(students.g1mobilenumber){
        vm.phones.push({ id: 3,
                guardian_name: students.g1fullname,
                guardian_relationship: Relationship(students.g1relationship),
                guardian_number: students.g1mobilenumber,
                guardian_number_type: 'Mobile Number'});
      }
      
      if(students.g2homenumber){
        vm.phones.push({ id: 4,
                guardian_name: students.g2fullname,
                guardian_relationship: Relationship(students.g2relationship),
                guardian_number: students.g2homenumber,
                guardian_number_type: 'Home Number'});
      }
      
      if(students.g2worknumber){
        vm.phones.push({id: 5,
                guardian_name: students.g2fullname,
                guardian_relationship: Relationship(students.g2relationship),
                guardian_number: students.g2worknumber,
                guardian_number_type: 'Work Number'});
      }
      
      if(students.g2mobilenumber){
        vm.phones.push({ id: 6,
                guardian_name: students.g2fullname,
                guardian_relationship: Relationship(students.g2relationship),
                guardian_number: students.g2mobilenumber,
                guardian_number_type: 'Mobile Number'});
      }   

    
    }



    function searchstudent(studentid) 
    {
        studentsService.getStudentwithClasses('api/students/studentwithclasses?id=' + studentid).then(function(response) {
            
            vm.studentname = response.student.fullname;
            vm.eform.student_id=response.student.id;
            vm.eform.guardian_full_name=response.student.g1fullname;
            vm.eform.guardian_relationship=response.student.g1relationship;
            vm.eform.fullname=response.student.fullname;

            vm.studentData=response.student;

            //console.log(vm.studentData);

            getEmail(response.student);
            getphonenumber(response.student);


          vm.ToDropDown = false;

            if (response.student_class) 
            {
                angular.forEach(response.student_class, function(data) {
                    vm.studentperiod = data.period + '' + data.period_letter + '\n';
                    vm.studentgrade = data.grade;
                });
            } else {
                vm.studentperiod = 'N/A';
                vm.studentgrade = 'N/A';
            }
            vm.studentgender = response.student.gender_type;
            
            
        });
    }


    function optionToggled()
    {
        if(vm.selectedItem!==0)
        {
            //vm.ToDropDown = false;
            vm.emails=[];
            vm.phones=[];
            vm.studentFound=0;
            searchstudent(vm.selectedItem);

        }
    }


    function afterselected(selected) {
      if (selected)
      {
          vm.eform.student_id=selected.originalObject.id;
          vm.eform.guardian_full_name=selected.originalObject.g1fullname;
          vm.eform.guardian_relationship=selected.originalObject.g1relationship;
          vm.eform.fullname=selected.originalObject.fullname;

          getEmail(selected);
          getphonenumber(selected);

          vm.ToDropDown = false;


      }

    }
    

    function studentFoundbyPeriod(SelectedPeriod,periodname)
    {
      vm.studentFound=1;
      vm.userSelectedPeriod = SelectedPeriod; 
      vm.periodname= periodname; 
      vm.searchByClass = true;
    }

    function studentFoundbySinglePeriod(period)
    {
      vm.studentFound=1;
      vm.userSelectedPeriod = period.id; 
      vm.periodname = period.period; 
      vm.searchByClass = true;
    }

    function getStduentsWithuserClasses(){
       
       studentsService.getallstudentbyUser('/api/students/studentsanduserclasses').then(function(data){
         vm.students = data[0];
         vm.userPeriods = data[1];
         
        
       });
     }

     getStduentsWithuserClasses();

    if(CommunicationMode===1)
    {
      vm.buttonName='Send message';
      vm.HeaderIcon='fa fa-comment';
      vm.HeaderText='Send A Text';
    }
    else if(CommunicationMode===2)
    {
      vm.buttonName='Saved';
      vm.HeaderIcon='fa fa-phone';
      vm.HeaderText='Make A Phone Call';
    }
    else if(CommunicationMode===3)
    {
      vm.buttonName='Send email';
      vm.HeaderIcon='fa fa-envelope';
      vm.HeaderText='Send an Email';
    }
  

    vm.form = {
        //letter_form_id: formid ? formid :'',
        letter_type : '',
        students : [],
        email_sent: '0',
        studentid : '',
        description : ''  
    };

     vm.eform = {
      mode: CommunicationMode,
      student_id: undefined,
      fullname: undefined,
      to: undefined,
      guardian_name: '',
      guardian_relationship: '',
      guardian_number: undefined,
      guardian_email: undefined,
      topic_id: undefined,
      category_id: undefined,
      type_id: undefined,
      canned_message_id: undefined,
      message: '',
      phone_id: undefined,
      email_id: undefined,
      selectedPeriod: '',
      call_status_id: undefined,
      additional_call_notes: undefined,
      
    };

     
     vm.CommunicationStudentName='';
     vm.cannedMessages='';
     vm.studentFound=0;
     vm.cancel = cancel;
     vm.changePhone = changePhone;
     
     
     vm.dialnumber = '';
     vm.endCallbtn = true;
     vm.redialbtn = false;
     vm.callSid = '';
     
     vm.phoneCall = phoneCall;

     vm.disconnect = disconnect;
     
     
     $scope.$watch('CommunicationCtrl.eform.guardian_number', changePhone);
     
    function FetchTopic()
    {
      if(vm.eform.category_id!==undefined && vm.eform.type_id!==undefined )
      {
            cannedMessagesService.topics(vm.eform.category_id, vm.eform.type_id).then(function (topics) {
              vm.topics = topics;
            }).finally(function () {
              vm.request = false;
            });
      }

    }

     function fetchCannedMessages() {
           
         if(vm.eform.topic_id!==undefined)
         {

            cannedMessagesService.messages({
              topic_id: vm.eform.topic_id,
              category_id: vm.eform.category_id,
              type_id: vm.eform.type_id,
            })
            .then(function (cannedMessages) 
            {
              vm.cannedMessages = cannedMessages;
            })
            .finally(function () {
              vm.request = false;
            });

         }
      
      
    }


    function SendBulkMessage()
     {
        var flag=1;

        if(vm.eform.category_id===undefined || vm.eform.category_id==="")
        {
          toastr.error('Category required.'); 
          flag=0;
        }

        if(vm.eform.type_id===undefined || vm.eform.type_id==="")
        {
          toastr.error('Type required.'); 
          flag=0;
        }

        if(vm.eform.message===undefined || vm.eform.message==="")
        {
          toastr.error('Message required.'); 
          flag=0;
        }
        
        if(CommunicationMode===2 && (vm.eform.call_status_id === '' || vm.eform.call_status_id ===undefined )){
        	 toastr.error('Call status required.'); 
        	flag=0;
        }

        if(flag===1)
        {
    
              vm.eform.email_id=1;
              vm.eform.phone_id=3;
              

              cannedMessagesService.create(vm.eform).then(function () 
                {

                  
                });

              var students = vm.eform.fullname;
              
              if(CommunicationMode===3)
              {
                 toastr.success('Email send successfully guardian of ' + students);
              }
              else if(CommunicationMode===2)
              {
                 toastr.success('Phone call saved for guardian of '+ students);
              }
              else if(CommunicationMode===1)
              {
                 toastr.success('Message send successfully guardian of '+ students);
              }

              //usSpinnerService.stop('spinner-1');   
              $uibModalInstance.close();
              
                 
                      
        }


     }




    function setCannedData(cannedTypes, cannedCategories, callStatuses) 
    {
        vm.cannedTypes = cannedTypes;
        vm.categories = cannedCategories;
        vm.callStatuses = callStatuses;
       
    }

    function toggleAllselected() {
      vm.selectedItem.push(vm.students);
    }



    /*function optionToggled(id,selected,item)
    {

        if(selected)
        {
            //console.log(item);
            if (vm.selectedItem.indexOf(item) == -1)
            {
                if(item !==undefined)
                {
                  //console.log(item);
                  vm.selectedItem.push(item);
                }
            }
        }
        else
        {
             var index = vm.selectedItem.indexOf(id);
             vm.selectedItem.splice(index, 1);
        }




    }*/


    function selectStudentByPeriod(){
      
          
          var data = {period: vm.userSelectedPeriod, searchkey: vm.searchtext };
          studentsService.getStudentsByuserPeriod('/api/students/serachstudentByperiod',data).then(function(response){
            
            //console.log(response);
            vm.students = response;
            vm.studentFound=1;
            
          });

          //console.log(vm.students);
        
     }


    function cancel() {
      $uibModalInstance.dismiss();
    }


    function changePhone(newval, oldval) {

        if(newval){

        vm.dialnumber = newval;
        }

      }
    
    var promise;
    var twilio = null;
    var twilio_sid;
    
    Twilio.Device.disconnect(function (conn) {
        // Called for all disconnections
        vm.endCallbtn = false;
        vm.redialbtn = true;
        twilio = null;
        getUserBalance();
    });

     Twilio.Device.error(function (error) {
        vm.endCallbtn = false;
        vm.redialbtn = true;
    });

    Twilio.Device.connect(function (conn) {
       /* $("#callModal").modal({
            backdrop: 'static',
            keyboard: false,
            show: true
        }); */   
    	toastr.success('Thank you.  We are now connected to your speaker and microphone.');
    	vm.openCallModal = true;
        vm.endCallbtn = true;
        vm.redialbtn = false;
        
        twilio_sid = conn.parameters.CallSid;  
        var addcallTrack = {'user_id':$rootScope.user.id, 'call_sid':twilio_sid};
        cannedMessagesService.checkCallduration('/api/add-user-call-track',addcallTrack);
    });

    function phoneCall(callvalue){
        if(vm.dialnumber){
            messagesService.getTwilioToken().then(function(response){
                Twilio.Device.setup(response);                
                var remainingCall = parseInt(vm.userBalance.call_balance - vm.userBalance.sended_call) * 60;
                var params = {"tocall": vm.dialnumber, "timeLimit": remainingCall}; //10 in seconds
                twilio = Twilio.Device.connect(params);            
            });
        }
    }

    
    function disconnect(){
        Twilio.Device.disconnectAll();	
    }
    
    vm.closeCall = function() {
    	vm.openCallModal = false;
        Twilio.Device.disconnectAll();
    };
    
    function getUserBalance(){
    	messagesService.getUserBalance('/api/email-sms-call-balance').then(function(response){
    		vm.userBalance = response;
    		
    	});
    }

  }
})();