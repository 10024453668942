(function () {
  'use strict';

  angular
    .module('app')
    .service('schoolPeriodsService', schoolPeriodsService);

  /* @ngInject */
  function schoolPeriodsService($resource) {
    /* jshint validthis: true */
    var vm = this;

    var Period = $resource('/api/school-periods');

    vm.all = all;

    //////////////////

    function all() {
      return Period.query().$promise;
    }
  }
})();