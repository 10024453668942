(function() {
    'use strict';

    angular
        .module('app')
        .factory('httpInterceptor', httpInterceptor);

    httpInterceptor.$inject = ['$q', '$injector', '$rootScope', 'usSpinnerService'];

    function httpInterceptor($q, $injector, $rootScope, usSpinnerService) {
        $rootScope.modalerror = false;
        $rootScope.ActiveAjaxConectionsWithouthNotifications = 0;
        return {
            request: function(config) {
                checker(config, "request");
                return config;
            },
            requestError: function(rejection) {
                checker(rejection.config, "request");
                return $q.reject(rejection);
            },
            response: function(response) {
                checker(response.config, "response");
                return response;
            },
            responseError: function(res) {

                $rootScope.ActiveAjaxConectionsWithouthNotifications = 0;
                usSpinnerService.stop('spinner-1');
                var status = res.status,
                    fetchedResponse = res.data;
                var toastr = $injector.get('toastr');
                var $state = $injector.get('$state');
                if(res.config.url === 'api/user/updatesubscription'){
                	$rootScope.$emit("subscriptionError",{name:'error'});
                	toastr.error('Your card number is incorrect');
                	return false;
                }

                if ((status === 401 || status === 400 || status === 404) && !($state.is('login') || $state.is('registration'))) {
                    $state.go('login');

                    toastr.error('You not authenticated for this action', 'Authentication Error');
                }

                showErrors(fetchedResponse, titleByCodeStatus(status));
                $rootScope.modalerror = false;
                return $q.reject(fetchedResponse);
            }
        };

        function titleByCodeStatus(status) {
            var title;

            switch (status) {
                case 422:
                    title = 'Validation Fail!';
                    break;
                case 403:
                    title = 'Access Denied!';
                    break;
                case 500:
                    title = 'Sorry. Server Error.';
                    break;
            }

            return title;
        }

        function getParameterByName(name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        }

        function checker(parameters, status) {
        	//alert(parameters.url);
            var stop_loader = getParameterByName('stop_loader',parameters.url);
        	var call_duration = parameters.url;
            if (status == "request") {
            	/*if(call_duration !=='/api/check-call-duration'){
                $rootScope.ActiveAjaxConectionsWithouthNotifications += 1;
                usSpinnerService.spin('spinner-1');
            	}*/

                if(stop_loader===0 || stop_loader===null)
                {
                  $rootScope.ActiveAjaxConectionsWithouthNotifications += 1;
                  usSpinnerService.spin('spinner-1');
                }

            }
            if (status == "response") {
                $rootScope.ActiveAjaxConectionsWithouthNotifications -= 1;
            }
            if ($rootScope.ActiveAjaxConectionsWithouthNotifications <= 0) {
                $rootScope.ActiveAjaxConectionsWithouthNotifications = 0;
                usSpinnerService.stop('spinner-1');

            }


        }

        function showErrors(response, title) {

            var toastr = $injector.get('toastr');
            var errors = [];

            if (typeof response === 'object') {
                for (var key in response) {
                    if (response.hasOwnProperty(key) && (response[key] instanceof Array)) {
                        for (var i in response[key]) {
                            if (response[key].hasOwnProperty(i)) {
                                errors.push({
                                    title: title ? title : 'Error',
                                    body: response[key][i]
                                });
                            }
                        }
                    } else {
                        errors.push({
                            title: title,
                            body: response[key]
                        });
                    }
                }
            } else if (typeof response === 'string') {
                errors.push({
                    title: title ? title : 'Error',
                    body: response.length < 512 ? response : 'Server Error.'
                });
            }

            for (var j = 0; j < errors.length; j++) {

                toastr.error(errors[j].body, errors[j].title);
            }


        }
    }
})();