(function () {
  'use strict';

  angular
    .module('app.behavior-analytics.teacher-details')
    .controller('BehaviorTeacherDetailsController', BehaviorTeacherDetailsController);

  /* @ngInject */
  function BehaviorTeacherDetailsController($scope, periods, teachers, modes, categories, types, behaviorAnalyticsService) {
    /* jshint validthis: true */
    var vm = this;

    vm.periods = periods;
    vm.teachers = teachers;
    vm.modes = modes;
    vm.categories = categories;
    vm.cannedTypes = types;
    vm.options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: true,
          autoSkip: true,
          autoSkipPadding: 100,
        }],
        yAxes: [{
          stacked: true,
          ticks: {
            beginAtZero:true,
            callback: (value) => {
              if (value % 1 === 0) {
                return value;
              }
            }
          }
        }]
      }
    };

    vm.filters = {};
    Object.defineProperties(vm.filters, {
      _teacher: {
        enumerable: false,
        value: vm.teachers[0],
        writable: true,
      },
      teacher_id: {
        enumerable: true,
        get: function () {
          if (this._teacher) return this._teacher.id;
        },
      },
      _period: {
        value: vm.periods[0],
        writable: true,
        enumerable: false,
      },
      period_slug: {
        enumerable: true,
        get: function () {
          return this._period.slug;
        },
      },
      period_start: {
        enumerable: true,
        get: function () {
          return this._period.start.format('D-M-Y');
        },
      },
      period_end: {
        enumerable: true,
        get: function () {
          return this._period.end.format('D-M-Y');
        },
      },
      _period_table: {
        value: vm.periods[0],
        writable: true,
        enumerable: false,
      },
      period_table_start: {
        enumerable: true,
        get: function () {
          return this._period_table.start.format('D-M-Y');
        },
      },
      period_table_end: {
        enumerable: true,
        get: function () {
          return this._period_table.end.format('D-M-Y');
        },
      },
      _mode: {
        value: vm.modes[0],
        writable: true,
        enumerable: false,
      },
      mode: {
        enumerable: true,
        get: function () {
          return this._mode.value;
        }
      },
      _canned_type: {
        value: vm.cannedTypes[0],
        writable: true,
        enumerable: false,
      },
      canned_type_slug: {
        enumerable: true,
        get: function () {
          return this._canned_type.slug;
        }
      },
      _category: {
        value: vm.categories[0],
        writable: true,
        enumerable: false,
      },
      category_slug: {
        enumerable: true,
        get: function () {
          return this._category.slug;
        }
      },
      page: {
        value: 1,
        writable: true,
        enumerable: true,
      }
    });

    vm.charts = {
      byPeriod: {
        data: [],
        labels: [],
        series: [],
      },
      byCategories: {
        data: [],
        labels: []
      },
      byType: {
        data: [],
        labels: [],
      }
    };

    vm.items = {};
    vm.request = false;

    vm.getStatistics = getStatistics;
    vm.changePage = changePage;

    $scope.$watchCollection('BehaviorTeacherDetailsCtrl.filters', function () {
      getStatistics();
    });

    /////////////////

    function getStatistics() {
      behaviorAnalyticsService.teacherDetails(vm.filters).then(function (response) {
        vm.items = response.items;
        vm.byType = response.byType;
        vm.byCategory = response.byCategory;
        vm.period = response.byPeriod;
        vm.total = response.total;

        recalculateCharts();
      }).finally(function () {
        vm.request = false;
      });
    }

    function recalculateCharts() {
      vm.charts.byCategories.data = vm.byCategory.map((item) => {
        return item.count;
      });
      vm.charts.byCategories.labels = vm.byCategory.map((item) => {
        return item.name;
      });

      vm.charts.byType.data = vm.byType.map((item) => {
        return item.count;
      });
      vm.charts.byType.labels = vm.byType.map((item) => {
          return item.name +' : '+ Math.round((item.count / vm.total) * 100, 2) + '% ';
      });


      vm.charts.byPeriod = vm.period;
    }

    function changePage(number) {
      vm.filters.page = number;

      getStatistics();
    }
  }
})();