(function () {
  'use strict';

  angular
    .module('app.referral-analytics.teacher-comparisons')
    .controller('ReferralTeacherComparisonsController', ReferralTeacherComparisonsController);

  /* @ngInject */
  function ReferralTeacherComparisonsController($scope, $filter, referralAnalyticsService, periods, teacherTypes, grades) {
    /* jshint validthis: true */
    var vm = this;

    vm.periods = periods;
    vm.options = {
      legend: {display: false},
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          ticks: {
            beginAtZero:true,
            callback: (value) => {
              if (value % 1 === 0) {
                return value;
              }
            }
          }
        }]
      }
    };
    vm.optionsWithLegend = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          ticks: {
            beginAtZero:true,
            callback: (value) => {
              if (value % 1 === 0) {
                return value;
              }
            }
          }
        }]
      }
    };

    vm.types = [
      {
        text: 'Subject',
        values: teacherTypes,
        property: 'subject',
      },
      // {
      //   text: 'Grade',
      //   values: grades,
      //   property: 'grade',
      // }
    ];

    vm.filters = {};
    Object.defineProperties(vm.filters, {
      _type: {
        value: vm.types[0],
        writable: true,
        enumerable: false,
      },
      type: {
        enumerable: true,
        get: function () {
          return this._type.property;
        }
      },
      _teacher_type_id: {
        enumerable: false,
        value: undefined,
        writable: true,
      },
      teacher_type_id: {
        enumerable: true,
        get: function () {
          if (this.type === 'subject') return this._teacher_type_id;
        },
      },
      _teacher_type: {
        enumerable: false,
        get: function () {
          if (this.type === 'subject') return $filter('filter')(this._type.values, {id: this._teacher_type_id})[0];
        },
      },
      _grade_id: {
        enumerable: false,
        value: undefined,
        writable: true,
      },
      _grade: {
        enumerable: false,
        get: function () {
          if (this.type === 'grade') return $filter('filter')(this._type.values, {id: this._grade_id})[0];
        },
      },
      grade_id: {
        enumerable: true,
        get: function () {
          if (this.type === 'grade') return this._grade_id;
        },
      },
      _period: {
        value: vm.periods[3],
        writable: true,
        enumerable: false,
      },
      period_start: {
        enumerable: true,
        get: function () {
          return this._period.start.format('D-M-Y');
        },
      },
      period_end: {
        enumerable: true,
        get: function () {
          return this._period.end.format('D-M-Y');
        },
      },
    });

    vm.charts = {
      totalCommunication: {
        data: [],
        labels: [],
      },
      totalPosNeg: {
        data: [],
        labels: [],
        series: ['Positive', 'Negative'],
      },
    };

    vm.getStatistics = getStatistics;

    $scope.$watchCollection('ReferralTeacherComparisonsCtrl.filters', function () {
      getStatistics();
    });

    /////////////////

    function getStatistics() {
      referralAnalyticsService.teacherComparisons(vm.filters).then(function (response) {
        vm.charts.totalCommunication.data = response.byTeachers.map((item) => {
          return item.count;
        });

        vm.charts.totalCommunication.labels = response.byTeachers.map((item) => {
          return item.name;
        });

        vm.charts.totalPosNeg.data = response.byRegard.data;
        vm.charts.totalPosNeg.labels = response.byRegard.labels;

        document.getElementById("communication").style.maxHeight = response.byTeachers.length * 25 + 150 + 'px';
        document.getElementById("positive-negative").style.maxHeight = response.byRegard.labels.length * 25 + 150 + 'px';
      });
    }
  }
})();