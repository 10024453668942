(function () {
  'use strict';

  angular
    .module('app')
    .filter('gender', gender);

  /* @ngInject */
  function gender() {
    return function (genderIntValue) {
      return parseInt(genderIntValue) === 1 ? 'Male' : 'Female';
    };
  }

})();