(function () {
  'use strict';

  angular
    .module('app')
    .filter('customDateTime', customDateTime);

  /* @ngInject */
  function customDateTime() {
    return function (date) {
      if (!date) {
        return;
      }

      if (!(date instanceof moment)) {
        date = moment(date);
      }

      return date.format('DD-MM-YYYY/HH.mma');
    };
  }
})();