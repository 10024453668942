(function() {
    'use strict';

    angular
        .module('app.address-book')
        .controller('CreateStudentController', CreateStudentController);

    /* @ngInject */
    function CreateStudentController($uibModalInstance, studentsService, $scope, $rootScope, createfrom, ClassesService, $timeout, $sce) {
        /* jshint validthis: true */
        var vm = this;

        
        vm.student = {
            fullname: '',
            studentid: '',
            bdate: moment().toDate(),
            accomodations: [],
            gender: '',
            grade:'',
            school: '',
            term: '',
            currentperiod: '',
            relationship_other_measurement: '',
            more_relationship_other_measurement: '',
            clubs: '',
            other_club: '',
            classes: '',
            notes: '',

            g1fullname: '',
            g1email: '',
            g1homenumber: '',
            g1worknumber: '',
            g1mobilenumber: '',
            g1preferredcomm: '',
            g1address: '',
            g1relationship: '',
            g1other_relation:'',

            g2value: '',
            g2fullname: '',
            g2email: '',
            g2homenumber: '',
            g2worknumber: '',
            g2mobilenumber: '',
            g2preferredcomm: '',
            g2address: '', 
            g2relationship: '',
            g2other_relation: ''
        };
        
       vm.addstundentCount = 0;
        vm.tooltipstatus = true;
        vm.dynamicPopover = {
        	    content: 'Hello, World!',
        	    templateUrl: 'myPopoverTemplate.html',
        	    title: 'select SST',
        	    popoverIsOpen : false
        	    
        	  };
        
        vm.db_class_list = [];

        vm.db_club_list = [];


        vm.create = create;
        vm.cancel = cancel;

        vm.errors = [];
        vm.value = '';

        vm.createAndAddStudent = createAndAddStudent;
        vm.openGd2 = openGd2;
        vm.closeGd2 = closeGd2;
        $scope.gd2 = false;
        $scope.openGd1More = true;
        $scope.closeGd2More = false;
        vm.addAccomodation = addAccomodation;
        vm.generateStudentId  = generateStudentId ;
        vm.setSST = setSST;
        vm.sstcheck = '';
        vm.errorRemove = errorRemove;
        
       // $scope.$watch('CreateStudentCtrl.sstcheck', activetooltip, false);

        //////////////////
        ClassesService.getUserCreatedPeriod('/api/classes/classperiod').then(function(data) {

            //console.log(data);

            if (data !== null) {
                angular.forEach(data, function(class_period) {
                    if (class_period.group_type === '1')
                        vm.db_class_list.push({
                            id: class_period.id,
                            name: class_period.name,
                            period: class_period.period,
                            period_letter: class_period.period_letter
                        });
                    else
                        vm.db_club_list.push({
                            id: class_period.id,
                            name: class_period.name
                        });
                });
            }
        }, function() {
            
        });

        $rootScope.$on('getRandomNum', function(event, value) {
            vm.student.studentid = value.num;
           
        });

        vm.student.studentid="";

        function generateStudentId()
        {
            studentsService.generateRandom().then(function(response){
             $('#studentid').hide();
           	 $('#studentiddiv').removeClass('has-error');
                vm.student.studentid=response;
            });
        }

        
        function setSST(value){
        	if(value == 'AIP-SST'){
        		var bip = vm.student.accomodations.indexOf('BIP-SST');
        		if(bip > -1)
        		{
        			vm.student.accomodations.splice(bip, 1);
        			var aip = vm.student.accomodations.indexOf('AIP-SST');
            		if(aip > -1)
            		{
            			vm.student.accomodations.splice(aip, 1);
            			
            		}else{
            			vm.student.accomodations.push('AIP-SST');
            		}
        		}
        	}
        	if(value == 'BIP-SST'){
        		var ai_p = vm.student.accomodations.indexOf('AIP-SST');
        		if(ai_p > -1)
        		{
        			vm.student.accomodations.splice(ai_p, 1);
        			var bi_p = vm.student.accomodations.indexOf('BIP-SST');
            		if(bi_p > -1)
            		{
            			vm.student.accomodations.splice(bi_p, 1);
            			
            		}else{
            			vm.student.accomodations.push('BIP-SST');
            		}
        		}
        	}
        	console.log(vm.student.accomodations);
        }
        
        function addAccomodation(value) {
            if(value !== '') {
                var idx = vm.student.accomodations.indexOf(value); 
                var idNone = vm.student.accomodations.indexOf('');
                if (idNone > -1) {
                    vm.student.accomodations.splice(idNone, 1);
                    //vm.tooltipstatus = false;
                }
                if (idx > -1) {
                
                
                    vm.student.accomodations.splice(idx, 1);
                    
                }
                else {
                	
                    vm.student.accomodations.push(value);
                }
            }
            else
            {
                vm.student.accomodations = [];
                vm.student.accomodations.push('');
            }
            console.log(vm.student.accomodations);
           
        }



        function create() {
        
            if (createfrom === 'formClasses') {
            		
                studentsService.createStudentfromClass(vm.student).then(function(response) {
                	$scope.st = angular.copy(vm.student);
                    $rootScope.$emit('setStudent_form', $scope.st);
                    resetStudentForm();
                    $uibModalInstance.close();
                }, function(errorCallback) {
                    vm.errors = errorCallback;
                });

            } else {
                studentsService.create(vm.student).then(function(student) {
                	 $rootScope.$emit('refreshPage', {sucess:true});
                    $uibModalInstance.close(student);
                }, function(errorCallback) {

                    vm.errors = errorCallback;
                });

            }

        }

        function cancel() {
        	if(vm.addstundentCount >0){
            	$rootScope.$emit('refreshPage', {sucess:true});
        	}
            $uibModalInstance.dismiss();
        }

        function resetStudentForm()
        {
                vm.student.studentid="";
                vm.student.fullname="";
                vm.student.bdate=moment().toDate();
                vm.student.accomodations=[];
                vm.student.gender="";
                vm.student.grade="";
                vm.student.school="";
                vm.student.term="";
                vm.student.currentperiod="";
                vm.student.relationship_other_measurement="";
                vm.student.more_relationship_other_measurement="";
                vm.student.clubs="";
                vm.student.other_club="";
                vm.student.classes="";
                vm.student.notes="";
                vm.student.g1fullname="";
                vm.student.g1homenumber="";
                vm.student.g1email="";
                vm.student.g1fullname="";
                vm.student.g1worknumber="";
                vm.student.g1mobilenumber="";
                vm.student.g1preferredcomm="";
                vm.student.g1address="";
                vm.student.g1relationship="";
                vm.student.g1other_relation="";
                vm.student.g2homenumber="";
                vm.student.g2email="";
                vm.student.g2fullname="";
                vm.student.g2worknumber="";
                vm.student.g2mobilenumber="";
                vm.student.g2preferredcomm="";
                vm.student.g2address="";
                vm.student.g2relationship="";
                vm.student.g2other_relation="";
        }

        function createAndAddStudent() {
        	 vm.addstundentCount += 1;
            if (createfrom === 'formClasses') {
            	$scope.user = angular.copy(vm.student);
                studentsService.createStudentfromClass(vm.student).then(function(response) {
                    $rootScope.$emit('setStudents_form', $scope.user);
                  //  vm.tooltipstatus = false;
                    vm.dynamicPopover.popoverIsOpen = false;
                    	resetStudentForm();
                   
                }, function(errorCallback) {
                    vm.errors = errorCallback;

                });

            } else {


                studentsService.create(vm.student).then(function(student) {
                    //$uibModalInstance.close(student);
                    //$uibModalInstance.dismiss();
                     resetStudentForm();
                    //$rootScope.$broadcast("callCreateStudentModal");
                }, function(errorCallback) {
                    vm.errors = errorCallback;
                });

            }
            
        }
        
       /* $rootScope.$on('clearStudentform', function(event, value){
        	 resetStudentForm();
        });*/

        function openGd2() {
            $scope.gd2 = true;
            $scope.closeGd2More = true;
            $scope.openGd1More = false;
            vm.student.g2value = 'has';
        }

        function closeGd2() {
            $scope.gd2 = false;
            $scope.closeGd2More = false;
            $scope.openGd1More = true;
            vm.student.g2value = '';
        }
        
        function errorRemove(idName){
        	
        	
        	var inputvalue = document.getElementsByName(idName)[0].value;

        	if(inputvalue !==''){
        	 $('#' +idName).hide();
        	 $('#' +idName + 'div').removeClass('has-error');
        	}else{
        		 $('#' +idName).show();
            	 $('#' +idName + 'div').addClass('has-error');
        	}
        }

    }
})();