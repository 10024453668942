(function() {
    'use strict';

    angular
        .module('app')
        .service('helperService', helperService);

    /* @ngInject */
    function helperService($uibModal, toastr, adminsService) {
        /* jshint validthis: true */
        var vm = this;

        vm.showAlert = showAlert;
        vm.communicationModal = communicationModal;

        vm.setCannedData = setCannedData;

        vm.getAdmindata = getAdmindata;

        vm.getVesteddata = getVesteddata;

        vm.cannedTypes = [];
        vm.cannedTopics = [];
        vm.cannedCategories = [];
        vm.callStatuses = [];
        vm.admins = [];
        vm.vestedmembers = [];
        vm.classesModal = classesModal;
        vm.newclassModal = newclassModal;
        vm.paperworkModal = paperworkModal;
        vm.paperworkformModal = paperworkformModal;
        vm.letterassignModal = letterassignModal;
        vm.previewModal = previewModal;
      vm.handleResponse = handleResponse;
        /*    vm.bulkmodal = bulkmodal;*/

        ////////////////////

    function handleResponse(response) {
      return response.data;
    }

    function setCannedData(cannedTypes, cannedCategories, callStatuses) {
      vm.cannedTypes = cannedTypes;
      vm.cannedCategories = cannedCategories;
      vm.callStatuses = callStatuses;
    }

        function showAlert(message) {
            toastr.success(message);
        }

        function getAdmindata(admins) {
            //vm.admins = admins;
            vm.admins = adminsService.all();
        }

        function getVesteddata(vestedmembers) {
            vm.vestedmembers = vestedmembers;
        }

        function communicationModal(student, type, partial, active, selectedPeriod, backdrop) {
            //    	alert(selectedPeriod);

            if (partial == 'message' || partial == 'call' || partial == 'email') {
                return $uibModal.open({
                    templateUrl: '/app/partials/address-book/communication/' + partial + '.html',
                    controller: 'MessageController',
                    controllerAs: 'MessageCtrl',
                    backdrop: backdrop ? false : true ,
                    size: 'lg',
                    resolve: {
                        type: function() {
                            return type;
                        },
                        student: function() {
                            return student;
                        },
                        cannedTypes: function() {
                            return vm.cannedTypes;
                        },
                        cannedCategories: function() {
                            return vm.cannedCategories;
                        },
                        callStatuses: function() {
                            return vm.callStatuses;
                        },
                        active: function() {
                            return active;
                        },
                        selectedPeriod: function() {

                            return selectedPeriod;
                        }

                    }
                }).result;
            } else {
                return $uibModal.open({
                    templateUrl: '/app/partials/address-book/communication/' + partial + '.html',
                    controller: 'ReferralController',
                    controllerAs: 'ReferralCtrl',
                    size: 'lg',
                    resolve: {
                        type: function() {
                            return type;
                        },
                        student: function() {
                            return student;
                        },
                        cannedTypes: function() {
                            return vm.cannedTypes;
                        },
                        cannedCategories: function() {
                            return vm.cannedCategories;
                        },
                        callStatuses: function() {
                            return vm.callStatuses;
                        },
                        active: function() {
                            return active;
                        },
                        selectedPeriod: function() {

                            return selectedPeriod;
                        },
                        admindata: function() {
                            return adminsService.all();
                        },
                        vesteddata: function() {
                            return vm.vestedmembers;
                        }


                    }
                }).result;

            }

        }

        function classesModal(student, type, modaltype, partial, size) {

            if (partial == 'write-note-modal' || partial == 'incident-modal' || partial == 'positive-behavior-incident-modal' || partial == 'negative-behavior-incident-modal' || partial == 'positive-academic-incident-modal' ||
                partial == 'negative-academic-incident-modal' || partial == 'icon-change-modal' || partial == 'bulk-horn-modal' || partial == 'paperwork-modal') {

                return $uibModal.open({
                    templateUrl: '/app/partials/classes/modals/' + partial + '.html',
                    controller: 'ClassesPopUpController',
                    controllerAs: 'ClassesPopUpCtrl',
                    windowClass: (modaltype === 'paperworks')? "custom_class_modal":'',
                    size: size,
                    resolve: {
                        type: function() {
                            return type;
                        },
                        modaltype: function() {
                            return modaltype;
                        },
                        student: function() {
                            return student;
                        },
                    }
                }).result;
            }

        }


        function newclassModal(partial, size, students, formData, selectedstudent) {

            return $uibModal.open({
                templateUrl: '/app/partials/classes/modals/' + partial + '.html',
                controller: 'NewclassPopUpController',
                controllerAs: 'NewclassPopUpCtrl',
                size: size,
                resolve: {
                    students: function() {
                        return students;
                    },
                    formData: function() {
                        return formData;
                    },
                    prevSelected: function(){
                    	return selectedstudent;
                    }
                }
            }).result;
        }

        function previewModal(partial, size, students){
        	return $uibModal.open({
                templateUrl: '/app/partials/classes/modals/' + partial + '.html',
                controller: 'PreviewPopUpController',
                controllerAs: 'PreviewPopUpCtrl',
                size: size,
                resolve: {
                    students: function() {
                        return students;
                    }
                }
            }).result;
        }

        function paperworkModal(student, tier, status, type, partial, size) {
            return $uibModal.open({
                templateUrl: '/app/partials/paperworks/modals/' + partial + '.html',
                controller: 'PaperworkModalController',
                controllerAs: 'PaperworkModalCtrl',
                size: size,
                resolve: {
                    student: function() {
                        return student;
                    },
                    type: function() {
                        return type;
                    },
                }

            }).result;
        }

        function letterassignModal(partial, size, formId, formtype, studentid, description, category, type, formname, students) {
            return $uibModal.open({
                templateUrl: '/app/partials/paperworkforms/modals/' + partial + '.html',
                controller: 'LetterassignModalController',
                controllerAs: 'LetterassignModalCtrl',
                windowClass:'custom_student_modal',
                size: size,
                resolve: {
                    formid: function() {
                        return formId;
                    },
                    formtype: function() {
                        return formtype;
                    },

                    studentId: function(){
                    	return studentid;
                    },

                    description: function(){
                    	return description;
                    },
                    category: function(){
                    	return category;
                    },
                    type: function(){
                    	return type;
                    },
                    formName : function(){
                    	return formname;
                    },
                    students: function(){
                    	return students;
                    }
                }

            }).result;

        }



        function paperworkformModal(student, formId, partial, size, type, formtype, reason) {

            return $uibModal.open({
                templateUrl: '/app/partials/paperworkforms/modals/' + partial + '.html',
                controller: 'PaperworkformModalController',
                controllerAs: 'PaperworkformModalCtrl',
                size: size,
                resolve: {
                    student: function() {
                        return student;
                    },
                    type: function() {
                        return type;
                    },
                    formtype: function() {
                        return formtype;
                    },
                    reason: function() {
                        return reason;
                    },

                    formid: function() {
                        return formId;
                    }
                }

            }).result.then(function(response) {
                
            });

        }


    }
})();