(function () {
  'use strict';

  angular
    .module('app')
    .filter('fullname', fullname);

  /* @ngInject */
  function fullname() {
    return function (user) {
      return user.first_name + ' ' + user.last_name;
    };
  }
})();