(function () {
  'use strict';

  angular
    .module('app.setup-account')
    .controller('SetupAccountController', SetupAccountController);

  /* @ngInject */
  function SetupAccountController($scope, $filter, states, authService, personTitles, staffRoles, staffTypes, $timeout, UserSettingService, $rootScope, toastr) {
    /* jshint validthis: true */
    let vm = this;

    vm.states = states;
    vm.staffTypes = staffTypes;
    vm.personTitles = personTitles;
    vm.scheduledOther = new Date();
    vm.form = {
      school: {},
      calendar: {},
      staff: [],
      mobile_number: '',
      isPaidUser:$rootScope.userRegisType
    };
    
    vm.form.calendar.bell_schedule = 1;
    vm.form.calendar.schedule_date = new Date();
    
    
    $rootScope.$on('subscriptionError', function(event, arr){
    	vm.Is_subscribe = false;
	});
    

    
    const finalStep = ($rootScope.userRegisType === '2') ? 5 : 4;

    console.log(staffRoles);

    vm.step = (authService.completedRegistration() ? finalStep : 0) + 1;

    vm.next = next;
    vm.back = back;
    vm.addStaffRow = addStaffRow;
    
    vm.Is_subscribe = false;
    vm.hideError = hideError;
    
    
    vm.setDateSchedule = [];
    vm.calculateDateSchedule = calculateDateSchedule;
    

    vm.startDateOnSetTime = startDateOnSetTime;
    vm.endDateOnSetTime = endDateOnSetTime;
    vm.exceptionDateOnSetTime = exceptionDateOnSetTime;
    vm.startDateBeforeRender = startDateBeforeRender;
    vm.endDateBeforeRender = endDateBeforeRender;
    vm.exceptionDateBeforeRender = exceptionDateBeforeRender;
    vm.removeExceptionDate = removeExceptionDate;
    vm.checkStaff = checkStaff;
    vm.setStaff = setStaff;
    vm.SubmitSubscription = SubmitSubscription;

    vm.subscription = {
    	      cardNumber: undefined,
    	      expMonth: undefined,
    	      expYear: undefined,
    	      cvv: undefined,
    	      stripe_token: undefined,
    	      plan: '',
    	      url: undefined,
    	    };

    
    prepareStaff();
    prepareCalendar();
    calculateDateSchedule();

    ///////////////

    function next() {
    	var isTrue = true;
    	if(($('#school_name').val()) === ''){
    		$("#school_name_err").html('');
    		$("#school_name_err").append("Please fill up School Name");
    		isTrue = false;
    	}
    	else if(($("#state_name").val()) === ''){
    		$("#state_err").append("Please fill up State");
    		isTrue = false;
    	}else if(($("#district").val()) === ''){
    		$("#district_err").html('');
    		$("#district_err").append("Please fill up Name of School District");
    		isTrue = false;
    	}
    	
    	if(!isTrue){return isTrue;}
    	
    	
    	
      if (vm.step === finalStep) {
        authService.setupAccount(prepareFormForRequest(vm.form)).then(() => {
          vm.step++;
        });
        
        $timeout(function () {
        	$("body,html").removeClass("setup-account-layout");
  		  authService.logout();
  	    }, 10000);
      }

      if (vm.step < finalStep) {
    	  if(vm.form.calendar.schedule_date === 'diff_date'){
    		  vm.form.calendar.schedule_date = $filter('date')(new Date(vm.scheduledOther), "dd-MM-yyyy");    		  
    	  }
    	  
    	  //alert(vm.form.scheduleDate);
        vm.step++;
      }

    }

    function back() {
      if (vm.step > 1 && vm.step <= finalStep) {
        vm.step--;
      }
    }

    function addStaffRow() {
      let staff = {
        title: 'Mr.',
        fullname: '',
        email: '',
      };

      addValidateProperties(staff);
      vm.form.staff.push(staff);
    }
    
    function SubmitSubscription()
    {
    	
       vm.flag=1;

      if(vm.subscription.cardNumber==="" || vm.subscription.cardNumber===undefined)
       {
         vm.flag=0;
         toastr.error('Card number required');
       }

       if(vm.subscription.expMonth==="" || vm.subscription.expMonth===undefined)
       {
         vm.flag=0;
         toastr.error('Expiration month required');
       }
       if(vm.subscription.expYear==="" || vm.subscription.expYear===undefined)
       {
         vm.flag=0;
         toastr.error('Expiration year required');
       }
       if(vm.subscription.cvv==="" || vm.subscription.cvv===undefined)
       {
         vm.flag=0;
         toastr.error('CVV required');
       }

       if(vm.flag===1)
       {	
    	   vm.Is_subscribe = true;
           UserSettingService.updateSubscription('api/user/updatesubscription',vm.subscription).then(function(data)
           {
                 
              vm.subscription.stripe_token=data.response.token; 

              if(vm.subscription.stripe_token!=="")
              {
                UserSettingService.Subscription('api/settings/subscription',vm.subscription).then(function(subs_data)
                 {
             	  
                     //console.log(subs_data);  
                     toastr.success('Subscription completed successfully');
                		next();
                       vm.subscription.cardName="";
                       vm.subscription.cardNumber="";
                       vm.subscription.expYear="";
                       vm.subscription.expMonth="";
                       vm.subscription.cvv="";
                       vm.Is_subscribe = false;
                      
                 });
              }
               
           });

       }
       
    }
    
    
    

    function prepareStaff() {
      staffRoles.forEach((staffRole, index) => {
        let staffType = $filter('filter')(vm.staffTypes, {slug: staffRole}, true);
        if (!staffType.length) {
          return;
        }

        staffType = staffType[0];

        let staff = {
          title: 'Mr.',
          fullname: '',
          email: '',
          type_id: staffType.id,
        };

        Object.defineProperties(staff, {
          _roleTitle: {
            enumerable: false,
            value: 'Who is your ' + staffType.title + '?'
          },
          _hideNotSure: {
            enumerable: false,
            value: !!(index === 0 || index === 1)
          }
        });

        addValidateProperties(staff);

        vm.form.staff.push(staff);
      });
    }

    function prepareCalendar() {
      Object.defineProperties(vm.form.calendar, {
        exception_dates: {
          enumerable: true,
          value: [],
          writable: true
        },
        _dateRangeStart: {
          enumerable: false,
          value: moment(),
          writable: true
        },
        _dateRangeEnd: {
          enumerable: false,
          value: moment(),
          writable: true
        },
        start: {
          enumerable: true,
          get: function () {
            return this._dateRangeStart.format('DD-MM-YYYY');
          }
        },
        end: {
          enumerable: true,
          get: function () {
            return this._dateRangeEnd.format('DD-MM-YYYY');
          }
        },
      });
    }

    function startDateOnSetTime() {
      $scope.$broadcast('start-date-changed');
      $scope.$broadcast('exception-date-changed');
    }

    function endDateOnSetTime() {
      $scope.$broadcast('end-date-changed');
      $scope.$broadcast('exception-date-changed');
    }

    function exceptionDateOnSetTime() {
      vm.form.calendar.exception_dates.push({
        date: vm.exceptionDate.format('DD-MM-YYYY')
      });

      $scope.$broadcast('exception-date-changed');
    }

    function startDateBeforeRender($dates) {
      let activeDate = vm.form.calendar._dateRangeEnd;

      $dates.filter((date) => {
        return date.localDateValue() >= activeDate.valueOf() || isWeekend(date);
      }).forEach((date) => {
        date.selectable = false;
      });
    }

    function endDateBeforeRender($view, $dates) {
      let activeDate = vm.form.calendar._dateRangeStart;

      $dates.filter((date) => {
        return date.localDateValue() <= activeDate.valueOf() || isWeekend(date);
      }).forEach((date) => {
        date.selectable = false;
      });
    }

    function exceptionDateBeforeRender($view, $dates, $leftDate) {
      let start = vm.form.calendar._dateRangeStart,
        end = vm.form.calendar._dateRangeEnd;

      $dates.filter((date) => {
        return date.localDateValue() <= start.valueOf() || date.localDateValue() >= end.valueOf() || isWeekend(date) || includeInExceptionDates(date);
      }).forEach((date) => {
        date.selectable = false;
      });
    }

    function isWeekend(date) {
      return moment(date.localDateValue()).isoWeekday() > 5;
    }

    function includeInExceptionDates(date) {
      let dateFormatted = moment(date.localDateValue()).format('DD-MM-YYYY');

      return vm.form.calendar.exception_dates.filter((exceptDate) => {
        return exceptDate.date === dateFormatted;
      }).length;
    }

    function removeExceptionDate(date) {
      vm.form.calendar.exception_dates.splice(vm.form.calendar.exception_dates.indexOf(date), 1);

      $scope.$broadcast('exception-date-changed');
    }

    function prepareFormForRequest(form) {
      let _form = angular.copy(form);

      _form.staff = _form.staff.filter((staff) => {
        return !staff.note_sure && staff.type_id && staff.fullname;
      });

      return _form;
    }

    function checkStaff() {
      return vm.form.staff.filter((staff) => {
        return !(staff.fullname && staff.email && staff.type_id || staff.not_sure);
      }).length;
    }

    function setStaff() {
      if(checkStaff()) {
        return ;
      }

      next();
    }

    function addValidateProperties(staff) {
      Object.defineProperties(staff, {
        _valid_title: {
          enumerable: false,
          get: function () {
            return this.title || this.not_sure;
          },
        },
        _valid_fullname: {
          enumerable: false,
          get: function () {
            return this.fullname || this.not_sure;
          }
        },
        _valid_email: {
          enumerable: false,
          get: function () {
            return this.email || this.not_sure;
          }
        },
        _valid_type_id: {
          enumerable: false,
          get: function () {
            return this.type_id || this.not_sure;
          }
        },
      });
    }
    
    function hideError(field){
    	
    	if(($("#"+ field).val()) !== ''){
    	$("#" + field + '_err').hide();
    	}else{
    		$("#" + field + '_err').show();
    	}
    }
    
    function calculateDateSchedule(){
    	  	
    	var d = new Date();

    	switch(d.getDay()){
    		case 0:
    			break;
    		case 1:
    			vm.setDateSchedule.push({date: $filter('date')(new Date(), "dd-MM-yyyy"), title:'Today Monday '+ $filter('date')(new Date(), "dd-MM-yyyy")},
    			{date: $filter('date')(new Date().setDate(new Date().getDate() + 1),"dd-MM-yyyy"), title:'Tomorrow Tuesday '+ $filter('date')(new Date().setDate(new Date().getDate() + 1),"dd-MM-yyyy")},
    			{date: $filter('date')(new Date().setDate(new Date().getDate() + 2),"dd-MM-yyyy"), title:'In 2 Days, Wednesday '+ $filter('date')(new Date().setDate(new Date().getDate() + 2),"dd-MM-yyyy")}
    			);
    			break;
    		case 2:
    			vm.setDateSchedule.push({date: $filter('date')(new Date(), "dd-MM-yyyy"), title:'Today Tuesday '+ $filter('date')(new Date(), "dd-MM-yyyy")},
    	    	{date: $filter('date')(new Date().setDate(new Date().getDate() + 1),"dd-MM-yyyy"), title:'Tomorrow Wednesday '+ $filter('date')(new Date().setDate(new Date().getDate() + 1),"dd-MM-yyyy")},
    	    	{date: $filter('date')(new Date().setDate(new Date().getDate() + 2),"dd-MM-yyyy"), title:'In 2 Days, Thursday '+ $filter('date')(new Date().setDate(new Date().getDate() + 2),"dd-MM-yyyy")}
    	    	);
    			break;
    		case 3:
    			vm.setDateSchedule.push({date: $filter('date')(new Date(), "dd-MM-yyyy"), title:'Today Wednesday '+ $filter('date')(new Date(), "dd-MM-yyyy")},
    	    	{date: $filter('date')(new Date().setDate(new Date().getDate() + 1),"dd-MM-yyyy"), title:'Tomorrow Thursday '+ $filter('date')(new Date().setDate(new Date().getDate() + 1),"dd-MM-yyyy")},
    	    	{date: $filter('date')(new Date().setDate(new Date().getDate() + 2),"dd-MM-yyyy"), title:'In 2 Days, Friday '+ $filter('date')(new Date().setDate(new Date().getDate() + 2),"dd-MM-yyyy")}
    	    	 );
    			break;
    		case 4:
    			vm.setDateSchedule.push({date: $filter('date')(new Date(), "dd-MM-yyyy"), title:'Today Thursday '+ $filter('date')(new Date(), "dd-MM-yyyy")},
    	    	{date: $filter('date')(new Date().setDate(new Date().getDate() + 1),"dd-MM-yyyy"), title:'Tomorrow Friday '+ $filter('date')(new Date().setDate(new Date().getDate() + 1),"dd-MM-yyyy")},
    	    	{date: $filter('date')(new Date().setDate(new Date().getDate() + 4),"dd-MM-yyyy"), title:'In 4 Days, Monday '+ $filter('date')(new Date().setDate(new Date().getDate() + 4),"dd-MM-yyyy")}
    	    	);
    			break;
    		case 5:
    			vm.setDateSchedule.push({date: $filter('date')(new Date(), "dd-MM-yyyy"), title:'Today Friday '+ $filter('date')(new Date(), "dd-MM-yyyy")},
    	    	{date: $filter('date')(new Date().setDate(new Date().getDate() + 3),"dd-MM-yyyy"), title:'In 3 Days Monday '+ $filter('date')(new Date().setDate(new Date().getDate() + 3), "dd-MM-yyyy")},
    	    	{date: $filter('date')(new Date().setDate(new Date().getDate() + 4),"dd-MM-yyyy"), title:'In 4 Days, Tuesday '+ $filter('date')(new Date().setDate(new Date().getDate() + 4),"dd-MM-yyyy")}
    	    	);
    			break;
    		case 6:
    			break;
    			
    	}
    	
    	
    }
    

  }
})();