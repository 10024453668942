(function () {
  'use strict';

  angular.module('app.response-to-intervention-analytics', [
    'app.response-to-intervention-analytics.quickview',
    'app.response-to-intervention-analytics.subject-grade-comparisons',
    'app.response-to-intervention-analytics.teacher-comparisons',
    'app.response-to-intervention-analytics.teacher-details',
  ]);

})();