(function (){
	'use strict';
	
	angular
		.module('app')
		.filter('incidentType',incidentType);
		
	incidentType.$inject =[];
	
	function incidentType(){
		return function (incidentValue){
		
		var incidentStringValue = '';
		
		switch(parseInt(incidentValue)){
			case 1:
				incidentStringValue = 'disruption';
				break;
			case 2:
				incidentStringValue = 'disrespect';
				break;
			case 3:
				incidentStringValue = 'property misuses';
				break;
			case 4:
				incidentStringValue = 'dress code';
				break;	
			case 5:
				incidentStringValue = 'electronic violation';
				break;	
			case 6:
				incidentStringValue = 'inappropriate location';
				break;					
			case 7:
				incidentStringValue = 'bullying/harassment';
				break;	
			case 8:	
				incidentStringValue = 'other';
				break;
		}
		
		if(incidentValue === ''){
			incidentStringValue ='All';
		}
		
		return incidentStringValue;
		};
		
	}
	
	
	
})();