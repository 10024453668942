(function () {
  'use strict';

  angular
    .module('app')
    .service('referralService', referralService);

  /* @ngInject */
  function referralService($http) {
    /* jshint validthis: true */
    var vm = this;

    vm.types = types;
    vm.topics = topics;
    vm.categories = categories;
    vm.callStatuses = callStatuses;
    vm.messages = messages;
    vm.create = create;
    vm.createBlast = createBlast;

    //////////////////////////////

    function types() {
      return $http.get('/api/types').then(fetchData);
    }

    function topics(category_id, type_id) {
      return $http.get('/api/topics?category_id=' + category_id + '&type_id=' + type_id).then(fetchData);
    }

    function categories() {
      return $http.get('/api/categories').then(fetchData);
    }

    function callStatuses() {
      return $http.get('/api/call-statuses').then(fetchData);
    }

    function messages(data) {
      return $http.get('/api/canned-messages', {params: data}).then(fetchData);
    }

    function create(message) {
      return $http.post('/api/referral', message).then(fetchData);
    }

    function createBlast(message) {
      return $http.post('/api/messages-blast', message).then(fetchData);
    }

    function fetchData(response) {
      return response.data;
    }
  }
})();