(function () {
  'use strict';

  angular
    .module('app.core')
    .service('vestedMembersService', vestedMembersService);

  function vestedMembersService($resource, $http) {
    /* jshint validthis: true */
    var vm = this;

    var VestedMember = $resource('/api/vested-members/:vestedMemberId', {vestedMemberId: '@id'}, {
      update: {method: 'PUT'}
    });

    vm.all = all;
    vm.create = create;
    vm.update = update;
    vm.remove = remove;
    vm.updatedVested = updatedVested;
    vm.removeVested = removeVested;

    /////////////////

    function all() {
      return VestedMember.query().$promise;
    }

    function create(data) {
      return VestedMember.save(data).$promise;
    }

    function update(vestedMember) {
      return VestedMember.update(vestedMember).$promise;
    }

    function remove(vestedMember) {
      return VestedMember.delete({vestedMemberId: vestedMember.id}).$promise;
    }
    
    function  updatedVested(vestedMember){
        
        return $http.post('/api/vested-members/vestedupdate', vestedMember).then(fetchData);
      }
      
      function removeVested(vestedMember){
        return $http.post('/api/vested-members/vesteddelete', vestedMember).then(fetchData);
      }
      
      function fetchData(response) {
          return response.data;
        }
        
      
    
  }
})();