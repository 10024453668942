(function () {
  'use strict';

  angular
    .module('app')
    .filter('messagesByType', messagesByType);

  /* @ngInject */
  function messagesByType() {
    return function (messages, type) {
      return messages.filter((message) => {
        return message.type.slug === type;
      });
    };
  }

})();