(function() {
    'use strict';

    angular
        .module('app')
        .config(
            function($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider) {

                $httpProvider.useApplyAsync(true);
                // For any unmatched url, redirect to /state1
                // Now set up the states
                $stateProvider
                    .state(
                        'login', {
                            url: '/login',
                            templateUrl: '/app/partials/login/login.html',
                            controller: 'LoginController',
                            controllerAs: 'LoginCtrl',
                            resolve: {
                                    /*loadMyCtrl: ['$ocLazyLoad', function($ocLazyLoad) {
                                          // you can lazy load files for an existing module
                                                 return $ocLazyLoad.load('js/custom.js');
                                        }]*/

                            }
                        })
                    .state(
                        'resetpassword', {
                            url: '/resetpassword/:uid',
                            templateUrl: '/app/partials/login/resetpassword.html',
                            controller: 'ResetPasswordController',
                            controllerAs: 'ResetPasswordCtrl',
                            resolve: {}
                        })  
                    .state('activation', {
                        url: '/activation?code',
                        templateUrl: '/app/partials/login/activation.html',
                        controller: 'ActivationController',
                        controllerAs: 'ActivationCtrl',
                        resolve: {
                            user: (authService, $stateParams) => {
                                return authService.activate($stateParams.code);
                            }
                        }
                    })
					.state('adminactivation', {
                        url: '/adminactivation?code&type',
                        templateUrl: '/app/partials/login/admin_activation.html',
                        controller: 'ActivationController',
                        controllerAs: 'ActivationCtrl',
                        resolve: {
                            user: (authService, $stateParams) => {
                                return authService.activate($stateParams.code);
                            }
                        }
                    })
                    .state(
                        'registration', {
                            url: '/registration/:utype',
                                templateUrl: '/app/partials/login/signup.html',
                                controller: 'RegistrationController',
                                controllerAs: 'RegistrationCtrl',
                                resolve: {
                                    types: function(teacherTypesService) {
                                        return teacherTypesService.all();
                                    }
                                }   
                        })
                    .state(
                        'address-book', {
                            parent: 'base',
                            url: "/address-book",
                            templateUrl: "/app/partials/address-book/address-book.html",
                            controller: 'AddressBookController',
                            controllerAs: 'AddressBookCtrl',
                            title: 'Address Book',
                            resolve: {
                                adminTypes: function(staffTypesService) {
                                    return staffTypesService.admin();
                                },
                                vestedMemberTypes: function(staffTypesService) {
                                    return staffTypesService.vestedMember();
                                },
                                /*students: function(
                                    studentsService) {
                                    return studentsService
                                        .all();

                                },

                                admins: function(adminsService) {
                                    return adminsService.all();
                                },
                                vestedMembers: function(
                                    vestedMembersService) {
                                    return vestedMembersService
                                        .all();
                                },
                                cannedTypes: function(
                                    $rootScope) {
                                    return $rootScope.cannedTypes;
                                },
                                cannedCategories: function(
                                    $rootScope) {
                                    return $rootScope.cannedCategories;
                                },
                                callStatuses: function(
                                    $rootScope) {
                                    return $rootScope.callStatuses;
                                }*/
                            }
                        })
                    .state(
                        'classes', {
                            parent: 'base',
                            abstract: true,
                            url: "/classes",
                            //templateUrl: "/app/partials/classes/class-period.html",
                            title: 'Classes',
                            views: {
                                '': {
                                    templateUrl: '/app/partials/classes/class-period.html',
                                    controller: function($scope, $stateParams, ClassesService) {
                                        
                                        $scope.db_class_list = [];
                                        $scope.db_club_list = [];
                                        $scope.class_id = 0;
                                        $scope.class_icon = 'fa fa-line-chart';
                                        $scope.class_text = 'All Classes';
                                        $scope.$on('class_id', function(event, arg) {
                                        $scope.class_id = arg;
                                        $scope.class_icon = 'fa fa-line-chart';
                                        $scope.class_text = 'All Classes';
                                        $scope.class_access_code = '';
                                        $scope.class_qr_code = '';
                                        $scope.qrcode_imagepath = '';
                                        $scope.unverified_student_count = '';
                                        var count=0;
                                        var IntervalTime=60000;


                                            ClassesService.getclassesData('api/classes?class_id=' + $scope.class_id).then(function(data) {
                                                if (data.class_period !== null) {
                                                    angular.forEach(data.class_period, function(class_period) {
                                                        if (class_period.group_type === '1') {
                                                            if (class_period.id == $scope.class_id) {
                                                                $scope.class_text = class_period.name + ' Period ' + class_period.period + '-' + class_period.period_letter;
                                                                $scope.class_icon = class_period.active_icon;
                                                                $scope.class_access_code = class_period.class_access_code;
                                                                $scope.class_qr_code = class_period.qr_code;
                                                                $scope.qrcode_imagepath= class_period.qrcode_imagepath;
                                                                $scope.unverified_student_count = class_period.unverified_student_count;
                                                            }
                                                        } else if (class_period.group_type === '2') {
                                                            if (class_period.id == $scope.class_id) {
                                                                $scope.class_text = class_period.name;
                                                                $scope.class_icon = class_period.active_icon;
                                                                $scope.class_access_code = class_period.class_access_code;
                                                                $scope.class_qr_code = class_period.qr_code;
                                                                $scope.qrcode_imagepath= class_period.qrcode_imagepath;
                                                                $scope.unverified_student_count = class_period.unverified_student_count;
                                                            }
                                                        }
                                                    });
                                                }
                                            });

                                      if($scope.class_id!=='' || $scope.class_id!==null || $scope.class_id!==undefined)
                                      {
                                        $scope.myinterval=setInterval(function(){

                                            var my_url=window.location.origin+'/#/base/classes/roaster/'+$scope.class_id;
                                            if(window.location.href ===my_url)
                                            {
                                                count=count+1;
                                                console.log(count);
                                                ClassesService.getclassesData('api/classes?stop_loader='+count+'&class_id=' + $scope.class_id).then(function(data) {
                                                    if (data.class_period !== null) {
                                                        angular.forEach(data.class_period, function(class_period) {
                                                            if (class_period.group_type === '1') {
                                                                if (class_period.id == $scope.class_id) {
                                                                    $scope.class_text = class_period.name + ' Period ' + class_period.period + '-' + class_period.period_letter;
                                                                    $scope.class_icon = class_period.active_icon;

                                                                    $scope.class_access_code = class_period.class_access_code;
                                                                    $scope.class_qr_code = class_period.qr_code;
                                                                    $scope.qrcode_imagepath= class_period.qrcode_imagepath;
                                                                    $scope.unverified_student_count = class_period.unverified_student_count;

                                                                }
                                                            } else if (class_period.group_type === '2') {
                                                                if (class_period.id == $scope.class_id) {
                                                                    $scope.class_text = class_period.name;
                                                                    $scope.class_icon = class_period.active_icon;
                                                                    $scope.class_access_code = class_period.class_access_code;
                                                                    $scope.class_qr_code = class_period.qr_code;
                                                                    $scope.qrcode_imagepath= class_period.qrcode_imagepath;
                                                                    $scope.unverified_student_count = class_period.unverified_student_count;
                                                                }
                                                            }
                                                        });
                                                    }
                                                });

                                            }
                                            
                                        }, IntervalTime); 
                                      }  


                                        });
                                        ClassesService.getclassesData('api/classes?class_id=0').then(function(data) {
                                            if (data.class_period !== null) {
                                                angular.forEach(data.class_period, function(class_period) {
                                                    if (class_period.group_type === '1') {
                                                        $scope.db_class_list.push({
                                                            id: class_period.id,
                                                            period: class_period.period,
                                                            period_letter: class_period.period_letter
                                                        });
                                                    } else if (class_period.group_type === '2') {
                                                        console.log('test');
                                                        $scope.db_club_list.push({
                                                            id: class_period.id,
                                                            name: class_period.name,
                                                            period: class_period.period,
                                                            period_letter: class_period.period_letter
                                                        });
                                                    }
                                                });
                                            }
                                        });
                                    },
                                }
                            },
                        })
                    .state(
                        'classes.roaster', {
                            url: "/roaster/:id?",
                            templateUrl: "/app/partials/classes/roaster.html",
                            controller: "RoasterController",
                            controllerAs: "RoasterCtrl",
                            title: 'Classes',

                            data: {
                                tab: 'roaster'
                            },
                            resolve: {


                            }
                        })
                    .state(
                        'classes.attendance', {
                            url: "/attendance/:id?",
                            templateUrl: "/app/partials/classes/take-attendance.html",
                            controller: "TakeattendanceController",
                            controllerAs: "TakeattendanceCtrl",
                            data: {
                                tab: 'take-attendance'
                            },
                            params: {
                            	IsfromAtendance: false,
                            },
                            title: 'Classes',
                            resolve: {
                                students: function(
                                    ClassesService) {
                                    return ClassesService
                                        .getclassesData('api/classes/getstudentByteacher');
                                }
                            }
                        })
                       
                    .state(
                        'addnewclass', {
                            parent: 'base',
                            // abstract: true,
                            url: "/classes/add/:club?",
                            params: {
                                id: null,
                                redirect_type:null
                            },
                            templateUrl: "/app/partials/classes/newclass-template.html",
                            controller: "AddnewclassController",
                            controllerAs: "AddnewclassCtrl",
                            title: 'Classes',
                            resolve: {
                                activateIcon: function(
                                    ClassesService) {
                                    return ClassesService
                                        .getIcon();
                                },
                                students: function(
                                    ClassesService) {
                                    return ClassesService
                                        .getclassesData('api/students/getstudentbyuser');
                                },
                                schoolPeriods: (schoolPeriodsService) => {
                                    return schoolPeriodsService.all();
                                },
                                schoolStudents: (schoolStudentsService) => {
                                    return schoolStudentsService.all();
                                }
                            }

                        })                                                
                    .state(
                        'student', {
                            parent: 'base',
                            abstract: true,
                            url: "/student/:studentId?type",
                            // params:{
                            // fromValue: null
                            // },

                            templateUrl: "/app/partials/student/template.html",
                            controller: 'StudentController',
                            controllerAs: 'StudentCtrl',
                            title: 'Student',
                            resolve: {
                                student: function(
                                    $stateParams,
                                    studentsService) {
                                    return studentsService
                                        .get($stateParams.studentId);
                                },
                                types: function(
                                    cannedMessagesService) {
                                    return cannedMessagesService
                                        .types();
                                },
                                categories: function(
                                    cannedMessagesService) {
                                    return cannedMessagesService
                                        .categories();
                                },
                            }
                        })
                    .state(
                        'student.summary', {
                            url: "/summary",
                            templateUrl: "/app/partials/student/summary.html",
                            data: {
                                tab: 'summary',
                            }
                        })
                    .state(
                        'student.info', {
                            url: "/information",
                            templateUrl: "/app/partials/student/info.html",
                            data: {
                                tab: 'info'
                            }
                        })
                    .state(
                        'student.reports', {
                            url: "/reports",
                            templateUrl: "/app/partials/student/reports.html",
                            data: {
                                tab: 'reports'
                            }
                        })
                    .state(
                        'reports', {
                            parent: 'base',
                            abstract: true,
                            url: "/reports",
                            templateUrl: "/app/partials/reports/template.html"
                        })
                    .state(
                        'reports.communication', {
                            url: "/communication",
                            data: {
                                tab: 'communication'
                            },
                            templateUrl: "/app/partials/reports/communication.html",
                            controller: 'Communi_cationController',
                            controllerAs: 'Communi_cationCtrl',
                            title: 'Reports',
                            resolve: {
                                students: function(
                                    studentsService) {
                                    return studentsService
                                        .all();
                                },
                                communication: function(
                                    studentsService) {
                                    return studentsService
                                        .getForRange({
                                            url: '/api/students/for-period',
                                            range: 'year',
                                            customRanges: ''
                                        });
                                },
  
                            }
                        })
                    .state(
                        'reports.attendance', {
                            url: "/attendance",
                            data: {
                                tab: 'attendance'
                            },
                            templateUrl: "/app/partials/reports/attendance.html",
                            controller: 'AttendanceController',
                            controllerAs: 'AttendanceCtrl',
                            title: 'Reports',
                            resolve: {
                                students: function(
                                    studentsService) {
                                    return studentsService
                                        .all();
                                },
                                attendanceByyear: function(
                                    studentsService) {
                                    return studentsService
                                        .getForRange({
                                            url: '/api/students/attendance',
                                            range: 'year',
                                            customRanges: ''
                                        });
                                },
                            }
                        })
                    .state(
                        'reports.behavior', {
                            url: "/behavior",
                            data: {
                                tab: 'behavior'
                            },
                            templateUrl: "/app/partials/reports/behavior.html",
                            controller: 'BehaviorController',
                            controllerAs: 'BehaviorCtrl',
                            title: 'Reports',
                            resolve: {
                                students: function(
                                    studentsService) {
                                    return studentsService
                                        .all();
                                },
                                behaviorByyear: function(
                                    studentsService) {
                                    return studentsService
                                        .getForRange({
                                            url: '/api/students/behavior',
                                            range: 'year',
                                            customRanges: ''
                                        });
                                },
                            }
                        })
                    .state(
                        'reports.paperwork', {
                            url: "/paperwork",
                            data: {
                                tab: 'paperwork'
                            },
                            templateUrl: "/app/partials/reports/paperwork.html",
                            controller: 'PaperworkController',
                            controllerAs: 'PaperworkCtrl',
                            title: 'Reports',
                            resolve: {

                                students: function(
                                    studentsService) {
                                    return studentsService
                                        .all();
                                },
                                paperworkByyear: function(
                                    studentsService) {
                                    return studentsService
                                        .getForRange({
                                            url: '/api/students/paperwork',
                                            range: 'year',
                                            customRanges: ''
                                        });
                                },

                            }
                        })
                    .state(
                        'reports.academic', {
                            url: "/academic",
                            data: {
                                tab: 'academic'
                            },
                            templateUrl: "/app/partials/reports/academic.html",
                            controller: 'AcademicController',
                            controllerAs: 'AcademicCtrl',
                            title: 'Reports',
                            resolve: {
                                students: function(
                                    studentsService) {
                                    return studentsService
                                        .all();
                                },
                                academicByyear: function(
                                    studentsService) {
                                    return studentsService
                                        .getForRange({
                                            url: '/api/students/academic',
                                            range: 'year',
                                            customRanges: ''
                                        });
                                },
                            }
                        })
                    .state(
                        'paperworks', {
                            parent: 'base',
                            abstract: true,
                            url: "/paperworks",
                            templateUrl: "/app/partials/paperworks/paperwork-template.html",

                        })
                    .state(
                        'paperworks.createForm', {
                            url: "/createform",
                            title: "Paperworks",
                            data: {
                                tab: 'createForm'
                            },
                            templateUrl: "/app/partials/paperworks/createform.html",
                            controller: 'CreateformController',
                            controllerAs: 'CreateformCtrl',

                        })
                    .state(
                        'paperworks.studentForm', {
                            url: "/studentform",
                            title: "StudentForm",
                            data: {
                                tab: 'studentForm'
                            },
                            params: {
                                sid: ''
                            },
                            templateUrl: "/app/partials/paperworks/studentform.html",
                            controller: 'StudentformController',
                            controllerAs: 'StudentformCtrl'
                        })
                    .state(
                        'paperworks.teacherForm', {
                            url: "/teacherform",
                            title: "TeacherForm",
                            data: {
                                tab: 'teacherForm'
                            },
                            templateUrl: "/app/partials/paperworks/teacherform.html",
                            controller: 'TeacherformController',
                            controllerAs: 'TeacherformCtrl',
                        })
                    .state(
                        'academicIntervention', {
                            parent: 'base',
                            url: "/paperworks/academicintervention/:studentId?/:reason?/:type",
                            title: "AcademicIntervention",
                            params: {
                                viewtype: 'edit',
                                paperworkfromClass:false
                            },
                            templateUrl: "/app/partials/paperworks/academic-intervention.html",
                            controller: 'AcademicInterventionController',
                            controllerAs: 'AcademicInterventionCtrl'

                        })
                    /*.state(
										'student_academicIntervention',
										{
											url : "/paperworks/academicintervention/:studentId",
											title : "AcademicIntervention",
											templateUrl : "/app/partials/paperworks/academic-intervention.html",
											controller : 'AcademicInterventionController',
											controllerAs : 'AcademicInterventionCtrl'

										})*/
                    .state('behaviorIntervention', {
                        parent: 'base',
                        url: "/paperworks/behaviorintervention/:studentId?/:reason?/:type",
                        title: "BehaviorIntervention",
                        params: {
                            viewtype: 'edit',
                            paperworkfromClass:false
                        },
                        templateUrl: "/app/partials/paperworks/behavior-intervention.html",
                        controller: "BehaviorInterventionController",
                        controllerAs: "BehaviorInterventionCtrl"

                    })
                    /*.state('student_behaviorIntervention',
										{
											url : "/paperworks/behaviorintervention/:studentId",
											title : "BehaviorIntervention",
											templateUrl : "/app/partials/paperworks/behavior-intervention.html",
											controller :"BehaviorInterventionController",
											controllerAs : "BehaviorInterventionCtrl"

										})		*/

                    .state('academicIntervention-sst', {
                        parent: 'base',
                        url: "/paperworks/academicInterventionsst/:studentId?/:reason?/:type",
                        title: "BehaviorInterventionsst",
                        params: {
                            viewtype: 'edit',
                            paperworkfromClass:false
                        },
                        templateUrl: "/app/partials/paperworks/academic-intervention-sst.html",
                        controller: "AcademicInterventionSstController",
                        controllerAs: "AcademicInterventionSstCtrl"

                    })
                    /*.state('student_academicIntervention-sst',
                    				{
                    			url: "/paperworks/academicInterventionsst/:studentId",
                    			title : "BehaviorInterventionsst",
                    			templateUrl: "/app/partials/paperworks/academic-intervention-sst.html",
                    			controller :"AcademicInterventionSstController",
                    			controllerAs : "AcademicInterventionSstCtrl"

                    			})	*/
                    .state('behaviorIntervention-sst', {
                        parent: 'base',
                        url: "/paperworks/behaviorinterventionsst/:studentId?/:reason?/:type",
                        title: "BehaviorInterventionsst",
                        params: {
                            viewtype: 'edit',
                            paperworkfromClass:false
                        },
                        templateUrl: "/app/partials/paperworks/behavior-intervention-sst.html",
                        controller: "BehaviorInterventionSstController",
                        controllerAs: "BehaviorInterventionSstCtrl"

                    })
                    /*.state('student_behaviorIntervention-sst',
                    				{
                    			url: "/paperworks/behaviorinterventionsst/:studentId",
                    			title : "BehaviorInterventionsst",
                    			templateUrl: "/app/partials/paperworks/behavior-intervention-sst.html",
                    			controller :"BehaviorInterventionSstController",
                    			controllerAs : "BehaviorInterventionSstCtrl"

                    			})*/
                    .state(
                        'paperworkforms', {
                            parent: 'base',
                            abstract: true,
                            url: "/paperworks/:formtype/:type",
                            templateUrl: "/app/partials/paperworkforms/paperworkforms-template.html"
                        })
                    .state(
                        'paperworkforms.letterform', {
                            url: "/letter/:studentId?",
                            title: "letterform",
                            params: {
                                formId: null,
                                unsaveform:false,
                                paperworkfromClass:false
                            },
                            views: {

                                "": {
                                    templateUrl: "/app/partials/paperworkforms/letterform.html",
                                    controller: 'LetterformController',
                                    controllerAs: 'LetterformCtrl',

                                    resolve: {
                                        students: function(
                                            studentsService) {
                                            return studentsService
                                                .getallstudentbyUser('/api/students/getstudentbyuser');
                                        },

                                        userperiods: function(
                                            ClassesService) {
                                            return ClassesService
                                                .getUserCreatedPeriod('/api/classes/usercreatedperiod');
                                        }
                                    }
                                },
                                "welcome@paperworkforms.letterform": {
                                    templateUrl: "/app/partials/paperworkforms/letters/welcome_letter.html"
                                },
                                "failure@paperworkforms.letterform": {
                                    templateUrl: "/app/partials/paperworkforms/letters/failure_letter.html"
                                },
                                "deficiency@paperworkforms.letterform": {
                                    templateUrl: "/app/partials/paperworkforms/letters/deficiency_letter.html"
                                },
                                "positive@paperworkforms.letterform": {
                                    templateUrl: "/app/partials/paperworkforms/letters/positive_letter.html"
                                },
                                "caughtgood@paperworkforms.letterform": {
                                    templateUrl: "/app/partials/paperworkforms/letters/caughtgood_letter.html"
                                },
                                "promotednext@paperworkforms.letterform": {
                                    templateUrl: "/app/partials/paperworkforms/letters/promotednext_letter.html"
                                },
                                "substituteinstruction@paperworkforms.letterform": {
                                    templateUrl: "/app/partials/paperworkforms/letters/substituteinstruction_letter.html"
                                },
                                "fieldtrip@paperworkforms.letterform": {
                                    templateUrl: "/app/partials/paperworkforms/letters/fieldtrip_letter.html"
                                },
                                "detention@paperworkforms.letterform": {
                                    templateUrl: "/app/partials/paperworkforms/letters/detention_letter.html"
                                },
                                "conference@paperworkforms.letterform": {
                                    templateUrl: "/app/partials/paperworkforms/letters/conferenceinvite_letter.html"
                                }

                            }
                        })
                    .state(
                        'paperworkforms.referralform', {

                            url: "/referral/:studentId?",
                            title: "Referral Form",
                            params: {
                                formId: null,
                                paperworkfromClass:false
                            },
                            views: {
                                "": {
                                    templateUrl: "/app/partials/paperworkforms/referralform.html",
                                    controller: 'ReferralformController',
                                    controllerAs: 'ReferralformCtrl',
                                    resolve: {
                                        students: function(
                                            studentsService) {
                                            return studentsService
                                                .getallstudentbyUser('/api/students/getstudentbyuser');
                                        },

                                        userperiods: function(
                                            ClassesService) {
                                            return ClassesService
                                                .getUserCreatedPeriod('/api/classes/usercreatedperiod');
                                        }
                                    }
                                },
                                "attendance@paperworkforms.referralform": {
                                    templateUrl: "/app/partials/paperworkforms/referrals/attendance_ref.html"
                                },
                                "socialwork@paperworkforms.referralform": {
                                    templateUrl: "/app/partials/paperworkforms/referrals/socialwork_ref.html"

                                },
                                "behavioral@paperworkforms.referralform": {
                                    templateUrl: "/app/partials/paperworkforms/referrals/behavior_ref.html"

                                },
                                "academic@paperworkforms.referralform": {
                                    templateUrl: "/app/partials/paperworkforms/referrals/academic_ref.html"

                                }
                            }

                        });
                $urlRouterProvider.otherwise("/login");
                //$urlRouterProvider.otherwise("/base/dashboard/main");
                //$locationProvider.html5Mode(true);
            });

})();