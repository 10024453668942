(function() {
	'use strict';

	angular.module('app.core').service('UploadService', UploadService);

	function UploadService($http) {

		var vm = this;

		vm.uploadFileToUrl = uploadFileToUrl;
		vm.downloadteplate = downloadteplate;

		function uploadFileToUrl(file, uploadUrl) {
			var fd = new FormData();
			fd.append('file', file);
		return 	 $http.post(uploadUrl, fd, {
                 transformRequest: angular.identity,
                 headers: {'Content-Type': undefined}
              })
           
              .then(function(data){
            	  return data;
              },function(){
              	return false;
              });
           
              

		}
		
		function downloadteplate(url){
			return $http.get(url).then(function(response){
				return response.data;
				
			});
		}
	}

})();