(function () {
  'use strict';

  angular
    .module('app')
    .service('subjectsService', subjectsService);

  /* @ngInject */
  function subjectsService($resource) {
    /* jshint validthis: true */
    var vm = this;

    var Subject = $resource('/api/subjects/:subjectId');

    vm.all = all;

    ///////////

    function all() {
      return Subject.query().$promise;
    }
  }
})();