(function () {
  'use strict';

  angular
    .module('app')
    .service('privateMessagesService', privateMessagesService);

  /* @ngInject */
  function privateMessagesService($http) {
    /* jshint validthis: true */
    var vm = this;

    vm.send = send;

    //////////////////

    function send(data) {
      return $http.post('/api/private-messages', data).then(function (response) {
        return response.data;
      });
    }
  }
})();