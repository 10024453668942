(function () {
  'use strict';

  angular
    .module('app.address-book')
    .filter('vestedMemberPosition', vestedMemberPosition);

  /* @ngInject */
  function vestedMemberPosition($filter) {
    return function (id, vestedMemberTypes) {
      let vestedMemberType = $filter('filter')(vestedMemberTypes, {id: id}, true);

      return vestedMemberType.length ? vestedMemberType[0].title : '';
    };
  }

})();