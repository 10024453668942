(function() {
    'use strict';

    angular
        .module('app.paperworks')
        .controller('PaperworkModalController', PaperworkModalController);

    function PaperworkModalController($scope, student, type, $uibModalInstance, paperworksService, helperService) {
        var vm = this;
        $scope.showbutton = 0;
        /*  assign properties*/
        vm.student = student;
        vm.monitors = [];
        vm.aip_interventions = [];
        vm.option = option;
        vm.aip = [];
        vm.date = new Date();




        /* assign methods*/
        vm.cancel = cancel;
        vm.getApiformdata = getApiformdata;
        vm.save = save;


        getApiformdata();



        function cancel() {
            $uibModalInstance.dismiss();
        }


        function getApiformdata() {
            var student_id = vm.student.id;
            paperworksService.getpaperworkAipIntervention('api/paperwork/' + student_id + '/aip?rti_type=' + type).then(function(response) {
                vm.monitors = response.ip_monitoring;
                vm.aip_interventions = response.ip_interventions;
            });
        }


        function save(formData) {

            paperworksService.saveActivity('api/paperwork/aip/store-monitor', formData).then(function(response) {
                if (response)
                    helperService.showAlert('The ' + type + ' information has saved.');
                $uibModalInstance.close();
            });
        }

        function option(data) {
            var arr = [];
            var size = Object.keys(data).length;
            for (var i = 1; i <= size; i++) {
                arr.push(data[i]);
            }

            return arr.toString();
        }

    }
})();