(function () {
  'use strict';

  angular
    .module('app.address-book')
    .controller('MessageController', MessageController);

  /* @ngInject */
  function MessageController($scope, $rootScope, $filter, $uibModalInstance, type, student, cannedTypes, cannedCategories,
                             callStatuses, cannedMessagesService, active,referralService, selectedPeriod, $interval, $timeout, messagesService, toastr) {
    /* jshint validthis: true */
    var vm = this;

    vm.request = false;

    vm.student = student;
    vm.types = cannedTypes;
    vm.categories = cannedCategories;
    vm.callStatuses = angular.copy(callStatuses);
    vm.cannedMessages = [];
    vm.isActiveByDefault = false;
    vm.callSid = '';

    vm.to = '';
    
    vm.phones = [];
    vm.emails = [];
    vm.dialnumber = '';
    vm.endCallbtn = true;
    vm.redialbtn = false;
    vm.settimer = '';
    vm.userBalance = '';
    vm.openCallModal = false;
    
    vm.getphonenumber = getphonenumber;
    vm.getEmail = getEmail;
    vm.phoneCall = phoneCall;
    
    
    vm.getUserBalance = getUserBalance;
    vm.checkStatus = checkStatus;

    vm.disconnect = disconnect;
    
    getphonenumber();
    getEmail();
    getUserBalance();


    //console.log(vm.student);
    	
    function getphonenumber(){
    	if(student.g1homenumber ){
    		vm.phones.push({id: 1,
            guardian_name: student.g1fullname,
            guardian_relationship: student.g1relationship,
            guardian_number: student.g1homenumber,
            guardian_number_type: 'Home Number'});
    	}
    	if(student.g1worknumber ){
    		vm.phones.push({id: 2,
                guardian_name: student.g1fullname,
                guardian_relationship: student.g1relationship,
                guardian_number: student.g1worknumber,
                guardian_number_type: 'Work Number'});
    	}
    	
    	if(student.g1mobilenumber){
    		vm.phones.push({ id: 3,
                guardian_name: student.g1fullname,
                guardian_relationship: student.g1relationship,
                guardian_number: student.g1mobilenumber,
                guardian_number_type: 'Mobile Number'});
    	}
    	
    	if(student.g2homenumber){
    		vm.phones.push({ id: 4,
                guardian_name: student.g2fullname,
                guardian_relationship: student.g2relationship,
                guardian_number: student.g2homenumber,
                guardian_number_type: 'Home Number'});
    	}
    	
    	if(student.g2worknumber){
    		vm.phones.push({id: 5,
                guardian_name: student.g2fullname,
                guardian_relationship: student.g2relationship,
                guardian_number: student.g2worknumber,
                guardian_number_type: 'Work Number'});
    	}
    	
    	if(student.g2mobilenumber){
    		vm.phones.push({ id: 6,
                guardian_name: student.g2fullname,
                guardian_relationship: student.g2relationship,
                guardian_number: student.g2mobilenumber,
                guardian_number_type: 'Mobile Number'});
    	}    	  
    
    }

    function getEmail(){
    	if(student.g1email){
    		vm.emails.push({id: 1,
    	        guardian_name: student.g1fullname,
    	        guardian_relationship: student.g1relationship,
    	        guardian_email: student.g1email});
    	}
    	
    	if(student.g2email){
    		vm.emails.push({
    			id: 2,
    	        guardian_name: student.g2fullname,
    	        guardian_relationship: student.g2relationship,
    	        guardian_email: student.g2email
    		});
    	}
    	
    }
    
  /*  vm.emails = [
      {
        id: 1,
        guardian_name: student.g1fullname,
        guardian_relationship: student.g1relationship,
        guardian_email: student.g1email
      },
      {
        id: 2,
        guardian_name: student.g2fullname,
        guardian_relationship: student.g2relationship,
        guardian_email: student.g2email
      },
    ];
*/
    vm.form = {
      mode: type,

      student_id: student.id,

      to: undefined,

      guardian_name: '',
      guardian_relationship: '',
      guardian_number: undefined,
      guardian_email: undefined,

      topic_id: undefined,
      category_id: undefined,
      type_id: undefined,
      canned_message_id: undefined,

      message: '',

      phone_id: undefined,
      email_id: undefined,
      selectedPeriod: selectedPeriod,

      call_status_id: undefined,
      additional_call_notes: undefined,
      
    };
    
    vm.refferalform = {
    	student_id: student.id,
    	user_id:'',
    	student_name : student.fullname,
    	student_gender: student.gender,
    	classes:student.classes,
    	reffered_by:'' ,   	
    	priority:'',
    	reason:'',
    	other_reason:'',
    	other_action:'',
    	description:'',
    	action:[],
    	sendto:[],
    	attendance_record:'',
    	behavior_record:''
    		
    };

    vm.send = send;
    vm.cancel = cancel;
    vm.fetchCannedMessages = fetchCannedMessages;
    vm.showPhone = showPhone;
    vm.showEmail = showEmail;
    
    vm.referralsend = referralsend;
   // vm.bootstrapModal = bootstrapModal;
    
   // bootstrapModal();

    $scope.$watch('MessageCtrl.form.canned_message_id', setTextArea);
    $scope.$watch('MessageCtrl.form.category_id', fetchTopics);
    $scope.$watch('MessageCtrl.form.type_id', fetchTopics);
    $scope.$watch('MessageCtrl.form.phone_id', changePhone);

    if (active) {
      setDefault();
    }

    ////////////////////////////////

    function fetchTopics() {
      if (!(vm.form.category_id && vm.form.type_id)) {
        return;
      }

      vm.request = true;

      cannedMessagesService.topics(vm.form.category_id, vm.form.type_id).then(function (topics) {
        vm.topics = topics;
      }).finally(function () {
        vm.request = false;
      });
    }

    function changePhone(newval, oldval) {
      var to = $filter('filter')(vm.phones, {id: vm.form.phone_id});
     
      if(newval){

      vm.dialnumber = to[0].guardian_number;
      }
      vm.callStatuses = angular.copy(callStatuses);

      angular.forEach(vm.callStatuses, function (obj) {
        obj.title = obj.title.replace(':guardian', to[0].guardian_name + '(' + $filter('guardian')(to[0].guardian_relationship) + ')');
      });
    }


    function setDefault() {
      var activePhone = $filter('filter')(vm.phones, {guardian_number: active});
      if (activePhone.length) {
        vm.form.phone_id = activePhone[0].id;
        vm.isActiveByDefault = true;
      }

      var activeEmail = $filter('filter')(vm.emails, {guardian_email: active});
      if (activeEmail.length) {
        vm.form.email_id = activeEmail[0].id;
        vm.isActiveByDefault = true;
      }
    }

    function setTextArea() {
    
      var cannedMessage = $filter('filter')(vm.cannedMessages, {id: vm.form.canned_message_id});
      if (cannedMessage.length) {
        vm.form.message = cannedMessage[0].message;
      }
    }

    function showPhone(phone) {
    	
    	// vm.dialnumber = phone.guardian_number;
    	var relation = $filter('guardian')(phone.guardian_relationship) ? ' (' + $filter('guardian')(phone.guardian_relationship) + ')': '';
      return phone.guardian_number + ' (' + phone.guardian_number_type + ') ' + phone.guardian_name + relation;
        
    
    }

    function showEmail(email) {
    	var relation = $filter('guardian')(email.guardian_relationship) ? ' (' + $filter('guardian')(email.guardian_relationship) + ')': '';
      return email.guardian_email + ' ' + email.guardian_name + relation ;
    }

    function setGuardian() {
      if (vm.form.phone_id) {
        var phone = $filter('filter')(vm.phones, {id: vm.form.phone_id});

        if (phone.length) {
          phone = phone[0];

          vm.form.guardian_full_name = phone.guardian_name;
          vm.form.guardian_relationship = phone.guardian_relationship;
          vm.form.guardian_number = phone.guardian_number;
        }
      }

      if (vm.form.email_id) {
        var email = $filter('filter')(vm.emails, {id: vm.form.email_id});

        if (email.length) {
          email = email[0];

          vm.form.guardian_full_name = email.guardian_name;
          vm.form.guardian_relationship = email.guardian_relationship;
          vm.form.guardian_email = email.guardian_email;
        }
      }
    }

    function send() {
    	$rootScope.modalerror=true;
    	
      setGuardian();

      cannedMessagesService.create(vm.form).then(function (response) {
       
    	  $rootScope.modalerror = false;
        $uibModalInstance.close({type_id: vm.form.type_id});
      });
    }

    function fetchCannedMessages() {
      if (!(vm.form.topic_id && vm.form.category_id && vm.form.type_id)) {
        return;
      }

      vm.request = true;

      cannedMessagesService.messages({
        topic_id: vm.form.topic_id,
        category_id: vm.form.category_id,
        type_id: vm.form.type_id,
      })
        .then(function (cannedMessages) {

          vm.cannedMessages = cannedMessages;

        })
        .finally(function () {
          vm.request = false;
        });
    }
    
    function cancel() {
      $uibModalInstance.dismiss();
    }
    var promise;
    var twilio = null;
    var twilio_sid;
    
    Twilio.Device.disconnect(function (conn) {
        // Called for all disconnections
        //$('#callModal').modal('hide');
        vm.endCallbtn = false;
        vm.redialbtn = true;
        //Twilio.Device.destroy();
        //service call to get call available count from log table
        twilio = null;
        getUserBalance();

    });
    
    Twilio.Device.error(function (error) {
        //$('#callModal').modal('hide');
        //Twilio.Device.destroy();
        //twilio = null;
        vm.endCallbtn = false;
        vm.redialbtn = true;
    });
    
    Twilio.Device.connect(function (conn) {
       /* $("#callModal").modal({
                  backdrop: 'static',
                  keyboard: false,
                  show: true
              });     */
    	 toastr.success('Thank you.  We are now connected to your speaker and microphone.');
    	  vm.openCallModal = true;
          vm.endCallbtn = true;
          vm.redialbtn = false;
          twilio_sid = conn.parameters.CallSid;  
          var addcallTrack = {'user_id':$rootScope.user.id, 'call_sid':twilio_sid};
          cannedMessagesService.checkCallduration('/api/add-user-call-track', addcallTrack).then(function(response){
          });
          
    });
    
    
    
    function checkStatus(sid)
    {
        promise = $interval(function() {
    		 cannedMessagesService.callPhoneNo('/api/call-status',{'callSid':sid}).then(function(response){
           });
    	}, 1000);
        
    }
    
    function phoneCall(callvalue){
        if(vm.dialnumber){
            messagesService.getTwilioToken().then(function(response){
                Twilio.Device.setup(response,{ debug: true });
                var remainingCall = parseInt(vm.userBalance.call_balance - vm.userBalance.sended_call) * 60;
                var params = {"tocall":vm.dialnumber, "timeLimit": remainingCall}; //10 in seconds
                twilio = Twilio.Device.connect(params);
            });
        }   
    }
    
    
    function disconnect(){
        Twilio.Device.disconnectAll();        
    }
    
    vm.closeCall = function() {
    	vm.openCallModal = false;
        Twilio.Device.disconnectAll();
        
    };
    
    function referralsend(){
    	
    	referralService.create(vm.refferalform).then(function(response){
    		  $uibModalInstance.close({type_id: vm.form.type_id});
    	});
    	
    }
    
    function getUserBalance(){
    	messagesService.getUserBalance('/api/email-sms-call-balance').then(function(response){
    		vm.userBalance = response;   		
    	});
    }
    

  }
})();