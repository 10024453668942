(function () {
  'use strict';

  angular
    .module('app')
    .service('teachersService', teachersService);

  /* @ngInject */
  function teachersService($resource) {
    /* jshint validthis: true */
    let vm = this;

    let Teacher = $resource('/api/school/teachers');

    vm.all = all;

    /////////////////

    function all() {
      return Teacher.query().$promise;
    }
  }
})();