(function () {
  'use strict';

  angular
    .module('app.behavior-analytics.quickview')
    .controller('BehaviorQuickviewController', BehaviorQuickviewController);

  /* @ngInject */
  function BehaviorQuickviewController($scope, teacherTypes, grades, periods, behaviorAnalyticsService) {
    /* jshint validthis: true */
    let vm = this;

    vm.periods = periods;
    vm.total = 0;
    vm.options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: true,
          autoSkip: true,
          autoSkipPadding: 100,
        }],
        yAxes: [{
          stacked: true,
          ticks: {
            beginAtZero:true,
            callback: (value) => {
              if (value % 1 === 0) {
                return value;
              }
            }
          }
        }]
      }
    };

    vm.types = [
      {
        text: 'Subject',
        values: teacherTypes,
        property: 'subject',
      },
      {
        text: 'Grade',
        values: grades,
        property: 'grade',
      }
    ];

    vm.filters = {};
    Object.defineProperties(vm.filters, {
      _type: {
        value: vm.types[0],
        writable: true,
        enumerable: false,
      },
      type: {
        enumerable: true,
        get: function () {
          return this._type.property;
        }
      },
        level: {
            enumerable: false,
            get: function () {
                if (this.type === 'grade') return 'Level';
                else return '';
            },
        },
        th_students: {
            enumerable: false,
            get: function () {
                if (this.type === 'grade'){
                  if(this._item.title === 'All Grades')
                  { return ' students'; }
                  else { return 'th grade students'; }

                }
                else { return ''; }
            },
        },
      _item: {
        enumerable: false,
        get: function () {
          if (this.type === 'subject') return this._type.values.filter((item) => {
            return item.id === this._teacher_type_id;
          })[0];
          if (this.type === 'grade') return this._type.values.filter((item) => {
            return item.id === this._grade_id;
          })[0];
        }
      },
      _teacher_type_id: {
        enumerable: false,
        value: undefined,
        writable: true,
      },
      teacher_type_id: {
        enumerable: true,
        get: function () {
          if (this.type === 'subject') return this._teacher_type_id;
        },
      },
      _grade_id: {
        enumerable: false,
        value: undefined,
        writable: true,
      },
      grade_id: {
        enumerable: true,
        get: function () {
          if (this.type === 'grade') return this._grade_id;
        },
      },
      _period: {
        value: vm.periods[3],
        writable: true,
        enumerable: false,
      },
      period_start: {
        enumerable: true,
        get: function () {
          return this._period.start.format('D-M-Y');
        },
      },
      period_end: {
        enumerable: true,
        get: function () {
          return this._period.end.format('D-M-Y');
        },
      },
      period_slug: {
        enumerable: true,
        get: function () {
          return this._period.slug;
        },
      },
    });

    vm.charts = {
      byPeriod: {
        data: [],
        labels: [],
        series: [],
      },
      byCategories: {
        data: [],
        labels: []
      },
      byType: {
        data: [],
        labels: [],
      }
    };

    vm.getStatistics = getStatistics;

    $scope.$watchCollection('BehaviorQuickviewCtrl.filters', function () {

        getStatistics();
    });

    /////////////////

    function getStatistics() {
      behaviorAnalyticsService.quickview(vm.filters).then(function (response) {

          vm.charts.byCategories.data = response.byCategory.map((item) => {
          return item.count;
        });
        vm.charts.byCategories.labels = response.byCategory.map((item) => {
          return item.name;
        });

        vm.charts.byType.data = response.byType.map((item) => {
          return item.count;
        });
        vm.charts.byType.labels = response.byType.map((item) => {
            return item.name +' : '+ Math.round((item.count / response.total) * 100, 2) + '% ';
        });

        vm.charts.byPeriod.labels = response.byPeriod.labels;
        vm.charts.byPeriod.series = response.byPeriod.series;
        vm.charts.byPeriod.data = response.byPeriod.data;

        vm.total = response.total;
      });
    }
  }
})();