(function () {
  'use strict';

  angular
    .module('app.core')
    .service('attendancesService', attendancesService);

  function attendancesService( $http) {
    /* jshint validthis: true */
    var vm = this;

    vm.get = get;
    vm.getCurrent = getCurrent;
    vm.submitAttendance = submitAttendance;
    
    function get(studentId){
    	return $http.get('/api/students/'+ studentId +'/attendances').then(fetchData);
    	
    }
    
    function fetchData(response){
    	return response.data;
    	
    }
    
    function getCurrent(period){
    	return $http.get('/api/students/attendance/current?period=' + period).then(fetchData);    	
    }
    
    function submitAttendance(data){
        return $http.post('api/students/attendance', data).then(fetchData);
    }

    
  }
})();