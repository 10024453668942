(function () {
  'use strict';

  angular
    .module('app.address-book')
    .controller('CreateVestedMemberController', CreateVestedMemberController);

  /* @ngInject */
  function CreateVestedMemberController($uibModalInstance, vestedMembersService, vestedMemberTypes) {
    /* jshint validthis: true */
    var vm = this;

    vm.vestedMember = {
      fullname: '',
      email: '',
      role: '',
      officenumber: '',
      mobilenumber: '',
      homenumber: '',
    };

    vm.vestedMemberTypes = vestedMemberTypes;

    vm.create = create;
    vm.cancel = cancel;

    //////////////////

    function create() {
      vestedMembersService.create(vm.vestedMember).then(function (vestedMember) {
        $uibModalInstance.close(vestedMember);
      });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

  }
})();