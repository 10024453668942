(function() {
    'use strict';

    angular
        .module('app.classes')
        .controller('ConfimationShareClassroomSignupLinkController', ConfimationShareClassroomSignupLinkController);

    /* @ngInject */
    function ConfimationShareClassroomSignupLinkController($state,helperService,SweetAlert,FormData,
      $uibModalInstance,$uibModal,$rootScope,$filter,ClassesService,toastr) {
        /* jshint validthis: true */
        var vm = this;

        vm.cancel=cancel;
        vm.submit=submit;
        vm.submit_backup=submit_backup;
        vm.FormData=FormData;
        vm.fullperiodname=vm.FormData.period+" "+vm.FormData.period_letter;
        vm.QrGeneralCode='';
        vm.QrAccessCode='';
        vm.QrImage='';
        vm.base_url='';
        vm.ResponseData='';

        vm.Text_1='';
        vm.Text_2='';
        if(vm.FormData.group_type==='1')
        {
            vm.Text_1='Period';
            vm.Text_2='classroom';
        }
        else if(vm.FormData.group_type==='2')
        {
            vm.Text_1='Club';
            vm.Text_2='club';
        }

        function submit_backup()
        {

            ClassesService.getBarCode('api/classes/accesscode').then(function(data) {
            vm.ResponseData=data;
            vm.QrGeneralCode=data.generalAccessCode;
            vm.QrAccessCode=data.QrAccessCode;
            vm.QrImage=data.qrImageName;
            vm.base_url=data.base_url;
            //vm.QrImage=vm.QrImage.substr(1);

            vm.FormData.start_time = $filter('date')(vm.FormData.start_time, 'HH:mm:s');
            vm.FormData.end_time = $filter('date')(vm.FormData.end_time, 'HH:mm:s');
            vm.FormData.qr_code=data.QrAccessCode;
            vm.FormData.class_access_code=data.generalAccessCode;
            vm.FormData.qr_image_name=data.qrImageName;

            //console.log(data);
            var urlparemeter = 'api/classes/addewnclass';
            ClassesService.create(urlparemeter, vm.FormData).then(function(response) {
            $uibModalInstance.close();
            var formtype = (vm.FormData.group_type == 1 ? 'class' : 'club');
            helperService.showAlert('You have Successfully creted ' + vm.FormData.name + " " + formtype);
              // open pdf as preview
                var childWindow="";
                var winName = 'MyWindow';
                var winURL = 'api/classes/preview';
                var windowoption = 'resizable=yes,height=600,width=800,location=0,menubar=0,scrollbars=1';
                var params = {
                    'class_access_code': vm.FormData.class_access_code,
                };

                var form = document.createElement("form");
                form.setAttribute("method", "post");
                form.setAttribute("action", winURL);
                form.setAttribute("target", winName);
                for (var i in params) {
                    if (params.hasOwnProperty(i)) {
                        var input = document.createElement('input');
                        input.type = 'hidden';
                        input.name = i;
                        input.value = params[i];
                        form.appendChild(input);
                    }
                }
                document.body.appendChild(form);
                childWindow = window.open('', winName, windowoption);
                form.target = winName;
                form.submit();
                document.body.removeChild(form);
                //$state.reload();
                 // confirmation msg
                
                
                $rootScope.user.has_class = true;
                        
                SweetAlert.confirm("Would you like to add another " + formtype + " ?", {
                    title: "Confirm!",
                    cancelButtonText: "No,plx don't cancel",
                    confirmButtonText: " Yes, I want to cancel",    
                })
                .then(function(isConfirm) {
                    if (isConfirm) 
                    {
                        vm.FormData="";
                        $state.go('dashboard.main');

                    }
                    else 
                    {

                        $state.reload();
                        //$state.go('addnewclass',{'id': 0,'redirect_type':1});
                    }
                }, function(p) {


                });

            
                
            });

        });


        }

        function submit()
        {
             $uibModalInstance.close();
             return $uibModal.open({
              templateUrl: '/app/partials/classes/share_classroom_signup_link.html',
              controller: 'ShareClassroomSignupLinkController',
              controllerAs: 'ShareClassroomSignupLinkCtrl',
              windowClass : "custom_modal2",
              size: 'md',
              resolve: {
                  FormData: function () {
                    return FormData;
                  } 
                }
              }).result.then(function () {

                $uibModalInstance.close();

              },function(){

                var formtype = (vm.FormData.group_type == 1 ? 'class' : 'club');
                helperService.showAlert('You have Successfully creted ' + vm.FormData.name + " " + formtype);
                $rootScope.user.has_class = true;
                        
                SweetAlert.confirm("Would you like to add another " + formtype + " ?", {
                    title: "Confirm!",
                    cancelButtonText: "No,plx don't cancel",
                    confirmButtonText: " Yes, I want to cancel",    
                })
                .then(function(isConfirm) {
                    if (isConfirm) 
                    {
                        vm.FormData="";
                        $state.go('dashboard.main');

                    }
                    else 
                    {

                        $state.reload();
                        //$state.go('addnewclass',{'id': 0,'redirect_type':1});
                    }
                }, function(p) {


                });

            


              });

        }

       
        function cancel()
        {
            $uibModalInstance.close();
        }

        

    }
})();