(function() {
    'use strict';

    angular
        .module('app')
        .run(run);

    function run($rootScope, $state, $injector, authService, toastr, usSpinnerService, adminsService, cannedMessagesService, vestedMembersService, $timeout, $urlRouter, statesWithoutAuthenticated, $auth, ClassesService) {
        
        $rootScope.reportsChange = function(stateParameter) {
            if ($state.current.name != stateParameter) {
                $timeout(function() {
                    //usSpinnerService.spin('spinner-1');
                }, 20);
            }

            $state.go(stateParameter);
        };
        

        
        $rootScope.removeBackground = function(){
        	 $("body,html").removeClass("setup-account-layout");
        };
        
        $rootScope.loggedinCSS = ['style', 'bootstrap.vertical-tabs', 'pages/studentinfo', 'pages/forms', 'pages/modal-referrals', 'style-auto', 'pages/wizard', 'pages/newclassperiod'];
        $rootScope.loginCSS = ['custom.min'];
        
        $rootScope.breadcrumb = [];
        $rootScope.today_date = new Date();
        $rootScope.today = moment();
        $rootScope.$state = $state;
        $rootScope.isSingleSubscription = authService.isSingleSubscription;
        $rootScope.logout = function(sendRequest) {
        	//$rootScope.outsitedesign = true;
            authService.logout(sendRequest).then(function() {
            	$("body,html").removeClass("setup-account-layout");
            	$rootScope.outsitedesign = true;
                toastr.success('Logout', 'You have successfuly loged out.');
            });
        };
        $rootScope.Isfromattendance = false;
        // Using in login form for hiding the form before user will not redirect to his authorized page
        $rootScope.isAuthenticated = $auth.isAuthenticated;
        $rootScope.db_class_list = [];
        $rootScope.db_club_list = [];
        $rootScope.isClass = false;
        if ($auth.isAuthenticated()) {
            //authService.setUser();
            authService.currentSessionId();

            // ToDo: change it to promise or change it globally by project
            if (authService.completedRegistration()) {
                adminsService.all().then(function(response) {
                    $rootScope.admins = response;
                });

                vestedMembersService.all().then(function(response) {
                    $rootScope.vestedMembers = response;
                });

            }

            ClassesService.getclassesData('api/classes').then(function(data) {

                if (data.class_period !== null) {
                    angular.forEach(data.class_period, function(class_period) {
                        $rootScope.isClass = true;
                        if (class_period.group_type === '1')
                            $rootScope.db_class_list.push({
                                id: class_period.id,
                                name: class_period.name,
                                period: class_period.period,
                                period_letter: class_period.period_letter
                            });
                        else
                            $rootScope.db_club_list.push({
                                id: class_period.id,
                                name: class_period.name
                            });
                    });
                }

            });
        }



        $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
        	$rootScope.outsitedesign = false;
        	if(toState.name === 'login' || toState.name === 'registration'){
        		if ($auth.isAuthenticated()) {
                    $state.go('dashboard.main');
                }
                $rootScope.outsitedesign = true;
        	}
        	
            //runSpin();
            if ($auth.isAuthenticated() && !authService.isAuthorized()) {
                if (fromState.name !== '') {
                    preventChangeState(event);
                   
                }

                if (!authService.isRequestingMe()) {
                    authService.me().then(function(user) {
                        if ((statesWithoutAuthenticated.indexOf(toState.name) !== -1)) {

                            if ($rootScope.currentState.current.name === 'resetpassword')
                            {
                                runSpin();
                                $state.go('resetpassword');
                            }
                            else if ($rootScope.currentState.current.name === 'login' || $rootScope.currentState.current.name === '') {
                                runSpin();
                                $state.go('dashboard.main');
                                
                            } else {
                                runSpin();
                                authService.defaultRedirect();
                            }
                        } else {
                            $urlRouter.sync();
                        }
                    });
                }
            } else if ($auth.isAuthenticated() && authService.isAuthorized() && !authService.isActivated() && toState.name !== 'setup-account' && toState.name !== 'activation') {
                preventChangeState(event);
                runSpin();
                $state.go('setup-account');
            } else if ($auth.isAuthenticated() && authService.isAuthorized() && authService.isActivated() && toState.name === 'setup-account') {
                preventChangeState(event);
                runSpin();
                authService.defaultRedirect();
            } else if (!$auth.isAuthenticated() && (statesWithoutAuthenticated.indexOf(toState.name) === -1)) {
                toastr.error('You not authenticated for this action', 'Authentication Error');

                preventChangeState(event);

                authService.logout(false);
            } else if ($auth.isAuthenticated() && (statesWithoutAuthenticated.indexOf(toState.name) !== -1)) {
                preventChangeState(event);
                runSpin();
                authService.defaultRedirect();
            }
            	
            if($auth.isAuthenticated()){
            	$rootScope.Isfromattendance = false;
            	if($rootScope.user.check_subscription === 0){
            		//alert(toState.name);
            		if(toState.name !== 'dashboard.setting'){
            		 authService.logout();
            		}
            	}
            }
            /*if($rootScope.user.check_subscription !== 'undefined' && $rootScope.user.check_subscription === 0){
                alert(toState.name);
            }*/
           
            $rootScope.previousState = fromState.name;
            $rootScope.ToStateName = toState.name;
            $rootScope.currentState = $state;
            var prev = $rootScope.previousState.split(".");
            $rootScope.prevname = prev[0];

        });

        function resetBredcrumb(state) {
            switch (state.name) {
                case 'dashboard.main':
                    $rootScope.breadcrumb = [];
                    $rootScope.breadcrumb.push({
                        'state': state.name,
                        'title': state.title
                    });
                    break;
                case 'address-book':
                    $rootScope.breadcrumb = [];
                    $rootScope.breadcrumb.push({
                        'state': state.name,
                        'title': state.title
                    });
                    break;
                case 'classes.roaster':
                    $rootScope.breadcrumb = [];
                    $rootScope.breadcrumb.push({
                        'state': state.name,
                        'title': state.title
                    });
                    break;
                case 'classes.attendance':
                    $rootScope.breadcrumb = [];
                    $rootScope.breadcrumb.push({
                        'state': state.name,
                        'title': state.title
                    });
                    break;
                case 'reports.communication':
                    $rootScope.breadcrumb = [];
                    $rootScope.breadcrumb.push({
                        'state': state.name,
                        'title': state.title
                    });
                    break;
                case 'reports.attendance':
                    $rootScope.breadcrumb = [];
                    $rootScope.breadcrumb.push({
                        'state': state.name,
                        'title': state.title
                    });
                    break;
                case 'reports.behavior':
                    $rootScope.breadcrumb = [];
                    $rootScope.breadcrumb.push({
                        'state': state.name,
                        'title': state.title
                    });
                    break;

                case 'reports.paperwork':
                    $rootScope.breadcrumb = [];
                    $rootScope.breadcrumb.push({
                        'state': state.name,
                        'title': state.title
                    });
                    break;
                case 'reports.academic':
                    $rootScope.breadcrumb = [];
                    $rootScope.breadcrumb.push({
                        'state': state.name,
                        'title': state.title
                    });
                    break;
                case 'paperworks.createForm':
                    $rootScope.breadcrumb = [];
                    $rootScope.breadcrumb.push({
                        'state': state.name,
                        'title': state.title
                    });
                    break;
                default:
                    $rootScope.breadcrumb.push(state.name);
                    break;
            }
        }

        /*

         Relate with reports. Set reportsCurrentTab for rootScope. It using to reports template.
         */
        $rootScope.$on('$stateChangeSuccess', function(event, toState) {

            if ($state.includes('reports')) {
                $rootScope.reportsCurrentTab = toState.data.tab;
            }
            if ($state.includes('student')) {
                $rootScope.studentCurrentTab = toState.data.tab;
            }
            if ($state.includes('classes')) {
                $rootScope.classesCurrentTab = toState.data.tab;
            }
            if ($state.includes('paperworks')) {
                if (toState.data)
                    $rootScope.classesCurrentTab = toState.data.tab;
            }
            if (toState.name !== '') {
                resetBredcrumb(toState);
            }

            event.targetScope.$watch("$viewContentLoaded", function() {
                stopSpin();
            });
        });

        $rootScope.$on('$stateChangeError', function(event, toState) {
            console.log('Error');
            stopSpin();
        });
        $rootScope.$on('$stateNotFound', function(event, toState) {
            console.log('Not found');
            stopSpin();
        });

        $rootScope.$on('$stateChangePermissionDenied', function(event, toState, toParams, options) {
            console.log('permission denied');
            stopSpin();

            /*if (!authService.isRequestingMe()) {
             authService.defaultRedirect();
             }*/
        });

        function preventChangeState(event) {
            event.preventDefault();
            stopSpin();
        }

        function runSpin() {
            usSpinnerService.spin('spinner-1');
        }

        function stopSpin() {
           // usSpinnerService.stop('spinner-1');
        }
    }
})();