(function () {
  'use strict';

  angular
    .module('app.dashboard.main')
    .controller('NotVerifiedStudentController', NotVerifiedStudentController);

  /* @ngInject */
  function NotVerifiedStudentController(SweetAlert,$state,$uibModal,studentsService,
    classid,$scope,toastr,ClassesService,$uibModalInstance,$rootScope) {

    /* jshint validthis: true */
    var vm = this;

    vm.notVerifiedStudent='';
    vm.notVerifiedStudentCount='';
    $rootScope.NotVerifiedStudentCount='';
    vm.deleteNotVerifiedStudent=deleteNotVerifiedStudent;
    vm.RunService=RunService;
    vm.cancel = cancel;
    vm.selectedItem=[];
    vm.toggleAllselected=toggleAllselected;
    vm.optionToggled=optionToggled;
    vm.AllSelected=false;
    vm.submitVerified=submitVerified;
    vm.WarningMessage=WarningMessage;

    RunService();



    function deleteNotVerifiedStudent(student)
    {

        SweetAlert.confirm("Are you sure to delete this student?", {
            title: "Confirmation!",
            cancelButtonText: "Cancel",
            confirmButtonText: "Yes,"    
        })
        .then(function(isConfirm) {
            if (isConfirm) 
            {

             studentsService.removestudent(student).then(function() 
             {
                toastr.success('Successfully deleted');
                RunService();
                $state.reload();

                //console.log(vm.notVerifiedStudentCount);

                if(vm.notVerifiedStudentCount===1)
                {
                    $uibModalInstance.close();
                }

                     
             });
                
            }
        }, function(p) {

        });
       
        /*return $uibModal.open({
                templateUrl: '/app/partials/dashboard/delete-not-verifiedstudent-confirmation.html',
                controller: 'NoStudentSelectedController',
                controllerAs: 'NoStudentSelectedCtrl',
                size: 'md',
                resolve: {
                    NotVerifiedStudent: function() {
                        return student;
                    },
                }
                
            }).result.then(function(response) {
                
                if(response===1)
                {
                    toastr.success('Successfully deleted');
                    RunService();
                    $state.reload();
                }
                
            });*/
    }

    function WarningMessage()
    {
            return $uibModal.open({
                templateUrl: '/app/partials/dashboard/no-student-selected-message.html',
                controller: 'NoStudentSelectedController',
                controllerAs: 'NoStudentSelectedCtrl',
                size: 'md',
                resolve: {
                    NotVerifiedStudent: function() {
                        return '';
                    },
                }
                
            }).result.then(function() {
                

            });

    }

    function submitVerified()
    {
        if(vm.selectedItem.length>0)
        {
             var class_id='';
             angular.forEach(vm.selectedItem, function(student) {
                
                class_id=student.classes;
                ClassesService.updateVerified('api/updateverified',student).then(function(data) {
                
                });    


            });
            RunService(); 
            vm.AllSelected=false;
            toastr.success('Successfully updated');
            $state.reload();
            $uibModalInstance.close();

        }
        else
        {
            //toastr.error('Please select student');
            //WarningMessage();
            sweetAlert("", "Please use checkbox for verify 1 or more students!", "error");
        }
    }

    function toggleAllselected()
    {
        if(vm.AllSelected)
        {
          angular.forEach(vm.notVerifiedStudent, function(selected_task) {
            vm.selectedItem.push(selected_task);
            selected_task.selected = vm.AllSelected;
          });

        }
        else
        {
            angular.forEach(vm.notVerifiedStudent, function(selected_task) {
                var index = vm.selectedItem.indexOf(selected_task);
                vm.selectedItem.splice(index, 1);
                selected_task.selected = vm.AllSelected;

            });
        }
        

        //console.log(vm.selectedItem);
    }

    function optionToggled(id,selected,item)
    {

        if(selected)
        {
            //console.log(item);
            if (vm.selectedItem.indexOf(item) == -1)
            {
                if(item !==undefined)
                {
                  //console.log(item);
                  vm.selectedItem.push(item);
                }
            }
        }
        else
        {
             var index = vm.selectedItem.indexOf(id);
             vm.selectedItem.splice(index, 1);
        }


        //console.log(vm.selectedItem);


    }

    function RunService()
    {


        ClassesService.getclassesData('api/classes?class_id=' + classid).then(function(data) {
        if (data.class_period !== null) 
        {
            angular.forEach(data.class_period, function(class_period) 
            {
                if (class_period.group_type === '1') 
                {
                    if (class_period.id == classid) 
                    {
                        vm.notVerifiedStudent=class_period.unverified_student;
                        vm.notVerifiedStudentCount = class_period.unverified_student_count;
                    }
                } else if (class_period.group_type === '2') 
                {
                    if (class_period.id == classid) 
                    {
                        vm.notVerifiedStudent=class_period.unverified_student;
                        vm.notVerifiedStudentCount = class_period.unverified_student_count;
                    }
                }
            });
        }

        //console.log(vm.notVerifiedStudent);
    });



    }


    function cancel() {

      $uibModalInstance.close();
      
    }
    


    
  }
})();